export const BOOK_APPOINTMENT_START = 'BOOK_APPOINTMENT_START';
export const BOOK_APPOINTMENT_SUCCESS = 'BOOK_APPOINTMENT_SUCCESS';
export const BOOK_APPOINTMENT_FAIL = 'BOOK_APPOINTMENT_FAIL';

export const UPDATE_APPOINTMENT_START = 'UPDATE_APPOINTMENT_START';
export const UPDATE_APPOINTMENT_SUCCESS = 'UPDATE_APPOINTMENT_SUCCESS';
export const UPDATE_APPOINTMENT_FAIL = 'UPDATE_APPOINTMENT_FAIL';

export const FETCH_APPOINTMENTS_START = 'FETCH_APPOINTMENTS_START';
export const FETCH_APPOINTMENTS_SUCCESS = 'FETCH_APPOINTMENTS_SUCCESS';
export const FETCH_APPOINTMENTS_FAIL = 'FETCH_APPOINTMENTS_FAIL';

export const FETCH_DAILY_INFO_START = 'FETCH_DAILY_INFO_START';
export const FETCH_DAILY_INFO_SUCCESS = 'FETCH_DAILY_INFO_SUCCESS';
export const FETCH_DAILY_INFO_FAIL = 'FETCH_DAILY_INFO_FAIL';

export const FETCH_TODAYS_CONSULTANTS_START = 'FETCH_TODAYS_CONSULTANTS_START';
export const FETCH_TODAYS_CONSULTANTS_SUCCESS = 'FETCH_TODAYS_CONSULTANTS_SUCCESS';
export const FETCH_TODAYS_CONSULTANTS_FAIL = 'FETCH_TODAYS_CONSULTANTS_FAIL';

export const UPDATE_APPOINTMENT_QUEUE_START = 'UPDATE_APPOINTMENT_QUEUE_START';
export const UPDATE_APPOINTMENT_QUEUE_SUCCESS = 'UPDATE_APPOINTMENT_QUEUE_SUCCESS';
export const UPDATE_APPOINTMENT_QUEUE_FAIL = 'UPDATE_APPOINTMENT_QUEUE_FAIL';

export const FETCH_DASHBOARD_APPOINTMENT_SUCCESS = 'FETCH_DASHBOARD_APPOINTMENT_SUCCESS';

export const END_SESSION_START = 'END_SESSION_START';
export const END_SESSION_END = 'END_SESSION_END';

export const TRANSFER_PATIENT_ALT_START = 'TRANSFER_PATIENT_ALT_START';
export const TRANSFER_PATIENT_ALT_END = 'TRANSFER_PATIENT_ALT_END';
