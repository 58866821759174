import { _notifyError, _notifySuccess, _sortItems } from 'utils';
import Axios from 'services/axios';
import * as bedTypes from '../types/bedTypes';
import { endApiCall } from '../../../../store/actions/apiActions';
import { CUSTOM_ERROR_MESSAGE } from "utils/constants";

const fetchBedsStart = () => ({
  type: bedTypes.FETCH_BEDS_START,
});

const fetchBedsSuccess = payload => ({
  type: bedTypes.FETCH_BEDS_SUCCESS,
  payload,
});

const fetchBedsFailed = msg => ({
  type: bedTypes.FETCH_BEDS_FAILED,
  error: msg,
});

export const fetchBeds = roomId => async dispatch => {
  dispatch(fetchBedsStart());
  const { data } = await Axios.get(`/rooms/${roomId}/beds`);
  if (!data.status) {
    dispatch(endApiCall());
    _notifyError(data.message);
    dispatch(fetchBedsFailed(data.message));
  } else {
    const payload = {
      items: _sortItems([...data.data], 'name'),
      filteredItems: _sortItems([...data.data], 'name'),
    };
    dispatch(endApiCall());
    dispatch(fetchBedsSuccess(payload));
  }
};

const addBedStart = () => ({ type: bedTypes.ADD_BED_START });

const addBedSuccess = payload => ({
  type: bedTypes.ADD_BED_SUCCESS,
  payload,
});

const addBedFailed = msg => ({
  type: bedTypes.ADD_BED_FAILED,
  error: msg,
});

export const addBed = (current, roomId, close) => {
  return async dispatch => {
    dispatch(addBedStart());

    try {
      const { data } = await Axios.post(`rooms/${roomId}/beds`, current);
      if (data.status) {
        dispatch(addBedSuccess(data.data));
        _notifySuccess('Bed successfully added.');
        close();
      }
    } catch (error) {
      _notifyError(CUSTOM_ERROR_MESSAGE);
      dispatch(addBedFailed(CUSTOM_ERROR_MESSAGE));
    }
  };
};

const updateBedStart = payload => ({
  type: bedTypes.UPDATE_BED_START,
  payload,
});

const updateBedSuccess = payload => ({
  type: bedTypes.UPDATE_BED_SUCCESS,
  payload,
});

const updateBedFailed = msg => ({
  type: bedTypes.UPDATE_BED_FAILED,
  error: msg,
});

export const updateSelectedBed = (current, close, action) => {
  return async dispatch => {
    const actionType = action ? { isDeactivating: true } : { isSaving: true };
    const { isActive, name, price } = current;

    dispatch(updateBedStart(actionType));
    try {
      const { data } = await Axios.patch(`beds/${current._id}`, {
        isActive,
        name,
        price,
      });

      if (data.status) {
        dispatch(updateBedSuccess(data.data));
        _notifySuccess(`Bed ${action || 'updated'} successfully.`);
        close();
      }
    } catch (error) {
      _notifyError(CUSTOM_ERROR_MESSAGE);
      dispatch(updateBedFailed(CUSTOM_ERROR_MESSAGE));
    }
  };
};

export const updateBedList = value => ({
  type: bedTypes.UPDATE_BEDS_LIST,
  payload: {
    filteredItems: value,
  },
});
