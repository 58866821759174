/* eslint-disable max-len */
/* eslint-disable no-case-declarations */
import * as inventoryTypes from '../types/inventoryTypes';
import initialState from './initialState';
import { updateObject } from 'utils';

const inventory = (state = initialState.inventoryOrder, action) => {
  switch (action.type) {
    case inventoryTypes.GET_INVENTORY_ORDERS_START:
      return updateObject(state, { isLoading: true });

    case inventoryTypes.GET_INVENTORY_ORDERS_SUCCESS:
      return updateObject(state, { ...action.payload, isLoading: false });

    case inventoryTypes.GET_INVENTORY_ORDERS_FAILURE:
      return updateObject(state, { message: action.payload, isLoading: false });

    case inventoryTypes.MOVE_INVENTORY_ORDER_SUCCESS:
      return updateObject(state, {
        docs: [action.payload, ...state.docs],
        isSaving: false,
      });

    case inventoryTypes.ACCEPT_ORDER_START:
      return updateObject(state, { ...action.payload, isAccepting: true });

    case inventoryTypes.ACCEPT_ORDER_SUCCESS:
      return updateObject(state, {
        docs: state.docs.map(order => {
          if (order._id === action.payload) {
            order.status = 'accepted';
            return order;
          }
          return order;
        }),
        isAccepting: false,
      });

    case inventoryTypes.ACCEPT_ORDER_FAILURE:
      return updateObject(state, { ...action.payload, isAccepting: false });

    case inventoryTypes.DECLINE_ORDER_START:
      return updateObject(state, { ...action.payload, isDeclining: true });

    case inventoryTypes.DECLINE_ORDER_FAILURE:
      return updateObject(state, { ...action.payload, isDeclining: false });

    case inventoryTypes.UPDATE_INVENTORY_ORDER_START:
      return updateObject(state, {
        ...(action.payload.type === 'declining' ? { isDeclining: true } : { isUpdating: true }),
      });

    case inventoryTypes.UPDATE_INVENTORY_ORDER_SUCCESS:
      return updateObject(state, {
        docs: state.docs.map(order => {
          if (order._id === action.payload._id) {
            order = action.payload;
            return order;
          }
          return order;
        }),
        isUpdating: false,
        isDeclining: false,
      });

    case inventoryTypes.UPDATE_INVENTORY_ORDER_FAILURE:
      return updateObject(state, { ...action.payload, isDeclining: false, isUpdating: false });

    default:
      return { ...state };
  }
};

export default inventory;
