export const ADD_TEST_STANDARDS_START = 'ADD_TEST_STANDARDS_START';
export const ADD_TEST_STANDARDS_SUCCESS = 'ADD_TEST_STANDARDS_SUCCESS';
export const ADD_TEST_STANDARDS_FAILED = 'ADD_TEST_STANDARDS_FAILED';

export const FETCH_TEST_STANDARDS_SUCCESS = 'FETCH_TEST_STANDARDS_SUCCESS';
export const FETCH_TEST_STANDARDS_FAILED = 'FETCH_TEST_STANDARDS_FAILED';
export const FETCH_TEST_STANDARDS_START = 'FETCH_TEST_STANDARDS_START';

export const UPDATE_TEST_STANDARDS_START = 'UPDATE_TEST_STANDARDS_START';
export const UPDATE_TEST_STANDARDS_FAILED = 'UPDATE_TEST_STANDARDS_FAILED';
export const UPDATE_TEST_STANDARDS_SUCCESS = 'UPDATE_TEST_STANDARDS_SUCCESS';

export const UPDATE_TEST_STANDARDS_LIST = 'UPDATE_TEST_STANDARDS_LIST';
