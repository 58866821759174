/* eslint-disable no-case-declarations */
import * as patientTypes from '../types/patientTypes';
import initialState from './initialState';
import { updateObject } from 'utils';

const patients = (state = initialState.patients, { type, payload, error }) => {
  switch (type) {
    case patientTypes.FETCH_ALL_PATIENTS_START:
      return updateObject(state, { isLoading: true });

    case patientTypes.FETCH_ALL_PATIENTS_SUCCESS:
      return updateObject(state, { ...payload, isLoading: false });

    case patientTypes.FETCH_ALL_PATIENTS_FAIL:
      return updateObject(state, { isLoading: false });

    case patientTypes.CREATE_PATIENT_START:
      return updateObject(state, { isSaving: true });

    case patientTypes.CREATE_PATIENT_SUCCESS:
      const newPatients = state.docs ? [payload, ...state.docs] : [payload];
      return updateObject(state, { docs: newPatients, isSaving: false });

    case patientTypes.CREATE_PATIENT_FAIL:
      return updateObject(state, { message: error, isSaving: false });

    case patientTypes.UPDATE_PATIENT_START:
      return updateObject(state, { isSaving: true });

    case patientTypes.UPDATE_PATIENT_SUCCESS:
      const updatedDocs = state.docs.map(doc => doc._id === payload._id ? payload : doc);
      return updateObject(state, { docs: updatedDocs, isSaving: false });
  
    case patientTypes.UPDATE_PATIENT_FAIL:
      return updateObject(state, { isSaving: false });

    default:
      return { ...state };
  }
};

export default patients;
