import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import InfiniteScroll from "../InfiniteScroll";
import { TaskContainer } from "../../components";
import { ConfirmModal, DeclineCommentModal, Loader, TodoModal } from "components";
import { TODO } from "utils/constants";
import { acceptTaskAction, declineTaskAction, extendDueDate, getActiveTask } from "modules/laboratory/redux/actions";
import { _formatDate } from "utils";

export const Todos = ({ todoTasks, isSaving, hasPermission, activeTask, isSubmitting, refresh }) => {
  const [todoModal, setTodoModal] = useState(false);
  const [declineCommentModal, setDeclineCommentModal] = useState(false);
  const [declineComment, setDeclineComment] = useState("");
  const [acceptModal, setAcceptModal] = useState(false);

  const dispatch = useDispatch();

  const toggleTodoModal = () => {
    setTodoModal(!todoModal);
  };
  const toggleAcceptModal = () => setAcceptModal(!acceptModal);
  const viewTodoTask = (task) => {
    dispatch(getActiveTask(task));
    toggleTodoModal();
  };
  const changeDueDate = (newDate) => {
    const response = window.confirm(`Are you sure you want to extend the due date to ${_formatDate(newDate)}`);
    if (response) return dispatch(extendDueDate(activeTask._id, newDate));
  };
  const triggerAcceptTask = () => {
    toggleTodoModal();
    toggleAcceptModal();
  };
  const toggleDeclineModal = () => {
    setDeclineCommentModal(!declineCommentModal);
    setDeclineComment("");
  };
  const triggerDeclineTask = () => {
    toggleDeclineModal();
  };
  const updateDeclineTask = () => {
    dispatch(declineTaskAction(activeTask._id, declineComment, toggleDeclineModal));
  };
  const acceptTask = () => {
    dispatch(acceptTaskAction(activeTask._id, toggleAcceptModal));
  };
  return (
    <>
      <ConfirmModal
        isSaving={isSaving}
        isOpen={acceptModal}
        color="blue"
        toggle={toggleAcceptModal}
        positiveClick={acceptTask}
        negativeClick={toggleAcceptModal}
      />
      <TodoModal
        isOpen={todoModal}
        toggle={toggleTodoModal}
        allowDateEdit
        isSubmitting={isSubmitting}
        changeDueDate={changeDueDate}
        leftButtonPress={triggerDeclineTask}
        rightButtonPress={triggerAcceptTask}
        leftButtonDisabled={!hasPermission(["decline-job", "super-edit"])}
        rightButtonDisabled={!(hasPermission(["accept-job", "super-edit"]) && activeTask?.dueDate)}
        entity={activeTask}
      />
      <DeclineCommentModal
        isOpen={declineCommentModal}
        toggle={toggleDeclineModal}
        comment={declineComment}
        onCommentChange={(e) => setDeclineComment(e.target.value)}
        isSaving={isSaving}
        disablePositiveClick={declineComment.length < 6}
        positiveClick={updateDeclineTask}
        negativeClick={toggleDeclineModal}
      />
      {todoTasks?.isLoading ? (
        <Loader />
      ) : (
        <TaskContainer items={todoTasks?.docs?.length || 0} showDateRange={false} refresh={refresh}>
          <InfiniteScroll taskObject={todoTasks} dispatch={dispatch} taskStatus={TODO} viewSingleTask={viewTodoTask} />
        </TaskContainer>
      )}
    </>
  );
};

Todos.propTypes = {
  todoTasks: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isSaving: PropTypes.bool,
  hasPermission: PropTypes.func,
  activeTask: PropTypes.object,
  isSubmitting: PropTypes.bool,
  refresh: PropTypes.func,
};
