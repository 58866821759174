import { _notifyError, printError } from "utils";
import * as reportTypes from "../types/reportTypes";
import Axios from "services/axios";

export const fetchReportSectionFilters = (section) => async (dispatch) => {
  dispatch({
    type: reportTypes.FETCH_REPORT_SECTION_FILTERS_START,
    section,
  });

  try {
    const { data } = await Axios.get(`reports/section/${section}`);
    if (data.status === "true") {
      dispatch({
        type: reportTypes.FETCH_REPORT_SECTION_FILTERS_SUCCESS,
        payload: data.data,
        section,
      });
      return true;
    }
  } catch (err) {
    _notifyError(err.message);
    return false;
  }
};

export const fetchReportSections = (queryParams) => async (dispatch) => {
  dispatch({
    type: reportTypes.FETCH_REPORT_SECTIONS_START,
  });

  try {
    const { data } = await Axios.get("reports/sections", queryParams);
    if (data.status === "true") {
      dispatch({
        type: reportTypes.FETCH_REPORT_SECTIONS_SUCCESS,
        payload: data.data,
      });
      return true;
    }
  } catch (err) {
    _notifyError(err.message);
    return false;
  }
};

export const fetchReport = (payload, section, queryParams) => async (dispatch) => {
  dispatch({
    type: reportTypes.FETCH_REPORT_START,
  });

  try {
    const { data } = await Axios.post(`reports?section=${section}${queryParams ? `&${queryParams}` : ""}`, payload);
    if (data.status === "true") {
      dispatch({
        type: reportTypes.FETCH_REPORT_SUCCESS,
        payload: data.data,
        section,
      });
    }
  } catch (error) {
    dispatch({
      type: reportTypes.FETCH_REPORT_FAIL,
    });
    const errorMessage = printError(error);
    _notifyError(errorMessage);
  }
};
