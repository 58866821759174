import * as types from "../types/bulkUploadTypes";
import initialState from "store/reducers/initialState";

export const bulkUploads = (state = initialState.bulkUploads, action) => {
  switch (action.type) {
    case types.FETCH_FILES_START:
      return {
        ...state,
        fetching: true,
      };
    case types.FETCH_FILES_SUCCESS:
      return {
        ...state,
        fetching: false,
        items: action.payload,
      };
    case types.FETCH_FILES_FAILURE:
      return { ...state, fetching: false };

    case types.FETCH_SAMPLES_START:
      return {
        ...state,
        fetching: true,
      };
    case types.FETCH_SAMPLES_SUCCESS:
      return {
        ...state,
        fetching: false,
        samples: [...action.payload],
      };
    case types.FETCH_SAMPLES_FAILURE:
      return { ...state, fetching: false };

    case types.UPLOAD_FILE_START:
      return { ...state, uploading: true };
    case types.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        uploading: false,
      };
    case types.UPLOAD_FILE_FAILURE:
      return { ...state, uploading: false };

    case types.PROCESS_FILE_START:
      return { ...state, processing: true };
    case types.PROCESS_FILE_SUCCESS:
      return { ...state, processing: false };
    case types.PROCESS_FILE_FAILURE:
      return {
        ...state,
        processing: false,
      };

    default:
      return { ...state };
  }
};
