/* eslint-disable no-case-declarations */
import * as financeAnalyticsTypes from '../types/analyticTypes';
import initialState from './initialState';
import { updateObject } from 'utils';

const financeAnalytics = (state = initialState.financeAnalytics, { type, payload }) => {
  switch (type) {
    case financeAnalyticsTypes.FETCH_FINANCE_SUMMARY_START:
      return updateObject(state, { summary: { ...state.summary, loading: true } });

    case financeAnalyticsTypes.FETCH_FINANCE_SUMMARY_SUCCESS:
      return updateObject(state, { summary: { ...state.summary, items: payload, loading: false } });

    case financeAnalyticsTypes.FETCH_FINANCE_SUMMARY_FAIL:
      return updateObject(state, { summary: { ...state.summary, loading: false } });

    case financeAnalyticsTypes.FETCH_TOP_PRODUCTS_START:
      return updateObject(state, { topProduct: { ...state.topProduct, loading: true } });

    case financeAnalyticsTypes.FETCH_TOP_PRODUCTS_SUCCESS:
      return updateObject(state, { topProduct: { ...state.topProduct, items: payload, loading: false } });

    case financeAnalyticsTypes.FETCH_TOP_PRODUCTS_FAIL:
      return updateObject(state, { topProduct: { ...state.topProduct, loading: false } });

    case financeAnalyticsTypes.FETCH_TOP_SERVICES_START:
      return updateObject(state, { topServices: { ...state.topServices, loading: true } });

    case financeAnalyticsTypes.FETCH_TOP_SERVICES_SUCCESS:
      return updateObject(state, { topServices: { ...state.topServices, items: payload, loading: false } });

    case financeAnalyticsTypes.FETCH_TOP_SERVICES_FAIL:
      return updateObject(state, { topServices: { ...state.topServices, loading: false } });

    case financeAnalyticsTypes.FETCH_TOP_HMO_START:
      return updateObject(state, { topHmo: { ...state.topHmo, loading: true } });

    case financeAnalyticsTypes.FETCH_TOP_HMO_SUCCESS:
      return updateObject(state, { topHmo: { ...state.topHmo, items: payload, loading: false } });

    case financeAnalyticsTypes.FETCH_TOP_HMO_FAIL:
      return updateObject(state, { topHmo: { ...state.topHmo, loading: false } });

    default:
      return { ...state };
  }
};

export default financeAnalytics;
