import React, { Suspense } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PageLoader } from 'components';

const NonauthRoute = ({
  component: Component,
  isAuthenticated,
  layout: Layout,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      return isAuthenticated ? (
        <Redirect to={'/select-branch'} />
      ) : (
        <Suspense fallback={<PageLoader />}>
          <Layout {...props} details={rest}>
            <Component {...props} />
          </Layout>
        </Suspense>
      );
    }}
  />
);

NonauthRoute.defaultProps = {
  location: {},
  isAuthenticated: false
};

NonauthRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  isAuthenticated: PropTypes.bool,
  layout: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  location: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default NonauthRoute;
