import { _notifyError, _sortItems, getSerializedParams } from 'utils';
import * as testStandardTypes from '../types/testStandardsTypes';
import { beginApiCall, endApiCall } from 'store/actions';
import Axios from 'services/axios';

const updatedState = (data) => ({
  isSaving: false,
  error: '',
  items: data,
  filteredItems: data,
});

const fetchTestStandardsSuccess = (payload) => ({
  type: testStandardTypes.FETCH_TEST_STANDARDS_SUCCESS,
  payload,
});

const fetchTestStandardsFailed = () => ({
  type: testStandardTypes.FETCH_TEST_STANDARDS_FAILED,
});

export const fetchTestStandards =
  (params = {}) =>
  async (dispatch) => {
    dispatch(beginApiCall());
    try {
      const { data } = await Axios.get('/test-standards', {
        params,
        paramsSerializer: function (params) {
          return getSerializedParams(params, true);
        },
      });
      if (data.status) {
        const payload = _sortItems(data.data?.docs, 'name');
        dispatch(endApiCall());
        dispatch(fetchTestStandardsSuccess(updatedState(payload)));
        return true;
      }
    } catch (err) {
      dispatch(endApiCall());
      _notifyError(err.message);
      dispatch(fetchTestStandardsFailed(err.message));
      return false;
    }
  };
