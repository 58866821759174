import React from "react";

const Required = () => {
  return (
    <span className="tw-text-xs" style={{ color: "red" }}>
      *
    </span>
  );
};

export default Required;
