import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { ButtonGroup } from 'reactstrap';
import { Button } from './ui/buttons/button';

export const ViewSwitcher = ({ config = [], toggleViewSwitch, activeView }) => {
  return (
    <ButtonGroup>
      {config.map((item, index) => (
        <Button
          key={index}
          onClick={() => toggleViewSwitch(item.id)}
          className={classnames('btn__primary btn__switch', { 'btn__switch--active': item.id === activeView })}
        >
          {item.label}
        </Button>
      ))}
    </ButtonGroup>
  );
};

ViewSwitcher.propTypes = {
  activeView: PropTypes.string,
  config: PropTypes.array,
  toggleViewSwitch: PropTypes.func,
};
