import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import appReducer from "./reducers";
import { rolesApi } from "modules/settings/redux/features/roleSlice";
import billsApi from "modules/finance/redux/features/billsSlice";
import { walletApi } from "modules/finance/redux/features/walletSlice";
import { patientApi } from "modules/front-desk/redux/features/patientSlice";
import { categoriesApi } from "store/features/categoriesSlice";
import { claimsApi } from "modules/finance/redux/features/claimsSlice";
import { plansApi } from "modules/settings/redux/features/plansSlice";
import { appointmentPurposeApi } from "modules/settings/redux/features/appointmentPurposeSlice";
import { admissionCapacityApi } from "modules/settings/redux/features/admissionCapacitySlice";
import cartApi from "modules/finance/redux/features/cart-slice";
import { formApi } from "modules/settings/redux/features/formSlice";
import servicesApi from "../modules/inventory/redux/features/services-slice";
import { staffApi } from "./features/staffSlice";
import { shiftApi } from "./features/shiftSlice";
import formEntryApi from "../modules/patients/redux/features/form-entry-slice";
import { smsApi } from "modules/settings/redux/features/smsSlice";

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ["auth", "user", "company", "department", "branches", "roles"],
  blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, appReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat([
      rolesApi.middleware,
      billsApi.middleware,
      walletApi.middleware,
      patientApi.middleware,
      categoriesApi.middleware,
      claimsApi.middleware,
      plansApi.middleware,
      servicesApi.middleware,
      appointmentPurposeApi.middleware,
      admissionCapacityApi.middleware,
      cartApi.middleware,
      formApi.middleware,
      formEntryApi.middleware,
      staffApi.middleware,
      shiftApi.middleware,
      smsApi.middleware,
    ]),
});

export const currentBranch = () =>
  store?.getState()?.user?.current?.branchId || store?.getState()?.user?.current?.value;

export const persistor = persistStore(store);
