export const ADD_ROLE_START = 'ADD_ROLE_START';
export const ADD_ROLE_SUCCESS = 'ADD_ROLE_SUCCESS';
export const ADD_ROLE_FAILED = 'ADD_ROLE_FAILED';

export const CHECK_EXISTING_ROLE = 'CHECK_EXISTING_ROLE';

export const FETCH_ROLES_START = 'FETCH_ROLES_START';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_FAILED = 'FETCH_ROLES_FAILED';

export const ROLES_PAGE_SORT = 'ROLES_PAGE_SORT';

export const UPDATE_ROLES_LIST = 'UPDATE_ROLES_LIST';
export const UPDATE_ROLE_START = 'UPDATE_ROLE_START';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAILED = 'UPDATE_ROLE_FAILED';

export const DELETE_ROLE_START = 'DELETE_ROLE_START';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_FAILED = 'DELETE_ROLE_FAILED';
