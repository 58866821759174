import { _notifyError, _notifySuccess, printError } from "utils";
import * as inventoryTypes from "../types/inventoryTypes";
// import { beginApiCall, endApiCall } from './apiActions';
import Axios from "services/axios";
import { CUSTOM_ERROR_MESSAGE } from "utils/constants";

const getServicesStart = (payload) => ({
  type: inventoryTypes.GET_INVENTORY_SERVICES_START,
  payload,
});

const getServicesSuccess = (payload) => ({
  type: inventoryTypes.GET_INVENTORY_SERVICES_SUCCESS,
  payload,
});

const getServicesFailure = (payload) => ({
  type: inventoryTypes.GET_INVENTORY_SERVICES_FAILURE,
  payload,
});

export const getServices = (queryDetails, location) => {
  const url = `/locations/${location}/items?type=service${queryDetails ? `&${queryDetails}` : ""}`;

  return async (dispatch) => {
    dispatch(getServicesStart());
    try {
      const { data } = await Axios.get(url);

      if (data.status) {
        dispatch(getServicesSuccess(data.data));
      }
    } catch (error) {
      const errorMessage = printError(error);
      _notifyError(errorMessage);
      dispatch(getServicesFailure(CUSTOM_ERROR_MESSAGE));
    }
  };
};

const addInventoryServiceStart = () => ({ type: inventoryTypes.ADD_INVENTORY_SERVICE_START });

const addInventoryServiceFailure = (error) => ({
  type: inventoryTypes.ADD_INVENTORY_SERVICE_FAILED,
  error,
});

const addInventoryServiceSuccess = (payload) => ({
  type: inventoryTypes.ADD_INVENTORY_SERVICE_SUCCESS,
  payload,
});

export const addInventoryService = (payload, close) => {
  return async (dispatch) => {
    dispatch(addInventoryServiceStart());
    try {
      const { data } = await Axios.post("/items", payload);
      if (data.status) {
        dispatch(addInventoryServiceSuccess(data.data));
        _notifySuccess("Created Service successfully.");
        close();
      }
    } catch (error) {
      const errorMessage = printError(error);
      _notifyError(errorMessage);
      dispatch(addInventoryServiceFailure(CUSTOM_ERROR_MESSAGE));
    }
  };
};

const updateInventoryServiceStart = () => ({ type: inventoryTypes.UPDATE_INVENTORY_SERVICE_START });

const updateInventoryServiceFailure = (error) => ({
  type: inventoryTypes.UPDATE_INVENTORY_SERVICE_FAILED,
  error,
});

const updateInventoryServiceSuccess = (payload) => ({
  type: inventoryTypes.UPDATE_INVENTORY_SERVICE_SUCCESS,
  payload,
});

export const updateInventoryService = (payload, itemId, close) => {
  return async (dispatch) => {
    dispatch(updateInventoryServiceStart());
    try {
      const { data } = await Axios.patch(`/items/${itemId}`, payload);
      if (data.status) {
        const cache = {
          data: data.data,
          itemId,
        };
        dispatch(updateInventoryServiceSuccess(cache));
        _notifySuccess(`Updated Service successfully.`);
        close();
      }
    } catch (error) {
      const errorMessage = error.response ? error.response.data : CUSTOM_ERROR_MESSAGE;
      _notifyError(errorMessage);
      dispatch(updateInventoryServiceFailure(CUSTOM_ERROR_MESSAGE));
    }
  };
};

const archiveServiceSuccess = (payload) => ({
  type: inventoryTypes.ARCHIVE_INVENTORY_SERVICE_SUCCESS,
  payload,
});

const archiveServiceFailed = (payload) => ({
  type: inventoryTypes.ARCHIVE_INVENTORY_SERVICE_FAILED,
  payload,
});

export const archiveInventoryService = (payload, action) => async (dispatch) => {
  try {
    const { data } = await Axios.patch(`/services/deactivate`, payload.value);
    if (data.status) {
      dispatch(archiveServiceSuccess({ items: payload.value, status: action === "Archived" }));
      _notifySuccess(`Service ${action} successfully.`);
      return true;
    }
  } catch (error) {
    const errorMessage = error.response ? error.response.data : CUSTOM_ERROR_MESSAGE;
    _notifyError(errorMessage);
    dispatch(archiveServiceFailed(CUSTOM_ERROR_MESSAGE));
    return false;
  }
};

const activateServiceSuccess = (payload) => ({
  type: inventoryTypes.ACTIVATE_INVENTORY_SERVICE_SUCCESS,
  payload,
});

const activateServiceFailure = (payload) => ({
  type: inventoryTypes.ACTIVATE_INVENTORY_SERVICE_FAILED,
  payload,
});

export const activateInventoryService = (payload, itemId, action) => async (dispatch) => {
  try {
    const { data } = await Axios.patch(`/items/${itemId}`, payload);
    if (data.status) {
      dispatch(activateServiceSuccess({ id: itemId, status: payload.isActive }));
      _notifySuccess(`Service ${action} successfully.`);
      return true;
    }
  } catch (error) {
    const errorMessage = error.response ? error.response.data : CUSTOM_ERROR_MESSAGE;
    _notifyError(errorMessage);
    dispatch(activateServiceFailure(CUSTOM_ERROR_MESSAGE));
    return false;
  }
};
