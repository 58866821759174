export const FETCH_SUBSCRIPTIONS_START = 'FETCH_SUBSCRIPTIONS_START';
export const FETCH_SUBSCRIPTIONS_SUCCESS = 'FETCH_SUBSCRIPTIONS_SUCCESS';

export const FETCH_PAYMENTS_START = 'FETCH_PAYMENTS_START';
export const FETCH_PAYMENTS_SUCCESS = 'FETCH_PAYMENTS_SUCCESS';

export const CANCEL_SUBSCRIPTION_START = 'CANCEL_SUBSCRIPTION_START';
export const CANCEL_SUBSCRIPTION_END = 'CANCEL_SUBSCRIPTION_END';

export const FETCH_CARDS_START = 'FETCH_CARDS_START';
export const FETCH_CARDS_SUCCESS = 'FETCH_CARDS_SUCCESS';
