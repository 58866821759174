import { _notifyError, _notifySuccess } from 'utils';
import * as bankTypes from '../types/bankTypes';
import { beginApiCall, endApiCall } from 'store/actions';
import Axios from 'services/axios';
import { CUSTOM_ERROR_MESSAGE } from 'utils/constants';

const fetchBankSuccess = (payload) => ({
  type: bankTypes.FETCH_BANKS_SUCCESS,
  payload,
});

const fetchBankFailed = (error) => ({
  type: bankTypes.FETCH_BANKS_FAILED,
  error,
});

export const fetchBanks = () => async (dispatch) => {
  dispatch(beginApiCall());
  const { data } = await Axios.get('/banks');

  if (data.status) {
    dispatch(endApiCall());
    dispatch(fetchBankSuccess(data.data));
  } else {
    dispatch(endApiCall());
    _notifyError(data.message);
    dispatch(fetchBankFailed(data.message));
  }
};

const addBankStart = (payload) => ({
  type: bankTypes.ADD_BANKS_START,
  payload,
});

const addBankEnd = (payload) => ({
  type: bankTypes.ADD_BANKS_END,
  payload,
});

const addBankSuccess = (payload) => ({
  type: bankTypes.ADD_BANKS_SUCCESS,
  payload,
});

const addBankFailed = (error) => ({
  type: bankTypes.ADD_BANKS_FAILED,
  error,
});

export const addBank = (bankList, close) => async (dispatch) => {
  dispatch(addBankStart());

  try {
    const { data } = await Axios.post('/banks', bankList);
    if (data.status) {
      dispatch(addBankEnd());
      dispatch(addBankSuccess(data.data));
      _notifySuccess(data.message);
      close();
    }
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error.message;
    _notifyError(errorMessage);
    dispatch(addBankEnd());
    dispatch(addBankFailed(CUSTOM_ERROR_MESSAGE));
  }
};

const updateBankSuccess = (payload) => ({
  type: bankTypes.UPDATE_BANKS_SUCCESS,
  payload,
});

const updateBankFailed = (error) => ({
  type: bankTypes.UPDATE_BANKS_FAILED,
  error,
});

export const updateBank = (bank, close) => async (dispatch) => {
  const { _id, name, code } = bank;
  dispatch(addBankStart());
  try {
    const { data } = await Axios.patch(`/banks/${_id}`, { name, code });
    if (data.status) {
      close();
      dispatch(addBankEnd());
      dispatch(updateBankSuccess(data.data));
      _notifySuccess(`Bank updated successfully.`);
    }
  } catch (error) {
    dispatch(addBankEnd());
    const errorMessage = error.response ? error.response.data.message : error.message;
    _notifyError(errorMessage);
    dispatch(updateBankFailed(CUSTOM_ERROR_MESSAGE));
  }
};

export const updateBankList = (value) => ({
  type: bankTypes.UPDATE_BANK_LIST,
  payload: value,
});
