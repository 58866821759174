import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import InfiniteScroll from "../InfiniteScroll";
import { DispatchedModal, Loader, ViewCommentModal } from "components";
import { TaskContainer } from "../../components";
import { DISPATCHED } from "utils/constants";
import { getActiveTask } from "modules/laboratory/redux/actions";

export const DispatchedTasks = ({ deliveredTasks, hasPermission, activeTask, refresh }) => {
  const [dispatchedModal, setDispatchedModal] = useState(false);
  const [deliveredModal, setDeliveredModal] = useState(false);
  const [viewCommentModal, setViewCommentModal] = useState(false);
  const dispatch = useDispatch();

  const toggleDispatchedModal = () => {
    setDispatchedModal(!dispatchedModal);
  };
  const toggleViewCommentModal = () => setViewCommentModal(!viewCommentModal);

  const viewDispatchedTask = (task) => {
    dispatch(getActiveTask(task));
    toggleDispatchedModal();
  };
  const toggleDeliveredModal = () => setDeliveredModal(!deliveredModal);
  const triggerDeliverTask = () => {
    toggleDispatchedModal();
    toggleDeliveredModal();
  };
  const viewComments = () => {
    toggleViewCommentModal();
  };
  return (
    <>
      <DispatchedModal
        isOpen={dispatchedModal}
        toggle={toggleDispatchedModal}
        leftButtonPress={triggerDeliverTask}
        leftButtonDisabled={!hasPermission(["deliver-job", "super-edit"])}
        onCommentClick={viewComments}
        entity={activeTask}
      />
      <ViewCommentModal
        color="grey"
        isOpen={viewCommentModal}
        toggle={toggleViewCommentModal}
        comments={activeTask?.comments || []}
        closeClick={toggleViewCommentModal}
      />
      {deliveredTasks?.isLoading ? (
        <Loader />
      ) : (
        <TaskContainer items={deliveredTasks?.docs?.length || 0} title="Dispatched" refresh={refresh}>
          <InfiniteScroll
            taskObject={deliveredTasks}
            dispatch={dispatch}
            taskStatus={DISPATCHED}
            viewSingleTask={viewDispatchedTask}
          />
        </TaskContainer>
      )}
    </>
  );
};

DispatchedTasks.propTypes = {
  deliveredTasks: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isSaving: PropTypes.bool,
  hasPermission: PropTypes.func,
  allTasks: PropTypes.object,
  activeTask: PropTypes.object,
  refresh: PropTypes.func,
};
