export const FETCH_SUPPLIERS_START = 'FETCH_SUPPLIERS_START';
export const FETCH_SUPPLIERS_SUCCESS = 'FETCH_SUPPLIERS_SUCCESS';
export const FETCH_SUPPLIERS_FAILED = 'FETCH_SUPPLIERS_FAILED';

export const ADD_SUPPLIER_START = 'ADD_SUPPLIER_START';
export const ADD_SUPPLIER_SUCCESS = 'ADD_SUPPLIER_SUCCESS';
export const ADD_SUPPLIER_FAILED = 'ADD_SUPPLIER_FAILED';

export const UPDATE_SUPPLIER_START = 'UPDATE_SUPPLIER_START';
export const UPDATE_SUPPLIER_SUCCESS = 'UPDATE_SUPPLIER_SUCCESS';
export const UPDATE_SUPPLIER_FAILED = 'UPDATE_SUPPLIER_FAILED';

export const UPDATE_SUPPLIERS_LIST = 'UPDATE_SUPPLIERS_LIST';
