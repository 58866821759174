export default {
  pharmacyPrescriptions: {
    patientPrescriptions: {
      items: {
        docs: [],
      },
    },
    prescriptions: {
      items: {},
    },
  },
  analytics: {},
};
