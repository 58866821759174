export const GET_INVENTORY_CONFIGURATION_SUCCESS = 'GET_INVENTORY_CONFIGURATION_SUCCESS';
export const SET_INVENTORY_CONFIGURATION_SUCCESS = 'SET_INVENTORY_CONFIGURATION_SUCCESS';

export const ADD_INVENTORY_SERVICE_START = 'ADD_INVENTORY_SERVICE_START';
export const ADD_INVENTORY_SERVICE_FAILED = 'ADD_INVENTORY_SERVICE_FAILED';
export const ADD_INVENTORY_SERVICE_SUCCESS = 'ADD_INVENTORY_SERVICE_SUCCESS';

export const UPDATE_INVENTORY_SERVICE_START = 'UPDATE_INVENTORY_SERVICE_START';
export const UPDATE_INVENTORY_SERVICE_FAILED = 'UPDATE_INVENTORY_SERVICE_FAILED';
export const UPDATE_INVENTORY_SERVICE_SUCCESS = 'UPDATE_INVENTORY_SERVICE_SUCCESS';

export const ADD_INVENTORY_ITEM_START = 'ADD_INVENTORY_ITEM_START';
export const ADD_INVENTORY_ITEM_FAILED = 'ADD_INVENTORY_ITEM_FAILED';
export const ADD_INVENTORY_ITEM_SUCCESS = 'ADD_INVENTORY_ITEM_SUCCESS';

export const UPDATE_INVENTORY_ITEM_START = 'UPDATE_INVENTORY_ITEM_START';
export const UPDATE_INVENTORY_ITEM_FAILED = 'UPDATE_INVENTORY_ITEM_FAILED';
export const UPDATE_INVENTORY_ITEM_SUCCESS = 'UPDATE_INVENTORY_ITEM_SUCCESS';

export const ADD_INVENTORY_ITEM_STOCK_START = 'ADD_INVENTORY_ITEM_STOCK_START';
export const ADD_INVENTORY_ITEM_STOCK_FAILED = 'ADD_INVENTORY_ITEM_STOCK_FAILED';
export const ADD_INVENTORY_ITEM_STOCK_SUCCESS = 'ADD_INVENTORY_ITEM_STOCK_SUCCESS';

export const UPDATE_INVENTORY_ITEM_STOCK_START = 'UPDATE_INVENTORY_ITEM_STOCK_START';
export const UPDATE_INVENTORY_ITEM_STOCK_FAILED = 'UPDATE_INVENTORY_ITEM_STOCK_FAILED';
export const UPDATE_INVENTORY_ITEM_STOCK_SUCCESS = 'UPDATE_INVENTORY_ITEM_STOCK_SUCCESS';

export const GET_INVENTORY_PRODUCTS_START = 'GET_INVENTORY_PRODUCTS_START';
export const GET_INVENTORY_PRODUCTS_SUCCESS = 'GET_INVENTORY_PRODUCTS_SUCCESS';
export const GET_INVENTORY_PRODUCTS_FAILURE = 'GET_INVENTORY_PRODUCTS_FAILURE';

export const GET_INVENTORY_SERVICES_START = 'GET_INVENTORY_SERVICES_START';
export const GET_INVENTORY_SERVICES_SUCCESS = 'GET_INVENTORY_SERVICES_SUCCESS';
export const GET_INVENTORY_SERVICES_FAILURE = 'GET_INVENTORY_SERVICES_FAILURE';

export const UPDATE_STOCK_VISIBILITY_START = 'UPDATE_STOCK_VISIBILITY_START';
export const UPDATE_STOCK_VISIBILITY_FAILED = 'UPDATE_STOCK_VISIBILITY_FAILED';
export const UPDATE_STOCK_VISIBILITY_SUCCESS = 'UPDATE_STOCK_VISIBILITY_SUCCESS';

export const GET_ARCHIVED_STOCK_START = 'GET_ARCHIVED_STOCK_START';
export const GET_ARCHIVED_STOCK_FAILED = 'GET_ARCHIVED_STOCK_FAILED';
export const GET_ARCHIVED_STOCK_SUCCESS = 'GET_ARCHIVED_STOCK_SUCCESS';

export const GET_INVENTORY_ORDERS_START = 'GET_INVENTORY_ORDERS_START';
export const GET_INVENTORY_ORDERS_SUCCESS = 'GET_INVENTORY_ORDERS_SUCCESS';
export const GET_INVENTORY_ORDERS_FAILURE = 'GET_INVENTORY_ORDERS_FAILURE';

export const ARCHIVE_INVENTORY_SERVICE_SUCCESS = 'ARCHIVE_INVENTORY_SERVICE_SUCCESS';
export const ARCHIVE_INVENTORY_SERVICE_FAILED = 'ARCHIVE_INVENTORY_SERVICE_FAILED';

export const ACTIVATE_INVENTORY_SERVICE_SUCCESS = 'ACTIVATE_INVENTORY_SERVICE_SUCCESS';
export const ACTIVATE_INVENTORY_SERVICE_FAILED = 'ACTIVATE_INVENTORY_SERVICE_FAILED';

export const GET_INVENTORY_TRANSFERS_START = 'GET_INVENTORY_TRANSFERS_START';
export const GET_INVENTORY_TRANSFERS_SUCCESS = 'GET_INVENTORY_TRANSFERS_SUCCESS';
export const GET_INVENTORY_TRANSFERS_FAILURE = 'GET_INVENTORY_TRANSFERS_FAILURE';

export const CREATE_INVENTORY_TRANSFER_SUCCESS = 'CREATE_INVENTORY_TRANSFER_SUCCESS';
export const UPDATE_INVENTORY_TRANSFER_START = 'UPDATE_INVENTORY_TRANSFER_START';
export const UPDATE_INVENTORY_TRANSFER_SUCCESS = 'UPDATE_INVENTORY_TRANSFER_SUCCESS';
export const UPDATE_INVENTORY_TRANSFER_FAILURE = 'UPDATE_INVENTORY_TRANSFER_FAILURE';

export const INITIATE_TRANSFER_START = 'INITIATE_TRANSFER_START';
export const INITIATE_TRANSFER_END = 'INITIATE_TRANSFER_END';

export const MOVE_INVENTORY_ORDER_START = 'MOVE_INVENTORY_ORDER_START';
export const MOVE_INVENTORY_ORDER_SUCCESS = 'MOVE_INVENTORY_ORDER_SUCCESS';
export const MOVE_INVENTORY_ORDER_FAILURE = 'MOVE_INVENTORY_ORDER_FAILURE';

export const GET_PRICELISTS = 'GET_PRICELISTS';
export const GET_PRICELISTS_START = 'GET_PRICELISTS_START';
export const GET_PRICELISTS_FAILURE = 'GET_PRICELISTS_FAILURE';

export const CREATE_PRICELIST_START = 'CREATE_PRICELIST_START';
export const CREATE_PRICELIST_SUCCESS = 'CREATE_PRICELIST_SUCCESS';
export const CREATE_PRICELIST_FAILURE = 'CREATE_PRICELIST_FAILURE';

export const UPDATE_PRICELIST_START = 'UPDATE_PRICELIST_START';
export const UPDATE_PRICELIST_SUCCESS = 'UPDATE_PRICELIST_SUCCESS';
export const UPDATE_PRICELIST_FAILURE = 'UPDATE_PRICELIST_FAILURE';

export const DELETE_PRICELIST_START = 'DELETE_PRICELIST_START';
export const DELETE_PRICELIST_SUCCESS = 'DELETE_PRICELIST_SUCCESS';
export const DELETE_PRICELIST_FAILURE = 'DELETE_PRICELIST_FAILURE';

export const GET_RECALLS_START = 'GET_RECALLS_START';
export const GET_RECALLS_SUCCESS = 'GET_RECALLS_SUCCESS';
export const GET_RECALLS_FAILURE = 'GET_RECALLS_FAILURE';

export const INITIATE_RECALL_START = 'INITIATE_RECALL_START';
export const INITIATE_RECALL_SUCCESS = 'INITIATE_RECALL_SUCCESS';
export const INITIATE_RECALL_FAILURE = 'INITIATE_RECALL_FAILURE';

export const UPDATE_RECALL_START = 'UPDATE_RECALL_START';
export const UPDATE_RECALL_SUCCESS = 'UPDATE_RECALL_SUCCESS';
export const UPDATE_RECALL_FAILURE = 'UPDATE_RECALL_FAILURE';

export const PROCESS_RECALL = 'PROCESS_RECALL';

export const GET_RETURNS_START = 'GET_RETURNS_START';
export const GET_RETURNS_SUCCESS = 'GET_RETURNS_SUCCESS';
export const GET_RETURNS_FAILURE = 'GET_RETURNS_FAILURE';

export const INITIATE_RETURN_START = 'INITIATE_RETURN_START';
export const INITIATE_RETURN_SUCCESS = 'INITIATE_RETURN_SUCCESS';
export const INITIATE_RETURN_FAILURE = 'INITIATE_RETURN_FAILURE';

export const UPDATE_RETURN_START = 'UPDATE_RETURN_START';
export const UPDATE_RETURN_SUCCESS = 'UPDATE_RETURN_SUCCESS';
export const UPDATE_RETURN_FAILURE = 'UPDATE_RETURN_FAILURE';

export const PROCESS_RETURN_SUCCESS = 'PROCESS_RETURN_SUCCESS';
export const PROCESS_RETURN_FAILURE = 'PROCESS_RETURN_FAILURE';
export const PROCESS_RETURN_START = 'PROCESS_RETURN_START';

export const GET_SINGLE_ORDER_START = 'GET_SINGLE_ORDER_START';
export const GET_SINGLE_ORDER_COMPLETE = 'GET_SINGLE_ORDER_COMPLETE';

export const ACCEPT_ORDER_START = 'ACCEPT_ORDER_START';
export const ACCEPT_ORDER_SUCCESS = 'ACCEPT_ORDER_SUCCESS';
export const ACCEPT_ORDER_FAILURE = 'ACCEPT_ORDER_FAILURE';

export const DECLINE_ORDER_START = 'DECLINE_ORDER_START';
export const DECLINE_ORDER_SUCCESS = 'DECLINE_ORDER_SUCCESS';
export const DECLINE_ORDER_FAILURE = 'DECLINE_ORDER_FAILURE';

export const UPDATE_INVENTORY_ORDER_START = 'UPDATE_INVENTORY_ORDER_START';
export const UPDATE_INVENTORY_ORDER_SUCCESS = 'UPDATE_INVENTORY_ORDER_SUCCESS';
export const UPDATE_INVENTORY_ORDER_FAILURE = 'UPDATE_INVENTORY_ORDER_FAILURE';

export const GET_INVENTORY_CONSUMABLES_START = 'GET_INVENTORY_CONSUMABLES_START';
export const GET_INVENTORY_CONSUMABLES_SUCCESS = 'GET_INVENTORY_CONSUMABLES_SUCCESS';
export const GET_INVENTORY_CONSUMABLES_FAILURE = 'GET_INVENTORY_CONSUMABLES_FAILURE';

export const DISPENSE_INVENTORY_CONSUMABLES_START = 'DISPENSE_INVENTORY_CONSUMABLES_START';
export const DISPENSE_INVENTORY_CONSUMABLES_SUCCESS = 'DISPENSE_INVENTORY_CONSUMABLES_SUCCESS';
export const DISPENSE_INVENTORY_CONSUMABLES_FAILURE = 'DISPENSE_INVENTORY_CONSUMABLES_FAILURE';

export const UPDATE_INVENTORY_CONSUMABLES_START = 'UPDATE_INVENTORY_CONSUMABLES_START';
export const UPDATE_INVENTORY_CONSUMABLES_SUCCESS = 'UPDATE_INVENTORY_CONSUMABLES_SUCCESS';
export const UPDATE_INVENTORY_CONSUMABLES_FAILURE = 'UPDATE_INVENTORY_CONSUMABLES_FAILURE';

export const RETURN_CONSUMABLES_FAILURE = 'RETURN_CONSUMABLES_FAILURE';
export const RETURN_CONSUMABLES_SUCCESS = 'RETURN_CONSUMABLES_SUCCESS';
export const RETURN_CONSUMABLES_START = 'RETURN_CONSUMABLES_START';

export const SET_REORDER_LEVEL_FAILED = 'SET_REORDER_LEVEL_FAILED';
export const SET_REORDER_LEVEL_START = 'SET_REORDER_LEVEL_START';
export const SET_REORDER_LEVEL_SUCCESS = 'SET_REORDER_LEVEL_SUCCESS';
