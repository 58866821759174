import * as appOptionTypes from '../types/appOptionTypes';
import { beginApiCall, endApiCall } from './apiActions';
import Axios from '../../services/axios';

const fetchAppOptionsSuccess = payload => ({
  type: appOptionTypes.FETCH_APP_OPTIONS_SUCCESS,
  payload,
});

const fetchAppOptionsFailure = payload => ({
  type: appOptionTypes.FETCH_APP_OPTIONS_FAILURE,
  payload,
});

export const fetchAppOptions = () => {
  return async dispatch => {
    dispatch(beginApiCall());
    try {
      const { data } = await Axios.get('/dropdown-options');

      if (data.status) {
        dispatch(fetchAppOptionsSuccess(data.data));
        dispatch(endApiCall());
      }
    } catch (err) {
      dispatch(fetchAppOptionsFailure(err));
      dispatch(endApiCall());
    }
  };
};
