import React, { lazy, useEffect } from 'react';
import { Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import PlainLayout from 'layouts/PlainLayout';
import AuthRoute from 'routes/AuthRoute';
import { fetchSubscriptions } from '../../settings/redux/actions';
// import AuthRoute from './AuthRoute';

const UserOnboarding = lazy(() => import('../pages/'));
const Setup = lazy(() => import('../pages/Setup'));
const Success = lazy(() => import('../pages/Success'));

const OnboardingRoutes = ({ match }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSubscriptions());
  }, []);

  const { loggedIn } = useSelector((state) => state.auth);

  return (
    <>
      <Switch>
        <AuthRoute
          exact
          path={`${match.path}`}
          isAuthenticated={loggedIn}
          layout={PlainLayout}
          component={UserOnboarding}
          accessGranted={true}
        />

        <AuthRoute
          exact
          path={`${match.path}/configuration`}
          isAuthenticated={loggedIn}
          layout={PlainLayout}
          component={Setup}
          accessGranted={true}
        />

        <AuthRoute
          exact
          path={`${match.path}/completed`}
          isAuthenticated={loggedIn}
          layout={PlainLayout}
          component={Success}
          accessGranted={true}
        />

        {/* <AuthRoute
          render={routeProps => (
            <Suspense fallback={<PageLoader />}>
              <ErrorPage {...routeProps} />
            </Suspense>
          )}
        /> */}
      </Switch>
    </>
  );
};

OnboardingRoutes.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default OnboardingRoutes;
