import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import moment from 'moment';

export const ViewLabCommentModal = (props) => (
  <Modal
    centered
    contentClassName={`modal--confirm ${props.color} `}
    isOpen={props.isOpen}
    nocloseicon="true"
    size="md"
    toggle={props.toggle}
  >
    <div className="modal--confirm__body">
      <h3 className="modal__title with-subtitle">Comments</h3>
      {props?.comments ? (
        <div className="form-control--user-comments">
          <div className="comment">
            <div className="comment-body">
              <p className="comment-body__header">
                {`${props?.comments?.author ? props?.comments?.author?.name : 'User'}`}{' '}
                <span className="float-right date">{moment(new Date(props?.comments?.date)).fromNow()}</span>
              </p>
              <p className="comment-body__footer">{props?.comments?.comment}</p>
            </div>
            {props?.comments?.files.map((file, index) => (
              <React.Fragment key={index}>
                <a href={file} target="_blank" rel="noreferrer">
                  View Attachment&nbsp; {props?.comments?.files.length > 1 && index + 1}{' '}
                </a>
                <br />
              </React.Fragment>
            ))}
            <span></span>
          </div>
        </div>
      ) : (
        <p>No comments made about this task</p>
      )}
    </div>

    <div className="modal--confirm__footer">
      <button className="btn modal--confirm__btn" onClick={props.closeClick}>
        Close
      </button>
    </div>
  </Modal>
);

ViewLabCommentModal.propTypes = {
  isOpen: PropTypes.bool,
  closeClick: PropTypes.func,
  toggle: PropTypes.func,
  comments: PropTypes.array,
  color: PropTypes.string,
};
