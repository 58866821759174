import { _notifyError } from 'utils';
import { spreadBranch } from 'services';
import Axios from 'services/axios';
import * as userTypes from '../types/userTypes';

const setAuthDataSuccess = payload => ({
  type: userTypes.SET_USER_DATA_SUCCESS,
  payload,
});

const setAuthDataFailure = error => ({
  type: userTypes.SET_USER_DATA_FAILURE,
  payload: error,
});

export const setAuthData = userDetails => async dispatch => {
  try {
    dispatch(setAuthDataSuccess(userDetails));
  } catch (error) {
    dispatch(setAuthDataFailure());
  }
};

const setCurrentBranchSuccess = payload => ({
  type: userTypes.SET_CURRENT_BRANCH,
  payload,
});

const setCurrentBranchFailure = error => ({
  type: userTypes.SET_CURRENT_BRANCH_FAILURE,
  payload: error,
});

export const setCurrentBranch = selectedBranch => async dispatch => {
  const { parentOrganizationId } = selectedBranch;
  const payload = { parentOrganizationId };
  if (selectedBranch.branchId) {
    payload.branchId = selectedBranch.branchId;
  }
  try {
    const { data } = await Axios.post('/select-organization', payload);
    if (!data.status) {
      dispatch(setCurrentBranchFailure());
    } else {
      dispatch(setCurrentBranchSuccess(selectedBranch));
    }
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error.message;
    _notifyError(errorMessage);
    dispatch(setCurrentBranchFailure());
  }
};

const fetchUserSuccess = payload => ({
  type: userTypes.FETCH_USER_SUCCESS,
  payload,
});

export const modifyUserData = payload => ({
  type: userTypes.MODIFY_USER_DATA,
  payload,
});

const fetchUserFailure = error => ({
  type: userTypes.FETCH_USER_FAILURE,
  payload: error,
});

export const fetchUser = () => async dispatch => {
  try {
    const { data } = await Axios.get('/staff-data');
    if (data.status) {
      const userInfo = spreadBranch(data.data);
      dispatch(fetchUserSuccess(userInfo));
    } else {
      dispatch(fetchUserFailure(data.message));
    }
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error.message;

    _notifyError(errorMessage);
    dispatch(fetchUserFailure(errorMessage));
  }
};

export const fetchStaffById = id => async dispatch => {
  try {
    const { data } = await Axios.get('/staff/' + id);
    if (data.status) {
      // eslint-disable-next-line
      const { ...rest } = data.data;
      dispatch({
        type: userTypes.MODIFY_USER_DATA,
        payload: { ...rest, details: { name: rest.name, email: rest.email, userId: rest._id } },
      });
    } else {
      dispatch(fetchUserFailure(data.message));
    }
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error.message;

    _notifyError(errorMessage);
    dispatch(fetchUserFailure(errorMessage));
  }
};

const initiateLogout = () => ({
  type: userTypes.INITIATE_USER_LOGOUT,
});

const finishLogout = payload => ({
  type: userTypes.FINSIH_USER_LOGOUT,
  payload,
});

const afterLogout = () => async dispatch => {
  dispatch({
    type: 'RESET_APP',
  });
  dispatch(finishLogout('success'));
};

export const userLogout = () => async dispatch => {
  dispatch(initiateLogout());
  try {
    dispatch(afterLogout());
  } catch (error) {
    dispatch(finishLogout({ error }));
    return false;
  }
};
