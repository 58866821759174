/* eslint-disable no-case-declarations */
import * as casenoteTypes from '../types/casenoteTypes';
import initialState from './initialState';
import { updateObject } from 'utils';

const casenotes = (state = initialState.casenotes, { type, payload }) => {
  switch (type) {
    case casenoteTypes.FETCH_CASENOTES_START:
      return updateObject(state, { isLoading: true });

    case casenoteTypes.FETCH_CASENOTES_SUCCESS:
      return updateObject(state, { isLoading: false, items: payload });

    case casenoteTypes.FETCH_CASENOTES_FAIL:
      return updateObject(state, { isLoading: false });

    case casenoteTypes.CREATE_CASENOTE_START:
      return updateObject(state, { isSaving: true });

    case casenoteTypes.CREATE_CASENOTE_SUCCESS:
      return updateObject(state, { isSaving: false });

    case casenoteTypes.CREATE_CASENOTE_FAIL:
      return updateObject(state, { isSaving: false });

    case casenoteTypes.ARCHIVE_CASENOTE_START:
      return updateObject(state, { isSaving: true });

    case casenoteTypes.ARCHIVE_CASENOTE_FAIL:
      return updateObject(state, { isSaving: false });

    case casenoteTypes.CREATE_CASENOTE_AND_END_SESSION_START:
      return updateObject(state, { isSavingCaseNoteAndEndingSession: true });
    case casenoteTypes.CREATE_CASENOTE_AND_END_SESSION_END:
      return updateObject(state, { isSavingCaseNoteAndEndingSession: false });

    default:
      return { ...state };
  }
};

export default casenotes;
