import React, { useEffect, useState } from 'react';
import { Button } from 'components/ui/buttons/button';
import { ReactComponent as Close } from 'assets/svg/close.svg';
import classnames from 'classnames';
import moment from 'moment';
import { createPortal } from 'react-dom';

export const SupportModal = () => {
  const currentDate = JSON.stringify(new Date());
  const pastDate = localStorage.getItem('pastDate');
  const hasPastDate = JSON.parse(pastDate);
  const [isOpen, setIsOpen] = useState(false);
  const a = moment(new Date());
  const b = moment(new Date(hasPastDate));
  const timeDifference = a.diff(b, 'hours', true);

  useEffect(() => {
    if (hasPastDate && timeDifference > 5) {
      setIsOpen(true);
      return;
    }
    setIsOpen(false);
  }, [hasPastDate, timeDifference]);

  const closeModal = () => {
    setIsOpen(false);
    localStorage.setItem('pastDate', currentDate);
  };

  const triggerSupportPage = () => {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      href: `/support`,
    }).click();
    closeModal();
  };

  return createPortal(
    <div
      className={classnames('support-modal', {
        'tw-hidden': !isOpen,
        'tw-block': isOpen,
      })}
    >
      <div className="support-modal-content tw-bg-primary-500">
        <div className="tw-flex tw-justify-between tw-items-center">
          <h4 className="fs-20">How can we help?</h4>

          <Close style={{ cursor: 'pointer' }} onClick={closeModal} color="#fff" strokeWidth={1} />
        </div>
        <p className="fs-14 tw-mt-3">We are just a few minutes away from responding to your complaint.</p>
        <div className="tw-mt-5">
          <Button variant="primary" className="tw-block tw-w-full btn btn-light" onClick={triggerSupportPage}>
            Send Complaint
          </Button>
        </div>
      </div>
    </div>,
    document.body,
  );
};

SupportModal.propTypes = {};
