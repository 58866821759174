import { _notifyError, _notifySuccess, _sortItems } from 'utils';
import * as discountTypes from '../types/discount';
import { beginApiCall, endApiCall } from '../../../../store/actions/apiActions';
import Axios from 'services/axios';
import { CUSTOM_ERROR_MESSAGE } from "utils/constants";

const updatedState = data => ({
  isSaving: false,
  error: '',
  items: data,
  filteredItems: data,
});


// const fetchDiscountCodeStart = payload => ({
//     type: discountTypes.FETCH_DISCOUNT_CODE_START,
//     payload
// });

const fetchDiscountCodeSuccess = payload => ({
  type: discountTypes.FETCH_DISCOUNT_CODE_SUCCESS,
  payload,
});

const fetchDiscountCodeFailed = () => ({
  type: discountTypes.FETCH_DISCOUNT_CODE_FAILED,
});

const addDiscountCodeStart = () => ({
  type: discountTypes.ADD_DISCOUNT_CODE_START,
});

const addDiscountCodeSuccess = payload => ({
  type: discountTypes.ADD_DISCOUNT_CODE_SUCCESS,
  payload,
});

const addDiscountCodeFailed = () => ({
  type: discountTypes.ADD_DISCOUNT_CODE_FAILED,
});

const updateDiscountCodeStart = () => ({
  type: discountTypes.UPDATE_DISCOUNT_CODE_START,
});

const updateDiscountCodeSuccess = payload => ({
  type: discountTypes.UPDATE_DISCOUNT_CODE_SUCCESS,
  payload,
});

const updateDiscountCodeFailed = payload => ({
  type: discountTypes.UPDATE_DISCOUNT_CODE_FAILED,
  payload,
});

export const fetchDiscountCodes = () => async dispatch => {
  dispatch(beginApiCall());
  try {
    const { data } = await Axios.get('discounts');
    if (data.status) {
      const payload = _sortItems(data.data, 'name');
      dispatch(endApiCall());
      dispatch(fetchDiscountCodeSuccess(updatedState(payload)));
      return true;
    }
  } catch (err) {
    dispatch(endApiCall());
    _notifyError(err.message);
    dispatch(fetchDiscountCodeFailed(err.message));
    return false;
  }
};

export const addDiscountCode = (current, arr, close) => {
  return async dispatch => {
    dispatch(addDiscountCodeStart());
    try {
      const { data, status } = await Axios.post('discounts', current);

      if (status) {
        const cache = _sortItems([...arr, ...data.data], 'code');
        dispatch(addDiscountCodeSuccess(updatedState(cache)));
        _notifySuccess('Discount successfully added.');
        close();
      }
    } catch (error) {
      _notifyError(CUSTOM_ERROR_MESSAGE);
      dispatch(addDiscountCodeFailed(CUSTOM_ERROR_MESSAGE));
    }
  };
};

export const updateDiscountCode = (id, current, updatedItem, close, action) => {
  return async dispatch => {
    dispatch(updateDiscountCodeStart());
    try {
      const { status } = await Axios.patch(`discounts/${id}`, current);

      if (status) {
        const err = `Discount code ${action} successfully.`;
        dispatch(updateDiscountCodeSuccess({ updatedObject: updatedItem }));

        _notifySuccess(err);
        close();
      } else {
        throw Error;
      }
    } catch (error) {
      _notifyError(CUSTOM_ERROR_MESSAGE);
      dispatch(updateDiscountCodeFailed(CUSTOM_ERROR_MESSAGE));
    }
  };
};

export const updateDiscountCodeList = value => ({
  type: discountTypes.UPDATE_DISCOUNT_CODE_LIST,
  payload: {
    filteredItems: value,
  },
});

// export const deleteDiscountCode = (id, toggle, toggleViewModal) => {
//   return async dispatch => {
//     dispatch();
//     try {
//       const { status } = await Axios.delete('deleteDiscountCode', [id]);

//       if (status) {
//         toggle();
//         toggleViewModal();
//         // dispatch(deleteDiscountCodeSuccess(id));

//         _notifySuccess(`Discount Code Deleted successfully.`);
//       }
//     } catch (error) {
//       _notifyError(CUSTOM_ERROR_MESSAGE);
//       // dispatch(deleteDiscountCodeFailed(CUSTOM_ERROR_MESSAGE));
//     }
//   };
// };
