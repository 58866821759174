export const SET_USER_DATA_SUCCESS = 'SET_USER_DATA_SUCCESS';
export const SET_USER_DATA_FAILURE = 'SET_USER_DATA_FAILURE';

export const SET_CURRENT_BRANCH = 'SET_CURRENT_BRANCH';
export const SET_CURRENT_BRANCH_FAILURE = 'SET_CURRENT_BRANCH_FAILURE';

export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

export const START_API_CALL = 'START_API_CALL';
export const END_API_CALL = 'END_API_CALL';

export const INITIATE_USER_LOGOUT = 'INITIATE_USER_LOGOUT';
export const FINSIH_USER_LOGOUT = 'FINSIH_USER_LOGOUT';

export const MODIFY_USER_DATA = 'MODIFY_USER_DATA';
