/* eslint-disable no-case-declarations */
import * as referralTypes from '../types/referralTypes';
import initialState from './initialState';
import { updateObject } from 'utils';

const vitals = (state = initialState.referral, { type, payload }) => {
  switch (type) {
    case referralTypes.FETCH_REFERRALS_START:
      return updateObject(state, { isLoading: true });

    case referralTypes.FETCH_REFERRALS_SUCCESS:
      return updateObject(state, { isLoading: false, items: payload });

    case referralTypes.FETCH_REFERRALS_FAIL:
      return updateObject(state, { isLoading: false });

    case referralTypes.ADD_REFERRAL_START:
      return updateObject(state, { isLoading: true });

    case referralTypes.ADD_REFERRAL_SUCCESS:
      return updateObject(state, { isLoading: false });

    case referralTypes.ADD_REFERRAL_FAIL:
      return updateObject(state, { isLoading: false });

    default:
      return { ...state };
  }
};

export default vitals;
