import { _notifyError, _notifySuccess, printError } from "utils";
import * as inventoryTypes from "../types/inventoryTypes";
import Axios from "services/axios";
import { CUSTOM_ERROR_MESSAGE } from "utils/constants";

const getConsumablesStart = () => ({
  type: inventoryTypes.GET_INVENTORY_CONSUMABLES_START,
});

const getConsumablesSuccess = (payload) => ({
  type: inventoryTypes.GET_INVENTORY_CONSUMABLES_SUCCESS,
  payload,
});

const getConsumablesFailure = (payload) => ({
  type: inventoryTypes.GET_INVENTORY_CONSUMABLES_FAILURE,
  payload,
});

export const getConsumables = (location, params) => {
  return async (dispatch) => {
    dispatch(getConsumablesStart());
    try {
      const { data } = await Axios.get(`locations/${location}/stock-movements?${params}`);
      if (data.status) {
        dispatch(getConsumablesSuccess(data.data));
      }
    } catch (error) {
      const errorMessage = printError(error);
      _notifyError(errorMessage);
      dispatch(getConsumablesFailure(CUSTOM_ERROR_MESSAGE));
    }
  };
};

const dispenseItemsStart = () => ({
  type: inventoryTypes.DISPENSE_INVENTORY_CONSUMABLES_START,
});

// eslint-disable-next-line no-unused-vars
const dispenseItemsSuccess = (payload) => ({
  type: inventoryTypes.DISPENSE_INVENTORY_CONSUMABLES_SUCCESS,
  payload,
});

const dispenseItemsFailure = (payload) => ({
  type: inventoryTypes.DISPENSE_INVENTORY_CONSUMABLES_FAILURE,
  payload,
});

export const dispenseItems = (toDispense, toggle) => {
  return async (dispatch) => {
    dispatch(dispenseItemsStart());
    const url = "/consumables";
    try {
      const { data } = await Axios.post(url, toDispense);
      if (data.status) {
        _notifySuccess("Items dispensed successfully");
        toggle();
        return true;
      }
      throw Error();
    } catch (error) {
      const errorMessage = printError(error);
      _notifyError(errorMessage);
      dispatch(dispenseItemsFailure(CUSTOM_ERROR_MESSAGE));
      return false;
    }
  };
};

const returnConsumablesStart = () => ({
  type: inventoryTypes.RETURN_CONSUMABLES_START,
});

const returnConsumablesSuccess = (payload) => ({
  type: inventoryTypes.RETURN_CONSUMABLES_SUCCESS,
  payload,
});

const returnConsumablesFailure = (payload) => ({
  type: inventoryTypes.RETURN_CONSUMABLES_FAILURE,
  payload,
});

export const returnConsumables = (id, toReturn, toggle) => {
  return async (dispatch) => {
    dispatch(returnConsumablesStart());
    try {
      const { data } = await Axios.post(`/consumables/${id}/return`, toReturn);
      if (data.status) {
        dispatch(returnConsumablesSuccess(data.data));
        _notifySuccess("Items Returned successfully");
        toggle();
        return true;
      }
      throw Error();
    } catch (error) {
      const errorMessage = printError(error);
      _notifyError(errorMessage);
      dispatch(returnConsumablesFailure(CUSTOM_ERROR_MESSAGE));
      return false;
    }
  };
};
