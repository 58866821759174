import React, { useState } from "react";
import { useDispatch } from "react-redux";
import InfiniteScroll from "../InfiniteScroll";
import PropTypes from "prop-types";
import { TaskContainer } from "../../components";
import { CommentModal, ConfirmModal, InprogressModal, Loader } from "components";
import {
  addComments,
  addResultAction,
  assignTask,
  completeTask,
  extendDueDate,
  getActiveTask,
  unassignTask,
} from "modules/laboratory/redux/actions";
import { IN_PROGRESS } from "utils/constants";
import { canCompleteTask } from "modules/laboratory/pages/helpers";
import { _formatDate, convertFile } from "utils";

export const InProgressTasks = ({
  activeTasks,
  hasPermission,
  isSaving,
  activeTask,
  userId,
  staffList,
  inProgress,
  refresh,
}) => {
  const [inprogressModal, setInprogressModal] = useState(false);
  const [completeModal, setCompleteModal] = useState(false);
  const [commentModal, setCommentModal] = useState(false);
  const [comment, setComment] = useState("");
  const [showComment, setShowComment] = useState(false);
  const [completeMode, setCompleteMode] = useState(false);
  const [result, setResult] = useState({ comment, files: [] });

  const dispatch = useDispatch();
  const toggleCompleteModal = () => setCompleteModal(!completeModal);
  const toggleInprogressModal = () => {
    setInprogressModal(!inprogressModal);
  };
  const toggleComments = () => {
    setShowComment(!showComment);
  };
  const _handleTaskAssign = (item) => {
    const isAssigned = activeTask.assignees.some((person) => person._id === item.id);
    if (isAssigned) return window.alert("Staff already assigned to this task");
    const task = activeTask._id;
    const staff = item.id;
    dispatch(assignTask(task, staff));
  };
  const triggerCompleteTask = () => {
    toggleInprogressModal();
    toggleCompleteModal();
  };
  const viewInprogressTask = (task) => {
    dispatch(getActiveTask(task));
    toggleInprogressModal();
  };
  const triggerAddResultModal = () => {
    setCompleteMode(true);
    setCommentModal(!commentModal);
    setComment("");
  };
  const toggleCommentModal = () => {
    setCompleteMode(false);
    setCommentModal(!commentModal);
    setComment("");
    setResult({ comment, files: [] });
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
    completeMode && setResult((prev) => ({ ...prev, comment: e.target.value }));
  };

  const updateNewComment = () => {
    dispatch(addComments(activeTask?._id, comment, toggleCommentModal));
  };
  const onFileChange = async (e) => {
    const fileItems = [];
    const { files } = e.target;
    for (let i = 0; i < files.length; i++) {
      files[i].id = i;
      files[i].binaryString = await convertFile(files[i]);
      fileItems.push(files[i]);
    }
    const filePack = Object.values(fileItems).map((file) => ({
      name: file.name,
      binaryString: file.binaryString,
      type: file.type,
    }));
    setResult((prev) => ({ ...prev, files: filePack }));
  };

  const onDeleteFIle = (e) => {
    const { id } = e.target;
    const { files } = result;
    const filesPack = [...files];
    setResult((prev) => ({ ...prev, files: filesPack.filter((file) => file.binaryString !== id) }));
  };

  const addResult = () => {
    dispatch(addResultAction(activeTask._id, result, toggleCommentModal));
  };
  const onCompleteTask = () => {
    if (!activeTask.results?.length > 0) return window.alert("You need to upload the test results");
    dispatch(completeTask(activeTask._id, toggleCompleteModal));
  };
  const _deleteAssignee = (id) => {
    const response = window.confirm("Are you sure you want to remove this staff from the assigned task?");
    if (response) {
      dispatch(unassignTask(activeTask._id, id));
    }
  };
  const changeDueDate = (newDate) => {
    const response = window.confirm(`Are you sure you want to extend the due date to ${_formatDate(newDate)}`);
    if (response) return dispatch(extendDueDate(activeTask._id, newDate));
  };

  return (
    <>
      <InprogressModal
        inProgress={inProgress}
        isOpen={inprogressModal}
        searchData={staffList}
        toggle={toggleInprogressModal}
        permitted={hasPermission(["assign-user", "super-edit"])}
        rightButtonPress={activeTask?.results.length > 0 ? triggerCompleteTask : triggerAddResultModal}
        rightButtonDisabled={
          !hasPermission(["complete-job", "super-edit"]) || !canCompleteTask(activeTask?.assignees, userId)
        }
        onCommentClick={toggleCommentModal}
        _handleTaskAssign={_handleTaskAssign}
        deleteAssignee={_deleteAssignee}
        allowDateEdit
        changeDueDate={changeDueDate}
        isSubmitting={isSaving}
        entity={activeTask}
      />
      <CommentModal
        isOpen={commentModal}
        isSaving={isSaving}
        showComment={showComment}
        toggleComments={toggleComments}
        toggle={toggleCommentModal}
        fileList={result?.files || []}
        onFileChange={onFileChange}
        onDeleteFIle={onDeleteFIle}
        comments={activeTask?.comments || null}
        comment={comment}
        disablePositiveClick={completeMode ? !(result.files?.length || comment) : !comment}
        onCommentChange={(e) => handleCommentChange(e)}
        positiveClick={completeMode ? addResult : updateNewComment}
        negativeClick={toggleCommentModal}
        complete={completeMode}
      />
      <ConfirmModal
        isSaving={isSaving}
        toggle={toggleCompleteModal}
        positiveClick={onCompleteTask}
        negativeClick={triggerAddResultModal}
        isOpen={completeModal}
        color="yellow"
        title="Complete?"
        subtitle="You need to add a result to complete this task."
        positiveText="Mark as done"
        negativeText="Add result"
      />
      {activeTasks?.isLoading ? (
        <Loader />
      ) : (
        <TaskContainer items={activeTasks?.totalDocs || 0} title="In Progress" refresh={refresh}>
          <InfiniteScroll
            taskObject={activeTasks}
            dispatch={dispatch}
            taskStatus={IN_PROGRESS}
            viewSingleTask={viewInprogressTask}
          />
        </TaskContainer>
      )}
    </>
  );
};

InProgressTasks.propTypes = {
  staffList: PropTypes.array,
  activeTasks: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  hasPermission: PropTypes.func,
  isSaving: PropTypes.bool,
  authData: PropTypes.object,
  activeTask: PropTypes.object,
  userId: PropTypes.string,
  inProgress: PropTypes.bool,
  refresh: PropTypes.func,
};
