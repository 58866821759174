import { _notifyError, _notifySuccess, _sortItems } from "utils";
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "services/baseQuery";
import { closeAllModals } from "store/features/modalSlice";

export const categoriesApi = createApi({
  reducerPath: "categoriesApi",
  baseQuery,
  tagTypes: ["Categories"],
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: () => ({
        url: `/categories`,
      }),
      providesTags: ["Categories"],
      transformResponse: (response) => _sortItems([...response.data], "title"),
    }),
    deleteCategory: builder.mutation({
      query: (id) => ({
        url: `/categories/${id}`,
        method: "DELETE",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          _notifySuccess(`Success : ${response.data.message}`);
          dispatch(closeAllModals());
        } catch (err) {
          _notifyError(`Error : ${err.error.data.message}`);
        }
      },
      invalidatesTags: ["Categories"],
    }),
    addCategory: builder.mutation({
      query: (current) => ({
        url: `/categories`,
        method: "POST",
        body: current,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          _notifySuccess(`Success : ${response.data.message}`);
          dispatch(closeAllModals());
        } catch (err) {
          _notifyError(`Error : ${err.error.data.message}`);
        }
      },
      invalidatesTags: ["Categories"],
    }),
    updateCategory: builder.mutation({
      query: ({ id, current }) => {
        const newPayload = Array.isArray(current) ? current : current;
        return {
          url: `/categories/${id}`,
          method: "PATCH",
          body: newPayload,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          _notifySuccess(`Success : ${response.data.message}`);
          dispatch(closeAllModals());
        } catch (err) {
          _notifyError(`Error : ${err.error.data.message}`);
        }
      },
      invalidatesTags: ["Categories"],
    }),
  }),
});

export const { useGetCategoriesQuery, useAddCategoryMutation, useUpdateCategoryMutation, useDeleteCategoryMutation } =
  categoriesApi;
