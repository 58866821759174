export const FETCH_BANKS_SUCCESS = 'FETCH_BANKS_SUCCESS';
export const FETCH_BANKS_FAILED = 'FETCH_BANKS_FAILED';

export const ADD_BANKS_START = 'ADD_BANKS_START';
export const ADD_BANKS_END = 'ADD_BANKS_END';

export const ADD_BANKS_SUCCESS = 'ADD_BANKS_SUCCESS';
export const ADD_BANKS_FAILED = 'ADD_BANKS_FAILED';

export const UPDATE_BANKS_SUCCESS = 'UPDATE_BANKS_SUCCESS';
export const UPDATE_BANKS_FAILED = 'UPDATE_BANKS_FAILED';

export const UPDATE_BANK_LIST = 'UPDATE_BANK_LIST';
