/* eslint-disable max-len */
/* eslint-disable no-case-declarations */
import * as taskManagementTypes from '../types/auditTrailTypes';
import initialState from './initialState';
import { updateObject } from 'utils';

const auditTrail = (state = initialState.taskManagement, { type, payload }) => {
  switch (type) {
    case taskManagementTypes.FETCH_AUDIT_TRAIL_START:
      return updateObject(state, { ...state.auditTrail, loading: true });

    case taskManagementTypes.FETCH_AUDIT_TRAIL_SUCCESS:
      return updateObject(state, { ...state.auditTrail, items: payload, loading: false });

    case taskManagementTypes.FETCH_AUDIT_TRAIL_FAILURE:
      return updateObject(state, { ...state.auditTrail, loading: false });

    default:
      return { ...state };
  }
};

export default auditTrail;
