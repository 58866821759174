/* eslint-disable no-case-declarations */
import * as claimTypes from '../types/claimTypes';
import initialState from './initialState';
import { updateObject } from 'utils';

const invoice = (state = initialState.claims, { type, payload, key }) => {
  switch (type) {
    case claimTypes.FETCH_CLAIMS_DASHBOARD_STATS_START:
      return updateObject(state, { stats: { ...state.stats, loading: true } });
    case claimTypes.FETCH_CLAIMS_DASHBOARD_STATS_SUCCESS:
      return updateObject(state, { stats: { ...state.stats, items: payload, loading: false } });
    case claimTypes.FETCH_CLAIMS_DASHBOARD_STATS_FAIL:
      return updateObject(state, { stats: { ...state.stats, loading: false } });

    case claimTypes.FETCH_CLAIMS_START:
      return updateObject(state, { items: { ...state.items, [key]: { ...state.items[key], loading: true } } });
    case claimTypes.FETCH_CLAIMS_SUCCESS:
      return updateObject(state, {
        items: { ...state.items, [key]: { ...state.items[key], items: payload, loading: false } },
      });
    case claimTypes.FETCH_CLAIMS_FAIL:
      return updateObject(state, { items: { ...state.items, [key]: { ...state.items[key], loading: false } } });

    case claimTypes.MARK_CLAIMS_SUBMITTED_START:
      return updateObject(state, { markClaimSubmittedLoading: true });
    case claimTypes.MARK_CLAIMS_SUBMITTED_END:
      return updateObject(state, { markClaimSubmittedLoading: false });

    case claimTypes.MARK_CLAIMS_PAID_START:
      return updateObject(state, { markClaimPaidLoading: true });
    case claimTypes.MARK_CLAIMS_PAID_END:
      return updateObject(state, { markClaimPaidLoading: false });

    case claimTypes.CREATE_CLAIMS_START:
      return updateObject(state, { createLoading: true });
    case claimTypes.CREATE_CLAIMS_END:
      return updateObject(state, { createLoading: false });
    default:
      return { ...state };
  }
};

export default invoice;
