import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const Check = props => {
  const currentUser = useSelector(state => state.user.current);
  const assignedPermissions = currentUser.permissions;
  let display = false;

  if (assignedPermissions) {
    switch (typeof props.permission) {
      case 'object':
        display = assignedPermissions.some(permission => {
          return props.permission.includes(permission);
        });
        break;
      case 'string':
        display = assignedPermissions.includes(props.permission);
        break;
      default:
        break;
    }
  }
  const result = display ? props.children || null : null;
  return result;
};

Check.propTypes = {
  permission: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Check;
