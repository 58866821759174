/* eslint-disable no-case-declarations */
import * as surgeryRecordTypes from '../types/surgeryRecordTypes';
import initialState from './initialState';
import { updateObject } from 'utils';

const SurgeryRecord = (state = initialState.surgeryRecord, { type, payload }) => {
  switch (type) {
    case surgeryRecordTypes.FETCH_CONSENT_START:
      return updateObject(state, { isLoading: true });

    case surgeryRecordTypes.FETCH_CONSENT_SUCCESS:
      return updateObject(state, { isLoading: false, items: payload });

    case surgeryRecordTypes.FETCH_CONSENT_FAIL:
      return updateObject(state, { isLoading: false });

    case surgeryRecordTypes.ADD_CONSENT_START:
      return updateObject(state, { isLoading: true });

    case surgeryRecordTypes.ADD_CONSENT_SUCCESS:
      return updateObject(state, { isLoading: false });

    case surgeryRecordTypes.ADD_CONSENT_FAIL:
      return updateObject(state, { isLoading: false });

    default:
      return { ...state };
  }
};

export default SurgeryRecord;
