import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "services/baseQuery";
import { _notifyError, _notifySuccess, getSerializedParams, printError } from "utils";
import { CUSTOM_ERROR_MESSAGE } from "utils/constants";

const billsApi = createApi({
  reducerPath: "billsApi",
  baseQuery,
  tagTypes: ["Bills"],
  endpoints: (builder) => ({
    fetchBills: builder.query({
      query: (queryParams) => {
        const query = getSerializedParams(queryParams, true);
        return {
          url: `/bills${query ? `?${query}` : ""}`,
        };
      },
      providesTags: ["Bills"],
      transformResponse: (response) => response.data,
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.status) {
            //
          }
        } catch (error) {
          const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
          _notifyError(errorMessage);
        }
      },
    }),
    fetchBill: builder.query({
      query: ({ id, ...queryParams }) => ({
        url: `/bills/${id}`,
        params: queryParams,
      }),
      providesTags: ["Bill"],
      transformResponse: (response) => response.data,
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.status) {
            //
          }
        } catch (error) {
          const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
          _notifyError(errorMessage);
        }
      },
    }),
    fetchPatientsBalance: builder.query({
      query: ({ id }) => ({
        url: `/patients/${id}/balances`,
      }),
      providesTags: ["Patients-Balance"],
      transformResponse: (response) => response.data,
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          const errorMessage = printError(error);
          _notifyError(errorMessage);
        }
      },
    }),
    fetchValueAddedServices: builder.query({
      query: ({ branchId }) => ({
        url: `/vas?branchId=${branchId}`,
      }),
      transformResponse: (response) => response.data,
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.status) {
            //
          }
        } catch (error) {
          const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
          _notifyError(errorMessage);
        }
      },
    }),
    deleteBillItem: builder.mutation({
      query: (itemId) => ({
        url: `/bill-items/${itemId}`,
        method: "DELETE",
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const response = await queryFulfilled;
          _notifySuccess(`Success : ${response.data.message}`);
        } catch (error) {
          const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
          _notifyError(errorMessage);
        }
      },
    }),
    stashBillItem: builder.mutation({
      query: (id) => ({
        url: `/bill-items/${id}/stash`,
        method: "POST",
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const response = await queryFulfilled;
          _notifySuccess(`Success : ${response.data.message}`);
        } catch (error) {
          const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
          _notifyError(errorMessage);
        }
      },
    }),
    fetchFinanceDashboardStats: builder.query({
      query: () => "/finance/dashboard-stats",
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.status) {
            //
          }
        } catch (error) {
          const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
          _notifyError(errorMessage);
        }
      },
    }),
    fetchWithdrawals: builder.query({
      query: ({ branchId, queryParams }) =>
        `/wallet-transactions?purpose=withdrawal&branchId=${branchId}&${queryParams}`,
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.status) {
            //
          }
        } catch (error) {
          const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
          _notifyError(errorMessage);
        }
      },
    }),
    withdrawals: builder.mutation({
      query: (payload) => ({
        url: "/wallet-withdrawal/patient",
        method: "POST",
        body: payload,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.status) {
            _notifySuccess("Withdrawal successfully made");
          }
        } catch (error) {
          const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
          _notifyError(errorMessage);
        }
      },
    }),
    createPayment: builder.mutation({
      query: ({ billId, payload }) => ({
        url: `/bills/${billId}/payments`,
        method: "POST",
        body: payload,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.status) {
            _notifySuccess(data.message || "Payment made successfully");
          }
        } catch (error) {
          const errorMessage = printError(error);
          _notifyError(errorMessage);
        }
      },
      invalidatesTags: ["Bills, Carts"],
    }),
    issueCredit: builder.mutation({
      query: (payload) => ({
        url: `/credit-notes`,
        method: "POST",
        body: payload,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.status) {
            _notifySuccess(data.message || "Credit issued successfully");
          }
        } catch (error) {
          const errorMessage = printError(error);
          _notifyError(errorMessage);
        }
      },
      invalidatesTags: ["Bills"],
    }),
    fetchCreditNotes: builder.query({
      query: (queryParams) => ({
        url: `/credit-notes`,
        params: queryParams,
      }),
      providesTags: ["Credit-Notes"],
      transformResponse: (response) => response.data,
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.status) {
            //
          }
        } catch (error) {
          const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
          _notifyError(errorMessage);
        }
      },
    }),
    createClaims: builder.mutation({
      query: ({ billId, payload }) => ({
        url: `/bills/${billId}/claims`,
        method: "POST",
        body: payload,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.status) {
            _notifySuccess(data.message || " Successfully");
          }
        } catch (error) {
          const errorMessage = printError(error);
          _notifyError(errorMessage);
        }
      },
      invalidatesTags: ["Bills"],
    }),
    cancelInvoice: builder.mutation({
      query: ({ billId, payload }) => ({
        url: `/bills/${billId}/cancel`,
        method: "POST",
        body: payload,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const response = await queryFulfilled;
          _notifySuccess(`Success : ${response.data.message}`);
          close?.();
        } catch (error) {
          const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
          _notifyError(errorMessage);
        }
      },
    }),
  }),
});

export const {
  useFetchBillsQuery,
  useFetchBillQuery,
  useFetchPatientsBalanceQuery,
  useFetchCreditNotesQuery,
  useFetchValueAddedServicesQuery,
  useCreateClaimsMutation,
  useCreatePaymentMutation,
  useIssueCreditMutation,
  useCancelInvoiceMutation,
} = billsApi;

export default billsApi;
