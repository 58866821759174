export const FETCH_COMPANY_START = 'FETCH_COMPANY_START';
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS';
export const FETCH_COMPANY_FAILED = 'FETCH_COMPANY_FAILED';

export const UPDATE_COMPANY_START = 'UPDATE_COMPANY_START';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAILED = 'UPDATE_COMPANY_FAILED';

export const FETCH_COMPANY_CUSTOMIZATION_START = 'FETCH_COMPANY_CUSTOMIZATION_START';
export const FETCH_COMPANY_CUSTOMIZATION_SUCCESS = 'FETCH_COMPANY_CUSTOMIZATION_SUCCESS';
export const FETCH_COMPANY_CUSTOMIZATION_FAILED = 'FETCH_COMPANY_CUSTOMIZATION_FAILED';

export const UPDATE_COMPANY_CUSTOMIZATION_START = 'UPDATE_COMPANY_CUSTOMIZATION_START';
export const UPDATE_COMPANY_CUSTOMIZATION_SUCCESS = 'UPDATE_COMPANY_CUSTOMIZATION_SUCCESS';
export const UPDATE_COMPANY_CUSTOMIZATION_FAILED = 'UPDATE_COMPANY_CUSTOMIZATION_FAILED';

export const UPDATE_COLORS = 'UPDATE_COLORS';

export const FETCH_LOCATIONS = 'FETCH_LOCATIONS';
export const FETCH_LOCATION = 'FETCH_LOCATION';

export const START_API_CALL = 'START_API_CALL';
export const END_API_CALL = 'END_API_CALL';
