import Axios from 'axios';
import { userLogout } from 'store/actions';
import { store } from 'store';
import { environment } from 'config';
import { _notifyError, printError } from 'utils';

const {
  api: { uri },
} = environment;

const axios = Axios.create({
  baseURL: uri,
  withCredentials: true,
});

axios.interceptors.response.use(
  (res) => res,
  (err) => {
    // alert(JSON.stringify(err));
    if ((err?.response?.data?.message || '').includes('has been deactivated')) {
      store.dispatch(userLogout());
      _notifyError(printError(err));
      throw err;
    }

    if ((err?.response?.data?.message || '').includes('not logged in')) {
      store.dispatch(userLogout());
      throw err;
    }

    throw err;
  },
);

export default axios;
