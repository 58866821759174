import Axios from "services/axios";
import { _notifyError, _notifySuccess, printError } from "utils";
import { CUSTOM_ERROR_MESSAGE } from "utils/constants";
import * as inventoryTypes from "../types/inventoryTypes";

const getRecallsStart = () => ({ type: inventoryTypes.GET_RECALLS_START });

const getRecallsSuccess = (payload) => ({
  type: inventoryTypes.GET_RECALLS_SUCCESS,
  payload,
});

const getRecallsFailure = (payload) => ({
  type: inventoryTypes.GET_RECALLS_FAILURE,
  payload,
});

export const getRecalls = (location, queryParams) => {
  return async (dispatch) => {
    dispatch(getRecallsStart());
    try {
      const { data } = await Axios.get(
        `/locations/${location}/stock-movements?type=recall${queryParams ? `&${queryParams}` : ""}`,
      );
      if (data.status) {
        dispatch(getRecallsSuccess(data.data));
      }
    } catch (error) {
      const errorMessage = printError(error);
      _notifyError(errorMessage);
      dispatch(getRecallsFailure(errorMessage));
    }
  };
};

const initiateRecallStart = () => ({ type: inventoryTypes.INITIATE_RECALL_START });

const initiateRecallFailure = (error) => ({
  type: inventoryTypes.INITIATE_RECALL_FAILURE,
  error,
});

// eslint-disable-next-line no-unused-vars
const initiateRecallSuccess = (payload) => ({
  type: inventoryTypes.INITIATE_RECALL_SUCCESS,
  payload,
});

export const initiateRecall = (payload, close) => {
  const { stocks, reason, from } = payload;

  return async (dispatch) => {
    dispatch(initiateRecallStart());
    try {
      const { data } = await Axios.post(`/recalls`, {
        from,
        stocks,
        reason,
      });
      if (data.status) {
        _notifySuccess("Item(s) recall initiated.");
        close();
      }
    } catch (error) {
      const errorMessage = error?.response
        ? error?.response?.data?.message || error?.response?.data
        : CUSTOM_ERROR_MESSAGE;
      _notifyError(errorMessage);
      dispatch(initiateRecallFailure(CUSTOM_ERROR_MESSAGE));
    }
  };
};

const updateRecallStart = () => ({ type: inventoryTypes.UPDATE_RECALL_START });

const updateRecallFailure = (error) => ({
  type: inventoryTypes.UPDATE_RECALL_FAILURE,
  error,
});

const updateRecallSuccess = (payload) => ({
  type: inventoryTypes.UPDATE_RECALL_SUCCESS,
  payload,
});

export const updateRecall = (payload, close) => {
  const { _id, stocks, from, reason, ...rest } = payload;

  return async (dispatch) => {
    dispatch(updateRecallStart());
    try {
      const { data } = await Axios.patch(`/recalls/${_id}`, {
        from,
        stocks,
        reason,
      });
      if (data.status) {
        const cache = { ...data.data, from, reason, ...rest };
        dispatch(updateRecallSuccess(cache));
        _notifySuccess(`Updated Recall item successfully.`);
        close();
      }
    } catch (error) {
      const errorMessage = error.response ? error.response.data : CUSTOM_ERROR_MESSAGE;
      _notifyError(errorMessage);
      dispatch(updateRecallFailure(CUSTOM_ERROR_MESSAGE));
    }
  };
};

export const processRecallSuccess = (payload) => ({
  type: inventoryTypes.PROCESS_RECALL,
  payload,
});

export const processRecall = (itemId, type, toggle) => {
  return async (dispatch) => {
    try {
      const { data } = await Axios.post(`/recalls/${itemId}/${type}`);
      let response = data?.data;

      if (data.status && response && response !== "") {
        dispatch(processRecallSuccess({ ...response, _id: itemId }));
        _notifySuccess(`Recall successfully ${type === "process" ? "processed" : "received"}`);
        toggle();

        return true;
      }

      throw Error(response === "" ? data?.message : null);
    } catch (error) {
      _notifyError(printError(error));
      return false;
    }
  };
};
