import * as React from "react";
import { cn } from "utils";
import PropTypes from "prop-types";

const Table = React.forwardRef(({ className, ...props }, ref) => (
  <div className="tw-relative tw-w-full tw-overflow-auto">
    <table ref={ref} className={cn("tw-w-full tw-caption-bottom tw-text-[14px]", className)} {...props} />
  </div>
));
Table.displayName = "Table";
Table.propTypes = {
  className: PropTypes.string,
};

const TableHeader = React.forwardRef(({ className, ...props }, ref) => (
  <thead ref={ref} className={cn("[&_tr]:tw-border-b", className)} {...props} />
));
TableHeader.displayName = "TableHeader";
TableHeader.propTypes = {
  className: PropTypes.string,
};

const TableBody = React.forwardRef(({ className, ...props }, ref) => (
  <tbody ref={ref} className={cn("[&_tr:last-child]:tw-border-0", className)} {...props} />
));
TableBody.displayName = "TableBody";
TableBody.propTypes = {
  className: PropTypes.string,
};

const TableFooter = React.forwardRef(({ className, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={cn("tw-border-t tw-bg-muted/50 tw-font-medium [&>tr]:last:tw-border-b-0", className)}
    {...props}
  />
));
TableFooter.displayName = "TableFooter";
TableFooter.propTypes = {
  className: PropTypes.string,
};

const TableRow = React.forwardRef(({ className, ...props }, ref) => (
  <tr
    ref={ref}
    className={cn(
      "tw-border-b tw-transition-colors tw-bg-white hover:tw-bg-muted/50 data-[state=selected]:tw-bg-muted",
      className,
    )}
    {...props}
  />
));

TableRow.displayName = "TableRow";
TableRow.propTypes = {
  className: PropTypes.string,
};

const TableHead = React.forwardRef(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={cn(
      "tw-h-[44px] tw-px-[16px] tw-text-left tw-align-middle tw-font-medium tw-text-primary-600 [&:has([role=checkbox])]:tw-pr-0",
      className,
    )}
    {...props}
  />
));
TableHead.displayName = "TableHead";
TableHead.propTypes = {
  className: PropTypes.string,
};

const TableCell = React.forwardRef(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={cn("tw-px-[16px] tw-py-[18px] tw-align-middle [&:has([role=checkbox])]:tw-pr-0", className)}
    {...props}
  />
));
TableCell.displayName = "TableCell";
TableCell.propTypes = {
  className: PropTypes.string,
};

const TableCaption = React.forwardRef(({ className, ...props }, ref) => (
  <caption ref={ref} className={cn("tw-mt-4 tw-text-sm tw-text-muted-foreground", className)} {...props} />
));
TableCaption.displayName = "TableCaption";
TableCaption.propTypes = {
  className: PropTypes.string,
};

export { Table, TableHeader, TableBody, TableFooter, TableHead, TableRow, TableCell, TableCaption };
