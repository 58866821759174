import React from "react";
import PropType from "prop-types";

export const Loader = ({ extraClass = "" }) => (
  <div className={`loader-block tw-col-span-12 ${extraClass}`}>
    <div className="loader">
      <div className="lds-roller">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  </div>
);

Loader.propTypes = {
  extraClass: PropType.string,
};
