import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/ui/buttons/button';
import { Modal } from './index';
import { UserCard } from 'components/usercard';
import { ModalSearch } from 'components';

const Footer = (props) => (
  <>
    <Button
      variant="primary"
      disabled={props.rightButtonDisabled}
      onClick={props.rightButtonPress}
      className="modal__btn modal__btn--complete"
    >
      Complete
    </Button>
  </>
);

Footer.propTypes = {
  rightButtonPress: PropTypes.func,
  rightButtonDisabled: PropTypes.bool,
};

export const InprogressModal = ({ ...props }) => (
  <Modal {...props} showDeadline color="yellow" footer={<Footer {...props} />} showComment>
    <div className="modal__list__item">
      {props.permitted ? (
        <>
          <p className="modal__list__text modal__list__text--1">Assign to others</p>
          <ModalSearch {...props} />
        </>
      ) : null}
      <div className="modal__list__text modal__list__text--2">
        <div className="row">
          {props.assignees.map(
            (ls) =>
              ls.name && (
                <div className="col-sm-6" key={ls._id}>
                  <UserCard label {...ls} showDelete deleteAssignee={props.deleteAssignee} />
                </div>
              ),
          )}
          {props.inProgress && (
            <>
              <div className="page-loader__dot red" /> <div className="page-loader__dot red" />
              <div className="page-loader__dot red" />
            </>
          )}
        </div>
      </div>
    </div>
  </Modal>
);
InprogressModal.defaultProps = {
  assignees: [],
};

InprogressModal.propTypes = {
  inProgress: PropTypes.bool,
  permitted: PropTypes.bool,
  deleteAssignee: PropTypes.func,
  assignees: PropTypes.array,
};
