import { _notifyError, _sortItems, _notifySuccess } from 'utils';
import * as categoryTypes from '../types/categoryTypes';
import { beginApiCall, endApiCall } from './apiActions';
import Axios from 'services/axios';
import { CUSTOM_ERROR_MESSAGE } from "utils/constants";

const updatedState = data => ({
  isSaving: false,
  error: '',
  items: data,
  filteredItems: data,
});

// eslint-disable-next-line

const fetchCategoriesSuccess = payload => ({
  type: categoryTypes.FETCH_CATEGORIES_SUCCESS,
  payload,
});

const fetchCategoriesFailed = () => ({
  type: categoryTypes.FETCH_CATEGORIES_FAILED,
});

// eslint-disable-next-line
const addCategoryStart = () => ({ type: categoryTypes.ADD_CATEGORY_START });

// eslint-disable-next-line
const addCategorySuccess = payload => ({
  type: categoryTypes.ADD_CATEGORY_SUCCESS,
  payload,
});

// eslint-disable-next-line
const addCategoryFailed = () => ({ type: categoryTypes.ADD_CATEGORY_FAILED });

// eslint-disable-next-line
const updateCategoryStart = () => ({
  type: categoryTypes.UPDATE_CATEGORY_START,
});

// eslint-disable-next-line
const updateCategorySuccess = payload => ({
  type: categoryTypes.UPDATE_CATEGORY_SUCCESS,
  payload,
});

const updateCategoryFailed = payload => ({
  type: categoryTypes.UPDATE_CATEGORY_FAILED,
  payload,
});

const deleteCategoryStart = () => ({
  type: categoryTypes.DELETE_CATEGORY_START,
});

const deleteCategoryFailed = payload => ({
  type: categoryTypes.DELETE_CATEGORY_FAILED,
  payload,
});

const deleteCategorySuccess = payload => ({
  type: categoryTypes.DELETE_CATEGORY_SUCCESS,
  payload,
});

export const addCategory = (category, toggle) => async dispatch => {
  dispatch(addCategoryStart());
  try {
    const { data } = await Axios.post(`/categories`, category);

    dispatch(addCategorySuccess(data.data));
    _notifySuccess(`Category created successfully.`);
    toggle();
    return true;
  } catch (error) {
    const err = (error.response || {}).data;
    const errMessage = typeof err === 'string' ? err : CUSTOM_ERROR_MESSAGE;

    _notifyError(errMessage);
    dispatch(addCategoryFailed(errMessage));
    return false;
  }
};

export const updateCategory = (id, current, updatedItem, close, action) => {
  return async dispatch => {
    dispatch(updateCategoryStart());

    try {
      await Axios.patch(`/categories/${id}`, current);
      dispatch(updateCategorySuccess({ updatedObject: updatedItem }));
      _notifySuccess(`Category ${action ? action : 'updated'} successfully.`);
      close();
    } catch (error) {
      _notifyError(CUSTOM_ERROR_MESSAGE);
      dispatch(updateCategoryFailed(CUSTOM_ERROR_MESSAGE));
    }
  };
};

export const fetchCategories = () => async dispatch => {
  dispatch(beginApiCall());
  const { data } = await Axios.get(`/categories`);

  if (data.status === 'true') {
    const payload = _sortItems(data.data, 'name');
    dispatch(endApiCall());
    dispatch(fetchCategoriesSuccess(updatedState(payload)));
  } else {
    dispatch(endApiCall());
    _notifyError(data.message);
    dispatch(fetchCategoriesFailed(data.message));
  }
};

export const deleteCategory = (id, toggle, toggleViewModal) => {
  return async dispatch => {
    dispatch(deleteCategoryStart());
    try {
      const { data } = await Axios.delete(`/categories/${id}`);

      if (data.status) {
        toggle();
        toggleViewModal();
        dispatch(deleteCategorySuccess(id));

        _notifySuccess(`Category Deleted successfully.`);
      }
    } catch (error) {
      _notifyError(CUSTOM_ERROR_MESSAGE);
      dispatch(deleteCategoryFailed(CUSTOM_ERROR_MESSAGE));
    }
  };
};

export const updateCategoryList = value => ({
  type: categoryTypes.UPDATE_CATEGORY_LIST,
  payload: {
    filteredItems: value,
  },
});
