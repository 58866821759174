export const FETCH_SPONSOR_START = 'FETCH_SPONSOR_START';
export const FETCH_SPONSOR_SUCCESS = 'FETCH_SPONSOR_SUCCESS';
export const FETCH_SPONSOR_FAILED = 'FETCH_SPONSOR_FAILED';
export const FETCHED_SPONSOR_SORT = 'FETCHED_SPONSOR_SORT';

export const ADD_SPONSOR_START = 'ADD_SPONSOR_START';
export const ADD_SPONSOR_SUCCESS = 'ADD_SPONSOR_SUCCESS';
export const ADD_SPONSOR_FAILED = 'ADD_SPONSOR_FAILED';

export const UPDATE_SPONSOR_START = 'UPDATE_SPONSOR_START';
export const UPDATE_SPONSOR_SUCCESS = 'UPDATE_SPONSOR_SUCCESS';
export const UPDATE_SPONSOR_FAILED = 'UPDATE_SPONSOR_FAILED';

export const UPLOAD_SPONSORS_SUCCESS = 'UPLOAD_SPONSORS_SUCCESS';
export const UPDATE_SPONSORS_LIST = 'UPDATE_SPONSORS_LIST';
