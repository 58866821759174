/* eslint-disable max-len */
/* eslint-disable no-case-declarations */
import * as inventoryTypes from '../types/inventoryTypes';
import initialState from './initialState';
import { updateObject } from 'utils';

const inventory = (state = initialState.inventoryConsumables, action) => {
  switch (action.type) {
    case inventoryTypes.GET_INVENTORY_CONSUMABLES_START:
      return updateObject(state, { isLoading: true });

    case inventoryTypes.GET_INVENTORY_CONSUMABLES_SUCCESS:
      return updateObject(state, { ...action.payload, isLoading: false });

    case inventoryTypes.GET_INVENTORY_CONSUMABLES_FAILURE:
      return updateObject(state, { message: action.payload, isLoading: false });

    case inventoryTypes.DISPENSE_INVENTORY_CONSUMABLES_SUCCESS:
      return updateObject(state, {
        docs: [action.payload, ...state.docs],
        isSaving: false,
      });

    case inventoryTypes.UPDATE_INVENTORY_CONSUMABLES_START:
      return updateObject(state, { isUpdating: true });

    case inventoryTypes.RETURN_CONSUMABLES_SUCCESS:
    case inventoryTypes.UPDATE_INVENTORY_CONSUMABLES_SUCCESS:
      return updateObject(state, {
        docs: state.docs.map(order => {
          if (order._id === action.payload._id) {
            order = action.payload;
            return order;
          }
          return order;
        }),
        isUpdating: false,
      });

    case inventoryTypes.UPDATE_INVENTORY_CONSUMABLES_FAILURE:
      return updateObject(state, { ...action.payload, isUpdating: false });

    default:
      return { ...state };
  }
};

export default inventory;
