export const SMS = [
    {
        title: "Registration",
        isCustom: true,
        status: 'Active',
        date: '20-10-2020'
    },
    {
        title: "Patient Appointment Remin...",
        isCustom: true,
        status: 'Inactive',
        date: '20-10-2020'
    },
    {
        title: "Invoice",
        isCustom: true,
        status: 'Active',
        date: '20-10-2020'
    },
    {
        title: "Referral",
        isCustom: true,
        status: 'Inactive',
        date: '20-10-2020'
    },
    {
        title: "Investigate",
        isCustom: true,
        status: 'Active',
        date: '20-10-2020'
    },
    {
        title: "Procedure",
        isCustom: false,
        status: 'Active',
        date: '20-10-2020'
    },
    {
        title: "Birthday Message",
        isCustom: false,
        status: 'Active',
        date: '20-10-2020'
    },
    {
        title: "Appreciation Message",
        isCustom: false,
        status: 'Active',
        date: '20-10-2020'
    },
    {
        title: "Wallet Deduction",
        isCustom: false,
        status: 'Inactive',
        date: '20-10-2020'
    },
    {
        title: "End Consultation",
        isCustom: false,
        status: 'Active',
        date: '20-10-2020'
    },
    {
        title: "Christmas Message",
        isCustom: false,
        status: 'Active',
        date: '20-10-2020'
    },
]