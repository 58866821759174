import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmModal } from './confirm';
import { UploadFile } from 'components';
import moment from 'moment';

export const CommentModal = props => (
  <ConfirmModal
    color="yellow"
    isOpen={props.isOpen}
    isSaving={props.isSaving}
    disabled={props.disablePositiveClick}
    negativeClick={props.negativeClick}
    negativeText="Cancel"
    positiveClick={props.positiveClick}
    positiveText={props.complete ? "Complete" : "Done"}
    subtitle={
      props.complete ? 'Attach test result' : 'Add comment'
    }
    title={props.complete ? 'Complete?' : 'Comment'}
    toggle={props.toggle}
    size="md"
  >
    <React.Fragment>

      {props.complete && <div className="mb-4">
        <p className="u-text bold">Upload File</p>
        <UploadFile onChange={props.onFileChange} multiple fileList={props.fileList} onDelete={props.onDeleteFIle} />
      </div>}

      <div className="mb-4">
        <p className="u-text bold">Comment</p>

        <textarea
          cols="30"
          rows="3"
          className="form-control form-control--comment"
          name="commen"
          value={props.comment}
          onChange={props.onCommentChange}
          placeholder="Please write comment"
        />
        <p className="mt-4 text-right cursor-pointer" onClick={props.toggleComments}>
          {props.comments?.length ? `${props.showComment ? 'hide' : 'show'} comments` : null}</p>
        {!props.complete && props.comments && props.comments.length ? (
          props.showComment &&
          <div className="form-control--user-comments">
            <div className="comment">
              {props.comments.map((comment, index) => (
                <div className="comment-body" key={index}>
                  <p className="comment-body__header">{`${comment.author ? comment.author.name : 'User'
                    }`} <span className="float-right date">{moment(new Date(comment.date)).fromNow()}</span></p>
                  <p className="comment-body__footer">
                    {comment.body}
                  </p>
                </div>
              ))}
            </div>
          </div>

        ) : null}
      </div>
    </React.Fragment>
  </ConfirmModal>
);

CommentModal.propTypes = {
  comment: PropTypes.string,
  complete: PropTypes.bool,
  fileList: PropTypes.array,
  isOpen: PropTypes.bool,
  isSaving: PropTypes.bool,
  disablePositiveClick: PropTypes.bool,
  negativeClick: PropTypes.func,
  onCommentChange: PropTypes.func,
  onFileChange: PropTypes.func,
  positiveClick: PropTypes.func,
  toggle: PropTypes.func,
  comments: PropTypes.array,
  onDeleteFIle: PropTypes.func,
  showComment: PropTypes.bool,
  toggleComments: PropTypes.func
};
