export const ADD_STAFF_START = 'ADD_STAFF_START';
export const ADD_STAFF_SUCCESS = 'ADD_STAFF_SUCCESS';
export const ADD_STAFF_FAILED = 'ADD_STAFF_FAILED';

export const CLOSE_FORM_ERROR = 'CLOSE_FORM_ERROR';

export const FETCH_STAFF_START = 'FETCH_STAFF_START';
export const FETCH_STAFF_SUCCESS = 'FETCH_STAFF_SUCCESS';
export const FETCH_STAFF_FAILED = 'FETCH_STAFF_FAILED';

export const DELETE_STAFF_START = 'DELETE_STAFF_START';
export const DELETE_STAFF_SUCCESS = 'DELETE_STAFF_SUCCESS';
export const DELETE_STAFF_FAILED = 'DELETE_STAFF_FAILED';

export const SET_SECONDARY_OPTIONS_STAFF = 'SET_SECONDARY_OPTIONS_STAFF';
export const STAFF_PAGE_SORT = 'STAFF_PAGE_SORT';

export const UPDATE_STAFF_LIST = 'UPDATE_STAFF_LIST';
export const UPDATE_STAFF_START = 'UPDATE_STAFF_START';
export const UPDATE_STAFF_SUCCESS = 'UPDATE_STAFF_SUCCESS';
export const UPDATE_STAFF_FAILED = 'UPDATE_STAFF_FAILED';
