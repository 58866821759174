import React, { lazy, useEffect } from "react";
import { Redirect, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import MainLayout from "layouts/MainLayout";
import AuthRoute from "routes/AuthRoute";
import { fetchBranches, fetchSponsors, setCurrency } from "store/actions";

// Lazy load components
const Dashboard = lazy(() => import("../pages/Dashboard"));
const Patients = lazy(() => import("../pages/Patients"));
const Transactions = lazy(() => import("../pages/Transactions"));
const Insight = lazy(() => import("../pages/Insight"));
const ServicesReport = lazy(() => import("../components/report/Services"));
const ConsumablesReport = lazy(() => import("../components/report/Consumables"));
const HMOReport = lazy(() => import("../components/report/HMO"));
const Hmo = lazy(() => import("../pages/Hmo"));
const PatientDetailsRoutes = lazy(() => import("../../patients/pages/Patients/PatientDetails"));
const ErrorPage = lazy(() => import("pages/Error"));

const FinanceRoutes = ({ match }) => {
  const dispatch = useDispatch();
  const { loggedIn } = useSelector((state) => state.auth);
  const branchId = useSelector((state) => state.user?.current?.branchId);
  const currency = useSelector(
    (state) => state.branches?.items?.find((branch) => branch._id === branchId)?.currency?.code,
  );

  useEffect(() => {
    dispatch(fetchBranches());
    dispatch(fetchSponsors());
  }, [dispatch]);

  useEffect(() => {
    if (currency) {
      dispatch(setCurrency(currency));
    }
  }, [currency, dispatch]);

  const routes = [
    { path: "/dashboard", component: Dashboard },
    { path: "/patients", component: Patients },
    { path: "/transactions", component: Transactions },
    { path: "/claims", component: Hmo },
    { path: "/patients/:id", component: PatientDetailsRoutes },
    { path: "/insight", component: Insight },
    { path: "/insight/hmos", component: HMOReport },
    { path: "/insight/services", component: ServicesReport },
    { path: "/insight/consumables", component: ConsumablesReport },
  ];

  return (
    <Switch>
      <Redirect exact from={`${match.path}`} to={`${match.path}/dashboard`} />

      {routes.map(({ path, component }) => (
        <AuthRoute
          key={path}
          exact
          path={`${match.path}${path}`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={component}
          accessGranted={true}
        />
      ))}

      <AuthRoute path="*" isAuthenticated={loggedIn} layout={MainLayout} component={ErrorPage} accessGranted={true} />
    </Switch>
  );
};

FinanceRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default FinanceRoutes;
