import React from 'react';
import PropTypes from 'prop-types';

export const Divider = ({ className = '', customStyle }) => {
  return (
    <div className={className} style={{ height: 1, background: 'rgba(67, 102, 118, 0.148353)', ...customStyle }}></div>
  );
};

Divider.propTypes = {
  className: PropTypes.string,
  customStyle: PropTypes.object,
};
