export const ADD_BRANCH_CONFIGURATION_SUCCESS = 'ADD_BRANCH_CONFIGURATION_SUCCESS';

export const CHECK_EXISTING_BRANCH = 'CHECK_EXISTING_BRANCH';

export const FETCH_BRANCHES_CONFIGURATION_START = 'FETCH_BRANCHES_CONFIGURATION_START';
export const FETCH_BRANCHES_CONFIGURATION_SUCCESS = 'FETCH_BRANCHES_CONFIGURATION_SUCCESS';
export const FETCH_BRANCHES_CONFIGURATION_FAILED = 'FETCH_BRANCHES_CONFIGURATION_FAILED';

export const BRANCHES_CONFIGURATION_PAGE_SORT = 'BRANCHES_CONFIGURATION_PAGE_SORT';

export const UPDATE_BRANCHES_CONFIGURATION_LIST = 'UPDATE_BRANCHES_CONFIGURATION_LIST';
export const UPDATE_BRANCH_CONFIGURATION_SUCCESS = 'UPDATE_BRANCH_CONFIGURATION_SUCCESS';
