import React from 'react';
import { Spinner } from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { userLogout } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from './ui/buttons/button';

export const PageControls = ({ ...props }) => {
  const { backClick, skip, skipClick, disabled, next, isSaving, nextClick, nextClickButtonText } = props;
  const { user } = useSelector((state) => state);
  const dispatch = useDispatch();
  const logOut = async () => {
    await dispatch(userLogout());
  };
  return (
    <div className="page-controls tw-flex tw-items-center tw-justify-center">
      {backClick && (
        <div className="page-controls__cta tw-ml-0">
          <Button onClick={backClick} variant="primary" modifier="outline">
            Back
          </Button>
        </div>
      )}
      {skip && (
        <div className="page-controls__cta">
          <Button className={classnames('btn__primary')} onClick={skipClick}>
            Skip
          </Button>
        </div>
      )}
      {next && (
        <div className="page-controls__cta tw-mr-6">
          <Button disabled={disabled} variant="primary" loading={isSaving} onClick={nextClick}>
            {nextClickButtonText || 'Continue'}
          </Button>
        </div>
      )}
      <Button
        style={{ position: 'relative', float: 'left' }}
        variant="primary"
        className="onboarding_logout_btn"
        onClick={() => logOut()}
      >
        Logout {user?.isLoggingOut && <Spinner />}
      </Button>
    </div>
  );
};

PageControls.defaultProps = {
  skip: false,
  next: true,
};

PageControls.propTypes = {
  skip: PropTypes.bool,
  skipClick: PropTypes.func,
  isSaving: PropTypes.bool,
  next: PropTypes.bool,
  nextClick: PropTypes.func,
  backClick: PropTypes.func,
  nextClickButtonText: PropTypes.string,
  disabled: PropTypes.bool,
};
