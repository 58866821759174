import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmModal } from './confirm';

export const DeclineCommentModal = props => (
  <ConfirmModal
    color="yellow"
    isOpen={props.isOpen}
    isSaving={props.isSaving}
    disabled={props.disablePositiveClick}
    negativeClick={props.negativeClick}
    negativeText="Cancel"
    positiveClick={props.positiveClick}
    positiveText="Done"
    subtitle="Kindly type in reason for declining"
    title="Comment"
    toggle={props.toggle}
  >
    <React.Fragment>
      <div className="mb-4">
        <p className="u-text bold">Comment</p>

        <textarea
          cols="30"
          rows="3"
          className="form-control form-control--comment"
          name="commen"
          value={props.comment}
          onChange={props.onCommentChange}
          placeholder="Please write reason if any or leave blank"
        />
      </div>
    </React.Fragment>
  </ConfirmModal>
);

DeclineCommentModal.propTypes = {
  comment: PropTypes.string,
  isOpen: PropTypes.bool,
  isSaving: PropTypes.bool,
  disablePositiveClick: PropTypes.bool,
  negativeClick: PropTypes.func,
  onCommentChange: PropTypes.func,
  positiveClick: PropTypes.func,
  toggle: PropTypes.func
};
