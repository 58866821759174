import { _notifyError, _notifySuccess, printError } from "utils";
import * as inventoryTypes from "../types/inventoryTypes";
import Axios from "services/axios";
import { currentBranch } from "store";
import { getProducts } from "./inventoryActions";
import { CUSTOM_ERROR_MESSAGE } from "utils/constants";

const getTransfersStart = () => ({ type: inventoryTypes.GET_INVENTORY_TRANSFERS_START });

const getTransfersSuccess = (payload) => ({
  type: inventoryTypes.GET_INVENTORY_TRANSFERS_SUCCESS,
  payload,
});

const getTransfersFailure = (payload) => ({
  type: inventoryTypes.GET_INVENTORY_TRANSFERS_FAILURE,
  payload,
});

export const getTransfers = (location, queryParams) => {
  return async (dispatch) => {
    dispatch(getTransfersStart());
    try {
      const { data } = await Axios.get(
        `/locations/${location}/stock-movements?type=transfer${queryParams ? `&${queryParams}` : ""}`,
      );

      if (data.status) {
        dispatch(getTransfersSuccess(data.data));
      }
    } catch (error) {
      const errorMessage = error.response ? error.response.data : CUSTOM_ERROR_MESSAGE;
      _notifyError(errorMessage);
      dispatch(getTransfersFailure(CUSTOM_ERROR_MESSAGE));
    }
  };
};

export const createTransferSuccess = (payload) => ({
  type: inventoryTypes.CREATE_INVENTORY_TRANSFER_SUCCESS,
  payload,
});

const updateTransferStart = () => ({ type: inventoryTypes.UPDATE_INVENTORY_TRANSFER_START });

const updateTransfersFailure = (payload) => ({
  type: inventoryTypes.UPDATE_INVENTORY_TRANSFER_FAILURE,
  error: payload,
});

const updateTransfersSuccess = (payload) => ({
  type: inventoryTypes.UPDATE_INVENTORY_TRANSFER_SUCCESS,
  payload,
});

export const updateTransfers = (_id, payload, close) => {
  return async (dispatch) => {
    dispatch(updateTransferStart());
    try {
      const { data } = await Axios.patch(`/transfers/${_id}`, payload);
      if (data.status) {
        const cache = { ...data.data };
        _notifySuccess("Transfer updated successfully!");
        close();
        dispatch(updateTransfersSuccess(cache));
      }
    } catch (error) {
      _notifyError(CUSTOM_ERROR_MESSAGE);
      dispatch(updateTransfersFailure());
    }
  };
};

export const processTransfer = (payload, type, close) => {
  const { _id: transferId, from, to, createdBy } = payload;
  const param = type === "approve" ? { approve: true } : null;
  return async (dispatch) => {
    dispatch(updateTransferStart());
    try {
      const { data } = await Axios.post(`/transfers/${transferId}/${type}`, param);
      if (data.status) {
        const cache = { ...data.data, from, to, createdBy };
        dispatch(updateTransfersSuccess(cache));
        _notifySuccess(`Transfer successfully ${type}d`);
        currentBranch() && dispatch(getProducts(null, currentBranch()));
        close();
      }
    } catch (error) {
      const errorMessage = printError(error);
      _notifyError(errorMessage);
      dispatch(updateTransfersFailure(CUSTOM_ERROR_MESSAGE));
    }
  };
};

export const declineTransfer = (payload, close) => {
  const { _id: transferId, reason, from, to, createdBy } = payload;
  return async (dispatch) => {
    try {
      const { data } = await Axios.post(`/transfers/${transferId}/decline`, { reason });
      if (data.status) {
        const cache = { ...data.data, from, to, createdBy };
        dispatch(updateTransfersSuccess(cache));
        _notifySuccess(`Transfer successfully rejected`);
        currentBranch() && dispatch(getProducts(null, currentBranch()));
        close();
      }
    } catch (error) {
      const errorMessage = printError(error);
      _notifyError(errorMessage);
      dispatch(updateTransfersFailure(CUSTOM_ERROR_MESSAGE));
    }
  };
};
