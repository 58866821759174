/* eslint-disable no-case-declarations */
import * as pastRecordTypes from '../types/pastRecordTypes';
import initialState from './initialState';
import { updateObject } from 'utils';

const pastRecords = (state = initialState.pastRecords, { type, payload }) => {
  switch (type) {
    case pastRecordTypes.FETCH_PAST_RECORDS_START:
      return updateObject(state, { isLoading: true });

    case pastRecordTypes.FETCH_PAST_RECORDS_SUCCESS:
      return updateObject(state, { isLoading: false, items: payload });

    case pastRecordTypes.FETCH_PAST_RECORDS_FAIL:
      return updateObject(state, { isLoading: false });

    case pastRecordTypes.UPLOAD_PAST_RECORD_SUCCESS:
      return updateObject(state, { items: [...state.items, payload] });

    default:
      return { ...state };
  }
};

export default pastRecords;
