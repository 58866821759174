import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "services/baseQuery";
import { _notifyError, _notifySuccess, getSerializedParams, printError } from "utils";
import { CUSTOM_ERROR_MESSAGE } from "utils/constants";
import { closeAllModals } from "store/features/modalSlice";

export const walletApi = createApi({
  reducerPath: "walletApi",
  baseQuery,
  tagTypes: ["Wallet-Transactions"],
  endpoints: (builder) => ({
    getTransactions: builder.query({
      query: (queryParams) => {
        const query = getSerializedParams(queryParams, true);
        return {
          url: `/wallet-transactions${query ? `?${query}` : ""}`,
          // params: queryParams,
        };
      },
      providesTags: ["Wallet-Transactions"],
      transformResponse: (response) => response.data,
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.status) {
            //
          }
        } catch (error) {
          const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
          _notifyError(errorMessage);
        }
      },
    }),
    cancelFund: builder.mutation({
      query: ({ id, ...payload }) => ({
        url: `/wallet-transactions/${id}/cancel`,
        method: "POST",
        body: payload,
      }),
      onQueryStarted: async (arg, { queryFulfilled, dispatch }) => {
        try {
          const response = await queryFulfilled;
          _notifySuccess(`Success : ${response.data.message}`);
          dispatch(closeAllModals());
        } catch (error) {
          const errorMessage = printError(error);
          _notifyError(errorMessage);
        }
      },
      invalidatesTags: ["Wallet-Transactions"],
    }),
    approveOrDeclineWithdrawal: builder.mutation({
      query: ({ id, ...payload }) => ({
        url: `/withdrawals/${id}`,
        method: "POST",
        body: payload,
      }),
      onQueryStarted: async (arg, { queryFulfilled, dispatch }) => {
        try {
          const response = await queryFulfilled;
          _notifySuccess(`Success : ${response.data.message}`);
          dispatch(closeAllModals());
        } catch (error) {
          const errorMessage = printError(error);
          _notifyError(errorMessage);
        }
      },
      invalidatesTags: ["Wallet-Transactions"],
    }),
    processWithdrawal: builder.mutation({
      query: ({ id }) => ({
        url: `/withdrawals/${id}/process`,
        method: "POST",
      }),
      onQueryStarted: async (arg, { queryFulfilled, dispatch }) => {
        try {
          const response = await queryFulfilled;
          _notifySuccess(`Success : ${response.data.message}`);
          dispatch(closeAllModals());
        } catch (error) {
          const errorMessage = printError(error);
          _notifyError(errorMessage);
        }
      },
      invalidatesTags: ["Wallet-Transactions"],
    }),
  }),
});

export const {
  useGetTransactionsQuery,
  useCancelFundMutation,
  useProcessWithdrawalMutation,
  useApproveOrDeclineWithdrawalMutation,
} = walletApi;
