import React from 'react';
import PropTypes from 'prop-types';

const SupportLayout = ({ ...props }) => {
  const { children } = props;
  return <div className="">{children}</div>;
};

SupportLayout.propTypes = {
  children: PropTypes.node,
};

export default SupportLayout;
