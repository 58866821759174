import React, { useState, useEffect, useCallback } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const PaginationComponent = props =>
  props.data.length > 1 ? (
    <div className="pagination--block">
      <div className="pagination__caption">{`PAGE ${props.currentPage + 1} of  ${props.data.length}`}</div>
      {props.rowsPerPage && (
        <span>
          <label className="paragraph-sm color-grey mr-3">Rows per page</label>
          {props.rowsPerPage}
        </span>
      )}
      <div className="pagination__inner">
        <Pagination aria-label="Page navigation example">
          <PaginationItem>
            <PaginationLink previous onClick={props.prev}>
              <i className="fas fa-angle-left" />
            </PaginationLink>
          </PaginationItem>

          {props.data.map((page, index) => (
            <PaginationItem key={index}>
              <PaginationLink
                className={classnames({ active: props.currentPage === index })}
                data-id={index}
                onClick={props.goTo}
              >
                {index + 1}
              </PaginationLink>
            </PaginationItem>
          ))}

          <PaginationItem>
            <PaginationLink next onClick={props.next}>
              <i className="fas fa-angle-right" />
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      </div>
    </div>
  ) : null;

PaginationComponent.propTypes = {
  currentPage: PropTypes.number,
  data: PropTypes.array,
  goTo: PropTypes.func,
  next: PropTypes.func,
  prev: PropTypes.func,
  rowsPerPage: PropTypes.element,
};

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';
/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
const range = (from, to, step = 1) => {
  let i = from;
  const ranges = [];

  while (i <= to) {
    ranges.push(i);
    i += step;
  }

  return ranges;
};

export const PaginationControls = props => {
  const { totalRecords, pageLimit, pageNeighbours, paginationHandler, pageHandler, currentPage } = props;
  const [records, setRecords] = useState(null);
  const [limit, setLimit] = useState(20);
  const [neighbours, setNeighbours] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (typeof pageLimit === 'number') {
      setLimit(pageLimit);
    }
    if (typeof totalRecords === 'number') {
      setRecords(totalRecords);
    }
    if (typeof pageNeighbours === 'number') {
      setNeighbours(Math.max(0, Math.min(pageNeighbours, 2)));
    }
    if (typeof pageLimit === 'number' && typeof totalRecords === 'number') {
      setTotalPages(Math.ceil(totalRecords / pageLimit));
    }
  }, [pageLimit, pageNeighbours, totalRecords]);

  const gotoPage = useCallback(
    pageIndex => {
      const page = Math.max(0, Math.min(pageIndex, totalPages));
      const pageData = {
        limit: limit,
        page: page,
        // totalPages,
        // records,
      };

      pageHandler(prev => {
        return {
          ...prev,
          page,
        };
      });
      paginationHandler(pageData);
    },
    [totalPages, paginationHandler, pageHandler, limit],
  );

  // useEffect(() => {
  //   gotoPage(1);
  // }, [gotoPage]);

  const handleClick = page => evt => {
    if (evt) evt.preventDefault();
    gotoPage(page);
  };

  const handleMoveLeft = evt => {
    if (evt) evt.preventDefault();
    gotoPage(currentPage - neighbours * 2 - 1);
  };

  const handleMoveRight = evt => {
    if (evt) evt.preventDefault();
    gotoPage(currentPage + neighbours * 2 + 1);
  };

  /**
   * Let's say we have 10 pages and we set pageNeighbours to 2
   * Given that the current page is 6
   * The pagination control will look like the following:
   *
   * (1) < {4 5} [6] {7 8} > (10)
   *
   * (x) => terminal pages: first and last page(always visible)
   * [x] => represents current page
   * {...x} => represents page neighbours
   */
  const fetchPageNumbers = () => {
    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = neighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - neighbours);
      const endPage = Math.min(totalPages - 1, currentPage + neighbours);

      let pages = range(startPage, endPage);

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };

  const pageList = fetchPageNumbers();
  if (!records || totalPages === 1) return null;

  return (
    <div className="pagination--block">
      <div className="pagination__caption">{`PAGE ${currentPage} of  ${totalPages}`}</div>

      <div className="pagination__inner">
        <Pagination aria-label="Page navigation" className="mb-0">
          {pageList.map(page => {
            if (page === LEFT_PAGE) {
              return (
                <PaginationItem key={page}>
                  <PaginationLink previous href="#" onClick={handleMoveLeft}>
                    <i className="fas fa-angle-left" />
                  </PaginationLink>{' '}
                </PaginationItem>
              );
            }

            if (page === RIGHT_PAGE) {
              return (
                <PaginationItem key={page}>
                  <PaginationLink next href="#" onClick={handleMoveRight}>
                    <i className="fas fa-angle-right" />
                  </PaginationLink>
                </PaginationItem>
              );
            }

            return (
              <PaginationItem
                key={page}
                active={currentPage === page}
                // {currentPage === page ? ' active' : ''}
              >
                <PaginationLink href="#" onClick={handleClick(page)}>
                  {page}
                </PaginationLink>
              </PaginationItem>
            );
          })}
        </Pagination>
      </div>
    </div>
  );
};

PaginationControls.propTypes = {
  totalRecords: PropTypes.number.isRequired,
  pageLimit: PropTypes.number.isRequired,
  pageNeighbours: PropTypes.number.isRequired,
  paginationHandler: PropTypes.func.isRequired,
  pageHandler: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
};

PaginationControls.propTypes = {};
