export const ADD_ROOM_START = 'ADD_ROOM_START';
export const ADD_ROOM_SUCCESS = 'ADD_ROOM_SUCCESS';
export const ADD_ROOM_FAILED = 'ADD_ROOM_FAILED';

export const FETCH_ROOMS_START = 'FETCH_ROOMS_START';
export const FETCH_ROOMS_SUCCESS = 'FETCH_ROOMS_SUCCESS';
export const FETCH_ROOMS_FAILED = 'FETCH_ROOMS_FAILED';

export const UPDATE_ROOMS_LIST = 'UPDATE_ROOMS_LIST';
export const UPDATE_ROOM_START = 'UPDATE_ROOM_START';
export const UPDATE_ROOM_SUCCESS = 'UPDATE_ROOM_SUCCESS';
export const UPDATE_ROOM_FAILED = 'UPDATE_ROOM_FAILED';
