import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "services/baseQuery";
import { _notifyError, _notifySuccess, _sortItems } from "utils";
import { closeAllModals } from "store/features/modalSlice";

export const rolesApi = createApi({
  reducerPath: "rolesApi",
  baseQuery,
  tagTypes: ["Roles"],
  endpoints: (builder) => ({
    getRoles: builder.query({
      query: () => "/roles",
      transformResponse: (response) => _sortItems([...response.data], "title"),
      providesTags: ["Roles"],
    }),
    deleteRole: builder.mutation({
      query: (id) => ({
        url: `/roles/${id}`,
        method: "DELETE",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          _notifySuccess(`Success : ${response.data.message}`);
          dispatch(closeAllModals());
        } catch (err) {
          _notifyError(`Error : ${err.error.data.message}`);
        }
      },
    }),
    updateRole: builder.mutation({
      query: ({ _id, title, isActive, permissions }) => ({
        url: `/roles/${_id}`,
        method: "PATCH",
        body: { title, isActive, permissions },
      }),
      invalidatesTags: ["Roles"],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          _notifySuccess(`Success : ${response.data.message}`);
          dispatch(closeAllModals());
        } catch (err) {
          _notifyError(`Error : ${err.error.data.message}`);
        }
      },
    }),
    addRole: builder.mutation({
      query: (payload) => ({
        url: `/roles`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Roles"],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          _notifySuccess(`Success : ${response.data.message}`);
          dispatch(closeAllModals());
          window.location.reload()
        } catch (err) {
          _notifyError(`Error : ${err.error.data.message}`);
        }
      },
    }),
  }),
});

export const { useGetRolesQuery, useAddRoleMutation, useGetRoleQuery, useUpdateRoleMutation, useDeleteRoleMutation } =
  rolesApi;
