/* eslint-disable linebreak-style */
import React from 'react';
import { ReactComponent as FileClip } from 'assets/svg/file-clip.svg';
import PropTypes from 'prop-types';

export const UploadImage = props => {
  const { onChange, multiple, fileName, fileList, onDelete, src, ...rest } = props;
  let inputRef;

  const pickFile = () => {
    if (props?.isDisabled) return;
    inputRef.click();
  };

  return (
    <div className="upload-file__block">
      <div
        className="upload-file"
        style={{ backgroundColor: src ? 'unset' : 'rgba(68, 157, 249, 0.05)' }}
        onClick={pickFile}
      >
        <input
          onChange={e => {
            e.target.files.length > 0 && onChange(e);
          }}
          type="file"
          multiple={multiple}
          ref={ref => {
            inputRef = ref;
          }}
          accept="image/*"
          {...rest}
        />

        {!multiple ? (
          src ? (
            <img src={src} style={{ maxHeight: 150 }} />
          ) : (
            <>
              <FileClip height={18} />
              <span>{fileName || 'Upload image'}</span>
            </>
          )
        ) : fileList.length === 0 ? (
          <span>Upload an image</span>
        ) : (
          <span>{`${fileList.length} images uploaded`}</span>
        )}
      </div>
      {multiple ? (
        <div className="upload-file__list">
          {fileList.map((file, index) => (
            <div className="upload-file__item" key={index}>
              <span>{file.name}</span>
              <span className="delete-file">
                <i className="fas fa fa-times" id={file.binaryString} onClick={onDelete} />
              </span>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

UploadImage.propTypes = {
  fileName: PropTypes.string,
  fileList: PropTypes.array,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  isDisabled: PropTypes.bool,
  src: PropTypes.string,
};
