import * as companyTypes from '../types/companyTypes';
import initialState from 'store/reducers/initialState';

const companyReducer = (state = initialState.company, action) => {
  switch (action.type) {
    case companyTypes.FETCH_COMPANY_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case companyTypes.FETCH_COMPANY_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: null,
        loading: false,
      };
    case companyTypes.FETCH_COMPANY_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case companyTypes.UPDATE_COLORS:
      return {
        ...state,
        ...action.payload,
      };
    case companyTypes.FETCH_COMPANY_CUSTOMIZATION_START:
      return { ...state, isSaving: true };
    case companyTypes.FETCH_COMPANY_CUSTOMIZATION_FAILED:
      return { ...state, isSaving: false };
    case companyTypes.FETCH_COMPANY_CUSTOMIZATION_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isSaving: false,
      };
    case companyTypes.UPDATE_COMPANY_START:
      return { ...state, isSaving: true };
    case companyTypes.UPDATE_COMPANY_FAILED:
      return { ...state, isSaving: false };
    case companyTypes.UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isSaving: false,
      };
    case companyTypes.UPDATE_COMPANY_CUSTOMIZATION_START:
      return { ...state, isSaving: true };
    case companyTypes.UPDATE_COMPANY_CUSTOMIZATION_FAILED:
      return { ...state, isSaving: false };
    case companyTypes.UPDATE_COMPANY_CUSTOMIZATION_SUCCESS:
      return {
        ...state,
        registrationSettings: action.payload,
        isSaving: false,
      };
    case companyTypes.FETCH_LOCATIONS:
      return {
        ...state,
        locations: action.payload,
      };
    case companyTypes.FETCH_LOCATION:
      return {
        ...state,
        location: action.payload,
      };
    default:
      return state;
  }
};

export default companyReducer;
