/* eslint-disable linebreak-style */
import React, { forwardRef, useState } from "react";
import classnames from "classnames";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { Input } from "reactstrap";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import PropTypes from "prop-types";
import { _notifyError } from "utils";
import { NumericFormat } from "react-number-format";
import { FormGroup } from "../ui/form-group";
import { Label } from "../ui/label";

export const FormInput = forwardRef((props, ref) => {
  const [style, setStyle] = useState({
    border: "none",
    width: "100%",
  });

  const blurStyle = {
    ...style,
    boxShadow: "none",
    outline: "none",
  };
  const containerStyle = {
    border: "transparent",
    height: "2.4rem",
    display: "flex",
    alignItems: "center",
  };

  return (
    <FormGroup
      className={classnames("tw-relative", props.className, { disabled: props.isDisabled })}
      onClick={props.onClick}
      style={props.style}
    >
      {!!props.label && (
        <Label
          className={classnames("tw-flex tw-items-center tw-space-x-0.5 tw-mb-0.5", props?.labelClass)}
          for={props.id}
        >
          {props.label} <span style={{ color: "red" }}>{props.isRequired ? "*" : ""}</span>
        </Label>
      )}
      {props.type === "tel" ? (
        <div className="">
          <ReactPhoneInput
            className="tw-appearance-none tw-w-full tw-font-normal tw-text-sm focus-visible:tw-outline-none
          placeholder:tw-text-base"
            onFocus={() => setStyle(blurStyle)}
            containerStyle={containerStyle}
            inputStyle={style}
            id={props.id}
            disabled={props.isDisabled}
            required={props.isRequired}
            name={props.id}
            placeholder="Enter phone number"
            type={props.type}
            value={props.value}
            country="ng"
            onChange={props.onChange}
          />
        </div>
      ) : props.type === "select" ? (
        <Input
          autoComplete={props.autoComplete}
          required={props.isRequired}
          className={classnames("form__field", { "form__field--select": props.type === "select" })}
          id={props.id}
          disabled={props.isDisabled}
          name={props.id}
          type={props.type}
          value={props.value}
          onChange={props.onChange}
          ref={ref}
        >
          <option value="" disabled defaultValue>
            {props.placeholder}
          </option>
          {props.children}
        </Input>
      ) : props.type === "location" ? (
        <GooglePlacesAutocomplete
          selectProps={{
            styles: {
              placeholder: (provided) => ({
                ...provided,
                fontSize: "1.4rem",
                fontWeight: "500",
                color: "#9b9b9b",
              }),
              input: (provided) => ({
                ...provided,
                letterSpacing: 1,
                fontSize: "1.4rem",
                fontWeight: "600",
              }),
              control: (provided) => ({
                ...provided,
                outline: "none",
                boxShadow: "none",
                border: 0,
              }),
              valueContainer: (provided) => ({
                ...provided,
                padding: 0,
              }),
            },
            ...props.selectProps,
          }}
          onLoadFailed={() => _notifyError("Could not get a location at this time, please try again!")}
          // eslint-disable-next-line no-undef
          apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY}
        />
      ) : props.type === "money" ? (
        <div className="tw-flex justify-content-between">
          {props.withQuantityControls && (
            <i
              className="fas fa-caret-left align align-self-center"
              onClick={() => {
                const currentValue = String(props.value || 0)
                  .replaceAll(",", "")
                  .replaceAll(props.suffix, "")
                  .replaceAll(props.prefix, "");
                return (
                  Number(currentValue) - props.increment > 0 && props.onChange(Number(currentValue) - props.increment)
                );
              }}
            />
          )}

          <NumericFormat
            suffix={props.suffix}
            prefix={props.prefix}
            valueIsNumericString={true}
            name="amount"
            className={`tw-w-full tw-appearance-none tw-h-[24px] read-only:tw-bg-natural-100 disabled:tw-bg-natural-100 
             tw-font-normal tw-text-[14px] placeholder:tw-text-[16px] focus-visible:tw-outline-none 
            ${props.inputClass}`}
            style={{ height: 30 }}
            value={props.value}
            disabled={props.isDisabled}
            placeholder={props.placeholder}
            onBlur={props.onBlur}
            onChange={(e) =>
              props.onChange({
                ...e,
                ...{
                  target: {
                    ...e.target,
                    value: String(e.target.value)
                      .replaceAll(",", "")
                      .replaceAll(props.suffix || "", "")
                      .replaceAll(props.prefix || "", ""),
                  },
                },
              })
            }
            thousandSeparator={props.thousandSeparator}
            decimalSeparator={props.decimalSeparator}
            allowNegative={false}
            autoComplete={props.autoComplete}
            max={props.max}
            min={props.min}
            onKeyUp={props.onKeyUp}
          />

          {props.withQuantityControls && (
            <i
              className="fas fa-caret-right align align-self-center"
              onClick={() => {
                const currentValue = String(props.value || 0)
                  .replaceAll(",", "")
                  .replaceAll(props.suffix, "")
                  .replaceAll(props.prefix, "");
                return Number(currentValue) + props.increment > -1
                  ? props.onChange(Number(currentValue) + props.increment)
                  : props.onChange(0.0);
              }}
            />
          )}
        </div>
      ) : props.type === "textarea" ? (
        <textarea
          cols={props.cols || "30"}
          rows={props.rows || "4"}
          className="form__field"
          // className="form-control form-control--comment"
          autoComplete={props.autoComplete}
          required={props.isRequired}
          id={props.id}
          disabled={props.isDisabled}
          name={props.id}
          placeholder={props.placeholder}
          onKeyDown={props.onKeyDown}
          onBlur={props.onBlur}
          value={props.value}
          onChange={props.onChange}
          ref={ref}
          readOnly={props.readOnly}
          defaultValue={props.defaultValue}
          style={{ width: "100%" }}
        />
      ) : (
        <input
          autoComplete={props.autoComplete}
          required={props.isRequired}
          className={`tw-appearance-none tw-relative tw-w-full tw-h-2xl tw-font-normal tw-text-sm 
          placeholder:tw-text-base focus-visible:tw-outline-none ${props.textColor}`}
          ref={ref}
          id={props.id}
          disabled={props.isDisabled}
          name={props.id}
          placeholder={props.placeholder}
          type={props.type}
          onKeyDown={props.onKeyDown}
          onBlur={props.onBlur}
          value={props.value}
          style={props.style}
          onChange={props.onChange}
          checked={props.checked}
          inputMode={props.inputMode}
          readOnly={props.readOnly}
          maxLength={props.maxLength}
          step={props.step}
          min={props.min}
          defaultValue={props.defaultValue}
        />
      )}
      {props.error && (
        <span
          style={{
            color: "red",
            position: "relative",
            top: "3.5rem",
            left: "-1.5rem",
            fontSize: "12px",
          }}
        >
          {props.error}
        </span>
      )}
    </FormGroup>
  );
});

FormInput.displayName = "FormInput";

FormInput.defaultProps = {
  className: "",
  placeholder: "",
  value: "",
  inputClass: "",
  increment: 1.0,
};

FormInput.propTypes = {
  autoComplete: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  rows: PropTypes.string,
  cols: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.string,
  inputMode: PropTypes.string,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  textColor: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  phoneCode: PropTypes.number,
  type: PropTypes.string,
  selectProps: PropTypes.object,
  checked: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  suffix: PropTypes.string,
  prefix: PropTypes.string,
  thousandSeparator: PropTypes.bool,
  decimalSeparator: PropTypes.bool,
  withQuantityControls: PropTypes.bool,
  inputClass: PropTypes.string,
  increment: PropTypes.number,
  readOnly: PropTypes.bool,
  defaultValue: PropTypes.string,
  onClick: PropTypes.func,
  onKeyUp: PropTypes.func,
  style: PropTypes.object,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
};
