import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "services/baseQuery";
import { _notifyError, _notifySuccess, getSerializedParams, printError } from "utils";

const servicesApi = createApi({
  reducerPath: "servicesApi",
  baseQuery,
  tagTypes: ["Products"],
  endpoints: (builder) => ({
    fetchServices: builder.query({
      query: ({ location, ...queryParams }) => {
        const queryFilters = getSerializedParams(queryParams, true);
        return {
          url: `/locations/${location}/items${queryFilters ? `?${queryFilters}` : ""}`,
        };
      },
      providesTags: ["Services"],
      transformResponse: (response) => response.data,
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.status) {
            //
          }
        } catch (error) {
          const errorMessage = printError(error);
          _notifyError(errorMessage);
        }
      },
    }),
    fetchBill: builder.query({
      query: ({ id }) => ({
        url: `/bills/${id}`,
      }),
      providesTags: ["Bill"],
      transformResponse: (response) => response.data,
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.status) {
            //
          }
        } catch (error) {
          const errorMessage = printError(error);
          _notifyError(errorMessage);
        }
      },
    }),
    fetchPatientsBalance: builder.query({
      query: ({ id }) => ({
        url: `/patients/${id}/balances`,
      }),
      providesTags: ["Patients-Balance"],
      transformResponse: (response) => response.data,
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          const errorMessage = printError(error);
          _notifyError(errorMessage);
        }
      },
    }),
    fetchValueAddedServices: builder.query({
      query: ({ branchId }) => ({
        url: `/vas?branchId=${branchId}`,
      }),
      transformResponse: (response) => response.data,
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.status) {
            //
          }
        } catch (error) {
          const errorMessage = printError(error);
          _notifyError(errorMessage);
        }
      },
    }),
    deleteBillItem: builder.mutation({
      query: (itemId) => ({
        url: `/bill-items/${itemId}`,
        method: "DELETE",
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const response = await queryFulfilled;
          _notifySuccess(`Success : ${response.data.message}`);
        } catch (error) {
          const errorMessage = printError(error);
          _notifyError(errorMessage);
        }
      },
    }),
    stashBillItem: builder.mutation({
      query: (id) => ({
        url: `/bill-items/${id}/stash`,
        method: "POST",
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const response = await queryFulfilled;
          _notifySuccess(`Success : ${response.data.message}`);
        } catch (error) {
          const errorMessage = printError(error);
          _notifyError(errorMessage);
        }
      },
    }),
    fetchFinanceDashboardStats: builder.query({
      query: () => "/finance/dashboard-stats",
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.status) {
            //
          }
        } catch (error) {
          const errorMessage = printError(error);
          _notifyError(errorMessage);
        }
      },
    }),
    fetchWithdrawals: builder.query({
      query: ({ branchId, queryParams }) =>
        `/wallet-transactions?purpose=withdrawal&branchId=${branchId}&${queryParams}`,
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.status) {
            //
          }
        } catch (error) {
          const errorMessage = printError(error);
          _notifyError(errorMessage);
        }
      },
    }),
    withdrawals: builder.mutation({
      query: (payload) => ({
        url: "/wallet-withdrawal/patient",
        method: "POST",
        body: payload,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.status) {
            _notifySuccess("Withdrawal successfully made");
          }
        } catch (error) {
          const errorMessage = printError(error);
          _notifyError(errorMessage);
        }
      },
    }),
    addService: builder.mutation({
      query: (payload) => ({
        url: `/items`,
        method: "POST",
        body: payload,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.status) {
            _notifySuccess("Service successfully added");
          }
        } catch (error) {
          const errorMessage = printError(error);
          _notifyError(errorMessage);
        }
      },
      invalidatesTags: ["Services"],
    }),
    deactivateServices: builder.mutation({
      query: (payload) => ({
        url: `/items/deactivate`,
        method: "PATCH",
        body: payload,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.status) {
            _notifySuccess(data.message || "Credit issued successfully");
          }
        } catch (error) {
          const errorMessage = printError(error);
          _notifyError(errorMessage);
        }
      },
      invalidatesTags: ["Services"],
    }),
    reactivateServices: builder.mutation({
      query: (payload) => ({
        url: `/items/reactivate`,
        method: "PATCH",
        body: payload,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.status) {
            _notifySuccess(data.message || "Credit issued successfully");
          }
        } catch (error) {
          const errorMessage = printError(error);
          _notifyError(errorMessage);
        }
      },
      invalidatesTags: ["Services"],
    }),
  }),
});

export const {
  useFetchServicesQuery,
  useAddServiceMutation,
  useDeactivateServicesMutation,
  useReactivateServicesMutation,
} = servicesApi;

export default servicesApi;
