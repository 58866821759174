import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "services/baseQuery";
import { _notifyError, _notifySuccess, printError } from "utils";
import { CUSTOM_ERROR_MESSAGE } from "utils/constants";
import { closeModal } from "store/features/modalSlice";
import billsApi from "./billsSlice";

const cartApi = createApi({
  reducerPath: "cartApi",
  baseQuery,
  tagTypes: ["Carts"],
  endpoints: (builder) => ({
    fetchCarts: builder.query({
      query: () => ({
        url: `/carts`,
      }),
      transformResponse: (response) => response.data,
      providesTags: ["Carts"],
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.status) {
            //
          }
        } catch (error) {
          const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
          _notifyError(errorMessage);
        }
      },
    }),
    fetchPatientCart: builder.query({
      query: ({ id }) => ({
        url: `patients/${id}/cart`,
      }),
      transformResponse: (response) => response.data,
      providesTags: ["Cart"],
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.status) {
            //
          }
        } catch (error) {
          const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
          _notifyError(errorMessage);
        }
      },
    }),
    addToCart: builder.mutation({
      query: (id) => ({
        url: `/bill-items/${id}/stash`,
        method: "POST",
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const response = await queryFulfilled;
          _notifySuccess(`Success : ${response.data.message}`);
        } catch (error) {
          const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
          _notifyError(errorMessage);
        }
      },
      invalidatesTags: ["Cart", "Carts"],
    }),
    removeItemFromCart: builder.mutation({
      query: ({ cartId, itemId }) => ({
        url: `/carts/${cartId}/items/${itemId}`,
        method: "DELETE",
      }),
      onQueryStarted: async (arg, { queryFulfilled, dispatch }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.status) {
            _notifySuccess("Item removed successfully");
            dispatch(closeModal(`deleteConfirmation`));
          }
        } catch (error) {
          const errorMessage = printError(error);
          _notifyError(errorMessage);
        }
      },
      invalidatesTags: ["Cart", "Carts"],
    }),
    updateItemQuantity: builder.mutation({
      query: ({ cartId, itemId, quantity }) => ({
        url: `/carts/${cartId}/items/${itemId}`,
        method: "PATCH",
        body: { quantity },
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.status) {
            _notifySuccess("Quantity updated successfully");
          }
        } catch (error) {
          const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
          _notifyError(errorMessage);
        }
      },
      invalidatesTags: ["Cart"],
    }),
    clearCart: builder.mutation({
      query: ({ cartId }) => ({
        url: `/carts/${cartId}/clear`,
        method: "POST",
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
          _notifyError(errorMessage);
        }
      },
      invalidatesTags: ["Carts"],
    }),
    checkout: builder.mutation({
      query: ({ cartId, ...payload }) => ({
        url: `/carts/${cartId}/checkout`,
        method: "POST",
        body: payload,
      }),
      onQueryStarted: async (arg, { queryFulfilled, dispatch }) => {
        try {
          const response = await queryFulfilled;
          dispatch(billsApi.util.invalidateTags(["Bills"]));
          _notifySuccess(`Success : ${response.data.message}`);
        } catch (error) {
          const errorMessage = printError(error.error);
          _notifyError(errorMessage);
        }
      },
      invalidatesTags: ["Carts", "Cart"],
    }),
  }),
});

export const {
  useFetchCartsQuery,
  useFetchPatientCartQuery,
  useClearCartMutation,
  useRemoveItemFromCartMutation,
  useUpdateItemQuantityMutation,
  useCheckoutMutation,
} = cartApi;

export default cartApi;
