/* eslint-disable no-unused-vars */
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "services/baseQuery";
import { _notifyError, _notifySuccess, _sortItems } from "utils";
import { closeAllModals } from "store/features/modalSlice";
import { SMS } from "modules/settings/pages/SMS/data";


export const smsApi = createApi({
  reducerPath: "smsApi",
  baseQuery,
  tagTypes: ["SMS"],
  endpoints: (builder) => ({
    getTemplates: builder.query({
        query: () => "/",
        transformResponse: (response) => _sortItems(SMS, "title"),
        providesTags: ["SMS"],
    }),
    addTemplate: builder.mutation({
        query: (payload) => ({
          url: `/sms`,
          method: "POST",
          body: payload,
        }),
        invalidatesTags: ["SMS"],
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
          try {
            const response = await queryFulfilled;
            _notifySuccess(`Success : ${response.data.message}`);
            dispatch(closeAllModals());
            window.location.reload()
          } catch (err) {
            _notifyError(`Error : ${err.error.data.message}`);
          }
        },
    }),
    sendInstantSms: builder.mutation({
        query: (payload) => ({
          url: `/sms`,
          method: "POST",
          body: payload,
        }),
        invalidatesTags: ["SMS"],
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
          try {
            const response = await queryFulfilled;
            _notifySuccess(`Success : ${response.data.message}`);
            dispatch(closeAllModals());
            window.location.reload()
          } catch (err) {
            _notifyError(`Error : ${err.error.data.message}`);
          }
        },
    }),
  })
})

export const { useAddTemplateMutation, useGetTemplatesQuery, useSendInstantSmsMutation } = smsApi;