import { _firstLetterUc, _notifyError, _notifySuccess, printError } from 'utils';
import * as inventoryTypes from '../types/inventoryTypes';
import { beginApiCall, endApiCall } from 'store/actions';
import Axios from 'services/axios';
import { createTransferSuccess } from 'modules/inventory/redux/actions';
// import { createTransferSuccess } from './inventoryTransferActions';
import { CUSTOM_ERROR_MESSAGE } from 'utils/constants';

/**
 * InventorySettings Configurations
 */
const getInventoryConfigurationSuccess = (payload) => ({
  type: inventoryTypes.GET_INVENTORY_CONFIGURATION_SUCCESS,
  payload,
});

export const getInventoryConfiguration = () => {
  return async (dispatch) => {
    dispatch(beginApiCall());
    try {
      const { data } = await Axios.get("/configurations");

      if (data.status) {
        const payload = { configurations: data.data ? data.data.inventory : {} };
        dispatch(getInventoryConfigurationSuccess(payload));
        dispatch(endApiCall());
      }
      dispatch(endApiCall());
    } catch (err) {
      dispatch(endApiCall());
    }
  };
};

const setInventoryConfigurationSuccess = (payload) => ({
  type: inventoryTypes.SET_INVENTORY_CONFIGURATION_SUCCESS,
  payload,
});

export const setInventoryConfiguration = (current) => {
  return async (dispatch) => {
    try {
      const { data } = await Axios.post("/configurations", current);
      if (data.status) {
        const payload = { configurations: current.inventory };
        dispatch(setInventoryConfigurationSuccess(payload));
        _notifySuccess("Created successfully.");
      }
    } catch (error) {
      const errorMessage = printError(error);
      _notifyError(errorMessage);
    }
  };
};

const addInventoryItemStart = () => ({ type: inventoryTypes.ADD_INVENTORY_ITEM_START });

const addInventoryItemFailure = (error) => ({
  type: inventoryTypes.ADD_INVENTORY_ITEM_FAILED,
  error,
});

const addInventoryItemSuccess = (payload) => ({
  type: inventoryTypes.ADD_INVENTORY_ITEM_SUCCESS,
  payload,
});

export const addInventoryItem = (payload, close) => {
  return async (dispatch) => {
    dispatch(addInventoryItemStart());
    try {
      const { data } = await Axios.post("/items", payload);
      if (data.status) {
        dispatch(addInventoryItemSuccess(data.data));
        _notifySuccess(`Created ${_firstLetterUc(payload.type)} successfully.`);
        close();
      }
    } catch (error) {
      const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
      _notifyError(errorMessage);
      dispatch(addInventoryItemFailure(CUSTOM_ERROR_MESSAGE));
    }
  };
};

const updateInventoryItemStart = () => ({ type: inventoryTypes.UPDATE_INVENTORY_ITEM_START });

const updateInventoryItemFailure = (error) => ({
  type: inventoryTypes.UPDATE_INVENTORY_ITEM_FAILED,
  error,
});

const updateInventoryItemSuccess = (payload) => ({
  type: inventoryTypes.UPDATE_INVENTORY_ITEM_SUCCESS,
  payload,
});

export const updateInventoryItem = (payload, itemId, close) => {
  return async (dispatch) => {
    dispatch(updateInventoryItemStart());
    try {
      const { data } = await Axios.patch(`/items/${itemId}`, payload);
      if (data.status) {
        const cache = {
          data: data.data,
          itemId,
        };
        dispatch(updateInventoryItemSuccess(cache));
        _notifySuccess(`Updated ${_firstLetterUc(payload.type)} successfully.`);
        close();
      }
    } catch (error) {
      const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
      _notifyError(errorMessage);
      dispatch(updateInventoryItemFailure(CUSTOM_ERROR_MESSAGE));
    }
  };
};

export const getInventoryProduct = (id) => {
  return async () => {
    try {
      const { data } = await Axios.get(`/products/${id}`);
      if (data.status) {
        return data.data;
      }
    } catch (error) {
      _notifyError(CUSTOM_ERROR_MESSAGE);
      return undefined;
    }
  };
};

const addInventoryItemStockStart = () => ({ type: inventoryTypes.ADD_INVENTORY_ITEM_STOCK_START });

const addInventoryItemStockFailure = (error) => ({
  type: inventoryTypes.ADD_INVENTORY_ITEM_STOCK_FAILED,
  error,
});

export const addInventoryItemStock = (payload, productId, close) => {
  return async (dispatch) => {
    dispatch(addInventoryItemStockStart());
    try {
      const { data } = await Axios.post(`/products/${productId}/add-stock`, payload);
      if (data.status) {
        // const cache = {
        //   productId,
        //   data: data.data,
        // };
        // dispatch(addInventoryItemStockSuccess(cache));
        _notifySuccess("Created stock successfully.");
        close();
        window.location.reload();
      }
    } catch (error) {
      _notifyError(error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE);
      dispatch(addInventoryItemStockFailure(CUSTOM_ERROR_MESSAGE));
    }
  };
};

const updateInventoryItemStockStart = () => ({ type: inventoryTypes.UPDATE_INVENTORY_ITEM_STOCK_START });

const updateInventoryItemStockFailure = (error) => ({
  type: inventoryTypes.UPDATE_INVENTORY_ITEM_STOCK_FAILED,
  error,
});

const updateInventoryItemStockSuccess = (payload) => ({
  type: inventoryTypes.UPDATE_INVENTORY_ITEM_STOCK_SUCCESS,
  payload,
});

export const updateInventoryItemStock = (payload, productId, close) => {
  const { expiryDate, batchId, costPrice, quantity, supplier } = payload;
  return async (dispatch) => {
    dispatch(updateInventoryItemStockStart());
    try {
      const { data } = await Axios.get(`/stocks/${payload._id}`, {
        expiryDate,
        batchId,
        costPrice,
        quantity,
        supplier,
      });
      if (data.status) {
        const cache = {
          productId,
          data: { ...payload, ...data.data },
        };

        dispatch(updateInventoryItemStockSuccess(cache));
        _notifySuccess("Updated stock successfully.");
        close();
      }
    } catch (error) {
      _notifyError(error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE);
      dispatch(updateInventoryItemStockFailure(CUSTOM_ERROR_MESSAGE));
    }
  };
};

export const getInventoryStock = (id) => {
  return async () => {
    try {
      const { data } = await Axios.get(`/stocks/${id}`);
      if (data.status) {
        return data.data;
      }
    } catch (error) {
      _notifyError(CUSTOM_ERROR_MESSAGE);
      return undefined;
    }
  };
};

const updateStockVisibilityStart = () => ({
  type: inventoryTypes.UPDATE_STOCK_VISIBILITY_START,
});

const updateStockVisibilitySuccess = (payload) => ({
  type: inventoryTypes.UPDATE_STOCK_VISIBILITY_SUCCESS,
  payload,
});

const updateStockVisibilityFailed = () => ({
  type: inventoryTypes.UPDATE_STOCK_VISIBILITY_FAILED,
});

export const updateStockVisibility = (payload, productId, stockId, close) => {
  return async (dispatch) => {
    dispatch(updateStockVisibilityStart());
    try {
      const { data } = await Axios.post(`/${productId ? "products" : "stocks"}/${stockId}/archive`, payload);
      if (data.status) {
        dispatch(updateStockVisibilitySuccess({ productId, stockId }));
        _notifySuccess(`Stock item ${payload.isActive ? "Unarchived" : "Archived"} successfully.`);
        close();
        return true;
      }
    } catch (error) {
      _notifyError(CUSTOM_ERROR_MESSAGE);
      dispatch(updateStockVisibilityFailed(CUSTOM_ERROR_MESSAGE));
      return false;
    }
  };
};

/**
 * InventorySettings Product
 */

const getProductsStart = (payload) => ({
  type: inventoryTypes.GET_INVENTORY_PRODUCTS_START,
  payload,
});

const getProductsSuccess = (payload) => ({
  type: inventoryTypes.GET_INVENTORY_PRODUCTS_SUCCESS,
  payload,
});

const getProductsFailure = (payload) => ({
  type: inventoryTypes.GET_INVENTORY_PRODUCTS_FAILURE,
  payload,
});

export const getProducts = (queryDetails, location) => {
  const url = `/locations/${location}/items?type=product${queryDetails ? `&${queryDetails}` : ""}`;
  return async (dispatch) => {
    dispatch(getProductsStart());

    try {
      const { data } = await Axios.get(url);

      if (data.status) {
        const payload = { products: data.data };
        dispatch(getProductsSuccess(payload));
      }
    } catch (error) {
      const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
      _notifyError(errorMessage);
      dispatch(getProductsFailure(CUSTOM_ERROR_MESSAGE));
    }
  };
};

export const getArchivedProducts = (queryDetails) => {
  const url = `/stocks/archives?${queryDetails ? `&${queryDetails}` : ""}`;
  return async (dispatch) => {
    dispatch(getProductsStart());

    try {
      const { data } = await Axios.get(url);

      if (data.status) {
        const payload = { products: data.data };
        dispatch(getProductsSuccess(payload));
      }
    } catch (error) {
      const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
      _notifyError(errorMessage);
      dispatch(getProductsFailure(CUSTOM_ERROR_MESSAGE));
    }
  };
};

export const listBranchProducts = (location, queryDetails) => {
  return async (dispatch) => {
    dispatch(getProductsStart());

    try {
      const { data } = await Axios.get(
        `/locations/${location}/items?type=product${queryDetails ? `?${queryDetails}` : ""}`,
      );
      if (data.status) {
        const payload = { products: data.data };
        dispatch(getProductsSuccess(payload));
      }
    } catch (error) {
      const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
      _notifyError(errorMessage);
      dispatch(getProductsFailure(CUSTOM_ERROR_MESSAGE));
    }
  };
};

const getArchivedStockStart = (payload) => ({
  type: inventoryTypes.GET_ARCHIVED_STOCK_START,
  payload,
});

const getArchivedStockSuccess = (payload) => ({
  type: inventoryTypes.GET_ARCHIVED_STOCK_SUCCESS,
  payload,
});

const getArchivedStockFailed = (payload) => ({
  type: inventoryTypes.GET_ARCHIVED_STOCK_FAILED,
  payload,
});

export const getArchivedStocks = () => {
  return async (dispatch) => {
    dispatch(getArchivedStockStart());
    try {
      const { data } = await Axios.get("/stocks/archived");
      if (data.status) {
        const payload = { products: data.data };
        dispatch(getArchivedStockSuccess(payload));
      }
    } catch (error) {
      const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
      _notifyError(errorMessage);
      dispatch(getArchivedStockFailed(CUSTOM_ERROR_MESSAGE));
    }
  };
};

const initiateTransferStart = () => ({ type: inventoryTypes.INITIATE_TRANSFER_START });

const initiateTransfersEnd = () => ({ type: inventoryTypes.INITIATE_TRANSFER_END });

export const initiateTransfers = (payload, close) => {
  const { from, to, items, createdBy } = payload;

  return async (dispatch) => {
    dispatch(initiateTransferStart());
    try {
      const { data } = await Axios.post("/stock-movements", {
        from: from.map((branch) => branch._id),
        to: to.map((branch) => branch._id),
        items,
        type: "transfer",
      });
      if (data.status) {
        _notifySuccess("Items transferred successfully!");
        const cache = { ...data.data, from, to, createdBy };
        // eslint-disable-next-line no-console
        dispatch(createTransferSuccess(cache));
        dispatch(initiateTransfersEnd());
        close();
      }
    } catch (error) {
      _notifyError(CUSTOM_ERROR_MESSAGE);
      dispatch(initiateTransfersEnd());
    }
  };
};
