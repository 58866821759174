import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "services/baseQuery";
import { _notifyError, _notifySuccess, _sortItems } from "utils";
import { closeAllModals } from "store/features/modalSlice";

export const plansApi = createApi({
  reducerPath: "plansApi",
  baseQuery,
  tagTypes: ["Plans"],
  endpoints: (builder) => ({
    getPlans: builder.query({
      query: () => "/plans",
      transformResponse: (response) => _sortItems([...response.data], "name"),
      providesTags: ["Plans"],
    }),
    updatePlan: builder.mutation({
      query: ({ _id, ...rest }) => ({
        url: `plans/${_id}`,
        method: "PATCH",
        body: { ...rest },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          _notifySuccess(`Success : ${response.data.message}`);
          dispatch(closeAllModals());
        } catch (err) {
          _notifyError(`Error : ${err.error.data.message}`);
        }
      },
      invalidatesTags: ["Plans"],
    }),
  }),
});

export const { useGetPlansQuery, useUpdatePlanMutation } = plansApi;
