import React from 'react';
import PropTypes from 'prop-types';

export const TextAreaWithHeader = ({ cols, rows, className, label, header, ...props }) => (
  <div className={`form-area ${className}`}>
    {header}
    <label htmlFor={props.id} style={{ fontSize: 16 }}>
      {label}
      <span style={{ color: 'red' }}>{props.isRequired ? '*' : ''}</span>
    </label>
    <textarea cols={cols} rows={rows} {...props} />
  </div>
);

TextAreaWithHeader.defaultProps = {
  className: '',
  cols: '30',
  rows: '8',
};

TextAreaWithHeader.propTypes = {
  className: PropTypes.string,
  cols: PropTypes.string,
  rows: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  isRequired: PropTypes.bool,
  header: PropTypes.element,
};
