import { _notifyError, _notifySuccess, _sortItems } from 'utils';
import Axios from 'services/axios';
import * as diseaseTypes from '../types/diseaseTypes';
import { beginApiCall, endApiCall } from '../../../../store/actions/apiActions';
import { CUSTOM_ERROR_MESSAGE } from "utils/constants";

const fetchDiseasesSuccess = payload => ({
  type: diseaseTypes.FETCH_DISEASES_SUCCESS,
  payload,
});

const fetchDiseasesFailed = msg => ({
  type: diseaseTypes.FETCH_DISEASES_FAILED,
  error: msg,
});

export const fetchDiseases = () => async dispatch => {
  dispatch(beginApiCall());
  const { data } = await Axios.get(`/diseases`);
  if (!data.status) {
    dispatch(endApiCall());
    _notifyError(data.message);
    dispatch(fetchDiseasesFailed(data.message));
  } else {
    const payload = {
      items: _sortItems([...data.data], 'name'),
      filteredItems: _sortItems([...data.data], 'name'),
    };
    dispatch(endApiCall());
    dispatch(fetchDiseasesSuccess(payload));
  }
};

const addDiseaseStart = () => ({ type: diseaseTypes.ADD_DISEASE_START });

const addDiseaseSuccess = payload => ({
  type: diseaseTypes.ADD_DISEASE_SUCCESS,
  payload,
});

const addDiseaseFailed = msg => ({
  type: diseaseTypes.ADD_DISEASE_FAILED,
  error: msg,
});

export const addDisease = (current, close) => {
  return async dispatch => {
    dispatch(addDiseaseStart());
    try {
      const { data } = await Axios.post(`/diseases`, current);

      if (data.status) {
        dispatch(addDiseaseSuccess(data.data));
        _notifySuccess('Disease successfully added.');
        close();
      }
    } catch (error) {
      _notifyError(error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE);
      dispatch(addDiseaseFailed(CUSTOM_ERROR_MESSAGE));
    }
  };
};

const updateDiseaseStart = payload => ({
  type: diseaseTypes.UPDATE_DISEASE_START,
  payload,
});

const updateDiseaseSuccess = payload => ({
  type: diseaseTypes.UPDATE_DISEASE_SUCCESS,
  payload,
});

const updateDiseaseFailed = msg => ({
  type: diseaseTypes.UPDATE_DISEASE_FAILED,
  error: msg,
});

export const updateSelectedDisease = (current, close, action) => {
  return async dispatch => {
    const actionType = action ? { isDeactivating: true } : { isSaving: true };
    const { name } = current;

    dispatch(updateDiseaseStart(actionType));
    try {
      const { data } = await Axios.patch(`/diseases/${current._id}`, { name });

      if (data.status) {
        dispatch(updateDiseaseSuccess(data.data));
        _notifySuccess(`Disease ${action || 'updated'} successfully.`);
        close();
      }
    } catch (error) {
      _notifyError(error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE);
      dispatch(updateDiseaseFailed(CUSTOM_ERROR_MESSAGE));
    }
  };
};

export const updateDiseaseList = value => ({
  type: diseaseTypes.UPDATE_DISEASES_LIST,
  payload: {
    filteredItems: value,
  },
});
