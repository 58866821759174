/* eslint-disable no-case-declarations */
import * as prescriptionTypes from '../types/prescriptionTypes';
import initialState from './initialState';
import { updateObject } from 'utils';

const prescriptions = (state = initialState.prescriptions, { type, payload }) => {
  switch (type) {
    case prescriptionTypes.FETCH_PRESCRIPTIONS_START:
      return updateObject(state, { isLoading: true });

    case prescriptionTypes.FETCH_PRESCRIPTIONS_SUCCESS:
      return updateObject(state, { isLoading: false, items: payload });

    case prescriptionTypes.FETCH_PRESCRIPTIONS_FAIL:
      return updateObject(state, { isLoading: false });

    case prescriptionTypes.ADD_PRESCRIPTION_START:
      return updateObject(state, { isSaving: true });

    case prescriptionTypes.ADD_PRESCRIPTION_SUCCESS:
      return updateObject(state, { isSaving: false });

    case prescriptionTypes.ADD_PRESCRIPTION_FAIL:
      return updateObject(state, { isSaving: false });

    default:
      return { ...state };
  }
};

export default prescriptions;
