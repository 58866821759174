import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { cn } from "utils";
import { Label } from "components/ui/label";
import Required from "components/required";

export const TextField = forwardRef((props, ref) => {
  const { label, className, ...otherProps } = props;
  return (
    <div className="tw-relative">
      {label && (
        <span
          className={cn(
            `tw-flex tw-items-center tw-space-x-0.5 tw-mb-0.5 tw-absolute tw-text-sm dark:text-gray-400 tw-duration-300
            tw-z-5 tw-start-5 tw-top-3 tw-text-blue-600`,
          )}
        >
          <Label label={label} />
          {props?.required && <Required />}
        </span>
      )}
      <input
        ref={ref}
        {...otherProps}
        className={cn(
          `tw-block tw-rounded-[4px] tw-px-5 tw-pb-3 tw-pt-8 tw-w-full tw-text-sm tw-text-gray-900 tw-bg-white 
          tw-border tw-border-natural-300 tw-appearance-none focus:tw-outline-none focus:tw-ring-0 tw-peer
          focus-within:tw-shadow-[0_0_1px_1px_rgba(0,0,0,0.1)]`,
          !label && `tw-pt-3`,
          className,
        )}
      />
    </div>
  );
});

TextField.propTypes = {
  register: PropTypes.func,
  validation: PropTypes.object,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  containerClass: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

TextField.displayName = "TextField";
