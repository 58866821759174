import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

export const CountLister = ({ listOptions, count, addTextColor, countClass }) => {
  const listerRef = useRef(null);
  const [display, setDisplay] = useState(false);

  const toggle = () => {
    setDisplay(!display);
  };

  const handleClickOutside = (event) => {
    if (listerRef.current && !listerRef.current.contains(event.target)) {
      setDisplay(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const clusterList = (items) => {
    if (items.length > 1) {
      const addCount = items.length - 1;
      return (
        <span>
          <span className={`popups__bubble-count font-weight-bold ${countClass}`}>{` +${count ?? addCount}`}</span>
        </span>
      );
    }
  };

  return (
    <>
      {listOptions && listOptions.length > 0 && (
        <div className="popups__bubble mr-3" ref={listerRef}>
          <div>
            {" "}
            <span
              className="popups__bubble-cursor"
              onClick={toggle}
              onMouseEnter={() => setDisplay(true)}
              onMouseLeave={() => setDisplay(false)}
              aria-hidden
            >
              {clusterList(listOptions)}
            </span>
          </div>
          <div
            className={classnames({
              show: display,
              "popups__bubble-options": true,
              "color-dark": addTextColor,
            })}
          >
            <div className="popups__bubble-header"></div>
            <ul className="popups__bubble__height">
              {listOptions &&
                listOptions.map((item, index) => {
                  if (index === 0) return null;
                  return <li key={index}>{item.name}</li>;
                })}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

CountLister.propTypes = {
  listOptions: PropTypes.array,
  addTextColor: PropTypes.bool,
  countClass: PropTypes.string,
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
