export const FETCH_CONSENT_SUCCESS = 'FETCH_CONSENT_SUCCESS';
export const FETCH_CONSENT_FAIL = 'FETCH_CONSENT_FAIL';
export const FETCH_CONSENT_START = 'FETCH_CONSENT_START';

export const ADD_CONSENT_START = 'ADD_CONSENT_START';
export const ADD_CONSENT_SUCCESS = 'ADD_CONSENT_SUCCESS';
export const ADD_CONSENT_FAIL = 'ADD_CONSENT_FAIL';

export const FETCH_OP_NOTES_SUCCESS = 'FETCH_OP_NOTES_SUCCESS';
export const FETCH_OP_NOTES_FAIL = 'FETCH_OP_NOTES_FAIL';
export const FETCH_OP_NOTES_START = 'FETCH_OP_NOTES_START';

export const ADD_OP_NOTE_START = 'ADD_OP_NOTE_START';
export const ADD_OP_NOTE_SUCCESS = 'ADD_OP_NOTE_SUCCESS';
export const ADD_OP_NOTE_FAIL = 'ADD_OP_NOTE_FAIL';
