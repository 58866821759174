import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

export const DragAndDrop = ({ handleDroppedFiles, children }) => {
  const [, setDragging] = useState(false);
  const [dragCount, setDragCount] = useState(0);

  const wrapperRef = useRef();

  const handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragIn = e => {
    e.preventDefault();
    e.stopPropagation();
    setDragCount(count => count + 1);
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };
  const handleDragOut = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      setDragCount(count => count - 1);
      if (dragCount === 0) {
        setDragging(false);
      }
    },
    [dragCount]
  );

  const handleDrop = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      setDragging(false);

      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        handleDroppedFiles(e.dataTransfer.files);
        e.dataTransfer.clearData();
      }
    },
    [handleDroppedFiles]
  );

  useEffect(() => {
    const wrapper = wrapperRef.current;
    wrapper.addEventListener('dragenter', handleDragIn);
    wrapper.addEventListener('dragleave', handleDragOut);
    wrapper.addEventListener('dragover', handleDrag);
    wrapper.addEventListener('drop', handleDrop);

    return () => {
      wrapper.removeEventListener('dragenter', handleDragIn);
      wrapper.removeEventListener('dragleave', handleDragOut);
      wrapper.removeEventListener('dragover', handleDrag);
      wrapper.removeEventListener('drop', handleDrop);
    };
  }, [handleDragOut, handleDrop]);

  return (
    <div style={{ position: 'relative', width: '100%' }} ref={wrapperRef}>
      {children}
    </div>
  );
};

DragAndDrop.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  handleDroppedFiles: PropTypes.func
};
