import { _notifyError } from 'utils';
import Axios from 'services/axios';
import * as utilityTypes from '../types/utilityTypes';

const fetchCurrenciesSuccess = payload => ({
  type: utilityTypes.FETCH_CURRENCY_SUCCESS,
  payload,
});

export const fetchCurrencies = () => async dispatch => {
  try {
    const { data } = await Axios.get(`/currencies`);
    // const transformed = _transformToSelectOptions(data, 'code');
    if (data.status) {
      dispatch(fetchCurrenciesSuccess(data.data));
    }
  } catch (err) {
    _notifyError(err.message);
  }
};

export const setCurrency = currency => ({
  type: utilityTypes.SET_CURRENCY,
  payload: currency,
});

const fetchPermissionsSuccess = payload => ({
  type: utilityTypes.FETCH_PERMISSIONS_SUCCESS,
  payload,
});

export const fetchPermissions = () => async dispatch => {
  try {
    const { data } = await Axios.get(`/dropdown-options`);
    if (data.status) {
      dispatch(fetchPermissionsSuccess(data.data['permissions']));
    }
  } catch (err) {
    _notifyError(err.message);
  }
};
