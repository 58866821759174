
export const swiperParams = {
  containerClass: 'swiper-container p-1 tw-pb-3 tw-pr-4',
  slidesPerView: 4,
  spaceBetween: 20,
  scrollbar: {
    el: '.swiper-scrollbar',
  },
  freeMode: true,
  mousewheel: true,
  breakpoints: {
    1600: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    1330: {
      slidesPerView: 2.5,
      spaceBetween: 20,
    },

    1200: {
      slidesPerView: 2.2,
      spaceBetween: 20,
    },
    830: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    650: {
      slidesPerView: 1.5,
      spaceBetween: 20,
    },
    450: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
  },
};

export const toastConfig = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

export const dayPickerStyle = {
  border: 'transparent',
  margin: '4px 0',
  outline: 'none',
};

export const deliveredOrders = [
  {
    deadline: '2021-10-04',
    client: {
      name: 'Sarah George', referredPatient: 'Patrick Spongebob'
    }, createdAt: '2021-05-03', services: [{ name: 'Eye Patch' }]
  },
  {
    deadline: '2021-05-20',
    client: { name: 'Abike Lawal', referredPatient: 'Remi Seye' }, createdAt: '2019-04-16', services: [{ name: 'Nose Removal' }]
  }
]

export const declinedOrders = [
  {
    deadline: '2021-10-04',
    client: {
      name: 'Sarah George', referredPatient: 'Patrick Spongebob'
    }, createdAt: '2021-05-03', services: [{ name: 'Eye Patch' }]
  },
  {
    deadline: '2021-05-20',
    client: { name: 'Abike Lawal', referredPatient: 'Remi Seye' }, createdAt: '2019-04-16', services: [{ name: 'Nose Removal' }]
  }
]

export const allOrders = [
  {
    _id: 1,
    deadline: '2021-10-04',
    client: { name: 'Sarah George', referredPatient: 'Patrick Spongebob' }, createdAt: '2021-05-03', services: [{ name: 'Eye Patch' }]
  },
  {
    _id: 2,
    deadline: '2021-05-20',
    client: { name: 'Abike Lawal', referredPatient: 'Remi Seye' }, createdAt: '2019-04-16', services: [{ name: 'Nose Removal' }]
  }
]

export const staffList = [
  { _id: 1, name: 'Idris Abdul' },
  { _id: 2, name: 'Paul Nwigwe' },
]

export const handlePagination = async (totalPages, currentPage, dispatch, action, status, prevData) => {
  if (totalPages === currentPage) return;
  await dispatch(action(status, `page=${currentPage + 1}`, prevData));
}
export const canCompleteTask = (assignees = [], staffId) => {
  return assignees.some(assignee => assignee._id === staffId)
}