// import { _notifyError, _notifySuccess, _sortItems } from "utils";
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "services/baseQuery";
// import { closeAllModals } from "store/features/modalSlice";

export const shiftApi = createApi({
  reducerPath: "shiftApi",
  baseQuery,
  tagTypes: ["Shift"],
  endpoints: (builder) => ({
    getStaff: builder.query({
      query: (queryParams) => ({
        url: `/shift`,
        params: queryParams
      }),
      providesTags: ["Shift"],
      transformResponse: (response) => response.data,
    }),
    // deleteCategory: builder.mutation({
    //   query: (id) => ({
    //     url: `/categories/${id}`,
    //     method: "DELETE",
    //   }),
    //   async onQueryStarted(arg, { dispatch, queryFulfilled }) {
    //     try {
    //       const response = await queryFulfilled;
    //       _notifySuccess(`Success : ${response.data.message}`);
    //       dispatch(closeAllModals());
    //     } catch (err) {
    //       _notifyError(`Error : ${err.error.data.message}`);
    //     }
    //   },
    //   invalidatesTags: ["Staff"],
    // }),
    // addCategory: builder.mutation({
    //   query: (current) => ({
    //     url: `/categories`,
    //     method: "POST",
    //     body: current,
    //   }),
    //   async onQueryStarted(arg, { dispatch, queryFulfilled }) {
    //     try {
    //       const response = await queryFulfilled;
    //       _notifySuccess(`Success : ${response.data.message}`);
    //       dispatch(closeAllModals());
    //     } catch (err) {
    //       _notifyError(`Error : ${err.error.data.message}`);
    //     }
    //   },
    //   invalidatesTags: ["Staff"],
    // }),
    // updateCategory: builder.mutation({
    //   query: ({ id, current }) => {
    //     const newPayload = Array.isArray(current) ? current : current;
    //     return {
    //       url: `/categories/${id}`,
    //       method: "PATCH",
    //       body: newPayload,
    //     };
    //   },
    //   async onQueryStarted(arg, { dispatch, queryFulfilled }) {
    //     try {
    //       const response = await queryFulfilled;
    //       _notifySuccess(`Success : ${response.data.message}`);
    //       dispatch(closeAllModals());
    //     } catch (err) {
    //       _notifyError(`Error : ${err.error.data.message}`);
    //     }
    //   },
    //   invalidatesTags: ["Staff"],
    // }),
  }),
});

export const { useGetShiftQuery, useAddShiftMutation, useUpdateShiftMutation, useShiftMutation } =
shiftApi;
