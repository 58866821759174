import React from 'react';
import PropTypes from 'prop-types';
// import { ReactComponent as Caution } from 'assets/svg/caution.svg';
// import { ReactComponent as Close } from 'assets/svg/close.svg';

export const AuthErrorCard = props => (
  <div className="card auth__card auth__card--error">
    <p className="text">
      {/* {props.showCaution && <Caution width={20} height={20} />} */}

      <span className={props.cssClass}>{props.error}</span>
    </p>

    <span className="close" onClick={props.onClose}>
      {/* <Close strokeWidth={1} /> */}
    </span>
  </div>
);

AuthErrorCard.propTypes = {
  error: PropTypes.string,
  onClose: PropTypes.func,
  cssClass: PropTypes.string,
  showCaution: PropTypes.bool,
};

AuthErrorCard.defaultProps = {
  error: '',
  cssClass: '',
  showCaution: true,
};
