import React from 'react';
import PropTypes from 'prop-types';

export const Tooltip = props => {
  const { onClose, next, title, subtitle, tooltipStyle } = props;
  return (
    <>
      <div className={`custom-tooltip custom-tooltip-${tooltipStyle}`}>
        <div className="custom-tooltip__indicator" />
        <div className="custom-tooltip__arrow" />
        <div className="custom-tooltip__body">
          <h2 className="title">{title}</h2>
          <p className="subtitle">{subtitle}</p>
          <div className="actions">
            <button onClick={onClose}>close</button>
            <button onClick={next}>
              {tooltipStyle === 'settings' ? 'done' : 'next'}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

Tooltip.defaultProps = {
  title: 'dashboard',
  subtitle:
    'Here you will find the strategic overview of the key performance in the hospital'
};

Tooltip.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onClose: PropTypes.func,
  next: PropTypes.func,
  tooltipStyle: PropTypes.string
};
