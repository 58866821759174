import React from 'react';
import PropTypes from 'prop-types';
import { TabPane } from 'reactstrap';

export const TabDetails = ({ component: Component, tabId, ...props }) => {
  return (
    <TabPane tabId={tabId}>
      <Component category={tabId} {...props} />
    </TabPane>
  );
};

TabDetails.propTypes = {
  tabId: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.node, PropTypes.func]),
};
