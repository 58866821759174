/* eslint-disable no-case-declarations */
import * as wardTypes from '../types/wardTypes';
import initialState from 'store/reducers/initialState';
import { updateObject, _sortItems } from 'utils';

export const wards = (state = initialState.wards, action) => {
  switch (action.type) {
    case wardTypes.FETCH_WARDS_START:
      return updateObject(state, { isLoading : true });

    case wardTypes.FETCH_WARDS_SUCCESS:
      return updateObject(state, { ...action.payload , isLoading : false});

    case wardTypes.FETCH_WARDS_FAILED:
      return updateObject(state, { error: action.error , isLoading : false });

    case wardTypes.ADD_WARD_START:
      return updateObject(state, { isSaving: true });

    case wardTypes.ADD_WARD_SUCCESS:
      const newWards = _sortItems([...state.items].concat(action.payload), 'name');
      return updateObject(state, { items: newWards, filteredItems: newWards, isSaving: false });

    case wardTypes.ADD_WARD_FAILED:
      return updateObject(state, {
        isSaving: false,
      });

    case wardTypes.UPDATE_WARD_START:
      return updateObject(state, { ...action.payload });

    case wardTypes.UPDATE_WARD_SUCCESS:
      const updated = [...state.items].map(prev =>
        String(prev._id) === String(action.payload._id) ? action.payload : prev,
      );
      return updateObject(state, {
        items: updated,
        filteredItems: updated,
        isSaving: false,
        isDeactivating: false,
      });

    case wardTypes.UPDATE_WARD_FAILED:
      return updateObject(state, {
        isSaving: false,
        isDeactivating: false,
      });

    case wardTypes.UPDATE_WARDS_LIST:
      return updateObject(state, action.payload);

    default:
      return { ...state };
  }
};
