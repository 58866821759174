import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Loader } from "components";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import { useDebounce } from "use-lodash-debounce";
import { FormGroup } from "components/ui/form-group";
import { Label } from "components/ui/label";

export const ProductDropdown = ({ field, onChange, searchFunction, value, getFullItem, ...props }) => {
  const ref = useRef();
  const inputRef = useRef();
  const [show, setShow] = useState(false);
  const [searchString, setSearchString] = useState("");
  const debouncedValue = useDebounce(searchString, 500);
  const [options, setOptions] = useState([]);
  const [loader, setLoader] = useState(false);

  const handleChange = (e) => {
    const { value } = e.target;
    setShow(true);
    setSearchString(String(value));
  };

  const showPlaceHolder = !(value && Array.isArray(value) && value.length > 0);

  const showOptionsOnClick =
    options.filter((item) => !(value || []).map((val) => val.value).includes(item.value)).length > 0;

  const unSelect = (val) => {
    onChange((value || []).filter((item) => item.value !== val));
  };

  useOnClickOutside(ref, () => setShow(false));

  useEffect(() => {
    async function fetchResult() {
      if (debouncedValue) {
        setLoader(true);
        const results = await searchFunction(debouncedValue);
        setOptions(
          (results || []).map((item) => ({ ...(getFullItem ? item : {}), label: item[field], value: item._id })),
        );
        setLoader(false);
      }
    }

    void fetchResult();
  }, [debouncedValue]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.onfocus = function () {
        inputRef.current.setAttribute("style", "width : auto !important");
        if (showOptionsOnClick) {
          setShow(true);
        }
      };
      inputRef.current.onblur = function () {
        inputRef.current.setAttribute("style", "width : 0 !important");
      };
    }
  }, [inputRef]);

  return (
    <div className="product-dropdown">
      <FormGroup className={`settings__form-group form__select  ${props.isDisabled && "disabled"} ${props.className}`}>
        <Label className="form__label tw-mb-3" for={props.id}>
          {props.label} <span style={{ color: "red" }}>{props.important ? "*" : ""}</span>
        </Label>
        <div ref={ref} className="product-dropdown-holder">
          <>
            <div className="product-dropdown-selector">
              <div className="product-dropdown-selected">
                {value &&
                  Array.isArray(value) &&
                  value.map((item, index) => (
                    <div key={`product-${index}`}>
                      <span>{item.label}</span>
                      <i
                        className="fas fa-times"
                        onClick={() => {
                          inputRef.current.focus();
                          unSelect(item.value);
                        }}
                      />
                    </div>
                  ))}
                <input
                  ref={inputRef}
                  className="form__field"
                  placeholder={showPlaceHolder ? props.placeholder : undefined}
                  onChange={(e) => handleChange(e)}
                  onClick={() => setShow(showOptionsOnClick && true)}
                  value={searchString}
                />
              </div>
            </div>

            {show && (
              <div className="batch-selector-options context-menu-body">
                {loader && (
                  <div className="product-dropdown-loader">
                    <Loader />
                  </div>
                )}
                <ul>
                  {Array.isArray(options) &&
                    options
                      .filter((item) => !(value || []).map((val) => val.value).includes(item.value))
                      .map((item) => (
                        <li
                          key={`product-option-${typeof item === "string" ? item : item.label}`}
                          onClick={() => {
                            setShow(false);
                            setSearchString("");
                            onChange([...(Array.isArray(value) ? value : []), item]);
                          }}
                        >
                          {typeof item === "string" ? item : item.label}
                        </li>
                      ))}
                </ul>
              </div>
            )}
          </>
        </div>
      </FormGroup>
    </div>
  );
};

ProductDropdown.defaultProps = {
  className: "",
  isRequired: true,
  placeholder: "",
  value: undefined,
  onChange: () => null,
  searchFunction: () => null,
  field: "name",
};

ProductDropdown.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  searchFunction: PropTypes.func,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  important: PropTypes.bool,
  onKeyDown: PropTypes.func,
  options: PropTypes.array,
  onChange: PropTypes.func,
  field: PropTypes.string,
  value: PropTypes.array,
  getFullItem: PropTypes.bool,
};
