/* eslint-disable max-len */
/* eslint-disable no-case-declarations */
import * as inventoryTypes from '../types/inventoryTypes';
import initialState from './initialState';
import { updateObject } from 'utils';

const inventory = (state = initialState.inventoryRecalls, action) => {
  switch (action.type) {
    case inventoryTypes.GET_RECALLS_START:
      return updateObject(state, { isLoading: true });

    case inventoryTypes.GET_RECALLS_SUCCESS:
      return updateObject(state, { ...action.payload, isLoading: false });

    case inventoryTypes.GET_RECALLS_FAILURE:
      return updateObject(state, { isLoading: false });

    case inventoryTypes.PROCESS_RECALL:
      return updateObject(state, {
        docs: state.docs.map(item => item._id === action.payload._id ?
          { ...action.payload, to: item["to"], from: item["from"] } : item)
      });

    case inventoryTypes.INITIATE_RECALL_START:
      return updateObject(state, { isSaving: true });

    case inventoryTypes.INITIATE_RECALL_SUCCESS:
      const newRecalledDocs = [action.payload, ...state.docs];
      return updateObject(state, { isSaving: false, docs: newRecalledDocs });

    case inventoryTypes.INITIATE_RECALL_FAILURE:
      return updateObject(state, { isSaving: false });

    case inventoryTypes.UPDATE_RECALL_START:
      return updateObject(state, { isSaving: true });

    case inventoryTypes.UPDATE_RECALL_SUCCESS:
      const updatedDocs = [...state.docs].map(doc => {
        if (doc._id === action.payload._id) {
          return action.payload;
        }
        return doc;
      });
      return updateObject(state, { isSaving: false, docs: updatedDocs });

    case inventoryTypes.UPDATE_RECALL_FAILURE:
      return updateObject(state, { isSaving: false });

    default:
      return { ...state };
  }
};

export default inventory;
