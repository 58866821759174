import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "services/baseQuery";
import { _sortItems } from "utils";

export const admissionCapacityApi = createApi({
  reducerPath: "admissionCapacityApi",
  baseQuery,
  tagTypes: ["Admission-Capacity"],
  endpoints: (builder) => ({
    getAdmissionCapacity: builder.query({
      query: () => "/admission-capacity",
      transformResponse: (response) => _sortItems([...response.data], "name"),
      providesTags: ["Admission-Capacity"],
    }),
  }),
});

export const { useGetAdmissionCapacityQuery } = admissionCapacityApi;
