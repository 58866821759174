import axios from 'services/axios';
import { _notifyError } from 'utils';
import { CUSTOM_ERROR_MESSAGE } from "utils/constants";
import {
  GET_NOTIFICATIONS_START,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
  MARK_NOTIFICATION_START,
  MARK_NOTIFICATION_SUCCESS,
  MARK_NOTIFICATION_FAIL,
} from 'store/types/notificationTypes';


const getNotificationsStart = () => ({
  type: GET_NOTIFICATIONS_START,
});

const getNotificationsSuccess = payload => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload,
});

const getNotificationsFailure = () => ({ type: GET_NOTIFICATIONS_FAIL });

export const fetchNotifications = () => {
  return async dispatch => {
    dispatch(getNotificationsStart());
    try {
      const { data } = await axios.get('/notifications');
      if (data.status) {
        dispatch(getNotificationsSuccess({ items: data.data }));
      }
    } catch ({ response }) {
      _notifyError(
        response ? (typeof response.data !== 'string' ? response.data.message : response.data) : CUSTOM_ERROR_MESSAGE,
      );
      dispatch(getNotificationsFailure());
    }
  };
};

export const markNotification = notificationId => {
  return async dispatch => {
    dispatch({ type: MARK_NOTIFICATION_START });
    try {
      const { data } = await axios.post(`notifications/${notificationId}/read`);
      if (data.status) {
        dispatch({ type: MARK_NOTIFICATION_SUCCESS });
      }
    } catch ({ response }) {
      _notifyError(
        response ? (typeof response.data !== 'string' ? response.data.message : response.data) : CUSTOM_ERROR_MESSAGE,
      );
      dispatch({ type: MARK_NOTIFICATION_FAIL });
    }
  };
};

export const markAllNotifications = ids => {
  return async dispatch => {
    dispatch({ type: MARK_NOTIFICATION_START });
    try {
      const { data } = await axios.post(`notifications/read`, { ids: ids });
      if (data.status) {
        dispatch({ type: MARK_NOTIFICATION_SUCCESS });
        dispatch(fetchNotifications());
      }
    } catch ({ response }) {
      _notifyError(
        response ? (typeof response.data !== 'string' ? response.data.message : response.data) : CUSTOM_ERROR_MESSAGE,
      );
      dispatch({ type: MARK_NOTIFICATION_FAIL });
    }
  };
};

export const deleteNotifications = ids => {
  return async dispatch => {
    dispatch({ type: MARK_NOTIFICATION_START });
    try {
      const { data } = await axios.post(`notifications/delete`, { ids: ids });
      if (data.status) {
        dispatch({ type: MARK_NOTIFICATION_SUCCESS });
        dispatch(fetchNotifications());
      }
    } catch ({ response }) {
      _notifyError(
        response ? (typeof response.data !== 'string' ? response.data.message : response.data) : CUSTOM_ERROR_MESSAGE,
      );
      dispatch({ type: MARK_NOTIFICATION_FAIL });
    }
  };
};
