import React from 'react';
import PropTypes from 'prop-types';

export const ProfileDetail = ({ label, info }) => {
    return (
        <div className="profile-detail">
            <p className="profile-detail__label">{label || ''}</p>
            <h4 className="profile-detail__info">{info || 'Not Provided'}</h4>
        </div>
    )
}

ProfileDetail.propTypes = {
    label: PropTypes.string,
    info: PropTypes.oneOfType([PropTypes.number, PropTypes.string,])
}