import * as React from "react";
import { DayPicker } from "react-day-picker";
import PropTypes from "prop-types";
import "react-day-picker/dist/style.css";
import { cn } from "utils";

function Calendar({ classNames, className, showOutsideDays = true, ...props }) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("", className)}
      classNames={{
        months: "tw-flex tw-flex-col sm:tw-flex-row tw-space-y-4 sm:tw-space-x-4 sm:tw-space-y-0",
        month: "tw-space-y-4",
        // caption: "tw-flex tw-justify-center tw-pt-1 tw-relative tw-items-center",
        // caption_label: "tw-text-sm tw-font-medium",
        // nav: "tw-space-x-1 tw-flex tw-items-center",
        // nav_button: cn(
        //   buttonVariants({ variant: "outline" }),
        //   "tw-h-7 tw-w-7 tw-bg-transparent tw-p-0 tw-opacity-50 hover:tw-opacity-100",
        // ),
        // nav_button_previous: "tw-absolute tw-left-1",
        // nav_button_next: "tw-absolute tw-right-1",
        table: "tw-w-full tw-border-collapse tw-space-y-1",
        head_row: "tw-flex",
        head_cell: "tw-text-muted-foreground tw-rounded-md tw-w-9 tw-font-normal tw-text-[12.8px]",
        row: "tw-flex tw-w-full tw-mt-2",
        cell:
          "tw-h-9 tw-w-9 tw-text-center tw-text-[14px] tw-p-0 tw-relative [&:has([aria-selected].day-range-end)]:tw-rounded-r-md " +
          "[&:has([aria-selected].day-outside)]:tw-bg-accent/50 [&:has([aria-selected])]:tw-bg-accent" +
          "first:[&:has([aria-selected])]:tw-rounded-l-md last:[&:has([aria-selected])]:tw-rounded-r-md focus-within:tw-relative " +
          "focus-within:tw-z-20",
        // day: cn(
        //   buttonVariants({ variant: "ghost" }),
        //   "tw-h-[36px] tw-w-[36px] tw-p-0 tw-font-normal aria-selected:tw-opacity-100",
        // ),
        day: cn("tw-h-9 tw-w-9 tw-rounded-md tw-p-0 tw-font-normal aria-selected:tw-opacity-100"),
        day_range_end: "tw-day-range-end",
        day_selected:
          "tw-bg-primary tw-text-primary-foreground hover:tw-bg-primary hover:tw-text-primary-foreground " +
          "focus:tw-bg-primary focus:tw-text-primary-foreground",
        day_today: "tw-bg-accent tw-text-accent-foreground",
        day_outside:
          "tw-day-outside tw-text-muted-foreground tw-opacity-50 aria-selected:tw-bg-accent/50 " +
          "aria-selected:tw-text-muted-foreground aria-selected:tw-opacity-30",
        day_disabled: "tw-text-muted-foreground tw-opacity-50",
        day_range_middle: "aria-selected:tw-bg-accent aria-selected:tw-text-accent-foreground",
        day_hidden: "tw-invisible",
        ...classNames,
        // }}
        // components={{
        //   Caption: (props) => {
        //     console.log(props, '00');
        //     return <NewYearMonthForm />;
        //   },
        //   // IconLeft: ({ ...props }) => <i className="iconmoon icon-arrow-left" />,
        //   // IconRight: ({ ...props }) => <i className="iconmoon icon-arrow-right" />,
      }}
      captionLayout="dropdown"
      {...props}
    />
  );
}

Calendar.displayName = "Calendar";

Calendar.defaultProps = {
  toYear: new Date(2034),
  fromYear: new Date(2020),
};

Calendar.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  classNames: PropTypes.object,
  showOutsideDays: PropTypes.object,
  toMonth: PropTypes.object,
  fromMonth: PropTypes.object,
  selected: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export { Calendar };
