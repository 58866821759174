export const FETCH_CLAIMS_DASHBOARD_STATS_START = 'FETCH_CLAIMS_DASHBOARD_STATS_START';
export const FETCH_CLAIMS_DASHBOARD_STATS_SUCCESS = 'FETCH_CLAIMS_DASHBOARD_STATS_SUCCESS';
export const FETCH_CLAIMS_DASHBOARD_STATS_FAIL = 'FETCH_CLAIMS_DASHBOARD_STATS_FAIL';

export const FETCH_CLAIMS_START = 'FETCH_CLAIMS_START';
export const FETCH_CLAIMS_SUCCESS = 'FETCH_CLAIMS_SUCCESS';
export const FETCH_CLAIMS_FAIL = 'FETCH_CLAIMS_FAIL';

export const MARK_CLAIMS_SUBMITTED_START = 'MARK_CLAIMS_SUBMITTED_START';
export const MARK_CLAIMS_SUBMITTED_END = 'MARK_CLAIMS_SUBMITTED_END';

export const MARK_CLAIMS_PAID_START = 'MARK_CLAIMS_PAID_START';
export const MARK_CLAIMS_PAID_END = 'MARK_CLAIMS_PAID_END';

export const CREATE_CLAIMS_START = 'CREATE_CLAIMS_START';
export const CREATE_CLAIMS_END = 'CREATE_CLAIMS_END';
