import * as React from "react";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import PropTypes from "prop-types";
import { cn } from "utils";

const Popover = PopoverPrimitive.Root;

const PopoverTrigger = PopoverPrimitive.Trigger;

const PopoverContent = React.forwardRef(({ className, align = "center", sideOffset = 4, ...props }, ref) => (
  <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      className={cn(
        "tw-z-50  tw-rounded-md tw-border tw-border-slate-200 tw-bg-white tw-p-4 tw-text-slate-950 tw-shadow-md " +
          "tw-outline-none data-[state=open]:tw-animate-in data-[state=closed]:tw-animate-out " +
          "data-[state=closed]:tw-fade-out-0 data-[state=open]:tw-fade-in-0 data-[state=closed]:tw-zoom-out-95 " +
          "data-[state=open]:tw-zoom-in-95 data-[side=bottom]:tw-slide-in-from-top-2 data-[side=left]:tw-slide-in-from-right-2 " +
          "data-[side=right]:tw-slide-in-from-left-2 data-[side=top]:tw-slide-in-from-bottom-2",
        className,
      )}
      {...props}
    />
  </PopoverPrimitive.Portal>
));
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

PopoverContent.propTypes = {
  className: PropTypes.string,
  align: PropTypes.string,
  sideOffset: PropTypes.number,
};

const PopoverDialogContent = React.forwardRef(({ className, align = "center", sideOffset = 4, ...props }, ref) => (
  <PopoverPrimitive.Content
    ref={ref}
    align={align}
    sideOffset={sideOffset}
    className={cn(
      "tw-z-50  tw-rounded-md tw-border tw-border-slate-200 tw-bg-white tw-p-4 tw-text-slate-950 tw-shadow-md " +
        "tw-outline-none data-[state=open]:tw-animate-in data-[state=closed]:tw-animate-out " +
        "data-[state=closed]:tw-fade-out-0 data-[state=open]:tw-fade-in-0 data-[state=closed]:tw-zoom-out-95 " +
        "data-[state=open]:tw-zoom-in-95 data-[side=bottom]:tw-slide-in-from-top-2 data-[side=left]:tw-slide-in-from-right-2 " +
        "data-[side=right]:tw-slide-in-from-left-2 data-[side=top]:tw-slide-in-from-bottom-2",
      className,
    )}
    {...props}
  />
));
PopoverDialogContent.displayName = PopoverPrimitive.Content.displayName;

PopoverDialogContent.propTypes = {
  className: PropTypes.string,
  align: PropTypes.string,
  sideOffset: PropTypes.number,
};

export { Popover, PopoverTrigger, PopoverContent, PopoverDialogContent };
