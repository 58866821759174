export const FETCH_ADMISSIONS_START = 'FETCH_ADMISSIONS_START';
export const FETCH_ADMISSIONS_SUCCESS = 'FETCH_ADMISSIONS_SUCCESS';
export const FETCH_ADMISSIONS_FAIL = 'FETCH_ADMISSIONS_FAIL';

export const FETCH_CURRENT_ADMISSION_START = 'FETCH_CURRENT_ADMISSION_START';
export const FETCH_CURRENT_ADMISSION_SUCCESS = 'FETCH_CURRENT_ADMISSION_SUCCESS';
export const FETCH_CURRENT_ADMISSION_FAIL = 'FETCH_CURRENT_ADMISSION_FAIL';

export const FETCH_RECOMMENDED_ADMISSIONS_START = 'FETCH_RECOMMENDED_ADMISSIONS_START';
export const FETCH_RECOMMENDED_ADMISSIONS_SUCCESS = 'FETCH_RECOMMENDED_ADMISSIONS_SUCCESS';
export const FETCH_RECOMMENDED_ADMISSIONS_END = 'FETCH_RECOMMENDED_ADMISSIONS_END';

export const ADMIT_PATIENT_FAILURE = 'ADMIT_PATIENT_FAILURE';
export const ADMIT_PATIENT_START = 'ADMIT_PATIENT_START';
export const ADMIT_PATIENT_SUCCESS = 'ADMIT_PATIENT_SUCCESS';

export const FETCH_DAILY_STATS_START = 'FETCH_DAILY_STATS_START';
export const FETCH_DAILY_STATS_FAILURE = 'FETCH_DAILY_STATS_FAILURE';
export const FETCH_DAILY_STATS_SUCCESS = 'FETCH_DAILY_STATS_SUCCESS';

export const UPDATE_DAILY_STATS = 'UPDATE_DAILY_STATS';

export const CANCEL_ADMISSION_START = 'CANCEL_ADMISSION_START';
export const CANCEL_ADMISSION_FAILURE = 'CANCEL_ADMISSION_FAILURE';
export const CANCEL_ADMISSION_SUCCESS = 'CANCEL_ADMISSION_SUCCESS';

export const DISCHARGE_ADMISSION_START = 'DISCHARGE_ADMISSION_START';
export const DISCHARGE_ADMISSION_FAILURE = 'DISCHARGE_ADMISSION_FAILURE';
export const DISCHARGE_ADMISSION_SUCCESS = 'DISCHARGE_ADMISSION_SUCCESS';

export const CHECKOUT_PATIENT_START = 'CHECKOUT_PATIENT_START';
export const CHECKOUT_PATIENT_FAILURE = 'CHECKOUT_PATIENT_FAILURE';
export const CHECKOUT_PATIENT_SUCCESS = 'CHECKOUT_PATIENT_SUCCESS';
