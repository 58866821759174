export default {
  inventory: {},
  inventoryOrder: {
    docs: [],
  },
  inventoryConsumables: {},
  inventoryPricelists: {},
  inventoryRecalls: {},
  inventoryReturns: {},
  inventoryServices: {},
  inventoryTransfers: {
    docs: [],
  },
};
