import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Suggestions } from '../suggestions';
import { _autocomplete } from 'utils';

export const ModalSearch = props => {
  const [suggestions, setSuggestions] = useState([]);
  const [input, setInput] = useState('');
  const [searchText, setSearchText] = useState('');

  const _handleChange = e => {
    const { value } = e.target;
    if (value === '' || value.match(/^[A-Z a-z]+$/)) {
      setSearchText(value);
      setInput(value);
    }
    let suggestionList = [];
    suggestionList = [..._autocomplete(value, props.searchData, 'name')];
    setSuggestions(suggestionList);
  };
  const _handleSuggestedItem = item => {
    setSearchText('');
    props._handleTaskAssign(item);
    setSuggestions([]);
  };

  return (
    <div className="modal-search">
      <div className="form__suggestions" style={{ width: '100%' }}>
        <input
          type="text"
          placeholder="Search for members to add"
          className="modal-search__bar"
          onChange={_handleChange}
          value={searchText}
          disabled={props.inProgress}
        />
        <Suggestions
          getSuggestedItem={_handleSuggestedItem}
          field="name"
          input={input}
          list={suggestions}
        />
      </div>
    </div>
  );
};
ModalSearch.propTypes = {
  _handleTaskAssign: PropTypes.func,
  _handleRemoval: PropTypes.func,
  searchData: PropTypes.array,
  inProgress: PropTypes.bool
};
