export const FETCH_BILLS_START = 'FETCH_BILLS_START';
export const FETCH_BILLS_SUCCESS = 'FETCH_BILLS_SUCCESS';
export const FETCH_BILLS_FAIL = 'FETCH_BILLS_FAIL';

export const FETCH_BILLS_PER_PATIENT_START = 'FETCH_BILLS_PER_PATIENT_START';
export const FETCH_BILLS_PER_PATIENT_SUCCESS = 'FETCH_BILLS_PER_PATIENT_SUCCESS';
export const FETCH_BILLS_PER_PATIENT_FAIL = 'FETCH_BILLS_PER_PATIENT_FAIL';

export const DELETE_BILL_ITEM_START = 'DELETE_BILL_ITEM_START';
export const DELETE_BILL_ITEM_END = 'DELETE_BILL_ITEM_END';

export const STASH_BILL_ITEM_START = 'STASH_BILL_ITEM_START';
export const STASH_BILL_ITEM_END = 'STASH_BILL_ITEM_END';

export const FETCH_FINANCE_DASHBOARD_STATS_START = 'FETCH_FINANCE_DASHBOARD_STATS_START';
export const FETCH_FINANCE_DASHBOARD_STATS_SUCCESS = 'FETCH_FINANCE_DASHBOARD_STATS_SUCCESS';
export const FETCH_FINANCE_DASHBOARD_STATS_FAIL = 'FETCH_FINANCE_DASHBOARD_STATS_FAIL';

export const FETCH_WITHDRAWALS_START = 'FETCH_WITHDRAWALS_START';
export const FETCH_WITHDRAWALS_SUCCESS = 'FETCH_WITHDRAWALS_SUCCESS';
export const FETCH_WITHDRAWALS_FAIL = 'FETCH_WITHDRAWALS_FAIL';

export const CREATE_PAYMENT_START = 'CREATE_PAYMENT_START';
export const CREATE_PAYMENT_END = 'CREATE_PAYMENT_END';

export const CONVERT_INVOICE_START = 'CONVERT_INVOICE_START';
export const CONVERT_INVOICE_END = 'CONVERT_INVOICE_END';

export const CANCEL_INVOICE_START = 'CANCEL_INVOICE_START';
export const CANCEL_INVOICE_END = 'CANCEL_INVOICE_END';
