/* eslint-disable max-len */
/* eslint-disable no-case-declarations */
import * as inventoryTypes from '../types/inventoryTypes';
import initialState from './initialState';
import { updateObject } from 'utils';

const inventory = (state = initialState.inventoryReturns, action) => {
  switch (action.type) {
    case inventoryTypes.GET_RETURNS_START:
      return updateObject(state, { isLoading: true });

    case inventoryTypes.GET_RETURNS_SUCCESS:
      return updateObject(state, { ...action.payload, isLoading: false });

    case inventoryTypes.GET_RETURNS_FAILURE:
      return updateObject(state, { isLoading: false });

    case inventoryTypes.INITIATE_RETURN_START:
      return updateObject(state, { isSaving: true });

    case inventoryTypes.INITIATE_RETURN_SUCCESS:
      const newReturnedDocs = [action.payload, ...state.docs];
      return updateObject(state, { isSaving: false, docs: newReturnedDocs });

    case inventoryTypes.INITIATE_RETURN_FAILURE:
      return updateObject(state, { isSaving: false });

    case inventoryTypes.UPDATE_RETURN_START:
      return updateObject(state, { isSaving: true });

    case inventoryTypes.UPDATE_RETURN_SUCCESS:
      const updatedDocs = [...state.docs].map(doc => {
        if (doc._id === action.payload._id) {
          return action.payload;
        }
        return doc;
      });
      return updateObject(state, { isSaving: false, docs: updatedDocs });

    case inventoryTypes.UPDATE_RETURN_FAILURE:
      return updateObject(state, { isSaving: false });

    case inventoryTypes.PROCESS_RETURN_SUCCESS:
      return updateObject(state, { isSaving: false, docs: [...state.docs].map(doc => doc._id === action.payload._id ? action.payload : doc) });

    default:
      return { ...state };
  }
};

export default inventory;
