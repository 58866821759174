import { _notifyError, _notifySuccess, _sortItems } from 'utils';
import * as supplierTypes from '../types/supplierTypes';
import { beginApiCall, endApiCall } from './apiActions';
import Axios from 'services/axios';
import { CUSTOM_ERROR_MESSAGE } from "utils/constants";

const updatedState = data => ({
  isSaving: false,
  error: '',
  items: data,
  filteredItems: data,
  newSupplier: {},
});


const fetchSuppliersSuccess = payload => ({
  type: supplierTypes.FETCH_SUPPLIERS_SUCCESS,
  payload,
});

const fetchSuppliersFailed = () => ({
  type: supplierTypes.FETCH_SUPPLIERS_FAILED,
});

const addSupplierStart = () => ({ type: supplierTypes.ADD_SUPPLIER_START });

const addSupplierSuccess = payload => ({
  type: supplierTypes.ADD_SUPPLIER_SUCCESS,
  payload,
});

const addSupplierFailed = () => ({ type: supplierTypes.ADD_SUPPLIER_FAILED });

const updateSupplierStart = () => ({
  type: supplierTypes.UPDATE_SUPPLIER_START,
});

const updateSupplierSuccess = payload => ({
  type: supplierTypes.UPDATE_SUPPLIER_SUCCESS,
  payload,
});

const updateSupplierFailed = payload => ({
  type: supplierTypes.UPDATE_SUPPLIER_FAILED,
  payload,
});

export const fetchSuppliers = () => async dispatch => {
  dispatch(beginApiCall());
  try {
    const { data } = await Axios.get('/suppliers');
    if (data.status === 'true') {
      const payload = _sortItems(data.data, 'name');
      dispatch(endApiCall());
      dispatch(fetchSuppliersSuccess(updatedState(payload)));
    }
  } catch (err) {
    dispatch(endApiCall());
    _notifyError(err.message);
    dispatch(fetchSuppliersFailed(err.message));
  }
};

export const addSupplier = (current, arr, close) => {
  return async dispatch => {
    dispatch(addSupplierStart());
    try {
      const { data } = await Axios.post('/suppliers', current);

      if (data.status === 'true') {
        const cache = _sortItems([...arr, ...data.data], 'name');
        dispatch(addSupplierSuccess(updatedState(cache)));
        _notifySuccess('Supplier successfully added.');
        close();
      }
    } catch (error) {
      _notifyError(CUSTOM_ERROR_MESSAGE);
      dispatch(addSupplierFailed(CUSTOM_ERROR_MESSAGE));
    }
  };
};

export const updateSelectedSupplier = (id, current, updatedObject, close, action) => {
  return async dispatch => {
    dispatch(updateSupplierStart());
    try {
      const { data } = await Axios.patch(`/suppliers/${id}`, current);
      if (data.status) {
        dispatch(updateSupplierSuccess({ updatedObject }));

        _notifySuccess(`Supplier ${action || 'updated'} successfully.`);
        close();
        return true;
      }
      throw Error;
    } catch (error) {
      _notifyError(CUSTOM_ERROR_MESSAGE);
      dispatch(updateSupplierFailed(CUSTOM_ERROR_MESSAGE));
      return false;
    }
  };
};

export const updateSuppliersList = value => ({
  type: supplierTypes.UPDATE_SUPPLIERS_LIST,
  payload: {
    filteredItems: value,
  },
});
