export const CREATE_PATIENT_START = 'CREATE_PATIENT_START';
export const CREATE_PATIENT_SUCCESS = 'CREATE_PATIENT_SUCCESS';
export const CREATE_PATIENT_FAIL = 'CREATE_PATIENT_FAIL';

export const FETCH_ALL_PATIENTS_START = 'FETCH_ALL_PATIENTS_START';
export const FETCH_ALL_PATIENTS_SUCCESS = 'FETCH_ALL_PATIENTS_SUCCESS';
export const FETCH_ALL_PATIENTS_FAIL = 'FETCH_ALL_PATIENTS_FAIL';
export const FETCH_PATIENT_START = 'FETCH_PATIENT_START';
export const FETCH_PATIENT_SUCCESS = 'FETCH_PATIENT_SUCCESS';
export const FETCH_PATIENT_FAIL = 'FETCH_PATIENT_FAIL';

export const UPDATE_PATIENT_START = 'UPDATE_PATIENT_START';
export const UPDATE_PATIENT_SUCCESS = 'UPDATE_PATIENT_SUCCESS';
export const UPDATE_PATIENT_FAIL = 'UPDATE_PATIENT_FAIL';
