import * as branchTypes from '../types/branchTypes';
import initialState from 'store/reducers/initialState';
import { updateObject } from 'utils';

export const branches = (state = initialState.branches, action) => {
  switch (action.type) {
    case branchTypes.FETCH_BRANCHES_SUCCESS:
      return updateObject(state, action.payload);

    case branchTypes.FETCH_BRANCHES_FAILED:
      return updateObject(state, { error: action.error });

    case branchTypes.UPLOAD_BRANCHES_SUCCESS:
      return updateObject(state, action.payload);

    case branchTypes.ADD_BRANCH_START:
      return updateObject(state, { formErrorMessage: null, isSaving: true });

    case branchTypes.ADD_BRANCH_SUCCESS:
      return updateObject(state, { ...action.payload, formErrorMessage: null });

    case branchTypes.ADD_BRANCH_FAILED:
      return updateObject(state, {
        formErrorMessage: action.error,
        isSaving: false
      });

    case branchTypes.UPDATE_BRANCH_START:
      return updateObject(state, { formErrorMessage: null, ...action.payload });

    case branchTypes.UPDATE_BRANCH_SUCCESS:
      return updateObject(state, {
        ...action.payload,
        formErrorMessage: null,
        isSaving: false,
        isDeactivating: false
      });

    case branchTypes.UPDATE_BRANCH_FAILED:
      return updateObject(state, {
        // formErrorMessage: action.error,
        isSaving: false,
        isDeactivating: false
      });

    case branchTypes.DELETE_BRANCH_START:
      return updateObject(state, { isDeactivating: true });

    case branchTypes.DELETE_BRANCH_SUCCESS:
      return updateObject(state, { ...action.payload, isDeactivating: false });

    case branchTypes.DELETE_BRANCH_FAILED:
      return updateObject(state, { isDeactivating: false });

    case branchTypes.BRANCHES_PAGE_SORT:
      return updateObject(state, action.payload);

    case branchTypes.UPDATE_BRANCHES_LIST:
      return updateObject(state, action.payload);

    default:
      return { ...state };
  }
};

export default branches;
