export const ADD_DEPARTMENT_START = 'ADD_DEPARTMENT_START';
export const ADD_DEPARTMENT_SUCCESS = 'ADD_DEPARTMENT_SUCCESS';
export const ADD_DEPARTMENT_FAILED = 'ADD_DEPARTMENT_FAILED';

export const CHECK_EXISTING_DEPARTMENT = 'CHECK_EXISTING_DEPARTMENT';

export const FETCH_DEPARTMENTS_START = 'FETCH_DEPARTMENTS_START';
export const FETCH_DEPARTMENTS_SUCCESS = 'FETCH_DEPARTMENTS_SUCCESS';
export const FETCH_DEPARTMENTS_FAILED = 'FETCH_DEPARTMENTS_FAILED';

export const DEPARTMENTS_PAGE_SORT = 'DEPARTMENTS_PAGE_SORT';
export const UPDATE_DEPARTMENTS_LIST = 'UPDATE_DEPARTMENTS_LIST';

export const UPDATE_DEPARTMENT_START = 'UPDATE_DEPARTMENT_START';
export const UPDATE_DEPARTMENT_SUCCESS = 'UPDATE_DEPARTMENT_SUCCESS';
export const UPDATE_DEPARTMENT_FAILED = 'UPDATE_DEPARTMENT_FAILED';

export const DELETE_DEPARTMENT_START = 'DELETE_DEPARTMENT_START';
export const DELETE_DEPARTMENT_SUCCESS = 'DELETE_DEPARTMENT_SUCCESS';
export const DELETE_DEPARTMENT_FAILED = 'DELETE_DEPARTMENT_FAILED';

export const UPLOAD_DEPARTMENTS_SUCCESS = 'UPLOAD_DEPARTMENTS_SUCCESS';

export const FETCH_PATIENTS_DEPARTMENTS_SUCCESS = 'FETCH_PATIENTS_DEPARTMENTS_SUCCESS';
export const FETCH_PATIENTS_DEPARTMENTS_FAILED = 'FETCH_PATIENTS_DEPARTMENTS_FAILED';
