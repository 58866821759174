/* eslint-disable no-case-declarations */
import * as medicationLogTypes from '../types/medicationLogTypes';
import initialState from './initialState';
import { updateObject } from 'utils';

const medicationLogs = (state = initialState.medicationLog, { type, payload }) => {
  switch (type) {
    case medicationLogTypes.FETCH_LOGS_START:
      return updateObject(state, { isLoading: true });

    case medicationLogTypes.FETCH_LOGS_SUCCESS:
      return updateObject(state, { isLoading: false , ...payload });

    case medicationLogTypes.FETCH_LOGS_FAIL:
      return updateObject(state, { isLoading: false });

    default:
      return { ...state };
  }
};

export default medicationLogs;
