import React, { useState } from "react";
import { Label } from "../ui/label";
import ReactSelect, { components } from "react-select";
import { DayPicker } from "react-day-picker";
import { formatDate } from "date-fns";
import PropTypes from "prop-types";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import { YearMonthForm } from "./yearForm";
import AsyncSelect from "react-select/async";
import classnames from "classnames";
import { FormGroup } from "../ui/form-group";
import Required from "components/required";

const selectStyle = {
  control: (base, state) => ({
    ...base,
    border: "4px solid rgba(151, 151, 151, 0.1)",
    borderRadius: 8,
    borderColor: state.isFocused ? "grey" : "red",
  }),
  groupHeading: (base) => ({
    ...base,
    color: "#6a69e4",
    textTransform: "capitalize",
    fontSize: "1.8rem",
  }),
};

const dayPickerStyle = {
  border: "transparent",
  margin: "0",
  width: "100%",
  outline: "none",
  height: "2.4rem",
};

export const CustomLabels = ({ children, ...props }) => (
  <components.Option {...props}>
    <input type="checkbox" onChange={() => null} />
    &nbsp;{children}
  </components.Option>
);
CustomLabels.propTypes = { children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]) };

export const Select = React.forwardRef((props, ref) => {
  const { label, className, isRequired, style, groupedOptions, ...rest } = props;
  return (
    <FormGroup
      className={`tw-py-[0.7rem] tw-h-auto ${className && className} ${props.isDisabled || (props.disabled && "disabled")}`}
      style={style}
      onClick={(e) => e.stopPropagation()}
    >
      {label && (
        <span className="tw-flex tw-items-center tw-space-x-0.5 tw-mb-0.5">
          <Label for={props.id} className="tw-capitalize">
            {label}
          </Label>
          {(props?.required || isRequired) && <Required />}
        </span>
      )}
      {props.isAsyncType ? (
        <AsyncSelect
          className="form__select tw-w-full tw-h-[24px]"
          ref={ref}
          classNamePrefix="modal-select"
          styles={selectStyle}
          {...rest}
        />
      ) : (
        <ReactSelect
          components={props.isGrouped ? { Option: CustomLabels, ...groupedOptions } : null}
          className="form__select tw-w-full tw-h-auto basic-multi-select"
          classNamePrefix="modal-select"
          styles={selectStyle}
          ref={ref}
          allowSelectAll={true}
          {...rest}
        />
      )}
    </FormGroup>
  );
});

Select.displayName = "Select";

export const DateInput = (props) => {
  const fromMonth =
    props.fromMonth || new Date(10 + new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  const toMonth = props.toMonth || new Date(`${new Date().getFullYear()}`);

  const [month, setMonth] = useState(new Date());

  const handleYearMonthChange = (month) => {
    setMonth(month);
  };

  return (
    <FormGroup
      className={classnames("tw-mb-0", props.className)}
      style={{ position: "relative", marginBottom: props.noBottomMargin ? 0 : null }}
    >
      <Label htmlFor={props.id} className={classnames("", props?.labelClass)}>
        {props.label} <span style={{ color: "red" }}>{props.isRequired ? "*" : ""}</span>
      </Label>
      <div className="tw-flex justify-content-between align-items-center">
        <DayPicker
          value={props.value || undefined}
          onDayChange={props.onDayChange}
          dayPickerProps={{
            selectedDays: props.value,
            disabledDays: props.disabledDays,
            month: month,
            modifiers: props.modifiers,
            fromMonth: fromMonth,
            toMonth: toMonth,
            captionElement: <YearMonthForm onChange={handleYearMonthChange} fromMonth={fromMonth} toMonth={toMonth} />,
          }}
          fromYear={props.fromYear}
          keepFocus={false}
          id={props.id}
          formatDate={formatDate}
          selected={props.value || undefined}
          placeholder={props.placeholder}
          format={props.dateFormat}
          // parseDate={parseDate}
          inputProps={{ readOnly: true, style: dayPickerStyle, value: props.value, disabled: props.isDisabled }}
        />
        {props.isAppointment ? (
          <div className="date-input date-input__calendar">
            <span>
              <i className="iconmoon icon-arrow-down" />
            </span>
          </div>
        ) : (
          <div className={props.className ? props.className : "date-input"}>
            <span>
              <i className="fas fa-calendar-alt" />
            </span>
          </div>
        )}
      </div>
    </FormGroup>
  );
};

export const TimeInput = (props) => (
  <div className={!props?.noMargin && "settings__form-group form-group"} style={{ position: "relative" }}>
    <label htmlFor={props.id} className="form__label">
      {props.label}
    </label>
    <TimePicker
      value={props.value}
      disabled={props.disabled}
      showSecond={props.showSecond}
      format={props.format}
      use12Hours={props.use12Hours}
      placeholder={props.placeholder}
      onChange={props.onChange}
    />
  </div>
);

Select.defaultProps = {
  isMulti: false,
  options: [],
  placeholder: "",
  value: null,
  style: {},
  isSearchable: true,
};

Select.propTypes = {
  isMulti: PropTypes.bool,
  isGrouped: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  isLoading: PropTypes.bool,
  grouped: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isSearchable: PropTypes.bool,
  menuIsOpen: PropTypes.bool,
  isAsyncType: PropTypes.bool,
  onMenuClose: PropTypes.func,
  onMenuOpen: PropTypes.func,
  isOptionSelected: PropTypes.func,
  hideSelectedOptions: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  style: PropTypes.object,
  defaultOptions: PropTypes.array,
  cacheOptions: PropTypes.bool,
  loadOptions: PropTypes.func,
};

DateInput.propTypes = {
  label: PropTypes.string,
  labelClass: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isAppointment: PropTypes.bool,
  noBottomMargin: PropTypes.bool,
  dayPickerProps: PropTypes.object,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  currentYear: PropTypes.number,
  toMonth: PropTypes.object,
  fromMonth: PropTypes.object,
  fromYear: PropTypes.number,
  disabledDays: PropTypes.object,
  onDayChange: PropTypes.func,
  dateFormat: PropTypes.string,
  modifiers: PropTypes.object,
  className: PropTypes.string,
};

TimeInput.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  showSecond: PropTypes.bool,
  value: PropTypes.object,
  disabled: PropTypes.bool,
};

TimeInput.defaultProps = {
  showSecond: false,
  disabled: false,
};
