import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { _notifyError } from "utils";
import { Spinner } from "reactstrap";
import classnames from "classnames";
import { FormGroup } from "components/ui/form-group";

export const QuantitySelector = (props) => {
  const { callBack, value, getEvent, upperLimit, loading, isDisabled, placeholder, containerClass } = props;
  const [maxValue, setMaxValue] = useState(0);

  useEffect(() => {
    if (upperLimit === undefined) setMaxValue(value);
    if (upperLimit === false) setMaxValue(undefined);
    if (typeof upperLimit === "number") setMaxValue(upperLimit);
  }, [upperLimit]);

  const getPrevNumber = (max, number) => {
    if (number > max) {
      while (number > max) {
        number = number - 1;
      }
    }
    return number;
  };

  const controlledOutcome = (newValue) => {
    if (newValue < 0 || newValue === 0) {
      return 0;
    }

    if (newValue === Infinity || String(newValue).includes("e")) {
      _notifyError("Range exceded");
      return 0;
    }

    const currentValue = value;

    if (maxValue !== undefined && currentValue !== undefined) {
      if (maxValue === 0) {
        if (currentValue !== 0) {
          if (newValue < currentValue) {
            // eslint-disable-next-line
            newValue = newValue;
          } else {
            newValue = currentValue;
          }
        } else {
          newValue = 0;
        }
      } else {
        if (newValue > maxValue) {
          newValue = getPrevNumber(maxValue, newValue);
        } else {
          // eslint-disable-next-line
          newValue = newValue;
        }
      }
    }

    return newValue;
  };
  //
  return (
    <FormGroup
      className={classnames("tw-w-full", containerClass, {
        "tw-bg-natural-100": props?.readOnly,
      })}
    >
      <div className="tw-flex tw-items-center tw-space-x-3">
        <i
          className="fas fa-caret-left align"
          onClick={() => {
            if (loading || isDisabled) return;
            const newValue = controlledOutcome(Number(value) - 1);
            getEvent ? callBack({ newValue, event: "decrement" }) : callBack(newValue);
          }}
        />
        {loading ? (
          <div className="tw-flex tw-justify-center tw-items-center">
            <Spinner size="sm" />
          </div>
        ) : (
          <input
            className="tw-w-full tw-appearance-none tw-h-2xl read-only:tw-bg-natural-100
                  disabled:tw-bg-natural-100 tw-text-center tw-font-normal tw-text-sm placeholder:tw-text-base
                   focus-visible:tw-outline-none"
            type="text"
            value={value}
            onPaste={(e) => e.preventDefault()}
            onChange={(e) => {
              if (isNaN(Number(e.target.value))) return;
              const newValue = controlledOutcome(Math.abs(Number(e.target.value)));
              getEvent ? callBack({ newValue, event: "direct" }) : callBack(newValue);
            }}
            onKeyDown={(e) => {
              if (e.keyCode < 48 && e.keyCode > 57) {
                e.preventDefault();
              }
            }}
            disabled={loading || isDisabled}
            placeholder={placeholder}
          />
        )}

        <i
          className="fas fa-caret-right align"
          onClick={() => {
            if (loading || isDisabled) return;
            const newValue = controlledOutcome(Number(value) + 1);
            getEvent ? callBack({ newValue, event: "increment" }) : callBack(newValue);
          }}
        />
      </div>
    </FormGroup>
  );
};

QuantitySelector.propTypes = {
  callBack: PropTypes.func,
  value: PropTypes.number,
  placeholder: PropTypes.string,
  getEvent: PropTypes.bool,
  isDisabled: PropTypes.bool,
  loading: PropTypes.bool,
  upperLimit: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
};
