import React from "react";
import PropTypes from "prop-types";
import { Loader } from "components";
import { Table, TableBody, TableHead, TableHeader, TableRow } from "components/ui/table";
import { cn } from "utils";

export const AddMoreTable = ({ heading, loader, ...props }) => {
  return (
    <>
      {loader ? (
        <div className="add-more-table-loader">
          <Loader />
        </div>
      ) : (
        props.children?.length > 0 && (
          <div className={`tw-flex tw-items-center`}>
            <Table className={cn("tw-w-full")}>
              <TableHeader className={props.theadClassName}>
                <TableRow className={props.tableFix}>
                  {heading.map((head, index) => (
                    <TableHead
                      key={`add-more-col-${head || index}`}
                      className={cn(
                        "tw-whitespace-nowrap tw-px-[10px] tw-py-[5px] tw-border-0 tw-bg-[#F2F2FC] tw-text-primary-600 " +
                          "tw-text-[14px]  ",
                        props.thClassName,
                      )}
                    >
                      {head}
                    </TableHead>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>{props.children}</TableBody>
            </Table>
          </div>
        )
      )}
    </>
  );
};

AddMoreTable.propTypes = {
  heading: PropTypes.array,
  children: PropTypes.node,
  tableFix: PropTypes.string,
  className: PropTypes.string,
  customStyle: PropTypes.object,
  theadClassName: PropTypes.string,
  thClassName: PropTypes.string,
  loader: PropTypes.bool,
  noMarginBottom: PropTypes.bool,
};
