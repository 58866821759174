/* eslint-disable no-case-declarations */
import * as labResultTypes from '../types/labResultTypes'
import initialState from './initialState';
import { updateObject } from 'utils';

const labResults = (state = initialState.labResults, { type, payload }) => {
  switch (type) {
    case labResultTypes.FETCH_LAB_RESULTS_START:
      return updateObject(state, { isLoading: true });

    case labResultTypes.FETCH_LAB_RESULTS_SUCCESS:
      return updateObject(state, { isLoading: false, items: payload });

    case labResultTypes.FETCH_LAB_RESULTS_FAIL:
      return updateObject(state, { isLoading: false });

    case labResultTypes.CREATE_LAB_RESULT_SUCCESS:
      return updateObject(state, { items: [...state.items, payload] });

    default:
      return { ...state };
  }
};

export default labResults;
