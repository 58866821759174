import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import PlainLayout from '../layouts/PlainLayout';
import AuthRoute from './AuthRoute';

const Modules = lazy(() => import('../modules'));
const ErrorPage = lazy(() => import('../pages/Error'));

const ModuleRoutes = ({ match }) => {
  const { loggedIn } = useSelector(state => state.auth);

  return (
    <>
      <Switch>
        <AuthRoute
          exact
          path={`${match.path}`}
          isAuthenticated={loggedIn}
          layout={PlainLayout}
          component={Modules}
          accessGranted={true}
        />
        <AuthRoute
          path="*"
          isAuthenticated={loggedIn}
          layout={PlainLayout}
          component={ErrorPage}
          accessGranted={true}
        />
      </Switch>
    </>
  );
};

ModuleRoutes.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired
};

export default ModuleRoutes;
