export const ADD_DISEASE_START = 'ADD_DISEASE_START';
export const ADD_DISEASE_SUCCESS = 'ADD_DISEASE_SUCCESS';
export const ADD_DISEASE_FAILED = 'ADD_DISEASE_FAILED';

export const FETCH_DISEASES_START = 'FETCH_DISEASES_START';
export const FETCH_DISEASES_SUCCESS = 'FETCH_DISEASES_SUCCESS';
export const FETCH_DISEASES_FAILED = 'FETCH_DISEASES_FAILED';

export const UPDATE_DISEASES_LIST = 'UPDATE_DISEASES_LIST';
export const UPDATE_DISEASE_START = 'UPDATE_DISEASE_START';
export const UPDATE_DISEASE_SUCCESS = 'UPDATE_DISEASE_SUCCESS';
export const UPDATE_DISEASE_FAILED = 'UPDATE_DISEASE_FAILED';
