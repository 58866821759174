

export const DateCompare = (task, filterMonth) => {
  const currentYear = new Date().getFullYear();

  const months = {
    january: 0,
    february: 1,
    march: 2,
    april: 3,
    may: 4,
    june: 5,
    july: 6,
    august: 7,
    september: 8,
    october: 9,
    november: 10,
    december: 11
  };

  if (
    new Date(task.createdAt).getFullYear() === currentYear &&
    new Date(task.createdAt).getMonth() === months[filterMonth]
  ) {
    return true;
  }
  return false;
};




