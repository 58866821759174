export const FETCH_PHARMACY_PRESCRIPTIONS_SUCCESS = 'FETCH_PHARMACY_PRESCRIPTIONS_SUCCESS';
export const FETCH_PHARMACY_PRESCRIPTIONS_FAIL ='FETCH_PHARMACY_PRESCRIPTIONS_FAIL';
export const FETCH_PHARMACY_PRESCRIPTIONS_START = 'FETCH_PHARMACY_PRESCRIPTIONS_START';

export const CREATE_PHARMACY_PRESCRIPTION_START = 'CREATE_PHARMACY_PRESCRIPTION_START';
export const CREATE_PHARMACY_PRESCRIPTION_SUCCESS = 'CREATE_PHARMACY_PRESCRIPTION_SUCCESS';
export const CREATE_PHARMACY_PRESCRIPTION_FAIL = 'CREATE_PHARMACY_PRESCRIPTION_FAIL';

export const UPDATE_PHARMACY_PRESCRIPTION_START = 'UPDATE_PHARMACY_PRESCRIPTION_START';
export const UPDATE_PHARMACY_PRESCRIPTION_END = 'UPDATE_PHARMACY_PRESCRIPTION_END';

export const FETCH_PATIENTS_PRESCRIPTIONS_SUCCESS = 'FETCH_PATIENTS_PRESCRIPTIONS_SUCCESS';
export const FETCH_PATIENTS_PRESCRIPTIONS_FAIL ='FETCH_PATIENTS_PRESCRIPTIONS_FAIL';
export const FETCH_PATIENTS_PRESCRIPTIONS_START = 'FETCH_PATIENTS_PRESCRIPTIONS_START';
