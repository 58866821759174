/* eslint-disable max-len */
/* eslint-disable no-case-declarations */
import * as inventoryTypes from '../types/inventoryTypes';
import initialState from './initialState';
import { updateObject } from 'utils';

const inventory = (state = initialState.inventory, action) => {
  switch (action.type) {
    case inventoryTypes.GET_INVENTORY_CONFIGURATION_SUCCESS:
      return updateObject(state, action.payload);

    case inventoryTypes.SET_INVENTORY_CONFIGURATION_SUCCESS:
      return updateObject(state, action.payload);

    case inventoryTypes.ADD_INVENTORY_ITEM_START:
      return updateObject(state, { isSaving: true });

    case inventoryTypes.ADD_INVENTORY_ITEM_FAILED:
      return updateObject(state, { isSaving: false });

    case inventoryTypes.ADD_INVENTORY_ITEM_SUCCESS:
      let productsCopy = { ...state.products };
      productsCopy = { ...productsCopy, docs: [...productsCopy.docs].concat(action.payload) };
      return updateObject(state, { products: productsCopy, isSaving: false });

    case inventoryTypes.UPDATE_INVENTORY_ITEM_START:
      return updateObject(state, { isSaving: true });

    case inventoryTypes.UPDATE_INVENTORY_ITEM_FAILED:
      return updateObject(state, { isSaving: false });

    case inventoryTypes.UPDATE_INVENTORY_ITEM_SUCCESS:
      const existingProducts = { ...state.products };
      existingProducts.docs = [...existingProducts.docs].map(doc => {
        if (doc._id === action.payload._id) {
          return { ...doc , ...action.payload };
        }
        return doc;
      });
      return updateObject(state, { products: existingProducts, isSaving: false });

    case inventoryTypes.ADD_INVENTORY_ITEM_STOCK_START:
      return updateObject(state, { isSaving: true });

    case inventoryTypes.ADD_INVENTORY_ITEM_STOCK_FAILED:
      return updateObject(state, { isSaving: false });

    case inventoryTypes.ADD_INVENTORY_ITEM_STOCK_SUCCESS:
      const oldProducts = { ...state.products };
      const oldProductsDocs = [...oldProducts.docs];
      const productWithStock = oldProductsDocs.find(doc => doc._id === action.payload.productId);
      productWithStock.stocks = productWithStock.stocks.concat(action.payload.data);
      const newProductsDocs = oldProductsDocs.map(doc => (doc._id === productWithStock._id ? productWithStock : doc));
      const updatedProducts = { ...oldProducts, docs: newProductsDocs };
      return updateObject(state, { products: updatedProducts, isSaving: false });

    case inventoryTypes.UPDATE_INVENTORY_ITEM_STOCK_START:
      return updateObject(state, { isSaving: true });

    case inventoryTypes.UPDATE_INVENTORY_ITEM_STOCK_FAILED:
      return updateObject(state, { isSaving: false });

    case inventoryTypes.UPDATE_INVENTORY_ITEM_STOCK_SUCCESS:
      const oldProductsCopy = { ...state.products };
      const productWithStockCopy = oldProductsCopy.docs.find(doc => doc._id === action.payload.productId);
      productWithStockCopy.stocks = productWithStockCopy.stocks.map(stock => {
        if (stock._id === action.payload.data._id) {
          return { ...stock, ...action.payload.data };
        }
        return stock;
      });
      const updatedProductsDocs = oldProductsCopy.docs.map(doc =>
        doc._id === productWithStockCopy._id ? productWithStockCopy : doc,
      );
      const updatedProductsCopy = { ...oldProductsCopy, docs: updatedProductsDocs };
      return updateObject(state, { products: updatedProductsCopy, isSaving: false });

    case inventoryTypes.UPDATE_STOCK_VISIBILITY_SUCCESS:
      return updateObject(state, {
        products: {
          docs: state.products.docs.map(product => {
            if (product._id === action.payload.productId) {
              product.stocks = product.stocks.filter(item => item._id !== action.payload.stockId);
              return product;
            }
            return product;
          }),
        },
        isSaving: false,
      });

    case inventoryTypes.GET_INVENTORY_PRODUCTS_START:
      return updateObject(state, { isLoading: true });

    case inventoryTypes.GET_INVENTORY_PRODUCTS_SUCCESS:
      return updateObject(state, { ...action.payload, isLoading: false });

    case inventoryTypes.GET_INVENTORY_PRODUCTS_FAILURE:
      return updateObject(state, { message: action.payload, isLoading: false });

    case inventoryTypes.INITIATE_TRANSFER_START:
      return updateObject(state, { isSaving: true });

    case inventoryTypes.INITIATE_TRANSFER_END:
      return updateObject(state, { isSaving: false });

    default:
      return { ...state };
  }
};

export default inventory;
