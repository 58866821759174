export const ADD_INPATIENT_SERVICE_START = 'ADD_INPATIENT_SERVICE_START';
export const ADD_INPATIENT_SERVICE_SUCCESS = 'ADD_INPATIENT_SERVICE_SUCCESS';
export const ADD_INPATIENT_SERVICE_FAILED = 'ADD_INPATIENT_SERVICE_FAILED';

export const FETCH_INPATIENT_SERVICES_START = 'FETCH_INPATIENT_SERVICES_START';
export const FETCH_INPATIENT_SERVICES_SUCCESS = 'FETCH_INPATIENT_SERVICES_SUCCESS';
export const FETCH_INPATIENT_SERVICES_FAILED = 'FETCH_INPATIENT_SERVICES_FAILED';

export const UPDATE_INPATIENT_SERVICES_LIST = 'UPDATE_INPATIENT_SERVICES_LIST';
export const UPDATE_INPATIENT_SERVICE_START = 'UPDATE_INPATIENT_SERVICE_START';
export const UPDATE_INPATIENT_SERVICE_SUCCESS = 'UPDATE_INPATIENT_SERVICE_SUCCESS';
export const UPDATE_INPATIENT_SERVICE_FAILED = 'UPDATE_INPATIENT_SERVICE_FAILED';
