import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import { _formatDate, _notifyError } from 'utils';
import { EmptyScreen } from 'modules/onboarding/components';

export const AreaChart = (props) => {
  const { acceptedTasks, activeTasks, completedTasks, allTasks } = props;
  const [state, setState] = useState({ labels: [], data: [] });
  const [staffGroupings, setStaffGroupings] = useState({});

  let dataset = [];
  const revampAssignees = (assignees) => {
    let assigneesArray = [];
    if (assignees) {
      const assigneesObj = Object.values(assignees);
      assigneesObj.forEach((person) => (person ? assigneesArray.push(Object.values(person)[0]?.name) : null));
    }
    return assigneesArray;
  };

  useEffect(() => {
    if ((acceptedTasks && activeTasks && completedTasks) || allTasks.length) {
      let totalTasks = [];
      totalTasks = props.withStatus ? [...allTasks] : [...acceptedTasks, ...activeTasks, ...completedTasks];
      dataset = totalTasks.map(({ _id, assignees, createdAt }) => ({
        _id,
        assignees: revampAssignees(assignees),
        createdAt: _formatDate(createdAt, 'YYYY-MM-DD'),
      }));
    }
  }, [acceptedTasks.length, activeTasks.length, completedTasks.length]);

  const compileAxis = () => {
    // eslint-disable-next-line no-undef
    const df = new dfd.DataFrame(dataset);
    const createdAts = df['createdAt'].dt.monthday();
    const maxDay = createdAts.max();
    df.addColumn({ column: 'dates', value: createdAts });
    const daysGroup = df.groupby(['dates']).count();
    const yaxis = Array.from({ length: daysGroup['dates'].max() }, () => 0);
    for (let i = 0; i < daysGroup['dates'].values.length; i++) {
      const currentindex = daysGroup['dates'].values[i];
      const axisValue = daysGroup['_id_count'].values[i];
      yaxis[currentindex] = axisValue;
    }
    const xaxis = Array.from({ length: maxDay + 1 }, (_, i) => i);
    setState({ ...state, labels: xaxis, data: yaxis });
  };

  const compileAssignees = () => {
    let new_dataset = [];
    dataset.forEach((item) => {
      item.assignees.map((person) => new_dataset.push({ createdAt: item.createdAt, assignee: person }));
    });

    if (new_dataset.length > 0) {
      // eslint-disable-next-line no-undef
      const newdf = new dfd.DataFrame(new_dataset);
      const createdAts = newdf['createdAt'].dt.monthday();
      newdf.addColumn({ column: 'dates', value: createdAts });

      let daysGroup = newdf.groupby(['assignee', 'dates']).count();
      daysGroup = daysGroup.rename({ mapper: { createdAt_count: 'number_of_tasks_assigned' } });
      let json_dataset = [];
      let new_jsonObject = {};
      daysGroup
        .to_json('newfile.csv')
        .then((json) => {
          json_dataset = JSON.parse(json);
          json_dataset.forEach((object) => {
            new_jsonObject[object.dates] = new_jsonObject[object.dates]
              ? [...new_jsonObject[object.dates], { name: object.assignee, value: object.number_of_tasks_assigned }]
              : [{ name: object.assignee, value: object.number_of_tasks_assigned }];
          });
          setStaffGroupings(new_jsonObject);
        })
        .catch((e) => {
          _notifyError(e.response);
        });
    }
  };
  useEffect(() => {
    if (dataset.length > 0) {
      props.setExportDataset(dataset);
      compileAssignees();
      compileAxis();
    }
  }, [dataset.length, compileAxis, compileAssignees]);

  const data = {
    labels: state.labels,
    datasets: [
      {
        fill: true,
        label: 'Total Tasks',
        backgroundColor: 'rgba(106, 105, 228, 0.1)',
        borderColor: '#6A69E4',
        borderWidth: 2,
        data: state.data,
        pointBorderColor: 'purple',
        pointBorderWidth: 6,
        hoverBorderWidth: 6,
      },
    ],
  };

  return (
    <div style={{ minHeight: '350px' }}>
      {state.labels.length ? (
        <>
          <Line
            data={data}
            options={{
              title: {
                display: true,
                fontSize: 20,
              },
              scales: {
                xAxes: [
                  {
                    gridLines: {
                      color: 'rgba(0, 0, 0, 0)',
                      display: false,
                    },
                  },
                ],
                yAxes: [
                  {
                    ticks: {
                      display: true,
                    },
                  },
                ],
              },
              legend: {
                display: false,
              },

              tooltips: {
                enabled: false,
                mode: 'index',
                position: 'nearest',
                custom: function (tooltipModel) {
                  var tooltipEl = document.getElementById('chartjs-tooltip');

                  if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjs-tooltip';
                    tooltipEl.innerHTML = '<table></table>';
                    this._chart.canvas.parentNode.appendChild(tooltipEl);
                  }

                  if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                  }

                  tooltipEl.classList.remove('above', 'below', 'no-transform');
                  if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                  } else {
                    tooltipEl.classList.add('no-transform');
                  }

                  function getBody(bodyItem) {
                    return bodyItem.lines;
                  }

                  if (tooltipModel.body) {
                    var titleLines = tooltipModel.title || [];
                    var bodyLines = tooltipModel.body.map(getBody);

                    var innerHtml = '<thead>';

                    titleLines.forEach(function () {
                      innerHtml +=
                        '<tr><th style="font-weight: bold;font-size: 12px; padding-bottom: 10px;color: #4A4A4A;">' +
                        state.labels[tooltipModel.dataPoints[0].index] +
                        '</th></tr>';
                    });
                    innerHtml += '</thead><tbody>';

                    bodyLines.forEach(function () {
                      var style = 'color: #9B9B9B;padding-bottom: 10px;font-size: 14px;';
                      var style_ = 'color: #9B9B9B;padding-bottom: 10px;font-size: 14px;padding-right: 70px;';
                      var style_total = 'color: #6A69E4;padding-bottom: 10px;font-size: 14px;';

                      staffGroupings[tooltipModel.dataPoints[0].index]
                        ? staffGroupings[tooltipModel.dataPoints[0].index].forEach((operate) => {
                            innerHtml +=
                              '<tr><td style="' +
                              style_ +
                              '">' +
                              operate.name +
                              '</td> <td style="' +
                              style +
                              '">' +
                              operate.value +
                              '</td></tr>';
                          })
                        : (innerHtml +=
                            '<tr><td style="' +
                            style_ +
                            '">' +
                            'No Assignees' +
                            '</td> <td style="' +
                            style +
                            '">' +
                            '-' +
                            '</td></tr>');

                      innerHtml +=
                        '<tr><td style="' +
                        style_total +
                        '">' +
                        'Total Tasks' +
                        '</td> <td style="' +
                        style_total +
                        '">' +
                        tooltipModel.dataPoints[0].yLabel +
                        '</td></tr>';
                    });
                    innerHtml += '</tbody>';

                    var tableRoot = tooltipEl.querySelector('table');
                    tableRoot.innerHTML = innerHtml;
                  }
                  var positionY = this._chart.canvas.offsetTop;
                  var positionX = this._chart.canvas.offsetLeft;

                  tooltipEl.style.opacity = 1;
                  tooltipEl.style.position = 'absolute';
                  tooltipEl.style.minWidth = '200px';
                  tooltipEl.style.zIndex = '20000000';
                  tooltipEl.style.left = positionX + tooltipModel.caretX + 'px';
                  tooltipEl.style.top = positionY + tooltipModel.caretY + 'px';
                  tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                  tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                  tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                  tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                  tooltipEl.style.background = '#ffffff';
                  tooltipEl.style.boxShadow = 'box-shadow: 0px 4px 40px rgba(67, 102, 118, 0.148353)';
                },
              },
            }}
          />
        </>
      ) : (
        <div className="settings__empty">
          <EmptyScreen title="No Orders In The System" />
        </div>
      )}
    </div>
  );
};

AreaChart.propTypes = {
  branch: PropTypes.string,
  dataset: PropTypes.string,
  setExportDataset: PropTypes.func,
  withStatus: PropTypes.bool,
  allTasks: PropTypes.array,
  setwithStatus: PropTypes.func,
  acceptedTasks: PropTypes.array,
  activeTasks: PropTypes.array,
  completedTasks: PropTypes.array,
  filterValue_branch: PropTypes.object,
  filterValue_period: PropTypes.object,
  setIteratedValues: PropTypes.func,
  DateCompare: PropTypes.func,
};
