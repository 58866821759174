export const FETCH_DAILY_BILLS_SUCCESS = 'FETCH_DAILY_BILLS_SUCCESS';
export const FETCH_DAILY_BILLS_START = 'FETCH_DAILY_BILLS_START';
export const FETCH_DAILY_BILLS_FAILURE = 'FETCH_DAILY_BILLS_FAILURE';

export const ADD_DAILY_BILLS_FAILED = 'ADD_DAILY_BILLS_FAILED';
export const ADD_DAILY_BILLS_START = 'ADD_DAILY_BILLS_START';
export const ADD_DAILY_BILLS_SUCCESS = 'ADD_DAILY_BILLS_SUCCESS';

export const FETCH_SERVICES_END = 'FETCH_SERVICES_END';
export const FETCH_SERVICES_START = 'FETCH_SERVICES_START';
export const FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS';

export const RAISE_DAILY_BILLS_END = 'RAISE_DAILY_BILLS_END';
export const RAISE_DAILY_BILLS_START = 'RAISE_DAILY_BILLS_START';
export const RAISE_DAILY_BILLS_SUCCESS = 'RAISE_DAILY_BILLS_SUCCESS';
