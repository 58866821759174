/* eslint-disable no-case-declarations */
import * as dailyBillsTypes from '../types/dailyBillsTypes';
import initialState from './initialState';
import { updateObject } from 'utils';

const dailyBills = (state = initialState.dailyBills, { type, payload, key }) => {
  switch (type) {
    case dailyBillsTypes.FETCH_DAILY_BILLS_START:
      return updateObject(state, { isLoading: true });

    case dailyBillsTypes.FETCH_DAILY_BILLS_SUCCESS:
      return updateObject(state, { isLoading: false, docs: payload });

    case dailyBillsTypes.FETCH_DAILY_BILLS_FAILURE:
      return updateObject(state, { isLoading: false });

    case dailyBillsTypes.ADD_DAILY_BILLS_SUCCESS:
      return updateObject(state, { docs: [...state.docs, payload] });

    case dailyBillsTypes.FETCH_SERVICES_START:
      return updateObject(state, { services: { ...state.services, isLoading: true } });
    case dailyBillsTypes.FETCH_SERVICES_SUCCESS:
      return updateObject(state, { services: { [key]: payload } });
    case dailyBillsTypes.FETCH_SERVICES_END:
      return updateObject(state, { services: { ...state.services, isLoading: false } });

    case dailyBillsTypes.RAISE_DAILY_BILLS_START:
      return updateObject(state, { isRaisingDailyBills: true });
    case dailyBillsTypes.RAISE_DAILY_BILLS_SUCCESS:
      return updateObject(state, payload);
    case dailyBillsTypes.RAISE_DAILY_BILLS_END:
      return updateObject(state, { isRaisingDailyBills: false });

    default:
      return { ...state };
  }
};

export default dailyBills;
