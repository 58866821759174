export const ADD_BED_START = 'ADD_BED_START';
export const ADD_BED_SUCCESS = 'ADD_BED_SUCCESS';
export const ADD_BED_FAILED = 'ADD_BED_FAILED';

export const FETCH_BEDS_START = 'FETCH_BEDS_START';
export const FETCH_BEDS_SUCCESS = 'FETCH_BEDS_SUCCESS';
export const FETCH_BEDS_FAILED = 'FETCH_BEDS_FAILED';

export const UPDATE_BEDS_LIST = 'UPDATE_BEDS_LIST';
export const UPDATE_BED_START = 'UPDATE_BED_START';
export const UPDATE_BED_SUCCESS = 'UPDATE_BED_SUCCESS';
export const UPDATE_BED_FAILED = 'UPDATE_BED_FAILED';
