import * as authTypes from "../types/authTypes";
import initialState from "store/reducers/initialState";

const authReducer = (state = initialState.auth, action) => {
  switch (action.type) {
    case authTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: action.payload.isLoggedIn,
        savedLocation: action.payload.currentLocation,
      };
    case authTypes.LOGIN_FAILURE:
      return {
        ...state,
        loggedIn: false,
      };
    case authTypes.RESET_REQUEST_SUCCESS:
      return {
        ...state,
      };
    case authTypes.RESET_REQUEST_FAILURE:
      return {
        ...state,
      };
    case authTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
      };
    case authTypes.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
      };
    case authTypes.LOGOUT_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default authReducer;
