/* eslint-disable no-case-declarations */
import * as progressNoteTypes from '../types/progressNoteTypes';
import initialState from './initialState';
import { updateObject } from 'utils';

const progressNotes = (state = initialState.progressNotes, { type, payload }) => {
  switch (type) {
    case progressNoteTypes.ADD_NOTE_START:
      return updateObject(state, { isSaving: true });

    case progressNoteTypes.ADD_NOTE_END:
      return updateObject(state, { isSaving: false });

    case progressNoteTypes.FETCH_PROGRESS_NOTES_SUCCESS:
      return updateObject(state, { items: payload });

    case progressNoteTypes.ADD_PHYSICIAN_NOTE_START:
      return updateObject(state, { isPhysicianSaving: true });

    case progressNoteTypes.ADD_PHYSICIAN_NOTE_END:
      return updateObject(state, { isPhysicianSaving: false });

    case progressNoteTypes.FETCH_PHYSICIAN_PROGRESS_NOTES_SUCCESS:
      return updateObject(state, { physician: payload });

    case progressNoteTypes.ADD_PHYSICIAN_PROGRESS_NOTE_SUCCESS:
      return updateObject(state, { physician: (state.physician || []).concat(payload), isPhysicianSaving: false });

    case progressNoteTypes.ADD_PROGRESS_NOTE_SUCCESS:
      return updateObject(state, { items: (state.items || []).concat(payload), isSaving: false });

    case progressNoteTypes.UPDATE_PROGRESS_NOTE_SUCCESS:
      const updatedItems = state.items.map(item => (item._id === payload._id ? { ...item, ...payload } : item));
      return updateObject(state, { items: updatedItems, isSaving: false });

    default:
      return { ...state };
  }
};

export default progressNotes;
