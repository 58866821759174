export const FETCH_LICENSE_START = 'FETCH_LICENSE_START';
export const FETCH_LICENSE_SUCCESS = 'FETCH_LICENSE_SUCCESS';
export const FETCH_LICENSE_FAILED = 'FETCH_LICENSE_FAILED';

export const ADD_PLANS_START = 'ADD_PLANS_START';
export const ADD_PLANS_SUCCESS = 'ADD_PLANS_SUCCESS';
export const ADD_PLANS_FAILED = 'ADD_PLANS_FAILED';

export const UPDATE_PLANS_START = 'UPDATE_PLANS_START';
export const UPDATE_PLANS_SUCCESS = 'UPDATE_PLANS_SUCCESS';
export const UPDATE_PLANS_FAILED = 'UPDATE_PLANS_FAILED';

export const UPDATE_LICENSE_LIST = 'UPDATE_LICENSE_LIST';