/* eslint-disable no-case-declarations */
import * as pharmacyPrescriptionTypes from '../types/pharmacyPrescriptionTypes';
import initialState from './initialState';
import { updateObject } from 'utils';

const pharmacyPrescriptions = (state = initialState.pharmacyPrescriptions, { type, payload }) => {
  switch (type) {
    case pharmacyPrescriptionTypes.FETCH_PHARMACY_PRESCRIPTIONS_START:
      return updateObject(state, { prescriptions: { ...state.patientPrescriptions, loading: true } });

    case pharmacyPrescriptionTypes.FETCH_PHARMACY_PRESCRIPTIONS_SUCCESS:
      return updateObject(state, {
        prescriptions: { ...state.prescriptions, items: payload, loading: false },
      });

    case pharmacyPrescriptionTypes.FETCH_PHARMACY_PRESCRIPTIONS_FAIL:
      return updateObject(state, {
        prescriptions: { ...state.prescriptions, items: payload, loading: false },
      });

    case pharmacyPrescriptionTypes.FETCH_PATIENTS_PRESCRIPTIONS_START:
      return updateObject(state, { patientPrescriptions: { ...state.patientPrescriptions, loading: true } });

    case pharmacyPrescriptionTypes.FETCH_PATIENTS_PRESCRIPTIONS_SUCCESS:
      return updateObject(state, {
        patientPrescriptions: { ...state.patientPrescriptions, items: payload, loading: false },
      });

    case pharmacyPrescriptionTypes.FETCH_PATIENTS_PRESCRIPTIONS_FAIL:
      return updateObject(state, {
        patientPrescriptions: { ...state.patientPrescriptions, items: payload, loading: false },
      });

    case pharmacyPrescriptionTypes.CREATE_PHARMACY_PRESCRIPTION_START:
      return updateObject(state, { isSaving: true });

    case pharmacyPrescriptionTypes.CREATE_PHARMACY_PRESCRIPTION_SUCCESS:
      return updateObject(state, { isSaving: false });

    case pharmacyPrescriptionTypes.CREATE_PHARMACY_PRESCRIPTION_FAIL:
      return updateObject(state, { isSaving: false });

    case pharmacyPrescriptionTypes.UPDATE_PHARMACY_PRESCRIPTION_START:
      return updateObject(state, { isSaving: true });

    case pharmacyPrescriptionTypes.UPDATE_PHARMACY_PRESCRIPTION_END:
      return updateObject(state, { isSaving: false });

    default:
      return { ...state };
  }
};

export default pharmacyPrescriptions;
