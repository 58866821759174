import React from 'react';
import { SearchBar, Filter } from 'components';
import classnames from 'classnames';
import { Button } from 'components/ui/buttons/button';
import PropTypes from 'prop-types';
import Check from 'services/Check';

export const TableActions = (props) => {
  const { customFilter: CustomFilter } = props;
  const { addControl: AddControl } = props;
  return (
    <div className="table-actions">
      <div className="table-actions__searchbar">
        <SearchBar {...props} />
      </div>

      <div className="table-actions__cta">
        <div className="table-actions__filter">
          {CustomFilter ? <CustomFilter {...props} /> : <Filter {...props} />}
        </div>
        <Check permission={props.addButtonPermission}>
          {props.showImportButton && (
            <div className="table-actions__import-action">
              <Button onClick={props.handleImport} className={classnames('btn__utility')}>
                Import
              </Button>
            </div>
          )}
          {props.showAddButton && !AddControl && (
            <div className="table-actions__add-action">
              <Button
                variant="primary"
                onClick={props.addClick}
                disabled={props.disableAddButton}
                className={classnames(props.clearButton ? 'btn__utility-border' : 'btn__secondary')}
              >
                {props.add}
              </Button>
            </div>
          )}
          {AddControl && (
            <div className="ml-5">
              {' '}
              <AddControl />{' '}
            </div>
          )}
        </Check>
      </div>
      <Check permission={props.addButtonPermission}>
        <button onClick={props.addClick} disabled={props.disableAddButton} className="table-actions__mobile-cta">
          <span>+</span>
        </button>
      </Check>
    </div>
  );
};

TableActions.defaultProps = {
  add: 'add',
  showAddButton: true,
  showImportButton: true,
  disableAddButton: false,
  clearButton: false,
};

TableActions.propTypes = {
  add: PropTypes.string,
  addClick: PropTypes.func,
  addButtonPermission: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  handleImport: PropTypes.func,
  showAddButton: PropTypes.bool,
  showImportButton: PropTypes.bool,
  disableAddButton: PropTypes.bool,
  clearButton: PropTypes.bool,
  customFilter: PropTypes.func,
  filterFunction: PropTypes.func,
  addControl: PropTypes.func,
};
