import { _notifySuccess, _notifyError, printError } from "utils";
import * as inventoryTypes from "../types/inventoryTypes";
import Axios from "services/axios";
import { CUSTOM_ERROR_MESSAGE } from "utils/constants";

const getReturnsStart = () => ({ type: inventoryTypes.GET_RETURNS_START });

const getReturnsSuccess = (payload) => ({
  type: inventoryTypes.GET_RETURNS_SUCCESS,
  payload,
});

const getReturnsFailure = (payload) => ({
  type: inventoryTypes.GET_RETURNS_FAILURE,
  payload,
});

export const getReturns = (location, queryParams) => {
  return async (dispatch) => {
    dispatch(getReturnsStart());
    try {
      const { data } = await Axios.get(
        `/locations/${location}/stock-movements?type=returns${queryParams ? `&${queryParams}` : ""}`,
      );
      if (data.status) {
        dispatch(getReturnsSuccess(data.data));
      }
    } catch (error) {
      const errorMessage = printError(error);
      _notifyError(errorMessage);
      dispatch(getReturnsFailure(errorMessage));
    }
  };
};

const initiateReturnStart = () => ({ type: inventoryTypes.INITIATE_RETURN_START });

const initiateReturnFailure = (error) => ({
  type: inventoryTypes.INITIATE_RETURN_FAILURE,
  error,
});

const initiateReturnSuccess = (payload) => ({
  type: inventoryTypes.INITIATE_RETURN_SUCCESS,
  payload,
});

export const initiateReturn = (payload, close) => {
  const { stocks, reason } = payload;

  return async (dispatch) => {
    dispatch(initiateReturnStart());
    try {
      const { data } = await Axios.post(`/returns`, {
        stocks,
        reason,
      });
      if (data.status) {
        dispatch(initiateReturnSuccess(data.data));
        _notifySuccess("Item(s) Return successful.");
        close();
      }
    } catch (error) {
      const errorMessage = printError(error);
      _notifyError(errorMessage);
      dispatch(initiateReturnFailure(CUSTOM_ERROR_MESSAGE));
    }
  };
};

const updateReturnStart = () => ({ type: inventoryTypes.UPDATE_RETURN_START });

const updateReturnFailure = (error) => ({
  type: inventoryTypes.UPDATE_RETURN_FAILURE,
  error,
});

const updateReturnSuccess = (payload) => ({
  type: inventoryTypes.UPDATE_RETURN_SUCCESS,
  payload,
});

export const updateReturn = (payload, close) => {
  const { _id, items, from, reason, ...rest } = payload;

  return async (dispatch) => {
    dispatch(updateReturnStart());
    try {
      const { data } = await Axios.patch(`/stock-movements/${_id}`, {
        from: from.map((branch) => branch._id),
        items,
        reason,
      });
      if (data.status) {
        const cache = { ...data.data, from, reason, ...rest };
        dispatch(updateReturnSuccess(cache));
        _notifySuccess(`Updated Return item successfully.`);
        close();
      }
    } catch (error) {
      const errorMessage = error.response ? error.response.data : CUSTOM_ERROR_MESSAGE;
      _notifyError(errorMessage);
      dispatch(updateReturnFailure(CUSTOM_ERROR_MESSAGE));
    }
  };
};

export const queryBatchId = async (batchId) => {
  try {
    const { data } = await Axios.get(`/stocks/search?batchId=${batchId}`);
    if (data.status) {
      return data.data;
    }
  } catch (error) {
    throw error.response;
  }
};

export const processReturnStart = () => ({
  type: inventoryTypes.PROCESS_RETURN_START,
});

export const processReturnFailure = () => ({
  type: inventoryTypes.PROCESS_RETURN_FAILURE,
});

export const processReturnSuccess = (payload) => ({
  type: inventoryTypes.PROCESS_RETURN_SUCCESS,
  payload,
});

export const processReturn = (itemId, type, toggle) => {
  return async (dispatch) => {
    try {
      dispatch(processReturnStart());
      const { data } = await Axios.post(`/returns/${itemId}/${type}`);
      if (data.status) {
        toggle();
        dispatch(processReturnSuccess(data.data));
        return true;
      }
    } catch (error) {
      dispatch(processReturnFailure());
      return false;
    }
  };
};
