export const FETCH_TASKS_START = 'FETCH_TASKS_START';
export const FETCH_TASKS_FAILURE = 'FETCH_TASKS_FAILURE';
export const FETCH_TASKS_SUCCESS = 'FETCH_TASKS_SUCCESS';

export const ADD_TASK_START = 'ADD_TASK_START';
export const ADD_TASK_SUCCESS = 'ADD_TASK_SUCCESS';
export const ADD_TASK_FAILURE = 'ADD_TASK_FAILURE';

export const SELECT_TASK = 'SELECT_TASK';
export const CLEAR_SELECTED_TASK = 'CLEAR_SELECTED_TASK';

export const ASSIGN_TASK_START = 'ASSIGN_TASK_START';
export const ASSIGN_TASK_SUCCESS = 'ASSIGN_TASK_SUCCESS';
export const ASSIGN_TASK_FAILURE = 'ASSIGN_TASK_FAILURE';

export const UNASSIGN_TASK_START = 'UNASSIGN_TASK_START';
export const UNASSIGN_TASK_SUCCESS = 'UNASSIGN_TASK_SUCCESS';
export const UNASSIGN_TASK_FAILURE = 'UNASSIGN_TASK_FAILURE';

export const CHANGE_TASK_STATUS_START = 'CHANGE_TASK_STATUS_START';
export const CHANGE_TASK_STATUS_FAILURE = 'CHANGE_TASK_STATUS_FAILURE';

export const START_TASK_SUCCESS = 'START_TASK_SUCCESS';
export const COMPLETE_TASK_SUCCESS = 'COMPLETE_TASK_SUCCESS';
export const DELIVER_TASK_SUCCESS = 'DELIVER_TASK_SUCCESS';

export const GET_CLIENTS_START = 'GET_CLIENTS_START';
export const GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS';
export const GET_CLIENTS_FAILURE = 'GET_CLIENTS_FAILURE';

export const ADD_COMMENT_START = 'ADD_COMMENT_START';
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS';
export const ADD_COMMENT_FAILURE = 'ADD_COMMENT_FAILURE';

export const ADD_RESULT_START = 'ADD_RESULT_START';
export const ADD_RESULT_SUCCESS = 'ADD_RESULT_SUCCESS';
export const ADD_RESULT_FAILURE = 'ADD_RESULT_FAILURE';

export const EXTEND_DUE_DATE_START = 'EXTEND_DUE_DATE_START';
export const EXTEND_DUE_DATE_SUCCESS = 'EXTEND_DUE_DATE_SUCCESS';
export const EXTEND_DUE_DATE_FAILURE = 'EXTEND_DUE_DATE_FAILURE';

export const DECLINE_TASK = 'DECLINE_TASK';
export const ACCEPT_TASK = 'ACCEPT_TASK';

export const FETCH_STATUS_BREAKDOWN_START = 'FETCH_STATUS_BREAKDOWN_START';
export const FETCH_STATUS_BREAKDOWN_SUCCESS = 'FETCH_STATUS_BREAKDOWN_SUCCESS';
export const FETCH_STATUS_BREAKDOWN_FAILURE = 'FETCH_STATUS_BREAKDOWN_FAILURE';