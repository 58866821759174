import { environment } from "config";
import axios from "axios";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";

const {
  api: { uri },
} = environment;

// eslint-disable-next-line no-unused-vars
const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: "" }) =>
  async ({ url, method, body, params, headers }) => {
    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        data: body,
        params,
        headers,
        withCredentials: true,
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const baseQuery = fetchBaseQuery({
  baseUrl: `${uri}`,
  prepareHeaders(headers) {
    return headers;
  },
  mode: "cors",
  credentials: "include",
});
