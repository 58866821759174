export const ADD_NOTE_START = 'ADD_NOTE_START';
export const ADD_NOTE_END = 'ADD_NOTE_END';

export const FETCH_SOAP_NOTES_SUCCESS = 'FETCH_SOAP_NOTES_SUCCESS';
export const ADD_SOAP_NOTE_SUCCESS = 'ADD_SOAP_NOTE_SUCCESS';

export const FETCH_PROGRESS_NOTES_SUCCESS = 'FETCH_PROGRESS_NOTES_SUCCESS';
export const ADD_PROGRESS_NOTE_SUCCESS = 'ADD_PROGRESS_NOTE_SUCCESS';

export const FETCH_NURSING_NOTES_SUCCESS = 'FETCH_NURSING_NOTES_SUCCESS';
export const ADD_NURSING_NOTE_SUCCESS = 'ADD_NURSING_NOTE_SUCCESS';

export const FETCH_OBSERVATION_CHART_SUCCESS = 'FETCH_OBSERVATION_CHART_SUCCESS';
export const ADD_OBSERVATION_CHART_SUCCESS = 'ADD_OBSERVATION_CHART_SUCCESS';

export const FETCH_CONTINUOUS_ASSESSMENTS_SUCCESS = 'FETCH_CONTINUOUS_ASSESSMENTS_SUCCESS';
export const ADD_CONTINUOUS_ASSESSMENTS_SUCCESS = 'ADD_CONTINUOUS_ASSESSMENTS_SUCCESS';

export const FETCH_WARD_ROUNDS_SUCCESS = 'FETCH_WARD_ROUNDS_SUCCESS';
export const ADD_WARD_ROUNDS_SUCCESS = 'ADD_WARD_ROUNDS_SUCCESS';

export const FETCH_ASSESSMENTS_SUCCESS = 'FETCH_ASSESSMENTS_SUCCESS';
export const ADD_ASSESSMENTS_SUCCESS = 'ADD_ASSESSMENTS_SUCCESS';
export const FETCH_FLUID_BALANCE_SUCCESS = 'FETCH_FLUID_BALANCE_SUCCESS';
export const ADD_FLUID_BALANCE_SUCCESS = 'ADD_FLUID_BALANCE_SUCCESS';

export const FETCH_IV_FLUID_SUCCESS = 'FETCH_IV_FLUID_SUCCESS';
export const ADD_IV_FLUID_SUCCESS = 'ADD_IV_FLUID_SUCCESS';
