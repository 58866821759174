import { combineReducers } from "@reduxjs/toolkit";
import auth from "./authReducer";
import company from "./companyReducer";
import user from "./userReducer";
import notifications from "./notificationReducer";
import { currencies, currency, permissions } from "./utilsReducer";
import inProgress from "./apiReducer";
import sponsors from "./sponsorReducer";
import branches from "./branchesReducer";
import categories from "./categoryReducer";
import staff from "./staffReducer";
import suppliers from "./supplierReducer";
import departments from "./departmentsReducer";
import patientsDepartments from "./patientsDepartments";
import appOptions from "./appOptionReducer";
import email from "./emailReducer";
import { taskManagement } from "modules/laboratory/redux/reducers";
import * as settingsReducers from "../../modules/settings/redux/reducers";
import * as inventoryReducers from "../../modules/inventory/redux/reducers";
import * as frontdeskReducers from "../../modules/front-desk/redux/reducers";
import * as patientsReducers from "modules/patients/redux/reducers";
import * as pharmacyReducers from "modules/pharmacy/redux/reducers";
import * as financeReducers from "modules/finance/redux/reducers";
import * as auditTrailReducers from "modules/audit-trail/redux/reducers";
import { rolesApi } from "modules/settings/redux/features/roleSlice";
import modalReducer from "../features/modalSlice";
import billsApi from "modules/finance/redux/features/billsSlice";
import { categoriesApi } from "store/features/categoriesSlice";
import { walletApi } from "modules/finance/redux/features/walletSlice";
import { patientApi } from "modules/front-desk/redux/features/patientSlice";
import { claimsApi } from "modules/finance/redux/features/claimsSlice";
import { plansApi } from "modules/settings/redux/features/plansSlice";
import { appointmentPurposeApi } from "modules/settings/redux/features/appointmentPurposeSlice";
import { admissionCapacityApi } from "modules/settings/redux/features/admissionCapacitySlice";
import cartApi from "modules/finance/redux/features/cart-slice";
import { formApi } from "modules/settings/redux/features/formSlice";
import servicesApi from "../../modules/inventory/redux/features/services-slice";
import { staffApi } from "store/features/staffSlice";
import { shiftApi } from "store/features/shiftSlice";
import formEntryApi from "../../modules/patients/redux/features/form-entry-slice";
import { smsApi } from "modules/settings/redux/features/smsSlice";

const allReducers = combineReducers({
  auth,
  user,
  notifications,
  currencies,
  currency,
  permissions,
  inProgress,
  branches,
  categories,
  departments,
  patientsDepartments,
  taskManagement,
  sponsors,
  staff,
  suppliers,
  company,
  email,
  appOptions,
  modal: modalReducer,
  ...patientsReducers,
  ...settingsReducers,
  ...inventoryReducers,
  ...frontdeskReducers,
  ...pharmacyReducers,
  ...financeReducers,
  ...auditTrailReducers,
  [rolesApi.reducerPath]: rolesApi.reducer,
  [billsApi.reducerPath]: billsApi.reducer,
  [walletApi.reducerPath]: walletApi.reducer,
  [patientApi.reducerPath]: patientApi.reducer,
  [categoriesApi.reducerPath]: categoriesApi.reducer,
  [claimsApi.reducerPath]: claimsApi.reducer,
  [plansApi.reducerPath]: plansApi.reducer,
  [appointmentPurposeApi.reducerPath]: appointmentPurposeApi.reducer,
  [admissionCapacityApi.reducerPath]: admissionCapacityApi.reducer,
  [cartApi.reducerPath]: cartApi.reducer,
  [formApi.reducerPath]: formApi.reducer,
  [servicesApi.reducerPath]: servicesApi.reducer,
  [staffApi.reducerPath]: staffApi.reducer,
  [shiftApi.reducerPath]: shiftApi.reducer,
  [formEntryApi.reducerPath]: formEntryApi.reducer,
  [smsApi.reducerPath]: smsApi.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_APP") {
    state = undefined;
  }
  return allReducers(state, action);
};

export default (state, action) => {
  return rootReducer(action.type === "LOGOUT_SUCCESS" ? { auth: { loggedIn: false } } : { ...state, ...auth }, action);
};
