/* eslint-disable no-case-declarations */
import * as appointmentTypes from '../types/appointmentTypes';
import initialState from './initialState';
import { updateObject } from 'utils';

const appointments = (state = initialState.appointments, { type, payload, error, rescheduledApptId }) => {
  switch (type) {
    case appointmentTypes.FETCH_APPOINTMENTS_START:
      return updateObject(state, { isLoading: true });

    case appointmentTypes.FETCH_APPOINTMENTS_SUCCESS:
      return updateObject(state, { items: payload, isLoading: false });

    case appointmentTypes.FETCH_APPOINTMENTS_FAIL:
      return updateObject(state, { isLoading: false });

    case appointmentTypes.FETCH_DASHBOARD_APPOINTMENT_SUCCESS:
      return updateObject(state, { dashboardStats: { todayAppointments: payload }, isLoading: false });

    case appointmentTypes.BOOK_APPOINTMENT_START:
      return updateObject(state, { isSaving: true });

    case appointmentTypes.BOOK_APPOINTMENT_SUCCESS:
      let newDocs = (state.items.docs || []).concat(payload);
      if (rescheduledApptId) {
        newDocs = newDocs.map(newDoc =>
          newDoc._id === rescheduledApptId ? { ...newDoc, status: 'cancelled' } : newDoc,
        );
      }
      return updateObject(state, { items: newDocs, isSaving: false });

    case appointmentTypes.BOOK_APPOINTMENT_FAIL:
      return updateObject(state, { message: error, isSaving: false });

    case appointmentTypes.UPDATE_APPOINTMENT_START:
      return updateObject(state, { isSaving: true });

    case appointmentTypes.UPDATE_APPOINTMENT_SUCCESS:
      const updatedItems = state.items.map(item => (item._id === payload._id ? { ...item, ...payload } : item));
      return updateObject(state, { items: updatedItems, isSaving: false });

    case appointmentTypes.UPDATE_APPOINTMENT_FAIL:
      return updateObject(state, { message: error, isSaving: false });

    case appointmentTypes.UPDATE_APPOINTMENT_QUEUE_START:
      return updateObject(state, { isSaving: true });

    case appointmentTypes.UPDATE_APPOINTMENT_QUEUE_SUCCESS:
      return updateObject(state, { isSaving: false });

    case appointmentTypes.UPDATE_APPOINTMENT_QUEUE_FAIL:
      return updateObject(state, { message: error, isSaving: false });

    case appointmentTypes.FETCH_DAILY_INFO_START:
      return updateObject(state, { isLoading: true });

    case appointmentTypes.FETCH_DAILY_INFO_SUCCESS:
      return updateObject(state, { dashboardStats: payload, isLoading: false });

    case appointmentTypes.FETCH_DAILY_INFO_FAIL:
      return updateObject(state, { dashboardStats: {}, isLoading: false });

    case appointmentTypes.FETCH_TODAYS_CONSULTANTS_START:
      return updateObject(state, { isTodayConsultantsLoading: true });

    case appointmentTypes.FETCH_TODAYS_CONSULTANTS_SUCCESS:
      return updateObject(state, {
        dashboardStats: { ...appointments.dashboardStats, todayConsultants: payload },
        isTodayConsultantsLoading: false,
      });

    case appointmentTypes.FETCH_TODAYS_CONSULTANTS_FAIL:
      return updateObject(state, { isTodayConsultantsLoading: false });

    case appointmentTypes.END_SESSION_START:
      return updateObject(state, { endSession: { isLoading: true } });

    case appointmentTypes.END_SESSION_END:
      return updateObject(state, { endSession: { isLoading: false } });

    case appointmentTypes.TRANSFER_PATIENT_ALT_START:
      return updateObject(state, { transferAltLoading: true });
    case appointmentTypes.TRANSFER_PATIENT_ALT_END:
      return updateObject(state, { transferAltLoading: false });

    default:
      return { ...state };
  }
};

export default appointments;
