import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as SearchSvg } from "assets/svg/search.svg";
import { TextField } from "components/ui/text-field";
import { cn } from "utils";

export const SearchBar = ({ className, ...props }) => {
  return (
    <div className={cn("tw-relative", className)}>
      <SearchSvg className="tw-absolute tw-top-[17px] tw-left-[10px] tw-flex tw-items-center tw-z-10" />
      <TextField type="text" className="tw-pl-[30px] md:tw-w-2/3 lg:tw-w-1/2" {...props} />
    </div>
  );
};

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyDown: PropTypes.func,
};
