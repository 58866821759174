import * as appointmentPurposeActions from '../types/appointmentPurposeTypes';
import initialState from 'store/reducers/initialState';
import { updateObject, _sortItems } from 'utils';

const updateItemField = (state, payload, field) =>
  state[field].map(prev => {
    if (String(prev._id) === String(payload._id)) {
      return payload;
    }
    return prev;
  });

export const appointmentPurpose = (state = initialState.suppliers, action) => {
  switch (action.type) {
    case appointmentPurposeActions.FETCH_APPOINTMENT_PURPOSES_SUCCESS:
      return updateObject(state, { ...action.payload, loading: false });

    case appointmentPurposeActions.FETCH_APPOINTMENT_PURPOSES_FAILED:
      return updateObject(state, { error: action.error, loading: false });

    case appointmentPurposeActions.FETCH_APPOINTMENT_PURPOSES_START:
      return updateObject(state, { formErrorMessage: null, isSaving: true, loading: true });

    case appointmentPurposeActions.ADD_APPOINTMENT_PURPOSE_START:
      return updateObject(state, { ...action.payload, isSaving: true });

    case appointmentPurposeActions.ADD_APPOINTMENT_PURPOSE_FAILED:
      return updateObject(state, {
        formErrorMessage: action.error,
        isSaving: false
      });

    case appointmentPurposeActions.ADD_APPOINTMENT_PURPOSE_SUCCESS:
      return updateObject(state, {
        items: _sortItems([...state.items, action.payload], 'name'),
        filteredItems: _sortItems([...state.items, action.payload], 'name'),
        isSaving: false,
        formErrorMessage: null,
      });

    case appointmentPurposeActions.UPDATE_APPOINTMENT_PURPOSE_START:
      return updateObject(state, {
        isSaving: true
      });

    case appointmentPurposeActions.UPDATE_APPOINTMENT_PURPOSE_FAILED:
      return updateObject(state, {
        isSaving: null
      });

    case appointmentPurposeActions.UPDATE_APPOINTMENT_PURPOSE_SUCCESS:
      return updateObject(state, {
        formErrorMessage: null,
        isSaving: false,
        items: _sortItems(updateItemField(state, action.payload, 'items'), 'name'),
        filteredItems: _sortItems(updateItemField(state, action.payload, 'filteredItems'), 'name')
      });

    case appointmentPurposeActions.DEACTIVATE_APPOINTMENT_PRURPOSE_START:
      return updateObject(state, {
        isSaving: true
      });

    case appointmentPurposeActions.DEACTIVATE_APPOINTMENT_PRURPOSE_FAILED:
      return updateObject(state, {
        isSaving: null
      });

    case appointmentPurposeActions.UPDATE_APPOINTMENT_PURPOSE_LIST:
      return updateObject(state, { ...action.payload, isSaving: null });

    default:
      return { ...state };
  }
};
