import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import noImage from 'assets/svg/camera.svg';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { userLogout } from 'store/actions';
import { Spinner } from 'reactstrap';

const UserLinks = ({ ...props }) => {
  const { panel, user } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const userRef = useRef(null);
  const imageEl = useRef(null);
  const [userBlockFocus, setUserBlockFocus] = useState(false);

  const ImageError = () => {
    imageEl.current.src = noImage;
  };

  const logOut = async () => {
    await dispatch(userLogout());
  };

  useOnClickOutside(userRef, () => setUserBlockFocus(false));

  useEffect(() => {
    if (panel === false) setUserBlockFocus(false);
  }, [panel]);

  return (
    <div className={classnames({ 'navigation-otherlinks': userBlockFocus })} ref={userRef}>
      <li role="button" className="avatar tw-flex justify-content-start align-items-center">
        {user && user?.current?.file?.[0] ? (
          <img
            src={user?.current?.file?.[0] || user.current.details.avatar}
            alt="Indigo User Avatar"
            onError={ImageError}
            ref={imageEl}
          />
        ) : (
          <img src={noImage} alt="Indigo User Avatar" />
        )}
        <div className="tw-flex flex-fill justify-content-between">
          <span
            className={classnames({
              'avatar-text': true,
              'avatar-display': panel === false,
              'ml-1': panel === false,
            })}
            onClick={() => setUserBlockFocus((prevState) => !prevState)}
          >
            {user?.current?.name}
          </span>
          <i className="iconmoon icon-checkmark" />
        </div>
      </li>

      <div
        className={classnames({
          show: userBlockFocus,
          'navigation-otherlinks-right': true,
          'pb-3': true,
        })}
      >
        {userBlockFocus && (
          <ul className="navigation-otherlinks__options">
            <li onClick={() => history.push('/profile')} className="cursor-pointer">
              Profile
            </li>
            <li className="tw-flex justify-content-between align-items-center text-capitalize" onClick={() => logOut()}>
              <div className="navigation-switcher__options-link">Logout</div>
              {user.isLoggingOut && <Spinner />}
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

UserLinks.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  panel: PropTypes.bool.isRequired,
};

export default withRouter(UserLinks);
