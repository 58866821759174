/* eslint-disable no-case-declarations */
import * as proceduresTypes from '../types/proceduresTypes';
import initialState from './initialState';
import { updateObject } from 'utils';

const procedures = (state = initialState.procedures, { type, payload }) => {
  switch (type) {
    case proceduresTypes.FETCH_PROCEDURES_START:
      return updateObject(state, { isLoading: true });

    case proceduresTypes.FETCH_PROCEDURES_SUCCESS:
      return updateObject(state, { isLoading: false, items: payload });

    case proceduresTypes.FETCH_PROCEDURES_FAIL:
      return updateObject(state, { isLoading: false });

    default:
      return { ...state };
  }
};

export default procedures;
