export const FETCH_CASENOTES_SUCCESS = 'FETCH_CASENOTES_SUCCESS';
export const FETCH_CASENOTES_FAIL = 'FETCH_CASENOTES_FAIL';
export const FETCH_CASENOTES_START = 'FETCH_CASENOTES_START';

export const CREATE_CASENOTE_START = 'CREATE_CASENOTE_START';
export const CREATE_CASENOTE_SUCCESS = 'CREATE_CASENOTE_SUCCESS';
export const CREATE_CASENOTE_FAIL = 'CREATE_CASENOTE_FAIL';

export const ARCHIVE_CASENOTE_START = 'ARCHIVE_CASENOTE_START';
export const ARCHIVE_CASENOTE_FAIL = 'ARCHIVE_CASENOTE_FAIL';

export const CREATE_CASENOTE_AND_END_SESSION_START = 'CREATE_CASENOTE_AND_END_SESSION_START';
export const CREATE_CASENOTE_AND_END_SESSION_END = 'CREATE_CASENOTE_AND_END_SESSION_END';
