import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "services/baseQuery";
import { _notifyError, _notifySuccess, getSerializedParams } from "utils";
import { closeAllModals } from "store/features/modalSlice";

export const patientApi = createApi({
  reducerPath: "patientApi",
  baseQuery,
  tagTypes: ["Patients"],
  endpoints: (builder) => ({
    getPatients: builder.query({
      query: (queryParams) => {
        const params = getSerializedParams(queryParams);
        return `/patients?${params}`;
      },
      transformResponse: (response) => response.data,
      providesTags: ["Patients"],
    }),
    getPatient: builder.query({
      query: ({ id }) => `/patients/${id}`,
      transformResponse: (response) => response.data,
      providesTags: ["Patients"],
    }),
    updateRole: builder.mutation({
      query: ({ _id, title, isActive, permissions }) => ({
        url: `/patient/${_id}`,
        method: "PATCH",
        body: { title, isActive, permissions },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          _notifySuccess(`Success : ${response.data.message}`);
          dispatch(closeAllModals());
        } catch (err) {
          _notifyError(`Error : ${err.error.data.message}`);
        }
      },
      invalidatesTags: ["Roles"],
    }),
  }),
});

export const { useGetPatientsQuery,useGetPatientQuery } = patientApi;
