/* eslint-disable max-len */
/* eslint-disable no-case-declarations */
import * as inventoryTypes from '../types/inventoryTypes';
import initialState from './initialState';
import { updateObject } from 'utils';

const inventory = (state = initialState.inventoryPricelists, action) => {
  switch (action.type) {
    case inventoryTypes.GET_PRICELISTS_START:
      return updateObject(state, { isLoading: true });

    case inventoryTypes.GET_PRICELISTS:
      return updateObject(state, { ...action.payload, isLoading: false });

    case inventoryTypes.GET_PRICELISTS_FAILURE:
      return updateObject(state, { isLoading: false });

    case inventoryTypes.CREATE_PRICELIST_START:
      return updateObject(state, { isSaving: true });

    case inventoryTypes.CREATE_PRICELIST_FAILURE:
      return updateObject(state, { isSaving: false });

    case inventoryTypes.CREATE_PRICELIST_SUCCESS:
      const newDocs = [...state.docs, ...action.payload];
      return updateObject(state, { docs: newDocs, isSaving: false });

    case inventoryTypes.UPDATE_PRICELIST_START:
      return updateObject(state, { isSaving: true });

    case inventoryTypes.UPDATE_PRICELIST_FAILURE:
      return updateObject(state, { isSaving: false });

    case inventoryTypes.UPDATE_PRICELIST_SUCCESS:
      const updatedDocs = state.docs.map(doc => (doc._id === action.payload._id ? action.payload : doc));
      return updateObject(state, { docs: updatedDocs, isSaving: false });

    case inventoryTypes.DELETE_PRICELIST_SUCCESS:
      return updateObject(state,
        { docs: state.docs.filter(item => item._id !== action.payload) }
      );

    default:
      return { ...state };
  }
};

export default inventory;
