import React from 'react';
import PropTypes from 'prop-types';
import { TabPane } from 'reactstrap';

const TabDetails = ({ component: Component, tabId }) => {
  return (
    <TabPane tabId={tabId}>
      <Component category={tabId} />
    </TabPane>
  );
};

TabDetails.propTypes = {
  tabId: PropTypes.string.isRequired,
  component: PropTypes.object
};

export default TabDetails;
