/* eslint-disable no-case-declarations */
import * as surgeryRecordTypes from '../types/surgeryRecordTypes';
import initialState from './initialState';
import { updateObject } from 'utils';

const surgeryOpNotes = (state = initialState.surgeryOpNotes, { type, payload }) => {
  switch (type) {
    case surgeryRecordTypes.FETCH_OP_NOTES_START:
      return updateObject(state, { isLoading: true });

    case surgeryRecordTypes.FETCH_OP_NOTES_SUCCESS:
      return updateObject(state, { isLoading: false, items: payload });

    case surgeryRecordTypes.FETCH_OP_NOTES_FAIL:
      return updateObject(state, { isLoading: false });

    case surgeryRecordTypes.ADD_OP_NOTE_START:
      return updateObject(state, { isSaving: true });

    case surgeryRecordTypes.ADD_OP_NOTE_SUCCESS:
      return updateObject(state, { items: (state.items || []).concat(payload), isSaving: false });

    case surgeryRecordTypes.ADD_OP_NOTE_FAIL:
      return updateObject(state, { isSaving: false });

    default:
      return { ...state };
  }
};

export default surgeryOpNotes;
