/* eslint-disable no-case-declarations */
import initialState from './initialState';

const vitals = (state = initialState.patientUtil, { type }) => {
  switch (type) {
    default:
      return { ...state };
  }
};

export default vitals;
