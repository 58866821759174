import React from "react";
import PropTypes from "prop-types";
import { cn } from "utils";

const Label = ({ label, id, className, children }) => {
  return (
    <label className={cn("tw-block tw-text-natural-500 tw-text-xs tw-font-medium tw-mb-0", className)} htmlFor={id}>
      {label || children}
    </label>
  );
};

Label.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

export { Label };
