import React from 'react';
import PropTypes from 'prop-types';
import { _notifyCustom } from 'utils';

const PaymentNotification = ({ data, close, rightControl }) => {
  return (
    <>
      <div className={`r notification-card-2 notification-card-2-active`}>
        <div className="notification-card-2-message">
          <h3 className="tw-mb-4 notification-card-2-message-title">Reminder</h3>
          {/*<small className="notification-card-2-message__tip small">{moment(data?.updatedAt).fromNow()}</small>*/}
          <p className="tw-mb-0 notification-card-2-message-text">{data?.message}</p>
        </div>
        {rightControl && (
          <div className="notification-card-2-control">
            <hr className="m-0" />
            <span className="tw-px-4 tw-pt-2 cursor-pointer" onClick={close}>
              Close
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export const _notifyExpiration = ({ duration, ...props }) => {
  _notifyCustom({ duration, component: PaymentNotification, ...props });
};

export default PaymentNotification;

PaymentNotification.propTypes = {
  data: PropTypes.object,
  rightControl: PropTypes.bool,
  messages: PropTypes.array,
  duration: PropTypes.number,
  close: PropTypes.func,
  handle: PropTypes.bool,
  getAcceptedVitals: PropTypes.func,
};
