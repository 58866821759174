import * as supplierTypes from '../types/supplierTypes';
import initialState from 'store/reducers/initialState';
import { updateObject } from 'utils';

const updateItemField = (state, payload, field) =>
  state[field].map(prev => {
    if (String(prev._id) === String(payload.updatedObject._id)) {
      return payload.updatedObject;
    }
    return prev;
  });

export const suppliers = (state = initialState.suppliers, action) => {
  switch (action.type) {
    case supplierTypes.FETCH_SUPPLIERS_SUCCESS:
      return updateObject(state, action.payload);

    case supplierTypes.FETCH_SUPPLIERS_FAILED:
      return updateObject(state, { error: action.error });

    case supplierTypes.ADD_SUPPLIER_START:
      return updateObject(state, { formErrorMessage: null, isSaving: true });

    case supplierTypes.ADD_SUPPLIER_SUCCESS:
      return updateObject(state, { ...action.payload, formErrorMessage: null });

    case supplierTypes.ADD_SUPPLIER_FAILED:
      return updateObject(state, {
        formErrorMessage: action.error,
        isSaving: false
      });

    case supplierTypes.UPDATE_SUPPLIER_START:
      return updateObject(state, { formErrorMessage: null, isSaving: true });

    case supplierTypes.UPDATE_SUPPLIER_SUCCESS:
      return updateObject(state, {
        formErrorMessage: null,
        isSaving: false,
        items: updateItemField(state, action.payload, 'items'),
        filteredItems: updateItemField(state, action.payload, 'filteredItems')
      });

    case supplierTypes.UPDATE_SUPPLIER_FAILED:
      return updateObject(state, {
        isSaving: false,
        isDeactivating: false
      });

    case supplierTypes.UPDATE_SUPPLIERS_LIST:
      return updateObject(state, { ...action.payload, isSaving: null });

    default:
      return { ...state };
  }
};

export default suppliers;
