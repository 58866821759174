/* eslint-disable no-case-declarations */
import * as admissiontypes from '../types/admissionTypes';
import initialState from './initialState';
import { updateObject } from 'utils';

const admissions = (state = initialState.admissions, { type, payload }) => {
  switch (type) {
    case admissiontypes.FETCH_ADMISSIONS_START:
      return updateObject(state, { isLoading: true });

    case admissiontypes.FETCH_ADMISSIONS_SUCCESS:
      return updateObject(state, { isLoading: false, ...payload });

    case admissiontypes.FETCH_ADMISSIONS_FAIL:
      return updateObject(state, { isLoading: false, docs: [] });

    case admissiontypes.FETCH_RECOMMENDED_ADMISSIONS_START:
      return updateObject(state, { recommended: { ...state.recommended, isLoading: true } });

    case admissiontypes.FETCH_RECOMMENDED_ADMISSIONS_SUCCESS:
      return updateObject(state, { recommended: { ...state.recommended, items: payload } });

    case admissiontypes.FETCH_RECOMMENDED_ADMISSIONS_END:
      return updateObject(state, { recommended: { ...state.recommended, isLoading: false } });

    case admissiontypes.FETCH_CURRENT_ADMISSION_START:
      return updateObject(state, { currentAdmission: { ...state.recommended, isLoading: true } });

    case admissiontypes.FETCH_CURRENT_ADMISSION_SUCCESS:
      return updateObject(state, { currentAdmission: { ...state.recommended, items: payload } });

    case admissiontypes.FETCH_CURRENT_ADMISSION_FAIL:
      return updateObject(state, { currentAdmission: { ...state.recommended, isLoading: false } });

    case admissiontypes.ADMIT_PATIENT_SUCCESS:
      return updateObject(state, { docs: [...state.docs, payload] });

    case admissiontypes.FETCH_DAILY_STATS_SUCCESS:
      return updateObject(state, { dailyStats: payload });

    case admissiontypes.UPDATE_DAILY_STATS:
      const dailyStatsObj = {
        ...(state.dailyStats?.[0] || {}),
        [payload]: (state.dailyStats?.[0][payload] || 0) + 1,
      };
      return updateObject(state, { dailyStats: [dailyStatsObj] });

    case admissiontypes.CANCEL_ADMISSION_START:
      return updateObject(state, { cancelAdmissionLoading: true });
    case admissiontypes.CANCEL_ADMISSION_SUCCESS:
    case admissiontypes.CANCEL_ADMISSION_FAILURE:
      return updateObject(state, { cancelAdmissionLoading: false });

    case admissiontypes.DISCHARGE_ADMISSION_START:
      return updateObject(state, { dischargeLoading: true });
    case admissiontypes.DISCHARGE_ADMISSION_SUCCESS:
    case admissiontypes.DISCHARGE_ADMISSION_FAILURE:
      return updateObject(state, { dischargeLoading: false });

    case admissiontypes.CHECKOUT_PATIENT_START:
      return updateObject(state, { checkoutLoading: true });
    case admissiontypes.CHECKOUT_PATIENT_SUCCESS:
    case admissiontypes.CHECKOUT_PATIENT_FAILURE:
      return updateObject(state, { checkoutLoading: false });

    default:
      return { ...state };
  }
};

export default admissions;
