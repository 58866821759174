import { useState, useEffect } from 'react';

export const useViewport = () => {
  const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width: 769px)').matches);

  const handleViewportChange = evt => setIsMobile(evt.matches);

  useEffect(() => {
    window.matchMedia('(max-width: 769px)').addEventListener('change', e => handleViewportChange(e));

    return () => {
      window.matchMedia('(max-width: 769px)').removeEventListener('change', e => handleViewportChange(e));
    };
  }, []);

  return [isMobile];
};
