import React from 'react';
import PropTypes from 'prop-types';
import { Modal as BtModal } from 'reactstrap';

export const PreviewModal = props => (
  <BtModal
    centered
    contentClassName="preview"
    isOpen={props.isOpen}
    size="lg"
    toggle={props.toggle}
  >
    <embed style={{ objectFit: 'contain' }} src={props.image} alt="Preview" />
  </BtModal>
);

PreviewModal.propTypes = {
  image: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func
};
