import * as React from "react";
import { cva } from "class-variance-authority";
import PropTypes from "prop-types";
import { cn } from "utils";
import { Icons } from "components/icons";

const buttonVariants = cva(
  "tw-inline-flex tw-items-center tw-justify-center tw-whitespace-nowrap tw-rounded-md " +
    "tw-font-medium tw-ring-offset-background tw-transition-colors focus-visible:tw-outline-none " +
    "focus-visible:tw-ring-2 " +
    "focus-visible:tw-ring-ring focus-visible:tw-ring-offset-2 disabled:tw-pointer-events-none " +
    "disabled:tw-opacity-50",
  {
    variants: {
      variant: {
        secondary: "tw-bg-white tw-text-text tw-shadow-button tw-border tw-border-gray-400",
        primary:
          "tw-bg-primary-500 tw-text-white hover:tw-bg-primary-600 tw-stroke-current disabled:tw-bg-natural-400 " +
          "disabled:tw-cursor-not-allowed",
        destructive:
          "tw-bg-destructive-500 tw-text-white hover:tw-bg-destructive-600 tw-stroke-current disabled:tw-bg-natural-100 " +
          "disabled:tw-cursor-not-allowed disabled:tw-text-natural-400",
        ghost: "hover:tw-bg-accent hover:tw-text-accent-foreground",
        link: "tw-text-primary tw-underline-offset-4 hover:tw-underline",
      },
      modifier: {
        outline: "tw-bg-transparent tw-border tw-border-current tw-stroke-current",
      },
      size: {
        default: "tw-h-[48px] tw-px-12 tw-text-[14px]",
        sm: "tw-h-[36px] tw-px-10 tw-text-[12px]",
        lg: "tw-h-[68px] tw-px-12 tw-text-[14px]",
        auto: "tw-min-h-full",
        icon: "tw-h-10 tw-w-10",
      },
      loading: {
        true: "tw-bg-current tw-cursor-progress",
      },
    },
    compoundVariants: [
      {
        modifier: "outline",
        variant: "destructive",
        className: "tw-text-destructive-500 hover:tw-bg-primary-50",
      },
      {
        loading: true,
        variant: "destructive",
        className: "tw-bg-destructive-700 tw-text-white",
      },
      {
        loading: true,
        variant: "primary",
        className: "tw-bg-primary-700 tw-text-white",
      },
      {
        modifier: "outline",
        variant: "primary",
        className: "tw-text-primary-500 hover:tw-bg-primary-50",
      },
    ],
    defaultVariants: {
      variant: "primary",
      size: "default",
    },
  },
);

const Button = React.forwardRef(
  ({ className, variant, size, loading, modifier, disabled, children, onClick, type = "button", ...props }, ref) => {
    return (
      <button
        type={type}
        ref={ref}
        disabled={disabled}
        className={cn(buttonVariants({ variant, modifier, size, className, loading }))}
        onClick={loading ? null : onClick}
        {...props}
      >
        {children}

        {loading && (
          <span>
            <Icons.spinner className="tw-animate-spin tw-ml-4" />
          </span>
        )}
      </button>
    );
  },
);

Button.displayName = "Button";

Button.propTypes = {
  size: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  modifier: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  className: PropTypes.string,
};

export { Button, buttonVariants };
