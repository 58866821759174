import React from 'react';
import { BrowserRouter, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ModuleRoutes from 'routes/ModuleRoutes';
import PublicRoute from 'routes/PublicRoute';
import AuditTrailRoutes from './modules/audit-trail';
import LaboratoryRoutes from './modules/laboratory/index';
import SettingsRoutes from 'modules/settings';
import InventoryRoutes from 'modules/inventory';
import OnboardingRoutes from 'modules/onboarding';
import FrontdeskRoutes from 'modules/front-desk';
import PatientRoutes from 'modules/patients';
import PharmacyRoutes from 'modules/pharmacy';
import FinanceRoutes from 'modules/finance';
import ReportingRoutes from 'modules/reporting';
import { ToastContainer } from 'react-toastify';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, ArcElement, BarElement, LineElement, Title, Tooltip, Legend);

const App = () => {
  function Scroll(prop) {
    React.useEffect(() => {
      window.scrollTo(0, 0);
    }, [prop.location]);

    return prop.children;
  }

  Scroll.propTypes = {
    location: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  };

  const ScrollToTop = withRouter(Scroll);

  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <ScrollToTop>
          <Switch>
            <Redirect exact from="/" to="/login" />
            <Route path="/onboarding" component={OnboardingRoutes} />
            <Route path="/modules" component={ModuleRoutes} />
            <Route path="/settings" component={SettingsRoutes} />
            <Route path="/inventory" component={InventoryRoutes} />
            <Route path="/laboratory" component={LaboratoryRoutes} />
            <Route path="/audit-trail" component={AuditTrailRoutes} />
            <Route path="/frontdesk" component={FrontdeskRoutes} />
            <Route path="/in-patient" component={PatientRoutes} />
            <Route path="/out-patient" component={PatientRoutes} />
            <Route path="/pharmacy" component={PharmacyRoutes} />
            <Route path="/billing" component={FinanceRoutes} />
            <Route path="/report" component={ReportingRoutes} />
            <Route path="/" component={PublicRoute} />
          </Switch>
        </ScrollToTop>
      </BrowserRouter>
    </>
  );
};

export default App;
