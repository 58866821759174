export default {
  admissions: {
    recommended: {},
    docs: [],
  },
  currentAppointment: {},
  casenotes: {},
  dailyBills: {
    services: {},
  },
  drugChart: {},
  medicationLog: {},
  prescriptions: {},
  nursingNotes: {},
  nursingNotesRecords: {},
  progressNotes: {},
  vitals: {},
  visitSummary: {},
  referral: {},
  surgeryRecord: {},
  surgeryOpNotes: {},
  patientUtil: {},
  procedures: {},
};
