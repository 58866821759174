/* eslint-disable no-case-declarations */
import * as walletTransactionTypes from '../types/walletTransactionTypes';
import initialState from './initialState';
import { updateObject } from 'utils';

const walletTransactions = (state = initialState.walletTransactions, { type, payload }) => {
  switch (type) {
    case walletTransactionTypes.FUND_WALLET_SUCCESS:
      return updateObject(state, { isSaving: false });

    case walletTransactionTypes.FUND_WALLET_START:
      return updateObject(state, { isSaving: true });

    case walletTransactionTypes.FUND_WALLET_FAILURE:
      return updateObject(state, { isSaving: false });

    case walletTransactionTypes.INITIATE_FUNDS_TRANSFER_SUCCESS:
      return updateObject(state, { isSaving: false, transactionDetails: payload });

    case walletTransactionTypes.INITIATE_FUNDS_TRANSFER_START:
      return updateObject(state, { isSaving: true });

    case walletTransactionTypes.INITIATE_FUNDS_TRANSFER_FAILURE:
      return updateObject(state, { isSaving: false });

    case walletTransactionTypes.CONFIRM_FUNDS_TRANSFER_SUCCESS:
      return updateObject(state, { isSaving: false });

    case walletTransactionTypes.CONFIRM_FUNDS_TRANSFER_START:
      return updateObject(state, { isSaving: true });

    case walletTransactionTypes.CONFIRM_FUNDS_TRANSFER_FAILURE:
      return updateObject(state, { isSaving: false });

    case walletTransactionTypes.INITIATE_WITHDRAWAL_SUCCESS:
      return updateObject(state, { isSaving: false });

    case walletTransactionTypes.INITIATE_WITHDRAWAL_START:
      return updateObject(state, { isSaving: true });

    case walletTransactionTypes.INITIATE_WITHDRAWAL_FAILURE:
      return updateObject(state, { isSaving: false });

    case walletTransactionTypes.CONFIRM_WITHDRAWAL_REQUEST_SUCCESS:
      return updateObject(state, { isSaving: false });

    case walletTransactionTypes.CONFIRM_WITHDRAWAL_REQUEST_START:
      return updateObject(state, { isSaving: true });

    case walletTransactionTypes.CONFIRM_WITHDRAWAL_REQUEST_FAIL:
      return updateObject(state, { isSaving: false });

    case walletTransactionTypes.PROCESS_WITHDRAWAL_SUCCESS:
      return updateObject(state, { isSaving: false });

    case walletTransactionTypes.PROCESS_WITHDRAWAL_START:
      return updateObject(state, { isSaving: true });

    case walletTransactionTypes.PROCESS_WITHDRAWAL_FAIL:
      return updateObject(state, { isSaving: false });

    case walletTransactionTypes.FETCH_ACCOUNT_STATEMENT_SUCCESS:
      return updateObject(state, { isLoading: false, accountStatement: payload });

    case walletTransactionTypes.FETCH_ACCOUNT_STATEMENT_START:
      return updateObject(state, { isLoading: true });

    case walletTransactionTypes.FETCH_ACCOUNT_STATEMENT_FAIL:
      return updateObject(state, { isLoading: false });

    default:
      return { ...state };
  }
};

export default walletTransactions;
