import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import InfiniteScroll from "../InfiniteScroll";
import { TaskContainer } from "../../components";
import { CompletedModal, ConfirmModal, Loader, ViewCommentModal } from "components";
import { COMPLETED } from "utils/constants";
import { deliverTaskAction, getActiveTask } from "modules/laboratory/redux/actions";

export const CompletedTasks = ({ completedTasks, isSaving, hasPermission, activeTask, refresh }) => {
  const [completedModal, setCompletedModal] = useState(false);
  const [pickupModal, setPickupModal] = useState(false);
  const [dispatchModal, setDispatchModal] = useState(false);
  const [viewCommentModal, setViewCommentModal] = useState(false);
  const [deliveryMethod, setDeliveryMethod] = useState("");
  const [recipient, setRecipient] = useState("");

  const dispatch = useDispatch();

  const togglePickupModal = () => {
    setRecipient("");
    setPickupModal(!pickupModal);
  };
  const toggleDispatchModal = () => {
    setRecipient("");
    setDispatchModal(!dispatchModal);
  };

  const toggleCompletedModal = () => {
    setCompletedModal(!completedModal);
  };

  const handleAttributeChange = (value) => {
    setRecipient(value);
  };
  const triggerDispatchTask = () => {
    setDeliveryMethod("dispatch");
    toggleCompletedModal();
    toggleDispatchModal();
  };

  const triggerPickupTask = () => {
    setDeliveryMethod("pickup");
    toggleCompletedModal();
    togglePickupModal();
  };
  const viewCompletedTask = (task) => {
    dispatch(getActiveTask(task));
    toggleCompletedModal();
  };
  const deliverTask = (method) => {
    const deliveryDetails = { method, recipient };
    const close = pickupModal ? togglePickupModal : toggleDispatchModal;
    dispatch(deliverTaskAction(activeTask._id, deliveryDetails, close));
  };
  const toggleViewCommentModal = () => setViewCommentModal(!viewCommentModal);

  const viewComments = () => {
    toggleViewCommentModal();
  };

  return (
    <>
      <ConfirmModal
        isSaving={isSaving}
        toggle={toggleDispatchModal}
        positiveClick={() => deliverTask("dispatch")}
        negativeClick={toggleDispatchModal}
        isOpen={dispatchModal}
        disabled={recipient.length < 5}
        recipient={recipient}
        handleAttributeChange={handleAttributeChange}
        color="green"
        title="Dispatch?"
        deliveryMethod={deliveryMethod}
        subtitle="Are you sure you want to dispatch this task?"
        positiveText="Yes"
        negativeText="No"
      />
      <ViewCommentModal
        color="grey"
        isOpen={viewCommentModal}
        toggle={toggleViewCommentModal}
        comments={activeTask?.comments || []}
        closeClick={toggleViewCommentModal}
      />
      <CompletedModal
        isOpen={completedModal}
        toggle={toggleCompletedModal}
        leftButtonPress={triggerDispatchTask}
        rightButtonPress={triggerPickupTask}
        leftButtonDisabled={!hasPermission(["dispatch-job", "super-edit"])}
        rightButtonDisabled={!hasPermission(["deliver-job", "super-edit"])}
        onCommentClick={viewComments}
        entity={activeTask}
      />
      <ConfirmModal
        isSaving={isSaving}
        toggle={togglePickupModal}
        positiveClick={() => deliverTask("pick up")}
        negativeClick={togglePickupModal}
        isOpen={pickupModal}
        deliveryMethod={deliveryMethod}
        disabled={recipient.length < 5}
        recipient={recipient}
        handleAttributeChange={handleAttributeChange}
        color="yellow"
        title="Pickup?"
        subtitle="Are you sure you want to pickup this task?"
        positiveText="Yes"
        negativeText="No"
      />
      {completedTasks?.isLoading ? (
        <Loader />
      ) : (
        <TaskContainer items={completedTasks?.docs?.length || 0} title="Completed" refresh={refresh}>
          <InfiniteScroll
            taskObject={completedTasks}
            dispatch={dispatch}
            taskStatus={COMPLETED}
            viewSingleTask={viewCompletedTask}
          />
        </TaskContainer>
      )}
    </>
  );
};

CompletedTasks.propTypes = {
  completedTasks: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isSaving: PropTypes.bool,
  hasPermission: PropTypes.func,
  allTasks: PropTypes.object,
  activeTask: PropTypes.object,
  refresh: PropTypes.func,
};
