export default {
  bills: {
    invoice: {
      items: {},
    },
    paid: {
      items: {},
    },
    cancelled: {
      items: {},
    },
    invoicePerPatient: {},
    paidPerPatient: {},
    stats: {
      items: {},
    },
    withdrawals: {
      items: {},
    },
    retail: {
      items: {},
    },
  },
  claims: {
    items: {},
    stats: {
      items: {},
    },
  },
  credit: {},
  walletTransactions: {},
  financeAnalytics: {
    summary: {
      items: {},
    },
    topProduct: {
      items: [],
    },
    topServices: {
      items: [],
    },
    topHmo: {
      items: [],
    },
  },
};
