export const FETCH_VITALS_SUCCESS = 'FETCH_VITALS_SUCCESS';
export const FETCH_VITALS_FAIL = 'FETCH_VITALS_FAIL';
export const FETCH_VITALS_START = 'FETCH_VITALS_START';

export const ADD_VITAL_START = 'ADD_VITAL_START';
export const ADD_VITAL_SUCCESS = 'ADD_VITAL_SUCCESS';
export const ADD_VITAL_FAIL = 'ADD_VITAL_FAIL';

export const ARCHIVE_VITALS_START = 'ARCHIVE_VITALS_START';
export const ARCHIVE_VITALS_END = 'ARCHIVE_VITALS_END';
