export const GET_ALL_SHIFTS_START = 'GET_ALL_SHIFTS_START';
export const GET_ALL_SHIFTS_SUCCESS = 'GET_ALL_SHIFTS_SUCCESS';
export const GET_ALL_SHIFTS_FAIL = 'GET_ALL_SHIFTS_FAIL';

// export const GET_ALL_SHIFTS_START = 'GET_ALL_SHIFTS_START';
export const GROUP_ALL_SHIFTS_SUCCESS = 'GROUP_ALL_SHIFTS_SUCCESS';
// export const GET_ALL_SHIFTS_FAIL = 'GET_ALL_SHIFTS_FAIL';

export const GET_SINGLE_SHIFT_START = 'GET_SINGLE_SHIFT_START';
export const GET_SINGLE_SHIFT_SUCCESS = 'GET_SINGLE_SHIFT_SUCCESS';
export const GET_SINGLE_SHIFT_FAIL = 'GET_SINGLE_SHIFT_FAIL';

export const CREATE_SHIFT_START = 'CREATE_SHIFT_START';
export const CREATE_SHIFT_SUCCESS = 'CREATE_SHIFT_SUCCESS';
export const CREATE_SHIFT_FAIL = 'CREATE_SHIFT_FAIL';

export const UPDATE_SHIFT_START = 'UPDATE_SHIFT_START';
export const UPDATE_SHIFT_SUCCESS = 'UPDATE_SHIFT_SUCCESS';
export const UPDATE_SHIFT_FAIL = 'UPDATE_SHIFT_FAIL';

export const DELETE_SHIFT_START = 'DELETE_SHIFT_START';
export const DELETE_SHIFT_SUCCESS = 'DELETE_SHIFT_SUCCESS';
export const DELETE_SHIFT_FAIL = 'DELETE_SHIFT_FAIL';

export const GET_SHIFTS_CONFIG_START = 'GET_SHIFTS_CONFIG_START';
export const GET_SHIFTS_CONFIG_SUCCESS = 'GET_SHIFTS_CONFIG_SUCCESS';
export const GET_SHIFTS_CONFIG_FAIL = 'GET_SHIFTS_CONFIG_FAIL';
