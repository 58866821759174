export const FETCH_DISCOUNT_CODE_FAILED = 'FETCH_DISCOUNT_CODE_FAILED';
export const FETCH_DISCOUNT_CODE_START = 'FETCH_DISCOUNT_CODE_START';
export const FETCH_DISCOUNT_CODE_SUCCESS = 'FETCH_DISCOUNT_CODE_SUCCESS';

export const UPDATE_DISCOUNT_CODE_LIST = 'UPDATE_DISCOUNT_CODE_LIST';

export const ADD_DISCOUNT_CODE_SUCCESS = 'ADD_DISCOUNT_CODE_SUCCESS';
export const ADD_DISCOUNT_CODE_FAILED = 'ADD_DISCOUNT_CODE_FAILED';
export const ADD_DISCOUNT_CODE_START = 'ADD_DISCOUNT_CODE_START';

export const UPDATE_DISCOUNT_CODE_START = 'UPDATE_DISCOUNT_CODE_START';
export const UPDATE_DISCOUNT_CODE_SUCCESS = 'UPDATE_DISCOUNT_CODE_SUCCESS';
export const UPDATE_DISCOUNT_CODE_FAILED = 'UPDATE_DISCOUNT_CODE_FAILED';
