import { _notifyError, _notifySuccess, _sortItems } from 'utils';
import Axios from 'services/axios';
import * as wardTypes from '../types/wardTypes';
import { endApiCall } from '../../../../store/actions';
import { CUSTOM_ERROR_MESSAGE } from "utils/constants";

const fetchWardsStart = () => ({
  type: wardTypes.FETCH_WARDS_START,
});

const fetchWardsSuccess = payload => ({
  type: wardTypes.FETCH_WARDS_SUCCESS,
  payload,
});

const fetchWardsFailed = msg => ({
  type: wardTypes.FETCH_WARDS_FAILED,
  error: msg,
});

export const fetchWards = (branchId, params) => async dispatch => {
  dispatch(fetchWardsStart());
  const { data } = await Axios.get(`/branches/${branchId}/wards`, { params });
  if (!data.status) {
    dispatch(endApiCall());
    _notifyError(data.message);
    dispatch(fetchWardsFailed(data.message));
  } else {
    const payload = {
      items: _sortItems([...data.data], 'name'),
      filteredItems: _sortItems([...data.data], 'name'),
    };
    dispatch(endApiCall());
    dispatch(fetchWardsSuccess(payload));
  }
};

const addWardStart = () => ({ type: wardTypes.ADD_WARD_START });

const addWardSuccess = payload => ({
  type: wardTypes.ADD_WARD_SUCCESS,
  payload,
});

const addWardFailed = msg => ({
  type: wardTypes.ADD_WARD_FAILED,
  error: msg,
});

export const addWard = (current, branchId, close) => {
  return async dispatch => {
    dispatch(addWardStart());
    try {
      const { data } = await Axios.post(`branches/${branchId}/wards`, current);

      if (data.status) {
        dispatch(addWardSuccess(data.data));
        _notifySuccess('Ward successfully added.');
        close();
      }
    } catch (error) {
      _notifyError(error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE);
      dispatch(addWardFailed(CUSTOM_ERROR_MESSAGE));
    }
  };
};

const updateWardStart = payload => ({
  type: wardTypes.UPDATE_WARD_START,
  payload,
});

const updateWardSuccess = payload => ({
  type: wardTypes.UPDATE_WARD_SUCCESS,
  payload,
});

const updateWardFailed = msg => ({
  type: wardTypes.UPDATE_WARD_FAILED,
  error: msg,
});

export const updateSelectedWard = (current, close, action) => {
  return async dispatch => {
    const actionType = action ? { isDeactivating: true } : { isSaving: true };
    const { isActive, name } = current;

    dispatch(updateWardStart(actionType));
    try {
      const { data } = await Axios.patch(`wards/${current._id}`, {
        isActive,
        name,
      });

      if (data.status) {
        dispatch(updateWardSuccess(data.data));
        _notifySuccess(`Ward ${action || 'updated'} successfully.`);
        close();
      }
    } catch (error) {
      _notifyError(CUSTOM_ERROR_MESSAGE);
      dispatch(updateWardFailed(CUSTOM_ERROR_MESSAGE));
    }
  };
};

export const updateWardList = value => ({
  type: wardTypes.UPDATE_WARDS_LIST,
  payload: {
    filteredItems: value,
  },
});
