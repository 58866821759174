/* eslint-disable no-case-declarations */
import * as currentAppointmentTypes from '../types/currentAppointmentTypes';
import initialState from './initialState';
import { updateObject } from 'utils';

const vitals = (state = initialState.currentAppointment, { type, payload }) => {
  switch (type) {
    case currentAppointmentTypes.SET_CURRENT_APPOINTMENT:
      return updateObject(state, payload);
    default:
      return { ...state };
  }
};

export default vitals;
