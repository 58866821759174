import React from 'react';
import PropType from 'prop-types';

export const ItemsHover = ({ items, nameField }) => {
  //Please supply an array of items only.

  if (!Array.isArray(items) || (Array.isArray(items) && items.length === 0)) {
    return <p> Not Provided </p>;
  }

  const firstValue = typeof items[0] === 'object' ? items[0][nameField || 'name'] : items[0];
  const remainder = items.length - 1;

  return (
    <>
      <div className="items-hover-parent">
        {firstValue} {remainder > 0 && <span className="color-primary"> + {remainder} </span>}
        {remainder > 0 && (
          <div className="items-hover">
            <div className="arrow" />
            <div className="items-hover-content">
              {items.map(item => (
                <p key={item[nameField || 'name']}> {item[nameField || 'name']} </p>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

ItemsHover.propTypes = {
  items: PropType.array,
  nameField: PropType.string,
};
