import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export const ViewFormatter = ({ field, value, renderProperty, className, marginBottom, status }) => {
  return (
    <div className={`view-formatter tw-whitespace-nowrap ${status && "color-red"} ${className || ""}`}>
      <p className={classnames("tw-text-sm tw-font-medium tw-text-natural-400", marginBottom ? marginBottom : null)}>
        {field}
      </p>
      {value && ["not provided", "not assigned", "not available"].includes(String(value).toLowerCase()) ? (
        <p
          className={`tw-text-base tw-font-normal tw-text-natural-700 not-assigned ${
            marginBottom ? marginBottom : null
          }`}
        >
          {value}
        </p>
      ) : Array.isArray(value) ? (
        value
          .filter((item) => item)
          .map((item, index) => (
            <p className="tw-text-base tw-font-normal tw-text-natural-700" key={index}>
              {" "}
              {item[renderProperty || "name"]}{" "}
            </p>
          ))
      ) : (
        <p className="tw-text-base tw-font-normal tw-text-natural-700">{value}</p>
      )}
    </div>
  );
};

ViewFormatter.propTypes = {
  field: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.number, PropTypes.node]),
  className: PropTypes.string,
  marginBottom: PropTypes.string,
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  renderProperty: PropTypes.string,
};
