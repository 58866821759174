import React from 'react';
import PropType from 'prop-types';
import classnames from 'classnames';

export const TabSwitcher = ({ tabs, activeTab, setActiveTab }) => (
  <div className="tab-switcher">
    {tabs.map((tabName, _i) => (
      <p
        className={classnames("tab-switcher__text", { active: activeTab === tabName})}
        onClick={() => setActiveTab(tabName)}
        key={_i}
      >
        {tabName|| ''}
      </p>
      
    ))}
  </div>
);

TabSwitcher.propTypes = {
  tabs: PropType.array,
  activeTab: PropType.string,
  setActiveTab: PropType.func,
};
