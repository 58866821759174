export const ADD_WARD_START = 'ADD_WARD_START';
export const ADD_WARD_SUCCESS = 'ADD_WARD_SUCCESS';
export const ADD_WARD_FAILED = 'ADD_WARD_FAILED';

export const FETCH_WARDS_START = 'FETCH_WARDS_START';
export const FETCH_WARDS_SUCCESS = 'FETCH_WARDS_SUCCESS';
export const FETCH_WARDS_FAILED = 'FETCH_WARDS_FAILED';

export const UPDATE_WARDS_LIST = 'UPDATE_WARDS_LIST';
export const UPDATE_WARD_START = 'UPDATE_WARD_START';
export const UPDATE_WARD_SUCCESS = 'UPDATE_WARD_SUCCESS';
export const UPDATE_WARD_FAILED = 'UPDATE_WARD_FAILED';
