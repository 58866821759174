import React from "react";
import PropTypes from "prop-types";
import Check from "services/Check";
import { SearchBar } from "./search";
import { cn } from "utils";

export const TableTop = (props) => {
  const {
    addButtonPermission,
    rightControl: RightControl,
    rightControlText,
    leftControl: LeftControl,
    leftControlText,
    noSearchBar,
    searchControl: SearchControl,
    searchClass = "",
  } = props;
  return (
    <div className="tw-flex tw-items-center tw-justify-between tw-w-full">
      <div className={cn("tw-w-full md:tw-max-w-[400px]", searchClass)}>
        {!noSearchBar && <>{SearchControl ? <SearchControl {...props} /> : <SearchBar {...props} />}</>}
      </div>

      <div className="tw-col-span-12 md:tw-col-span-6 tw-flex tw-flex-col lg:tw-flex-row tw-justify-end tw-space-x-10 tw-items-stretch">
        {LeftControl && <LeftControl leftControlText={leftControlText} cssClass="" {...props} />}

        <Check permission={addButtonPermission}>
          {RightControl && <RightControl rightControlText={rightControlText} {...props} />}
        </Check>
      </div>
    </div>
  );
};

TableTop.propTypes = {
  rightControl: PropTypes.func,
  leftControl: PropTypes.func,
  searchControl: PropTypes.func,
  rightControlText: PropTypes.string,
  searchClass: PropTypes.string,
  leftControlText: PropTypes.string,
  addButtonPermission: PropTypes.array,
  noSearchBar: PropTypes.bool,
};
