import * as frontdeskTypes from '../types';
import initialState from './initialState';
import { updateObject } from 'utils';

const shiftConfig = (state = initialState.shiftConfig, action) => {
  switch (action.type) {
    case frontdeskTypes.GET_SHIFTS_CONFIG_START:
      return updateObject(state, { isLoading: true });

    case frontdeskTypes.GET_SHIFTS_CONFIG_SUCCESS:
      return updateObject(state, { ...action.payload, isLoading: false });

    case frontdeskTypes.GET_SHIFTS_CONFIG_FAIL:
      return updateObject(state, { message: action.payload, isLoading: false });
    default:
      return { ...state };
  }
};

export default shiftConfig;
