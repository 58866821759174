export const FETCH_PLANS_START = 'FETCH_PLANS_START';
export const FETCH_PLANS_SUCCESS = 'FETCH_PLANS_SUCCESS';
export const FETCH_PLANS_FAILED = 'FETCH_PLANS_FAILED';

export const ADD_PLANS_START = 'ADD_PLANS_START';
export const ADD_PLANS_SUCCESS = 'ADD_PLANS_SUCCESS';
export const ADD_PLANS_FAILED = 'ADD_PLANS_FAILED';

export const UPDATE_PLANS_START = 'UPDATE_PLANS_START';
export const UPDATE_PLANS_SUCCESS = 'UPDATE_PLANS_SUCCESS';
export const UPDATE_PLANS_FAILED = 'UPDATE_PLANS_FAILED';

export const UPDATE_PLANS_LIST = 'UPDATE_PLANS_LIST';

export const DELETE_PLANS_START = 'DELETE_PLANS_START';
export const DELETE_PLANS_SUCCESS = 'DELETE_PLANS_SUCCESS';
export const DELETE_PLANS_FAILED = 'DELETE_PLANS_FAILED';
