import React from 'react';
import PropTypes from 'prop-types';

export const StatusPill = ({ icon, status, color }) => {
  return (
    <div className={`status-pill bg-status table__badge ${color}`}>
      <div>
        {icon && <i className={`status-pill-icon ${icon}`} />}
        <span> {status} </span>
      </div>
    </div>
  );
};

StatusPill.propTypes = {
  icon: PropTypes.string,
  status: PropTypes.string,
  color: PropTypes.string,
};
