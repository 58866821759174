import * as frontdeskTypes from '../types';
import initialState from './initialState';
import { updateObject } from 'utils';

const shifts = (state = initialState.shifts, action) => {
  switch (action.type) {
    case frontdeskTypes.GET_ALL_SHIFTS_START:
      return updateObject(state, { isLoading: true });

    case frontdeskTypes.GET_ALL_SHIFTS_SUCCESS:
      return updateObject(state, { ...action.payload, isLoading: false });

    case frontdeskTypes.GROUP_ALL_SHIFTS_SUCCESS:
      return updateObject(state, { grouped: { ...action.payload }, isLoading: false });

    case frontdeskTypes.GET_ALL_SHIFTS_FAIL:
      return updateObject(state, { message: action.payload, isLoading: false });

    case frontdeskTypes.GET_SINGLE_SHIFT_START:
      return updateObject(state, { isLoading: true });

    case frontdeskTypes.GET_SINGLE_SHIFT_SUCCESS:
      return updateObject(state, { ...action.payload, isLoading: false });

    case frontdeskTypes.GET_SINGLE_SHIFT_FAIL:
      return updateObject(state, { message: action.payload, isLoading: false });

    case frontdeskTypes.CREATE_SHIFT_START:
      return updateObject(state, { isCreating: true });

    case frontdeskTypes.CREATE_SHIFT_SUCCESS:
      return updateObject(state, { ...action.payload, isCreating: false });

    case frontdeskTypes.CREATE_SHIFT_FAIL:
      return updateObject(state, { message: action.payload, isCreating: false });

    case frontdeskTypes.UPDATE_SHIFT_START:
      return updateObject(state, { isUpdating: true });

    case frontdeskTypes.UPDATE_SHIFT_SUCCESS:
      return updateObject(state, { ...action.payload, isUpdating: false });

    case frontdeskTypes.UPDATE_SHIFT_FAIL:
      return updateObject(state, { message: action.payload, isUpdating: false });

    case frontdeskTypes.DELETE_SHIFT_START:
      return updateObject(state, { isDeleting: true });

    case frontdeskTypes.DELETE_SHIFT_SUCCESS:
      return updateObject(state, { ...action.payload, isDeleting: false });

    case frontdeskTypes.DELETE_SHIFT_FAIL:
      return updateObject(state, { message: action.payload, isDeleting: false });

    default:
      return { ...state };
  }
};

export default shifts;
