export default {
  auth: {},
  banks: {},
  branchesConfig: {},
  user: {},
  notifications: {},
  laboratory: {},
  onboarding: {},
  branches: {},
  departments: {},
  patientsDepartments: {},
  roles: {},
  staff: {},
  currencies: [],
  currency: null,
  permissions: [],
  inProgress: false,
  inPatients: {},
  inventory: {},
  inventoryPricelists: {},
  inventoryRecalls: {},
  inventoryReturns: {},
  inventoryServices: {},
  inventoryTransfers: {},
  taskManagement: {},
  company: {},
  sponsors: {},
  wards: {},
  rooms: {},
  beds: {},
  diseases: {},
  suppliers: {},
  patients: {},
  email: {},
  report: {},
  subscription: {},
  appOptions: {},
  license: {},
};
