/* eslint-disable no-case-declarations */
import * as inPatientTypes from '../types/inPatientTypes';
import initialState from 'store/reducers/initialState';
import { updateObject, _sortItems } from 'utils';

export const inPatients = (state = initialState.inPatients, action) => {
  switch (action.type) {
    case inPatientTypes.FETCH_INPATIENT_SERVICES_SUCCESS:
      return updateObject(state, action.payload);

    case inPatientTypes.FETCH_INPATIENT_SERVICES_FAILED:
      return updateObject(state, { error: action.error });

    case inPatientTypes.ADD_INPATIENT_SERVICE_START:
      return updateObject(state, { isSaving: true });

    case inPatientTypes.ADD_INPATIENT_SERVICE_SUCCESS:
      const newServices = _sortItems([...state.items].concat(action.payload), 'name');
      return updateObject(state, { items: newServices, filteredItems: newServices, isSaving: false });

    case inPatientTypes.ADD_INPATIENT_SERVICE_FAILED:
      return updateObject(state, {
        isSaving: false,
      });

    case inPatientTypes.UPDATE_INPATIENT_SERVICE_START:
      return updateObject(state, { ...action.payload });

    case inPatientTypes.UPDATE_INPATIENT_SERVICE_SUCCESS:
      const updated = [...state.items].map(prev =>
        String(prev._id) === String(action.payload._id) ? action.payload : prev,
      );
      return updateObject(state, {
        items: updated,
        filteredItems: updated,
        isSaving: false,
        isDeactivating: false,
      });

    case inPatientTypes.UPDATE_INPATIENT_SERVICE_FAILED:
      return updateObject(state, {
        isSaving: false,
        isDeactivating: false,
      });

    case inPatientTypes.UPDATE_INPATIENT_SERVICES_LIST:
      return updateObject(state, action.payload);

    default:
      return { ...state };
  }
};
