import React from 'react';
import PropTypes from 'prop-types';
import { _formatSuggestions } from 'utils';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

export const Suggestions = (props) => {
  return (
    <>
      {props.list.length > 0 ? (
        <>
          {props.showSuggestions &&
            (props.field ? (
              <ul className="suggestions">
                {props.list.map((item, index) => {
                  const status = props.checkDisableCondition ? props.checkDisableCondition(item) : false;
                  const disabled = props.disable ? props.disable(item) : false;
                  return (
                    <li
                      key={index}
                      className={classNames('suggestion', {
                        'suggestion-disabled': disabled,
                        'suggestion-display': status,
                      })}
                      onClick={() => (disabled ? null : props.getSuggestedItem(item))}
                      dangerouslySetInnerHTML={{
                        __html: _formatSuggestions(props.input, item[props.field]),
                      }}
                    />
                  );
                })}
              </ul>
            ) : (
              <div className="suggestions-frontdesk">
                {props.list.slice(0, 6).map((item, index) => (
                  <div key={index} className="suggestion" onClick={() => props.getSuggestedItem(item)}>
                    <NavLink to={props.getSuggestedItem ? () => props.getSuggestedItem(item) : '#'}>
                      <span>{item.name}</span>
                      <span className="ml-3 color-grey">
                        {`•`}
                        <span className="ml-3">{item.mrn}</span>
                      </span>
                      <span className="ml-3 color-grey">
                        {`|`}
                        <span className="ml-3">{item.phoneNumber}</span>
                      </span>
                    </NavLink>
                  </div>
                ))}
                {props.list.length > 6 && (
                  <div className="suggestions-reroute">
                    <NavLink
                      to={{
                        pathname: `${props.listPath}`,
                        state: { param: props.input, filter: props.filter },
                      }}
                    >
                      <span className="color-primary">{`See all results for ${props.input}`}</span>
                    </NavLink>
                  </div>
                )}
              </div>
            ))}
        </>
      ) : null}
    </>
  );
};

Suggestions.propTypes = {
  data: PropTypes.array,
  field: PropTypes.string,
  listPath: PropTypes.string,
  filter: PropTypes.string,
  list: PropTypes.array,
  getSuggestedItem: PropTypes.func,
  checkDisableCondition: PropTypes.func,
  disable: PropTypes.func,
  input: PropTypes.string,
  showSuggestions: PropTypes.bool,
};

Suggestions.defaultProps = {
  list: [],
  showSuggestions: true,
};
