import React from "react";
import PropTypes from "prop-types";
import { Switch } from "components/ui/switch";
import { Label } from "components/ui/label";
import { cn } from "utils";

export const Toggle = ({ hasLeftText, leftText, hasRightText, rightText, id, checked, onSwitch, style }) => (
  <div className="toggle" style={style}>
    {hasLeftText && <p className="toggle__option toggle__option--left">{leftText || "Patient"}</p>}
    <div className="toggler">
      <label htmlFor={id}>
        <input type="checkbox" className="clientSwitch" id={id} checked={checked} onChange={onSwitch} />
        <span className="switch" />
        <span className="switch__button"> </span>
      </label>
    </div>
    {hasRightText && <p className="toggle__option toggle__option--right">{rightText || "Company"}</p>}
  </div>
);

Toggle.propTypes = {
  onSwitch: PropTypes.func,
  hasLeftText: PropTypes.bool,
  leftText: PropTypes.string,
  hasRightText: PropTypes.bool,
  checked: PropTypes.bool,
  rightText: PropTypes.string,
  //the toggle wnt work without id
  id: PropTypes.string,
  style: PropTypes.object,
};

export const MainToggler = ({ text, className, checked = false, onSwitch, isDisabled }) => (
  // <div className={`toggle ${positionClass} tw-w-full`} style={{ marginLeft: `${removeMargin ? 0 : '57%'}` }}>
  //   <div className={`toggler ${positionClass && 'tw-ml-3'}`}>
  //     <label htmlFor={id}>
  //       <input
  //         type="checkbox"
  //         id={id}
  //         className="clientSwitch"
  //         name={id}
  //         checked={checked}
  //         onChange={onSwitch}
  //         onClick={(e) => e.stopPropagation()}
  //         disabled={isDisabled}
  //       />
  //       <span className="switch is-checked" />
  //       <span className="switch__button"> </span>
  //     </label>
  //   </div>
  //   <p className="toggle__option toggle__option--right" style={{ color: textColor || '#F5A623' }}>
  //     {text || 'Company'}
  //   </p>
  // </div>

  <div className="tw-flex tw-items-center tw-space-x-2">
    <Switch checked={checked} onCheckedChange={onSwitch} disabled={isDisabled} />
    <Label htmlFor="hasVariants" className={cn("", className)}>
      {text}
    </Label>
  </div>
);

MainToggler.propTypes = {
  onSwitch: PropTypes.func,
  text: PropTypes.string,
  className: PropTypes.string,
  textColor: PropTypes.string,
  positionClass: PropTypes.string,
  checked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  removeMargin: PropTypes.bool,
};
