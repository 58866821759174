export const FETCH_FILES_SUCCESS = "FETCH_FILES_SUCCESS";
export const FETCH_FILES_START = "FETCH_FILES_START";
export const FETCH_FILES_FAILURE = "FETCH_FILES_FAILURE";

export const FETCH_SAMPLES_SUCCESS = "FETCH_SAMPLES_SUCCESS";
export const FETCH_SAMPLES_START = "FETCH_SAMPLES_START";
export const FETCH_SAMPLES_FAILURE = "FETCH_SAMPLES_FAILURE";

export const UPLOAD_FILE_START = "UPLOAD_FILE_START";
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";

export const PROCESS_FILE_START = "PROCESS_FILE_START";
export const PROCESS_FILE_FAILURE = "PROCESS_FILE_FAILURE";
export const PROCESS_FILE_SUCCESS = "PROCESS_FILE_SUCCESS";
