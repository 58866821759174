import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { ToastContainer } from "react-toastify";
import PropTypes from "prop-types";
import { MainSideBar, NavBar } from "components";
import { useViewport } from "hooks/useViewport";
import {
  auditTrailNavOptions,
  billingNavOptions,
  centralStoreNavOptions,
  clinicNavOptions,
  frontDeskNavOptions,
  inventoryNavOptions,
  labsNavOptions,
  pharmacyNavOptions,
  reportNavOptions,
  settingsNavOptions,
} from "config/nav-options";
import { fetchStaffById, userLogout } from "store/actions";
import { _showTimeStamp, _updateTheme, isOutorInPatient, TIMEOUTS } from "utils";
import moment from "moment";
import { _notifyExpiration } from "modules/settings/components/PaymentNotification";
import { SupportModal } from "components/supportModal";
import Axios from "../services/axios";
import IdlePopUp from "../components/idlePopUp";
import { useEnvironment } from "hooks/useEnvironment";
import { SOCKET_EVENTS } from "utils/constants";
import { _notifySocket } from "modules/patients/components/NotificationCard";
import SoftwareUpdate from "components/softwareUpdate";
import { socketIo } from "socket";

const domEvents = ["click", "scroll", "keypress"];

const MainLayout = ({ ...props }) => {
  const dispatch = useDispatch();
  const { children, pageClass, ...rest } = props;
  const [panel, setPanel] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const [isMobile] = useViewport();
  const [isProduction] = useEnvironment("production");

  const { company } = useSelector((state) => state);
  const user = useSelector((state) => state.user);
  const isParentBranch = useSelector((state) => state.user?.current?.parent);
  const { nextPaymentDate } = useSelector((store) => store.subscription?.data?.[0]) || {};
  const openWidowExpiryDate = moment(nextPaymentDate).add(12, "d");
  const currentDate = new Date();

  const differenceInDays = (start, end = new Date()) => {
    const date1 = new Date(start);
    const date2 = new Date(end);
    const oneDay = 1000 * 60 * 60 * 24;
    const diffInTime = date2.getTime() - date1.getTime();
    return Math.abs(Math.round(diffInTime / oneDay));
  };

  const daysToNextPayment = differenceInDays(currentDate, nextPaymentDate);
  const daysToExtension = differenceInDays(currentDate, openWidowExpiryDate);

  const getUserData = useCallback(async () => {
    await dispatch(fetchStaffById(user?.current?.profile._id));
  }, [dispatch]);

  const applyTheme = useCallback(() => {
    if (company.error === null && company.loading === false) {
      _updateTheme(company.primaryColour, company.secondaryColour);
    }
  }, [company]);

  useEffect(() => {
    if (!isMobile) {
      if (showNav) setPanel(false);
      setShowNav(false);
    }
    // eslint-disable-next-line
  }, [isMobile]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  useEffect(() => {
    if (company) {
      applyTheme();
    }
  }, [company, applyTheme]);

  useEffect(() => {
    const lastReminderDate = JSON.parse(localStorage.getItem("reminder"));
    const cannotShowReminder = lastReminderDate ? moment(currentDate).isSame(lastReminderDate?.date, "day") : false;
    if (cannotShowReminder) return;

    const expirationListener = (data) => {
      _notifyExpiration({
        duration: 6000,
        rightControl: true,
        handle: true,
        limit: 1,
        data,
      });
    };

    const expiryDayMsg = `Your subscription is about to expire today and your card will be debited by ${
      _showTimeStamp(nextPaymentDate).time
    }`;

    const beforeExpiryDayMsg = `Your subscription is about to expire in ${
      daysToNextPayment > 1 ? `${daysToNextPayment} Days` : `${daysToNextPayment} Day`
    } and your card will be debited by ${_showTimeStamp(nextPaymentDate).time}`;

    const afterExpiryDayMsg = `We are unable to bill your debit card, PLEASE update payment to
            avoid service disruption in ${daysToExtension > 1 ? `${daysToExtension} Days` : `${daysToExtension} Day`}`;

    if (nextPaymentDate !== undefined) {
      if (daysToNextPayment === 0) {
        expirationListener({ message: expiryDayMsg });
      }

      if (daysToNextPayment <= 5 && daysToNextPayment >= 1) {
        expirationListener({ message: beforeExpiryDayMsg });
      }

      if (daysToExtension <= 11 && daysToExtension >= 1) {
        expirationListener({ message: afterExpiryDayMsg });
      }

      localStorage.setItem("reminder", JSON.stringify({ date: currentDate }));
    }
  }, [nextPaymentDate]);

  const resolveRoutes = () => {
    const { match } = rest;

    switch (match.path.split("/")[1]) {
      case "laboratory":
        return { nav: labsNavOptions() };
      case "inventory" || "central-store":
        return { nav: inventoryNavOptions(isParentBranch) };
      case "in-patient":
        return { nav: clinicNavOptions("in-patient") };
      case "out-patient":
        return { nav: clinicNavOptions("out-patient") };
      case "central-store":
        return { nav: centralStoreNavOptions() };
      case "audit-trail":
        return { nav: auditTrailNavOptions() };
      case "settings":
        return { nav: settingsNavOptions(isProduction).sidebarOptions };
      case "frontdesk":
        return { nav: frontDeskNavOptions() };
      case "pharmacy":
        return { nav: pharmacyNavOptions(isProduction) };
      case "billing":
        return { nav: billingNavOptions() };
      case "report":
        return { nav: reportNavOptions() };
      case "profile":
        return { nav: settingsNavOptions().sidebarOptions };
      default:
        break;
    }
  };

  const options = resolveRoutes();

  const mobileSideBarAction = () => {
    setShowNav((prevState) => !prevState);
    setPanel((prevState) => !prevState);
  };

  const fetchTuneConfig = () => {
    Axios.get(`/get-tune`)
      .then((response) => {
        if (response.status) {
          const { notificationTone } = response?.data?.data || {};
          localStorage.setItem("sound", JSON.stringify(notificationTone));
        }
      })
      .catch(() => {
        //
      });
  };

  const savedSound = localStorage.getItem(`sound`);
  let tune = null;
  if (savedSound !== "undefined") tune = JSON.parse(savedSound);
  useEffect(() => {
    if (!tune) {
      fetchTuneConfig();
    }
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const lastInteraction = JSON.parse(localStorage.getItem("lastInteraction"));

  const setTimer = useCallback(() => {
    if (!isProduction) {
      localStorage.setItem("lastInteraction", JSON.stringify(new Date()));
    }
  }, []);

  useEffect(() => {
    domEvents.forEach((event) => document.addEventListener(event, setTimer));

    return () => {
      domEvents.forEach((event) => document.removeEventListener(event, setTimer));
    };
  }, [setTimer]);

  useEffect(() => {
    const id = setTimeout(() => {
      if (isOutorInPatient()) {
        setIsOpen(true);
      }
    }, TIMEOUTS.IDLE_TIMEOUT);

    return clearTimeout.bind(null, id);
  }, [lastInteraction]);

  const handleLogout = async () => {
    await dispatch(userLogout());
  };

  const [state, setState] = useState({
    showUpdate: false,
    message: "",
  });

  const { showUpdate, message } = state;

  const toggleUpdate = (message) => {
    setState({ showUpdate: !showUpdate, message });
  };

  const {
    current: { sections = [], modules = [], _id, parentOrganizationId },
  } = user;

  let rooms = [...modules, ...sections.map((section) => section?.name), _id];
  const channelNames = rooms.map((room) => `${room}-${parentOrganizationId}`);
  channelNames.push("General");

  useEffect(() => {
    // Join rooms
    const socket = channelNames.map((channelName) => {
      return socketIo(`?channelName=${channelName}`);
    });

    // eslint-disable-next-line array-callback-return
    SOCKET_EVENTS.forEach((event) => {
      // eslint-disable-next-line array-callback-return
      socket.map((item) => {
        // Handle and suppress error events
        item.on("connect_error", () => {
          // You can choose to silently handle the error
        });
        item.on("reconnect_error", () => {
          // console.log('Socket reconnect error:', err.message); // Handle reconnection errors
        });
        item.on("error", () => {
          // Handle error silently or notify users differently
          // No console.log here to suppress error logs
        });

        item.on(event.event, (data) => {
          if (event?.event === "release-notes") {
            toggleUpdate(data);
            return;
          }

          _notifySocket({
            duration: 900000,
            rightControl: true,
            handle: false,
            data,
            toastId: "socket",
            event: event.event,
            title: event.title,
            callback: () => toggleUpdate(data),
          });
        });
      });
    });
  }, []);

  return (
    <div className="dashboard">
      <SoftwareUpdate
        message={message}
        isOpen={showUpdate}
        toggle={() => setState({ showUpdate: !showUpdate, message: "" })}
      />
      {!isProduction && <IdlePopUp isOpen={isOpen} onClose={handleLogout} toggle={() => setIsOpen(false)} />}
      <ToastContainer />
      <main className={classnames("layout-page", pageClass)}>
        <div className="main-app-layout">
          <div
            className={classnames({
              "side-layout tw-bg-primary": true,
              "side-expanded": panel === true,
              "side-mobile": isMobile,
              "side-mobile-expanded": showNav,
            })}
            onMouseEnter={() => !isMobile && setPanel(true)}
            onMouseLeave={() => !isMobile && setPanel(false)}
          >
            <MainSideBar
              sidebarOptions={options && options.nav}
              panel={panel}
              avatar={null}
              isSetting={props.match.path.split("/")[1] === "settings"}
              notifications={[]}
              mobileClickAction={isMobile && mobileSideBarAction}
            />
          </div>
          <div
            className={classnames({
              "main-layout ": true,
            })}
          >
            <>
              {isMobile && <NavBar drawerAction={mobileSideBarAction} />}
              <SupportModal />
              {children}
            </>
          </div>
        </div>
      </main>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
  pageClass: PropTypes.string,
  match: PropTypes.object,
};

export default MainLayout;
