import * as departmentTypes from '../types/departmentTypes';
import initialState from 'store/reducers/initialState';
import { updateObject } from 'utils';

export const departments = (state = initialState.patientsDepartments, action) => {
  switch (action.type) {
    case departmentTypes.FETCH_PATIENTS_DEPARTMENTS_SUCCESS:
      return updateObject(state, action.payload);

    case departmentTypes.FETCH_PATIENTS_DEPARTMENTS_FAILED:
      return updateObject(state, { error: action.error });

    default:
      return { ...state };
  }
};

export default departments;
