import * as plansTypes from '../types/plansTypes';
import initialState from 'store/reducers/initialState';
import { updateObject } from 'utils';

export const plans = (state = initialState.suppliers, action) => {
  switch (action.type) {
    case plansTypes.FETCH_PLANS_SUCCESS:
      return updateObject(state, action.payload);

    case plansTypes.FETCH_PLANS_FAILED:
      return updateObject(state, { error: action.error });

    case plansTypes.FETCH_PLANS_START:
      return updateObject(state, { formErrorMessage: null, isSaving: true });

    case plansTypes.ADD_PLANS_START:
      return updateObject(state, { ...action.payload, isSaving: true });

    case plansTypes.ADD_PLANS_FAILED:
      return updateObject(state, {
        formErrorMessage: action.error,
        isSaving: false,
      });

    case plansTypes.ADD_PLANS_SUCCESS:
      return updateObject(state, { ...action.payload, formErrorMessage: null });

    case plansTypes.UPDATE_PLANS_START:
      return updateObject(state, { formErrorMessage: null, ...action.payload });

    case plansTypes.UPDATE_PLANS_FAILED:
      return updateObject(state, {
        isSaving: false,
        isDeactivating: false,
      });

    case plansTypes.UPDATE_PLANS_SUCCESS:
      return updateObject(state, {
        items: action.payload,
        filteredItems: action.payload,
        isSaving: false,
        isDeactivating: false,
      });

    case plansTypes.DELETE_PLANS_START:
      return updateObject(state, {
        isSaving: true,
      });

    case plansTypes.DELETE_PLANS_FAILED:
      return updateObject(state, {
        isSaving: null,
      });

    case plansTypes.DELETE_PLANS_SUCCESS:
      return updateObject(state, {
        formErrorMessage: null,
        isSaving: null,
        items: state.items.filter(item => item._id !== action.payload),
        filteredItems: state.filteredItems.filter(item => item._id !== action.payload),
      });

    case plansTypes.UPDATE_PLANS_LIST:
      return updateObject(state, { ...action.payload, isSaving: null });

    default:
      return { ...state };
  }
};
