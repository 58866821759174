import React from "react";
import frontDesk from "assets/svg/front-desk.svg";
import PropTypes from "prop-types";

export const LandingCard = (props) => {
  return (
    <div className="card card__landing-screen">
      <div className="overlay-effect"></div>
      <div className="avatar">
        <img src={props.avatar ?? frontDesk} alt="lions" />
      </div>
      <div className="details">
        <h2 className="title">{props.title ?? "front desk"}</h2>
        <p className="subtitle">{props.subtitle ?? "Manage International Classification of Diseases"}</p>
      </div>
    </div>
  );
};

LandingCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  avatar: PropTypes.any,
};
