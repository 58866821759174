import React from "react";
import { Modal, ModalBody } from "reactstrap";
import PropTypes from "prop-types";
import { ReactComponent as Close } from "assets/svg/close.svg";
import { Button } from "./ui/buttons/button";

export const ConfirmationModal = (props) => {
  const { isOpen, toggle, cssClass, handleSave, body, loading } = props;

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="" centered backdropClassName="backdrop-style" className={cssClass}>
      <div className="modal__header">
        <div className="container-fluid">
          <div className="modal__header--inner tw-flex align-items-center justify-content-between">
            <div className="mt-3">
              <h2 className="tw-text-[18px] tw-font-bold">Confirmation</h2>
            </div>

            <div className="modal__header--icon">
              <Close style={{ cursor: "pointer" }} onClick={props.toggle} color="#fa4659" strokeWidth={1} />
            </div>
          </div>
        </div>
      </div>
      <ModalBody>
        <div className="p-5 tw-flex align-items-center justify-content-center text-center">{body}</div>
        <div className="tw-flex tw-justify-center tw-gap-5 tw-mt-14">
          <Button variant="primary" modifier="outline" className="mr-2" onClick={() => toggle()}>
            Cancel
          </Button>
          <Button variant="primary" loading={loading} onClick={handleSave}>
            Proceed
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  handleSave: PropTypes.func,
  body: PropTypes.element,
  loading: PropTypes.bool,
  cssClass: PropTypes.string,
};
