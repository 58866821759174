import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import { Select } from 'components';
import { Button } from 'components/ui/buttons/button';

('');

export const BranchSelect = (props) => {
  const branches = [
    { value: 'ikeja', label: 'Ikeja' },
    { value: 'lekki', label: 'Lekki' },
    { value: 'vi', label: 'VI' },
    { value: 'surulere', label: 'Surulere' },
  ];
  return (
    <Modal isOpen={props.isOpen} size="sm" centered={true}>
      <div className="modal__header">
        <div className="container-fluid">
          <div className="modal__header--inner">
            <div className="modal__header--text">
              <h3 className="modal__header__title text-center">Select a Branch</h3>

              <h4 className="modal__header__subtitle">Which branch are you working from?</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="modal__body">
        <div className="container-fluid">
          <form className="form auth__form">
            <div className="row">
              <div className="col-12">
                <Select
                  label="Branch"
                  placeholder="Select branch"
                  onChange={(e) => props.setBranch(e)}
                  options={branches}
                  value={props.branch}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Button variant="primary" disabled={!props.branch} block onClick={props.saveBranch}>
                  Continue
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

BranchSelect.propTypes = {
  branch: PropTypes.object,
  isOpen: PropTypes.bool,
  saveBranch: PropTypes.func,
  setBranch: PropTypes.func,
};
