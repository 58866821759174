/* eslint-disable no-case-declarations */
import * as billTypes from '../types/billTypes';
import initialState from './initialState';
import { updateObject } from 'utils';

const invoice = (state = initialState.bills, { type, payload, billType }) => {
  switch (type) {
    case billTypes.FETCH_BILLS_START:
      return updateObject(state, { [billType]: { ...state[billType], loading: true } });
    case billTypes.FETCH_BILLS_SUCCESS:
      return updateObject(state, { [billType]: { ...state[billType], items: payload, loading: false } });
    case billTypes.FETCH_BILLS_FAIL:
      return updateObject(state, { [billType]: { ...state[billType], loading: false } });

    case billTypes.FETCH_BILLS_PER_PATIENT_START:
      return updateObject(state, { [billType]: { ...state[billType], loading: true } });
    case billTypes.FETCH_BILLS_PER_PATIENT_SUCCESS:
      return updateObject(state, { [billType]: { ...state[billType], items: payload, loading: false } });
    case billTypes.FETCH_BILLS_PER_PATIENT_FAIL:
      return updateObject(state, { [billType]: { ...state[billType], loading: false } });

    case billTypes.DELETE_BILL_ITEM_START:
      return updateObject(state, { isBillItemDeleting: true });
    case billTypes.DELETE_BILL_ITEM_END:
      return updateObject(state, { isBillItemDeleting: false });

    case billTypes.STASH_BILL_ITEM_START:
      return updateObject(state, { isBillItemStashing: true });
    case billTypes.STASH_BILL_ITEM_END:
      return updateObject(state, { isBillItemStashing: false });

    case billTypes.FETCH_FINANCE_DASHBOARD_STATS_START:
      return updateObject(state, { stats: { ...state.stats, loading: true } });
    case billTypes.FETCH_FINANCE_DASHBOARD_STATS_SUCCESS:
      return updateObject(state, { stats: { ...state.stats, items: payload, loading: false } });
    case billTypes.FETCH_FINANCE_DASHBOARD_STATS_FAIL:
      return updateObject(state, { stats: { ...state.stats, loading: false } });

    case billTypes.FETCH_WITHDRAWALS_START:
      return updateObject(state, { withdrawals: { ...state.withdrawals, loading: true } });
    case billTypes.FETCH_WITHDRAWALS_SUCCESS:
      return updateObject(state, { withdrawals: { ...state.withdrawals, items: payload, loading: false } });
    case billTypes.FETCH_WITHDRAWALS_FAIL:
      return updateObject(state, { withdrawals: { ...state.withdrawals, loading: false } });

    case billTypes.CONVERT_INVOICE_START:
      return updateObject(state, { isProformaConverting: true });
    case billTypes.CONVERT_INVOICE_END:
      return updateObject(state, { isProformaConverting: false });

    case billTypes.CANCEL_INVOICE_START:
      return updateObject(state, { isInvoiceCancelling: true });
    case billTypes.CANCEL_INVOICE_END:
      return updateObject(state, { isInvoiceCancelling: false });

    case billTypes.CREATE_PAYMENT_START:
      return updateObject(state, { isCreatingPayment: true });
    case billTypes.CREATE_PAYMENT_END:
      return updateObject(state, { isCreatingPayment: false });

    default:
      return { ...state };
  }
};

export default invoice;
