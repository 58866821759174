import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { AddMoreTable } from 'components';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { _formatDate } from 'utils';
import moment from 'moment';

export const BatchSelector = ({ callBack, value, options }) => {
  const ref = useRef();
  const [show, setShow] = useState(false);
  const filteredOptions = options.filter(item => !moment(new Date()).isBefore(new Date(item.expiryDate)));

  useOnClickOutside(ref, () => setShow(false));

  return (
    <div className="batch-selector" ref={ref} onClick={() => setShow(prevState => !prevState)}>
      <div className="batch-selector-input">
        <span>{value}</span>
        <i className="fas fa-sort-down" />
      </div>
      {show && (
        <div className="batch-selector-options context-menu-body">
          <AddMoreTable heading={['Batch ID', 'Qty', 'Expiry date']}>
            {Array.isArray(filteredOptions) &&
              filteredOptions.map(batchObj => {
                const item = Object.values(batchObj)[0];
                const { batchId, quantity, expiryDate } = item;
                return (
                  <tr key={`batch-selector-${item.batchId}`} onClick={() => callBack(item.batchId)} role="button">
                    <td className="tw-pr-5">
                      {' '}
                      <p> {batchId} </p>
                    </td>
                    <td className="tw-pr-5">
                      {' '}
                      <p> {quantity} </p>{' '}
                    </td>
                    <td>
                      {' '}
                      <p> {expiryDate ? _formatDate(expiryDate) : 'Not provided'} </p>
                    </td>
                  </tr>
                );
              })}
          </AddMoreTable>
        </div>
      )}
    </div>
  );
};

BatchSelector.propTypes = {
  callBack: PropTypes.func,
  value: PropTypes.string,
  options: PropTypes.array,
};

export const AnotherBatchSelector = ({ callBack, value, options }) => {
  const ref = useRef();
  const [show, setShow] = useState(false);

  useOnClickOutside(ref, () => setShow(false));

  return (
    <div ref={ref}>
      {!show && (
        <div role="button">
          <span className="color-indigo" onClick={() => setShow(true)}>
            <i className="fas fa-plus tw-mr-3" />
            Select another batch
          </span>
        </div>
      )}
      {show && <BatchSelector value={value} options={options} callBack={batch => callBack(batch)} />}
    </div>
  );
};

AnotherBatchSelector.propTypes = {
  callBack: PropTypes.func,
  value: PropTypes.string,
  options: PropTypes.array,
};
