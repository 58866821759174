import { _notifyError, _notifySuccess, _sortItems } from 'utils';
import Axios from 'services/axios';
import * as roomTypes from '../types/roomTypes';
import { endApiCall } from '../../../../store/actions';
import { CUSTOM_ERROR_MESSAGE } from "utils/constants";

const fetchRoomsSuccess = payload => ({
  type: roomTypes.FETCH_ROOMS_SUCCESS,
  payload,
});

const fetchRoomsFailed = msg => ({
  type: roomTypes.FETCH_ROOMS_FAILED,
  error: msg,
});

const fetchRoomsStart = () => ({
  type: roomTypes.FETCH_ROOMS_START,
});

export const fetchRooms = (wardId, params) => async dispatch => {
  dispatch(fetchRoomsStart());
  const { data } = await Axios.get(`/wards/${wardId}/rooms`, { params });

  if (!data.status) {
    dispatch(endApiCall());
    _notifyError(data.message);
    dispatch(fetchRoomsFailed(data.message));
  } else {
    const payload = {
      items: _sortItems([...data.data], 'name'),
      filteredItems: _sortItems([...data.data], 'name'),
    };
    dispatch(endApiCall());
    dispatch(fetchRoomsSuccess(payload));
  }
};

const addRoomStart = () => ({ type: roomTypes.ADD_ROOM_START });

const addRoomSuccess = payload => ({
  type: roomTypes.ADD_ROOM_SUCCESS,
  payload,
});

const addRoomFailed = msg => ({
  type: roomTypes.ADD_ROOM_FAILED,
  error: msg,
});

export const addRoom = (current, wardId, close) => {
  return async dispatch => {
    dispatch(addRoomStart());
    try {
      const { data } = await Axios.post(`wards/${wardId}/rooms`, current);

      if (data.status) {
        dispatch(addRoomSuccess(data.data));
        _notifySuccess('Room successfully added.');
        close();
      }
    } catch (error) {
      _notifyError(CUSTOM_ERROR_MESSAGE);
      dispatch(addRoomFailed(CUSTOM_ERROR_MESSAGE));
    }
  };
};

const updateRoomStart = payload => ({
  type: roomTypes.UPDATE_ROOM_START,
  payload,
});

const updateRoomSuccess = payload => ({
  type: roomTypes.UPDATE_ROOM_SUCCESS,
  payload,
});

const updateRoomFailed = msg => ({
  type: roomTypes.UPDATE_ROOM_FAILED,
  error: msg,
});

export const updateSelectedRoom = (current, close, action) => {
  return async dispatch => {
    const actionType = action ? { isDeactivating: true } : { isSaving: true };
    const { isActive, name } = current;

    dispatch(updateRoomStart(actionType));
    try {
      const { data } = await Axios.patch(`rooms/${current._id}`, {
        isActive,
        name,
      });

      if (data.status) {
        dispatch(updateRoomSuccess(data.data));
        _notifySuccess(`Room ${action || 'updated'} successfully.`);
        close();
      }
    } catch (error) {
      _notifyError(CUSTOM_ERROR_MESSAGE);
      dispatch(updateRoomFailed(CUSTOM_ERROR_MESSAGE));
    }
  };
};

export const updateRoomList = value => ({
  type: roomTypes.UPDATE_ROOMS_LIST,
  payload: {
    filteredItems: value,
  },
});
