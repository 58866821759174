import React from 'react';
import PropType from 'prop-types';

const Spinner = ({ width = 22, height = 22, stroke = 'currentColor', ...props }) => {
  const pathProps = { stroke: stroke, strokeWidth: '1.5', strokeLinecap: 'round', strokeLinejoin: 'round' };
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 22 22" fill="none" {...props}>
      <path d="M11 1L11 4" {...pathProps} />
      <path d="M11 18L11 21" {...pathProps} />
      <path d="M21 11L18 11" {...pathProps} />
      <path d="M4 11L1 11" {...pathProps} />
      <path d="M18.071 3.92555L15.9497 6.04688" {...pathProps} />
      <path d="M6.05003 15.949L3.92871 18.0703" {...pathProps} />
      <path d="M3.92897 3.92555L6.05029 6.04688" {...pathProps} />
      <path d="M15.9495 15.949L18.0708 18.0703" {...pathProps} />
    </svg>
  );
};

Spinner.propTypes = {
  width: PropType.string,
  height: PropType.string,
  stroke: PropType.string,
};
export default Spinner;
