/* eslint-disable no-case-declarations */
import * as analyticTypes from '../types/analyticTypes';
import initialState from './initialState';
import { updateObject } from 'utils';

const analytics = (state = initialState.analytics, { type, payload }) => {
  switch (type) {
    case analyticTypes.FETCH_SECTION_DRUG_COUNT_SUCCESS:
      return updateObject(state, { sectionDrugCount: payload });

    case analyticTypes.FETCH_DISPENSED_PRESCRIPTIONS_SUCCESS:
      return updateObject(state, { dispensedPrescription: payload });

    case analyticTypes.FETCH_DRUG_REVENUE_SUCCESS:
      return updateObject(state, { drugRevenue: payload });

    case analyticTypes.FETCH_OUT_OF_STOCK_DRUGS_SUCCESS:
      return updateObject(state, { outOfStockDrugs: payload });

    case analyticTypes.FETCH_DISPENSED_COUNT_SUCCESS:
      return updateObject(state, { dispensedDrugCount: payload });
      
    case analyticTypes.FETCH_EXPIRED_DRUGS_SUCCESS:
      return updateObject(state, { expiredDrugs: payload });

    default:
      return { ...state };
  }
};

export default analytics;
