import React, { useEffect, useState } from 'react';
import { Modal } from 'reactstrap';
import { ReactComponent as Close } from 'assets/svg/close.svg';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from './ui/buttons/button';
import { Editor } from"react-draft-wysiwyg"
import { EditorState, convertFromRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EDITOR_TOOLBAR } from 'utils/constants';

const SoftwareUpdate = ({ isOpen, toggle, message }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  let contentState = message !== "" && convertFromRaw(JSON.parse(message));

  useEffect(() => {
    contentState = message !== "" && convertFromRaw(JSON.parse(message));
    if (message !== "" && contentState) {
      setEditorState(EditorState.createWithContent(contentState));
    }
 }, [message]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md" className="software" centered backdropClassName="backdrop-style">
      <div className="software-update__header">
        <div className="tw-flex justify-content-between">
          <div>
            <h3 className="subject-tag">Software Update!</h3>
            <h1 className="fs-32">What&apos;s new?</h1>
            <p className="fs-14">Prepare ahead of what&apos;s is included in this update</p>
          </div>
          <Close style={{ cursor: 'pointer' }} onClick={() => null} color="#fa4659" strokeWidth={1} />
        </div>
      </div>
      <div className="patients-referral-form">
        <Editor
          toolbar={EDITOR_TOOLBAR}
          toolbarHidden={true}
          readOnly={true}
          editorState={editorState}
          toolbarStyle={{ background: '#e9ecef', padding: '10px 8px 6px 8px' }}
          wrapperClassName={`tw-rounded-[4px]`}
          editorClassName="tw-px-4 tw-min-h-[150px] tw-text-black"
          onEditorStateChange={(value) => setEditorState(value)}
        />
        <div className="form__settings-footer mt-5 tw-w-1/2">
          <div className="tw-flex tw-w-full tw-items-center tw-justify-around">
            <div className="mx-4">
              <Button
                className={classNames('btn btn__utility', {
                  btn__loading: false,
                })}
                onClick={toggle}
                style={{ minWidth: '140px' }}
              >
                Cancel
              </Button>
            </div>
            <div className="mx-4">
              <Button
                variant="primary"
                className={classNames('btn btn__secondary', {
                  btn__loading: false,
                })}
                loadig={false}
                onClick={() => {
                  window.location.reload();
                }}
                style={{ minWidth: '140px' }}
              >
                Refresh
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

SoftwareUpdate.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  message: PropTypes.string,
};

export default SoftwareUpdate;
