import * as categoryAction from '../types/categoryTypes';
import initialState from 'store/reducers/initialState';
import { _sortItems } from 'utils';
import { updateObject } from 'utils';

export const categories = (state = initialState.suppliers, action) => {
  const updateItemField = (payload, field) =>
    state[field].map(prev => {
      if (String(prev._id) === String(payload.updatedObject._id)) {
        return payload.updatedObject;
      }
      return prev;
    });

  switch (action.type) {
    case categoryAction.FETCH_CATEGORIES_SUCCESS:
      return updateObject(state, action.payload);

    case categoryAction.FETCH_CATEGORIES_FAILED:
      return updateObject(state, { error: action.error });

    case categoryAction.FETCH_CATEGORIES_START:
      return updateObject(state, { formErrorMessage: null, isSaving: true });

    case categoryAction.ADD_CATEGORY_START:
      return updateObject(state, {
        ...action.payload,
        formErrorMessage: null,
        isSaving: true,
      });

    case categoryAction.ADD_CATEGORY_FAILED:
      return updateObject(state, {
        formErrorMessage: action.error,
        isSaving: false,
      });

    case categoryAction.ADD_CATEGORY_SUCCESS:
      return updateObject(state, {
        items: _sortItems([...state.items, ...action.payload], 'name'),
        filteredItems: _sortItems([...state.items, ...action.payload], 'name'),
        formErrorMessage: null,
        isSaving: false,
      });

    case categoryAction.UPDATE_CATEGORY_START:
      return updateObject(state, {
        formErrorMessage: null,
        isSaving: true,
      });

    case categoryAction.UPDATE_CATEGORY_SUCCESS:
      return updateObject(state, {
        items: updateItemField(action.payload, 'items'),
        filteredItems: updateItemField(action.payload, 'filteredItems'),
        formErrorMessage: null,
        isSaving: false,
      });

    case categoryAction.UPDATE_CATEGORY_FAILED:
      return updateObject(state, {
        formErrorMessage: null,
        isSaving: false,
      });

    case categoryAction.DELETE_CATEGORY_START:
      return updateObject(state, {
        formErrorMessage: null,
        isSaving: true,
      });

    case categoryAction.DELETE_CATEGORY_FAILED:
      return updateObject(state, {
        formErrorMessage: null,
        isSaving: false,
      });

    case categoryAction.DELETE_CATEGORY_SUCCESS:
      return updateObject(state, {
        items: state.items.filter(item => item._id !== action.payload),
        filteredItems: state.filteredItems.filter(item => item._id !== action.payload),
        formErrorMessage: null,
        isSaving: false,
      });

    case categoryAction.UPDATE_CATEGORY_LIST:
      return updateObject(state, { ...action.payload, isSaving: null });

    default:
      return { ...state };
  }
};

export default categories;
