import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { NavItem } from 'reactstrap';
import classnames from 'classnames';

export const TabLink = ({ title, tabId, tabIcon, path, activeTab, toggle }) => {
  return (
    <NavItem>
      <Link
        className={` ${classnames({ active: activeTab === tabId })} nav-link`}
        onClick={() => {
          toggle(tabId);
        }}
        to={`${path}?tab=${tabId}`}
      >
        {tabIcon && <span className={`tw-mr-3 ${tabIcon} tw-text-[2.38rem]`} />}
        <span className="nav-text tw-whitespace-nowrap tw-no-scrollbar">{title}</span>
      </Link>
    </NavItem>
  );
};

TabLink.propTypes = {
  title: PropTypes.string.isRequired,
  activeTab: PropTypes.string.isRequired,
  tabId: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  tabIcon: PropTypes.string,
};
