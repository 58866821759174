
import * as taskManagementTypes from '../types/taskManagementTypes';
import initialState from './initialState';
import { updateObject } from 'utils';

export const taskManagement = (state = initialState.taskManagement, action) => {
  switch (action.type) {
    case taskManagementTypes.FETCH_TASKS_START:
      return updateObject(state, { ...action.payload });
    case taskManagementTypes.FETCH_TASKS_SUCCESS:
      return updateObject(state, { ...action.payload });
    case taskManagementTypes.FETCH_TASKS_FAILURE:
      return updateObject(state, { ...action.payload });

    case taskManagementTypes.ADD_TASK_START:
      return updateObject(state, { isSaving: true });
    case taskManagementTypes.ADD_TASK_SUCCESS:
      return updateObject(state, { isSaving: false });
    case taskManagementTypes.ADD_TASK_FAILURE:
      return updateObject(state, { isSaving: false });

    case taskManagementTypes.SELECT_TASK:
      return updateObject(state, { selected: action.payload })
    case taskManagementTypes.CLEAR_SELECTED_TASK:
      return updateObject(state, { selected: {} });

    case taskManagementTypes.ASSIGN_TASK_START:
      return updateObject(state, { inProgress: true });
    case taskManagementTypes.ASSIGN_TASK_SUCCESS:
      return updateObject(state, {
        selected: {
          ...state.selected,
          assignees: [...action.payload]
        },
        inProgress: false
      });
    case taskManagementTypes.ASSIGN_TASK_FAILURE:
      return updateObject(state, { inProgress: false });

    case taskManagementTypes.UNASSIGN_TASK_START:
      return updateObject(state, { inProgress: true });
    case taskManagementTypes.UNASSIGN_TASK_SUCCESS:
      return updateObject(state, {
        selected: {
          ...state.selected,
          assignees: [...action.payload]
        },
        inProgress: false
      });
    case taskManagementTypes.UNASSIGN_TASK_FAILURE:
      return updateObject(state, { inProgress: false });

    case taskManagementTypes.CHANGE_TASK_STATUS_START:
      return updateObject(state, { isSubmitting: true });

    case taskManagementTypes.START_TASK_SUCCESS:
      return updateObject(state, { isSubmitting: false });
    case taskManagementTypes.CHANGE_TASK_STATUS_FAILURE:
      return updateObject(state, { isSubmitting: false });
    case taskManagementTypes.COMPLETE_TASK_SUCCESS:
      return updateObject(state, { isSubmitting: false });
    case taskManagementTypes.DELIVER_TASK_SUCCESS:
      return updateObject(state,
        { isSubmitting: false }
      );
    case taskManagementTypes.DECLINE_TASK:
      return updateObject(state, { isSubmitting: false });
    case taskManagementTypes.ACCEPT_TASK:
      return updateObject(state, { isSubmitting: false });

    case taskManagementTypes.GET_CLIENTS_START:
      return updateObject(state, { clients: { isLoading: true } });
    case taskManagementTypes.GET_CLIENTS_SUCCESS:
      return updateObject(state, { clients: { ...action.payload, isLoading: false } });
    case taskManagementTypes.GET_CLIENTS_FAILURE:
      return updateObject(state, { clients: { isLoading: false } });

    case taskManagementTypes.ADD_COMMENT_START:
      return updateObject(state, { isSubmitting: true });
    case taskManagementTypes.ADD_COMMENT_SUCCESS:
      return updateObject(state, { isSubmitting: false });
    case taskManagementTypes.ADD_COMMENT_FAILURE:
      return updateObject(state, { isSubmitting: false });

    case taskManagementTypes.ADD_RESULT_START:
      return updateObject(state, { isSubmitting: true });
    case taskManagementTypes.ADD_RESULT_SUCCESS:
      return updateObject(state, { selected: { ...state.selected, result: action.payload }, isSubmitting: false });
    case taskManagementTypes.ADD_RESULT_FAILURE:
      return updateObject(state, { isSubmitting: false });

    case taskManagementTypes.EXTEND_DUE_DATE_START:
      return updateObject(state, { isSubmitting: true });
    case taskManagementTypes.EXTEND_DUE_DATE_SUCCESS:
      return updateObject(state, { selected: { ...state.selected, dueDate: action.payload }, isSubmitting: false });
    case taskManagementTypes.EXTEND_DUE_DATE_FAILURE:
      return updateObject(state, { isSubmitting: false });

    case taskManagementTypes.FETCH_STATUS_BREAKDOWN_START:
      return updateObject(state, { reports: { ...state.reports, loading: true } });
    case taskManagementTypes.FETCH_STATUS_BREAKDOWN_SUCCESS:
      return updateObject(state, { reports: { ...state.reports, statusBreakdown: action.payload, loading: false } });
    case taskManagementTypes.FETCH_STATUS_BREAKDOWN_FAILURE:
      return updateObject(state, { reports: { ...state.reports, loading: false } });

    default:
      return { ...state };
  }
};