import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as EmptySvg2 } from "assets/svg/empty-state-2.svg";
import { Button } from "components/ui/buttons/button";
import Check from "services/Check";

export const EmptyScreen = (props) => {
  const {
    aux,
    title = "No Branches",
    addButtonPermission,
    titleClassName,
    primaryControl: PrimaryControl,
    primaryClick,
    addButtonText = "Add branch",
    secondaryClick,
    subTitle,
    footer = false,
    showImportButton = true,
    showAddButton = true,
  } = props;

  return (
    <div className="settings__empty-content">
      <div className="settings__empty-img">{aux ? <EmptySvg2 /> : ""}</div>
      <p className={`tw-text-[16px] tw-text-natural-700 ${titleClassName}`}>{title}</p>
      <p className={`tw-text-[14px] tw-text-natural-500`}>{subTitle || ""}</p>
      {footer && (
        <Check permission={addButtonPermission}>
          <div className="settings__empty-footer">
            {PrimaryControl ? (
              <PrimaryControl {...props} />
            ) : showAddButton ? (
              <div className="add-action">
                <Button variant="primary" onClick={primaryClick}>
                  {addButtonText}
                </Button>
              </div>
            ) : null}
            {showImportButton && (
              <div className="import-action">
                <Button variant="primary" modifier="outline" onClick={secondaryClick}>
                  Upload list
                </Button>
              </div>
            )}
          </div>
        </Check>
      )}
    </div>
  );
};

EmptyScreen.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  addButtonText: PropTypes.string,
  footer: PropTypes.bool,
  aux: PropTypes.bool,
  titleClassName: PropTypes.string,
  addButtonPermission: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  showAddButton: PropTypes.bool,
  showImportButton: PropTypes.bool,
  primaryClick: PropTypes.func,
  secondaryClick: PropTypes.func,
  primaryControl: PropTypes.func,
};
