/* eslint-disable no-case-declarations */
import * as drugChartTypes from '../types/drugChartTypes';
import initialState from './initialState';
import { updateObject } from 'utils';

const drugChart = (state = initialState.drugChart, { type, payload }) => {
  switch (type) {
    case drugChartTypes.FETCH_DRUG_CHART_START:
      return updateObject(state, { isLoading: true });

    case drugChartTypes.FETCH_DRUG_CHART_SUCCESS:
      return updateObject(state, { isLoading: false, docs: payload });

    case drugChartTypes.FETCH_DRUG_CHART_FAILED:
      return updateObject(state, { isLoading: false });

    case drugChartTypes.ADMINISTER_MEDICATION_SUCCESS:
      const updatedObj = state.docs.map(doc => {
        if (payload && doc._id === payload._id) {
          return {
            ...doc,
            ...payload,
          };
        }
        return doc;
      });
      return updateObject(state, { docs: updatedObj });

    default:
      return { ...state };
  }
};

export default drugChart;
