import React from "react";
import { Modal } from "reactstrap";
import PropTypes from "prop-types";
import { Loader } from "../common";
import { FormInput } from "components/common";
import { Button } from "../ui/buttons/button";

export const ConfirmModal = (props) => {
  const {
    color = "blue",
    negativeText = "No",
    positiveText = "Yes",
    subtitle = "Please acknowledge work order received",
    title = "Accept?",
    size = "lm",
    showFooter = true,
  } = props;
  return (
    <Modal
      centered
      contentClassName={`modal--confirm ${color} `}
      isOpen={props.isOpen}
      size={size}
      toggle={props.toggle}
    >
      <div ref={props.Ref}>
        <div className="modal--confirm__body">
          <h3 className="modal__title with-subtitle">{title}</h3>

          <p className="modal__subtitle">{subtitle}</p>

          {props.deliveryMethod && (
            <FormInput
              type="text"
              id={"recipient"}
              value={props.recipient}
              onChange={(e) => props.handleAttributeChange(e.target.value)}
              placeholder={"Enter recipient's name"}
            />
          )}

          {props.isUpdating && (
            <div className="modal__spinner">
              <Loader />
            </div>
          )}

          {props.children}
        </div>

        {showFooter && (
          <div className="modal--confirm__footer tw-gap-x-6">
            <Button loading={props.isSaving} onClick={props.positiveClick}>
              {positiveText}
            </Button>
            <Button variant="secondary" onClick={props.negativeClick}>
              {negativeText}
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  isSaving: PropTypes.bool,
  isUpdating: PropTypes.bool,
  negativeClick: PropTypes.func,
  negativeText: PropTypes.string,
  positiveClick: PropTypes.func,
  positiveText: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  toggle: PropTypes.func,
  size: PropTypes.string,
  Ref: PropTypes.object,
  recipient: PropTypes.string,
  deliveryMethod: PropTypes.string,
  handleAttributeChange: PropTypes.func,
  showFooter: PropTypes.bool,
};
