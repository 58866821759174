import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

export const InfoBar = ({ children, sideClass, className }) => {
  return (
    <div className={classNames('tw-flex', className)}>
      <span className={classNames('tw-shrink-0 tw-grow-0 tw-w-[5px]', sideClass)}></span>
      {children}
    </div>
  );
};

InfoBar.propTypes = {
  children: PropTypes.node,
  sideClass: PropTypes.string,
  className: PropTypes.string,
};
