import React from "react";
import PropTypes from "prop-types";

import { AuthNav } from "components";

const AuthLayout = (props) => {
  const { children, displayShapes = true } = props;
  return (
    <div className="auth">
      {displayShapes && (
        <>
          <div className="auth__shape circle" />
          <div className="auth__shape square square--1" />
          <div className="auth__shape square square--2" />
        </>
      )}

      <AuthNav />

      <div className={`auth__body tw-z-50 ${props.pageClass || ""}`}>
        <div className="md:tw-max-w-[720px] lg:tw-max-w-[1140px] tw-w-full tw-px-6 md:tw-px-0 tw-mx-auto ">
          <div className="tw-grid tw-grid-cols-12 tw-items-center">{children}</div>
        </div>
      </div>
    </div>
  );
};

AuthLayout.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  displayShapes: PropTypes.bool,
  pageClass: PropTypes.string,
  cardClass: PropTypes.string,
};

export default AuthLayout;
