import { createSlice } from "@reduxjs/toolkit";

const modalSlice = createSlice({
  name: "modal",
  initialState: {
    modals: {}, // Object to keep track of open modals
  },
  reducers: {
    openModal: (state, action) => {
      state.modals[action.payload] = true; // Set modal identifier to true
    },
    closeModal: (state, action) => {
      state.modals[action.payload] = false; // Set modal identifier to false
    },
    closeAllModals: (state) => {
      state.modals = {}; // Clear all modals
    },
  },
});

export const { openModal, closeModal, closeAllModals } = modalSlice.actions;
export default modalSlice.reducer;
