
export const FETCH_FINANCE_SUMMARY_START = 'FETCH_FINANCE_SUMMARY_START';
export const FETCH_FINANCE_SUMMARY_FAIL = 'FETCH_FINANCE_SUMMARY_FAIL';
export const FETCH_FINANCE_SUMMARY_SUCCESS = 'FETCH_FINANCE_SUMMARY_SUCCESS';

export const FETCH_TOP_PRODUCTS_START = 'FETCH_TOP_PRODUCTS_START';
export const FETCH_TOP_PRODUCTS_FAIL = 'FETCH_TOP_PRODUCTS_FAIL';
export const FETCH_TOP_PRODUCTS_SUCCESS = 'FETCH_TOP_PRODUCTS_SUCCESS';

export const FETCH_TOP_SERVICES_START = 'FETCH_TOP_SERVICES_START';
export const FETCH_TOP_SERVICES_FAIL = 'FETCH_TOP_SERVICES_FAIL';
export const FETCH_TOP_SERVICES_SUCCESS = 'FETCH_TOP_SERVICES_SUCCESS';

export const FETCH_TOP_HMO_START = 'FETCH_TOP_HMO_START';
export const FETCH_TOP_HMO_FAIL = 'FETCH_TOP_HMO_FAIL';
export const FETCH_TOP_HMO_SUCCESS = 'FETCH_TOP_HMO_SUCCESS';
