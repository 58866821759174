export const ADD_BRANCH_START = 'ADD_BRANCH_START';
export const ADD_BRANCH_SUCCESS = 'ADD_BRANCH_SUCCESS';
export const ADD_BRANCH_FAILED = 'ADD_BRANCH_FAILED';

export const CHECK_EXISTING_BRANCH = 'CHECK_EXISTING_BRANCH';

export const FETCH_BRANCHES_START = 'FETCH_BRANCHES_START';
export const FETCH_BRANCHES_SUCCESS = 'FETCH_BRANCHES_SUCCESS';
export const FETCH_BRANCHES_FAILED = 'FETCH_BRANCHES_FAILED';

export const BRANCHES_PAGE_SORT = 'BRANCHES_PAGE_SORT';

export const UPDATE_BRANCHES_LIST = 'UPDATE_BRANCHES_LIST';
export const UPDATE_BRANCH_START = 'UPDATE_BRANCH_START';
export const UPDATE_BRANCH_SUCCESS = 'UPDATE_BRANCH_SUCCESS';
export const UPDATE_BRANCH_FAILED = 'UPDATE_BRANCH_FAILED';

export const DELETE_BRANCH_START = 'DELETE_BRANCH_START';
export const DELETE_BRANCH_SUCCESS = 'DELETE_BRANCH_SUCCESS';
export const DELETE_BRANCH_FAILED = 'DELETE_BRANCH_FAILED';

export const UPLOAD_BRANCHES_SUCCESS = 'UPLOAD_BRANCHES_SUCCESS';
