import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SettingsModal } from 'components';
import { BlackListSupplier } from 'modules/inventory/components';

const BlackListSelectedSupplier = ({ ...props }) => {
  const { selected } = props;
  const [state, setState] = useState({
    blackListModal: false,
    entity: {},
  });

  const { entity, blackListModal } = state;

  const triggerBlackListSupplier = useCallback(() => {
    setState({
      ...state,
      viewedSupplier: { ...selected },
      entity: { ...selected },
      blackListModal: !blackListModal,
    });
  }, [state, selected, blackListModal]);

  const toggleEditModal = () => {
    setState({ ...state, blackListModal: !blackListModal, entity: {} });
  };

  useEffect(() => {
    if (props.trigger !== 0) {
      triggerBlackListSupplier();
    }
    // eslint-disable-next-line
  }, [props.trigger]);

  return (
    <>
      {blackListModal && (
        <SettingsModal
          isOpen={blackListModal}
          toggle={toggleEditModal}
          title={'Reason'}
          subtitle={'Why are you blacklisting this supplier?'}
          size="lg"
          component={BlackListSupplier}
          entity={entity}
          cssClass={'modal-reason'}
        />
      )}
    </>
  );
};

BlackListSelectedSupplier.defaultProps = {};

BlackListSelectedSupplier.propTypes = {
  selected: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  trigger: PropTypes.number,
};

export default BlackListSelectedSupplier;
