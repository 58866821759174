/* eslint-disable no-case-declarations */
import * as roomTypes from '../types/roomTypes';
import initialState from 'store/reducers/initialState';
import { updateObject, _sortItems } from 'utils';

export const rooms = (state = initialState.rooms, action) => {
  switch (action.type) {
    case roomTypes.FETCH_ROOMS_START:
      return updateObject(state, {  isLoading : true });

    case roomTypes.FETCH_ROOMS_SUCCESS:
      return updateObject(state, { ...action.payload , isLoading : false });

    case roomTypes.FETCH_ROOMS_FAILED:
      return updateObject(state, { error: action.error , isLoading : false });

    case roomTypes.ADD_ROOM_START:
      return updateObject(state, { isSaving: true });

    case roomTypes.ADD_ROOM_SUCCESS:
      const newRooms = _sortItems([...state.items].concat(action.payload), 'name');
      return updateObject(state, { items: newRooms, filteredItems: newRooms, isSaving: false });

    case roomTypes.ADD_ROOM_FAILED:
      return updateObject(state, {
        isSaving: false,
      });

    case roomTypes.UPDATE_ROOM_START:
      return updateObject(state, { ...action.payload });

    case roomTypes.UPDATE_ROOM_SUCCESS:
      const updated = [...state.items].map(prev =>
        String(prev._id) === String(action.payload._id) ? action.payload : prev,
      );
      return updateObject(state, {
        items: updated,
        filteredItems: updated,
        isSaving: false,
        isDeactivating: false,
      });

    case roomTypes.UPDATE_ROOM_FAILED:
      return updateObject(state, {
        isSaving: false,
        isDeactivating: false,
      });

    case roomTypes.UPDATE_ROOMS_LIST:
      return updateObject(state, action.payload);

    default:
      return { ...state };
  }
};
