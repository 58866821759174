import React from 'react';
import PropTypes from 'prop-types';

export const TextArea = ({ cols, rows, className, label, ...props }) => (
  <div className={`form-area ${className}`}>
    <label htmlFor={props.id}>
      {label}
      <span style={{ color: 'red' }}>{props.isRequired ? '*' : ''}</span>
    </label>
    <textarea cols={cols} rows={rows} {...props} />
  </div>
);

TextArea.defaultProps = {
  className: '',
  cols: '30',
  rows: '8',
};

TextArea.propTypes = {
  className: PropTypes.string,
  cols: PropTypes.string,
  rows: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  isRequired: PropTypes.bool,
};
