import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const getDate = value => {
  if (value) {
    const date = moment(value);
    return {
      day: date.format('DD'),
      month: date.format('MM'),
      year: date.format('yyyy'),
    };
  }
  return {
    day: '',
    month: '',
    year: '',
  };
};

const SplitDateInput = ({ onChange, placeholder, value }) => {
  const [state, setState] = useState(() => getDate(value));

  useEffect(() => {
    setState(getDate(value));
  }, [value]);

  const { day, month, year } = state;

  const handleChange = e => {
    const newState = { ...state, [e.target.name]: e.target.value };
    setState(newState);
    if (newState.year && newState.month && newState.day) {
      onChange(`${newState.year}-${newState.month}-${newState.day}`);
    }
  };

  return (
    <span className="surgery_consent" style={{ whiteSpace: 'nowrap' }}>
      <input
        name="day"
        style={{ width: 34 }}
        value={day}
        onChange={handleChange}
        placeholder={placeholder ? 'dd' : ''}
      />
      /
      <input
        name="month"
        style={{ width: 34 }}
        value={month}
        onChange={handleChange}
        placeholder={placeholder ? 'mm' : ''}
      />
      /
      <input
        name="year"
        style={{ width: 54 }}
        value={year}
        onChange={handleChange}
        placeholder={placeholder ? 'yyyy' : ''}
      />
    </span>
  );
};

SplitDateInput.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.bool,
  value: PropTypes.string,
};

export default SplitDateInput;
