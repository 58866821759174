export const FETCH_APPOINTMENT_PURPOSES_START =
  'FETCH_APPOINTMENT_PURPOSES_START';
export const FETCH_APPOINTMENT_PURPOSES_SUCCESS =
  'FETCH_APPOINTMENT_PURPOSES_SUCCESS';
export const FETCH_APPOINTMENT_PURPOSES_FAILED =
  'FETCH_APPOINTMENT_PURPOSES_FAILED';

export const ADD_APPOINTMENT_PURPOSE_START = 'ADD_APPOINTMENT_PURPOSE_START';
export const ADD_APPOINTMENT_PURPOSE_SUCCESS =
  'ADD_APPOINTMENT_PURPOSE_SUCCESS';
export const ADD_APPOINTMENT_PURPOSE_FAILED = 'ADD_APPOINTMENT_PURPOSE_FAILED';

export const UPDATE_APPOINTMENT_PURPOSE_START =
  'UPDATE_APPOINTMENT_PURPOSE_START';
export const UPDATE_APPOINTMENT_PURPOSE_SUCCESS =
  'UPDATE_APPOINTMENT_PURPOSE_SUCCESS';
export const UPDATE_APPOINTMENT_PURPOSE_FAILED =
  'UPDATE_APPOINTMENT_PURPOSE_FAILED';

export const UPDATE_APPOINTMENT_PURPOSE_LIST =
  'UPDATE_APPOINTMENT_PURPOSE_LIST';

export const DEACTIVATE_APPOINTMENT_PRURPOSE_START =
  'DEACTIVATE_APPOINTMENT_PRURPOSE_START';

export const DEACTIVATE_APPOINTMENT_PRURPOSE_SUCCESS =
  'DEACTIVATE_APPOINTMENT_PRURPOSE_SUCCESS';

export const DEACTIVATE_APPOINTMENT_PRURPOSE_FAILED =
  'DEACTIVATE_APPOINTMENT_PRURPOSE_FAILED';
