import initialState from './initialState';
import * as types from '../types/nursingNotesRecordsTypes';
import { updateObject } from 'utils';

const nursingNotesRecords = (state = initialState.nursingNotesRecords, { type, payload }) => {
  switch (type) {
    case types.FETCH_NURSING_NOTES_RECORDS_START:
      return updateObject(state, { isLoading: true });
    case types.FETCH_NURSING_NOTES_RECORDS_SUCCESS:
      return updateObject(state, { isLoading: false, items: payload });
    case types.FETCH_NURSING_NOTES_RECORDS_END:
      return updateObject(state, { isLoading: false, items: [] });
    default:
      return state;
  }
};
export default nursingNotesRecords