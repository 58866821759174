import React, { useState } from 'react';
import { ReactComponent as FilterSvg } from 'assets/svg/filter.svg';
import PropTypes from 'prop-types';

export const Filter = (props) => {
  const { otherFilter: OtherFilter } = props;

  const [showPrimary, setShowPrimary] = useState(false);
  const [showSecondary, setShowSecondary] = useState(false);

  const _closeAll = () => {
    setShowPrimary(false);
    setShowSecondary(false);
  };

  const handleFilterOption = (param) => {
    if (param === 'up' || param === 'down') {
      props.sortTable(param);
      setShowPrimary(false);
      setShowSecondary(false);
    } else {
      props.setFilterParam(param);
      setShowSecondary(true);
    }
  };
  const handleSecondaryFilter = (param) => {
    props.filterValue(param);
    setShowPrimary(false);
    setShowSecondary(false);
  };

  return (
    <div className="tw-flex align-items-center">
      {OtherFilter && (
        <OtherFilter
          handleSecondaryFilter={handleSecondaryFilter}
          handleFilterOption={props.setFilterParam}
          options={props.secondary}
        />
      )}

      <div onMouseEnter={() => setShowPrimary(true)} onMouseLeave={_closeAll} className="filter-action">
        <FilterSvg />
        <span className="filter-action__title">{props.title || 'filter'}</span>
        {showPrimary && props.defaultSort ? (
          <div className="options options-primary">
            <li onClick={() => handleFilterOption('up')}>ascending</li>
            <li onClick={() => handleFilterOption('down')}>descending</li>
          </div>
        ) : showPrimary && props.primary.length ? (
          <div className="options options-primary">
            {props.showDefaultOptions && (
              <>
                <li onClick={() => handleFilterOption('up')}>ascending</li>
                <li onClick={() => handleFilterOption('down')}>descending</li>
              </>
            )}
            {props.primary.map((option) => (
              <li key={option} onClick={() => handleFilterOption(option)}>
                {option}
              </li>
            ))}
          </div>
        ) : showPrimary && props.hasForm ? (
          <div className="filter-form">{props.children}</div>
        ) : null}
        {showSecondary && props.secondary.length ? (
          <div className="options options-secondary">
            {props.secondary.map((option) => (
              <li
                key={option}
                title={option}
                aria-hidden="true"
                tabIndex="-1"
                onClick={() => handleSecondaryFilter(option)}
              >
                {option}
              </li>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

Filter.defaultProps = {
  primary: [],
  secondary: [],
  defaultSort: true,
  showDefaultOptions: true,
};

Filter.propTypes = {
  primary: PropTypes.array,
  title: PropTypes.string,
  secondary: PropTypes.array,
  defaultSort: PropTypes.bool,
  showDefaultOptions: PropTypes.bool,
  hasForm: PropTypes.bool,
  sortTable: PropTypes.func,
  filterValue: PropTypes.func,
  otherFilter: PropTypes.func,
  setFilterParam: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
