import React, { lazy, useEffect } from "react";
import { Redirect, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import MainLayout from "../../../layouts/MainLayout";
import AuthRoute from "../../../routes/AuthRoute";
import { getSerializedParams } from "utils";
import {
  fetchBranches,
  fetchDepartments,
  fetchOrganizationCustomization,
  fetchSponsors,
  fetchStaff,
} from "store/actions";
import { fetchAppointmentPurposes, fetchBranchesConfiguration, fetchPlans } from "../../settings/redux/actions";
import { getProducts } from "../../inventory/redux/actions";

const Dashboard = lazy(() => import("../pages/Dashboard"));
const DashboardListing = lazy(() => import("../../patients/pages/Patients/Listing"));
const PatientsList = lazy(() => import("../pages/Patients/PatientsList"));
const PatientDetailsRoutes = lazy(() => import("../pages/Patients"));
const PharmacyPrescription = lazy(() => import("../pages/Prescription"));
const Insight = lazy(() => import("../pages/Insight"));
const Retail = lazy(() => import("../pages/Retail"));
const DispensedDrugReport = lazy(() => import("../pages/Insight/DrugReport"));
const RecallPatient = lazy(() => import("../pages/Prescription/Recall/RecalledItemsPatients"));

const ErrorPage = lazy(() => import("pages/Error"));

const PharmacyRoutes = ({ match }) => {
  const { loggedIn } = useSelector((state) => state.auth);
  const { current } = useSelector((state) => state.user);
  // TODO check accessGranted value using the user permitted modules for the current branch
  // TODO const hasAccess = hasModuleAccess(current?.location?.modules, 'pharmacy');

  const dispatch = useDispatch();

  useEffect(() => {
    if (current && current.parentOrganizationId) {
      dispatch(fetchSponsors(current.parentOrganizationId));
      dispatch(fetchDepartments(current.parentOrganizationId));
      dispatch(fetchStaff(current.value));
    }
  }, [dispatch, current]);

  useEffect(() => {
    dispatch(fetchAppointmentPurposes());
    dispatch(fetchPlans());
    dispatch(fetchBranches());
    dispatch(fetchOrganizationCustomization());
    dispatch(fetchBranchesConfiguration());
    dispatch(getProducts(getSerializedParams({ limit: 10000 }), current?.branchId || undefined));
  }, []);

  return (
    <>
      <Switch>
        <Redirect exact from={`${match.path}`} to={`${match.path}/dashboard`} />

        <AuthRoute
          exact
          path={`${match.path}/dashboard`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={Dashboard}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/dashboard/patients/:service`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          exact
          component={DashboardListing}
          accessGranted={true}
        />

        <AuthRoute
          exact
          path={`${match.path}/patients`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={PatientsList}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/patients/:id`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={PatientDetailsRoutes}
          accessGranted={true}
        />

        <AuthRoute
          exact
          path={`${match.path}/sales`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={PharmacyPrescription}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/sales/:batchId/patients`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={RecallPatient}
          accessGranted={true}
        />

        <AuthRoute
          exact
          path={`${match.path}/retail`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={Retail}
          accessGranted={true}
        />

        <AuthRoute
          exact
          path={`${match.path}/insight`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={Insight}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/insight/dispensed-drugs`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={DispensedDrugReport}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/insight/expired-drugs`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={DispensedDrugReport}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/insight/stock`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={DispensedDrugReport}
          accessGranted={true}
        />

        <AuthRoute path="*" isAuthenticated={loggedIn} layout={MainLayout} component={ErrorPage} accessGranted={true} />
      </Switch>
    </>
  );
};

PharmacyRoutes.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default PharmacyRoutes;
