/* eslint-disable max-len */
/* eslint-disable no-case-declarations */
import * as inventoryTypes from '../types/inventoryTypes';
import initialState from './initialState';
import { updateObject } from 'utils';

const inventory = (state = initialState.inventoryServices, action) => {
  switch (action.type) {
    case inventoryTypes.GET_INVENTORY_SERVICES_START:
      return updateObject(state, { isLoading: true });

    case inventoryTypes.GET_INVENTORY_SERVICES_SUCCESS:
      return updateObject(state, { ...action.payload, isLoading: false });

    case inventoryTypes.GET_INVENTORY_SERVICES_FAILURE:
      return updateObject(state, { message: action.payload, isLoading: false });

    case inventoryTypes.ADD_INVENTORY_SERVICE_START:
      return updateObject(state, { isSaving: true });

    case inventoryTypes.ADD_INVENTORY_SERVICE_FAILED:
      return updateObject(state, { isSaving: false });

    case inventoryTypes.ADD_INVENTORY_SERVICE_SUCCESS:
      let inventoryServicesCopy = { ...state };
      inventoryServicesCopy = {
        ...inventoryServicesCopy,
        docs: [...inventoryServicesCopy.docs].concat(action.payload),
      };
      return updateObject(state, { ...inventoryServicesCopy, isSaving: false });

    case inventoryTypes.UPDATE_INVENTORY_SERVICE_START:
      return updateObject(state, { isSaving: true });

    case inventoryTypes.UPDATE_INVENTORY_SERVICE_FAILED:
      return updateObject(state, { isSaving: false });

    case inventoryTypes.UPDATE_INVENTORY_SERVICE_SUCCESS:
      const existingInventoryServices = { ...state };
      existingInventoryServices.docs = [...existingInventoryServices.docs].map(doc => {
        if (doc._id === action.payload.itemId) {
          const current = { ...doc, ...action.payload.data };
          return current;
        }
        return doc;
      });
      return updateObject(state, { ...existingInventoryServices, isSaving: false });

    case inventoryTypes.ARCHIVE_INVENTORY_SERVICE_SUCCESS:
      const { items: archivedList } = action.payload;
      return { ...state, docs: state.docs.filter(item => !archivedList.includes(item._id)) };

    case inventoryTypes.ACTIVATE_INVENTORY_SERVICE_SUCCESS:
      return { ...state, docs: state.docs.filter(item => item._id !== action.payload.id) };

    default:
      return { ...state };
  }
};

export default inventory;
