import React from "react";
import PropTypes from "prop-types";
import { Button } from "components/ui/buttons/button";
import { Modal } from "./index";
import { UserCard } from "components/usercard";

const Footer = (props) => (
  <div className="tw-flex tw-justify-center tw-items-center tw-gap-x-5">
    <Button
      disabled={props.leftButtonDisabled}
      onClick={props.leftButtonPress}
      className="modal__btn modal__btn--dispatch"
    >
      Dispatch
    </Button>
    <Button
      variant="primary"
      disabled={props.rightButtonDisabled}
      onClick={props.rightButtonPress}
      className="modal__btn modal__btn--complete"
    >
      Pickup
    </Button>
  </div>
);

Footer.propTypes = {
  leftButtonPress: PropTypes.func,
  rightButtonPress: PropTypes.func,
  leftButtonDisabled: PropTypes.bool,
  rightButtonDisabled: PropTypes.bool,
};

export const CompletedModal = ({ ...props }) => {
  return (
    <Modal {...props} color="green" footer={<Footer {...props} />} showComment>
      <div className="modal__list__item">
        {props.assignees && props.assignees.length ? (
          <>
            <p className="modal__list__text modal__list__text--1">Assignees</p>
            <div className="modal__list__text modal__list__text--2">
              <div className="row">
                {props.assignees.map(
                  (ls) =>
                    ls.name && (
                      <div className="col-sm-6" key={ls._id}>
                        <UserCard label {...ls} />
                      </div>
                    ),
                )}
              </div>
            </div>
          </>
        ) : null}
      </div>
    </Modal>
  );
};

CompletedModal.defaultProps = {
  assignees: [],
};

CompletedModal.propTypes = {
  assignees: PropTypes.array,
};
