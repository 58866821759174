export const ADD_NOTE_START = 'ADD_NOTE_START';
export const ADD_NOTE_END = 'ADD_NOTE_END';

export const FETCH_PROGRESS_NOTES_SUCCESS = 'FETCH_PROGRESS_NOTES_SUCCESS';
export const ADD_PROGRESS_NOTE_SUCCESS = 'ADD_PROGRESS_NOTE_SUCCESS';
export const UPDATE_PROGRESS_NOTE_SUCCESS = 'UPDATE_PROGRESS_NOTE_SUCCESS';

export const FETCH_PHYSICIAN_PROGRESS_NOTES_SUCCESS = 'FETCH_PHYSICIAN_PROGRESS_NOTES_SUCCESS';
export const ADD_PHYSICIAN_PROGRESS_NOTE_SUCCESS = 'ADD_PHYSICIAN_PROGRESS_NOTE_SUCCESS';
export const ADD_PHYSICIAN_NOTE_START = 'ADD_PHYSICIAN_NOTE_START';
export const ADD_PHYSICIAN_NOTE_END = 'ADD_PHYSICIAN_NOTE_END';
