import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "services/baseQuery";
import { _notifyError, _notifySuccess, _sortItems } from "utils";
import { closeAllModals } from "store/features/modalSlice";

export const appointmentPurposeApi = createApi({
  reducerPath: "appointmentPurposeApi",
  baseQuery,
  tagTypes: ["Appointment-Purpose"],
  endpoints: (builder) => ({
    getAppointmentPurpose: builder.query({
      query: () => "/appointment-purposes",
      transformResponse: (response) => _sortItems([...response.data], "name"),
      providesTags: ["Appointment-Purpose"],
    }),
    updateAppointmentPurpose: builder.mutation({
      query: ({ _id, ...rest }) => ({
        url: `/appointment-purposes/${_id}`,
        method: "PATCH",
        body: { ...rest },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          _notifySuccess(`Success : ${response.data.message}`);
          dispatch(closeAllModals());
        } catch (err) {
          _notifyError(`Error : ${err.error.data.message}`);
        }
      },
      invalidatesTags: ["Appointment-Purpose"],
    }),
  }),
});

export const { useGetAppointmentPurposeQuery, useUpdateAppointmentPurposeMutation } = appointmentPurposeApi;
