import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import classnames from 'classnames';
import moment from 'moment';
import { TIMEOUTS } from '../utils';
import { Button } from './ui/buttons/button';

('');

const IdlePopUp = ({ isOpen, onClose, toggle }) => {
  const [time, setTime] = useState('30:00');

  const getRemainingTime = () => {
    const duration = moment.duration({ hours: 0, minutes: 0, seconds: 1 });
    const newTime = moment(time, 'mm:ss').subtract(duration).format('mm:ss');
    setTime(newTime);
  };

  const mounted = useRef(0);

  useEffect(() => {
    if (isOpen) {
      if (time === '00:00') {
        mounted.current && clearInterval(mounted.current);
        onClose?.();
        return;
      }

      mounted.current = setInterval(() => getRemainingTime(), TIMEOUTS.ONE_SEC);
    }

    return () => {
      mounted.current && clearTimeout(mounted.current);
    };
  }, [isOpen, time]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md" centered backdropClassName="backdrop-style">
      <div className="tw-flex justify-content-center align-items-end text-center p-5" style={{ minHeight: `394px` }}>
        <div className="pb-5">
          <h3 className="fs-24 fw-900 mb-2">Your session is about to expire</h3>
          <p className="fs-16 fw-450 text-muted" style={{ marginBottom: `75px` }}>
            You will be logged out in <span className="text-dark">{time}</span>
          </p>
          <p className="fs-16 fw-500 text-muted mb-4">Do you want to stay logged in?</p>
          <div className="tw-flex flex-column flex-md-row  align-items-center justify-content-center">
            <Button
              variant="primary"
              className={classnames('btn btn__utility mr-3 mb-3 mb-md-0', {
                btn__loading: false,
              })}
              loading={false}
              onClick={onClose}
            >
              No, Logout
            </Button>

            <Button
              variant="primary"
              className={classnames('btn btn__secondary ', {
                btn__loading: false,
              })}
              loading={false}
              onClick={() => {
                toggle();
                mounted.current && clearInterval(mounted.current);
              }}
            >
              Yes, Stay logged in
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

IdlePopUp.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  toggle: PropTypes.func,
};

export default IdlePopUp;
