import initialState from 'store/reducers/initialState';
import * as appOptionTypes from '../types/appOptionTypes';
import { updateObject } from 'utils';

const optionsReducer = (state = initialState.appOptions, { type, payload }) => {
  switch (type) {
    case appOptionTypes.FETCH_APP_OPTIONS_SUCCESS:
      return updateObject(state, { ...state.appOptions, items: payload, loading: false });

    case appOptionTypes.FETCH_APP_OPTIONS_FAILURE:
      return updateObject(state);

    default:
      return { ...state };
  }
};

export default optionsReducer;
