export const FUND_WALLET_START = 'FUND_WALLET_START';
export const FUND_WALLET_SUCCESS = 'FUND_WALLET_SUCCESS';
export const FUND_WALLET_FAILURE = 'FUND_WALLET_FAILURE';

export const CONFIRM_FUNDS_TRANSFER_START = 'CONFIRM_FUNDS_TRANSFER_START';
export const CONFIRM_FUNDS_TRANSFER_SUCCESS = 'CONFIRM_FUNDS_TRANSFER_SUCCESS';
export const CONFIRM_FUNDS_TRANSFER_FAILURE = 'CONFIRM_FUNDS_TRANSFER_FAILURE';

export const RESEND_TRANSFER_OTP_START = 'RESEND_TRANSFER_OTP_START';
export const RESEND_TRANSFER_OTP_FAIL = 'RESEND_TRANSFER_OTP_FAIL';
export const RESEND_TRANSFER_OTP_SUCCESS = 'RESEND_TRANSFER_OTP_SUCCESS';

export const INITIATE_FUNDS_TRANSFER_START = 'INITIATE_FUNDS_TRANSFER_START';
export const INITIATE_FUNDS_TRANSFER_SUCCESS = 'INITIATE_FUNDS_TRANSFER_SUCCESS';
export const INITIATE_FUNDS_TRANSFER_FAILURE = 'INITIATE_FUNDS_TRANSFER_FAILURE';

export const INITIATE_WITHDRAWAL_START = 'INITIATE_WITHDRAWAL_START';
export const INITIATE_WITHDRAWAL_SUCCESS = 'INITIATE_WITHDRAWAL_SUCCESS';
export const INITIATE_WITHDRAWAL_FAILURE = 'INITIATE_WITHDRAWAL_FAILURE';

export const CONFIRM_WITHDRAWAL_REQUEST_START = 'CONFIRM_WITHDRAWAL_REQUEST_START';
export const CONFIRM_WITHDRAWAL_REQUEST_FAIL = 'CONFIRM_WITHDRAWAL_REQUEST_FAIL';
export const CONFIRM_WITHDRAWAL_REQUEST_SUCCESS = 'CONFIRM_WITHDRAWAL_REQUEST_SUCCESS';

export const PROCESS_WITHDRAWAL_START = 'PROCESS_WITHDRAWAL_START';
export const PROCESS_WITHDRAWAL_FAIL = 'PROCESS_WITHDRAWAL_FAIL';
export const PROCESS_WITHDRAWAL_SUCCESS = 'PROCESS_WITHDRAWAL_SUCCESS';

export const FETCH_ACCOUNT_STATEMENT_START = 'FETCH_ACCOUNT_STATEMENT_START';
export const FETCH_ACCOUNT_STATEMENT_FAIL = 'FETCH_ACCOUNT_STATEMENT_FAIL';
export const FETCH_ACCOUNT_STATEMENT_SUCCESS = 'FETCH_ACCOUNT_STATEMENT_SUCCESS';
