/* eslint-disable no-case-declarations */
import * as nursingNoteTypes from '../types/nursingNoteTypes';
import initialState from './initialState';
import { updateObject } from 'utils';

const nursingNotes = (state = initialState.nursingNotes, { type, payload }) => {
  switch (type) {
    case nursingNoteTypes.ADD_NOTE_START:
      return updateObject(state, { isSaving: true });

    case nursingNoteTypes.ADD_NOTE_END:
      return updateObject(state, { isSaving: false });

    case nursingNoteTypes.FETCH_SOAP_NOTES_SUCCESS:
      return updateObject(state, { soapNoteItems: payload });

    case nursingNoteTypes.ADD_SOAP_NOTE_SUCCESS:
      return updateObject(state, { soapNoteItems: (state.soapNoteItems || []).concat(payload), isSaving: false });

    case nursingNoteTypes.FETCH_PROGRESS_NOTES_SUCCESS:
      return updateObject(state, { progressNoteItems: payload });

    case nursingNoteTypes.ADD_PROGRESS_NOTE_SUCCESS:
      return updateObject(state, {
        progressNoteItems: (state.progressNoteItems || []).concat(payload),
        isSaving: false,
      });

    case nursingNoteTypes.FETCH_NURSING_NOTES_SUCCESS:
      return updateObject(state, { nursingNoteItems: payload });

    case nursingNoteTypes.ADD_NURSING_NOTE_SUCCESS:
      return updateObject(state, { nursingNoteItems: (state.nursingNoteItems || []).concat(payload), isSaving: false });

    case nursingNoteTypes.FETCH_OBSERVATION_CHART_SUCCESS:
      return updateObject(state, { observationChartItems: payload });

    case nursingNoteTypes.ADD_OBSERVATION_CHART_SUCCESS:
      return updateObject(state, {
        observationChartItems: (state.observationChartItems || []).concat(payload),
        isSaving: false,
      });

    case nursingNoteTypes.FETCH_CONTINUOUS_ASSESSMENTS_SUCCESS:
      return updateObject(state, { continuousAssessmentItems: payload });

    case nursingNoteTypes.ADD_CONTINUOUS_ASSESSMENTS_SUCCESS:
      return updateObject(state, {
        continuousAssessmentItems: (state.continuousAssessmentItems || []).concat(payload),
        isSaving: false,
      });

    case nursingNoteTypes.FETCH_FLUID_BALANCE_SUCCESS:
      return updateObject(state, { fluidBalanceItems: payload });

    case nursingNoteTypes.ADD_FLUID_BALANCE_SUCCESS:
      return updateObject(state, {
        fluidBalanceItems: (state.fluidBalanceItems || []).concat(payload),
        isSaving: false,
      });

    case nursingNoteTypes.FETCH_WARD_ROUNDS_SUCCESS:
      return updateObject(state, { wardRoundItems: payload });

    case nursingNoteTypes.ADD_WARD_ROUNDS_SUCCESS:
      return updateObject(state, {
        wardRoundItems: (state.wardRoundItems || []).concat(payload),
        isSaving: false,
      });

    case nursingNoteTypes.FETCH_ASSESSMENTS_SUCCESS:
      return updateObject(state, { assessmentFormItems: payload });

    case nursingNoteTypes.ADD_ASSESSMENTS_SUCCESS:
      return updateObject(state, {
        isSaving: false,
      });

    case nursingNoteTypes.ADD_IV_FLUID_SUCCESS:
      return updateObject(state, {
        isSaving: false,
      });
    case nursingNoteTypes.FETCH_IV_FLUID_SUCCESS:
      return updateObject(state, { ivFluidItems: payload });
    default:
      return { ...state };
  }
};

export default nursingNotes;
