import React, { Suspense } from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PageLoader } from 'components';

const AuthRoute = ({ component: Component, isAuthenticated, layout: Layout, accessGranted, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (isAuthenticated && accessGranted) {
        return (
          <Suspense fallback={<PageLoader />}>
            <Layout {...props}>
              <Component {...props} />
            </Layout>
          </Suspense>
        );
      }
      if (isAuthenticated) {
        return (
          <Redirect
            to={{
              pathname: '/select-branch',
              state: { from: props.location.pathname },
            }}
          />
        );
      }
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location.pathname },
          }}
        />
      );
    }}
  />
);

AuthRoute.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  layout: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  isAuthenticated: PropTypes.bool,
  accessGranted: PropTypes.bool,
};

export default AuthRoute;
