import * as userTypes from '../types/userTypes';
import initialState from 'store/reducers/initialState';

const userReducer = (state = initialState.user, action) => {
  switch (action.type) {
    case userTypes.SET_USER_DATA_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case userTypes.MODIFY_USER_DATA:
      return {
        ...state,
        current: {
          ...state.current,
          ...action.payload,
        },
      };
    case userTypes.SET_USER_DATA_FAILURE:
      return {
        ...state,
      };
    case userTypes.SET_CURRENT_BRANCH:
      return {
        ...state,
        current: action.payload,
      };
    case userTypes.SET_CURRENT_BRANCH_FAILURE:
      return {
        ...state,
      };
    case userTypes.FETCH_USER_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case userTypes.FETCH_USER_FAILURE:
      return {
        ...state,
      };
    case userTypes.INITIATE_USER_LOGOUT:
      return {
        ...state,
        isLoggingOut: true,
      };
    case userTypes.FINSIH_USER_LOGOUT:
      return {
        ...state,
        ...(action.payload.error ? { isLoggingOut: false } : {}),
      };
    default:
      return state;
  }
};

export default userReducer;
