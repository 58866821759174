import * as diagnosisTypes from '../types/diagnosisTypes';
import initialState from 'store/reducers/initialState';
import { updateObject } from 'utils';

export const diagnosis = (state = initialState.suppliers, action) => {
  switch (action.type) {
    case diagnosisTypes.FETCH_DIAGNOSIS_SUCCESS:
      return updateObject(state, { ...action.payload, loading: false });

    case diagnosisTypes.FETCH_DIAGNOSIS_FAILED:
      return updateObject(state, { error: action.error });

    case diagnosisTypes.FETCH_DIAGNOSIS_START:
      return updateObject(state, { formErrorMessage: null, isSaving: true });

    case diagnosisTypes.UPDATE_DIAGNOSIS_LIST:
      return updateObject(state, { ...action.payload, isSaving: null });

    default:
      return { ...state };
  }
};
