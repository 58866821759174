import React, { useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Modal as BtModal } from "reactstrap";

import { ReactComponent as Close } from "assets/svg/close.svg";
import { ReactComponent as Comment } from "assets/svg/comment.svg";
import { CountLister } from "components/popups/Countlister";
import { Loader } from "modules/laboratory/components/InfiniteScroll";
import { format, isBefore, startOfDay } from "date-fns";
import { Popover, PopoverDialogContent, PopoverTrigger } from "components/ui/popover";
import { Button } from "components/ui/buttons/button";
import { cn } from "utils";
import Required from "components/required";
import { Calendar } from "components/ui/calendar";
import { ViewLabCommentModal } from "components/modal/viewLabComments";

function DateInput({ onDayChange, value, label, ...props }) {
  return (
    <Popover modal={true} className="tw-relative tw-w-full">
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "md:tw-w-full tw-flex tw-h-[48px] tw-items-center tw-border-[#CED6DE] tw-border tw-justify-start " +
              "tw-text-left tw-font-normal tw-px-0",
            !value && "text-muted-foreground",
          )}
        >
          <div className="tw-flex tw-items-end tw-justify-between tw-w-full tw-px-8">
            <div className="">
              <p className="tw-text-natural-500 tw-text-[12px] tw-mb-0.5">
                {label} {props.isRequired && <Required />}
              </p>
              {value ? (
                format(value, "PPP")
              ) : (
                <span className="tw-text-natural-400">{props.placeholder || "DD / MM / YYYY"}</span>
              )}
            </div>

            <i className="iconmoon icon-calendar tw-mb-1.5" />
          </div>
        </Button>
      </PopoverTrigger>

      <PopoverDialogContent className="tw-w-auto">
        <Calendar
          mode="single"
          selected={value}
          defaultMonth={value}
          onSelect={(e) => {
            onDayChange(e);
          }}
          initialFocus
          {...props}
        />
      </PopoverDialogContent>
    </Popover>
  );
}

export const Modal = (props) => {
  const { entity = {}, showDeadline = false } = props;
  const [viewCommentModal, setViewCommentModal] = useState(false);
  const [selectedResult, setSelectedResult] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);

  const toggleViewCommentModal = () => {
    setViewCommentModal(!viewCommentModal);
  };

  const showLabComments = (result) => {
    setSelectedResult(result);
    setViewCommentModal(true);
  };

  const disablePastDays = (day) => {
    const today = startOfDay(new Date());
    return isBefore(day, today);
  };

  const services = entity.services || [];
  const firstService = services?.[0] || {};

  return (
    <>
      <BtModal
        isOpen={props.isOpen}
        contentClassName={`${props.color}`}
        toggle={props.toggle}
        onClosed={() => setSelectedDate(null)}
      >
        <div className="modal__header">
          <div className="container-fluid">
            <div className="modal__header--inner">
              <div className="modal__header--text">
                {entity.patient && <h3 className="modal__header__title">{entity.patient.name}</h3>}

                {entity.createdAt && (
                  <h4 className="modal__header__subtitle">{new Date(entity.createdAt).toDateString()}</h4>
                )}
              </div>

              <div className="modal__header--icon">
                {props.showComment && (
                  <div className="comment" onClick={props.onCommentClick}>
                    <Comment width={25} height={25} />
                    <span>comment</span>
                  </div>
                )}

                <Close style={{ cursor: "pointer" }} onClick={props.toggle} color="#fa4659" strokeWidth={1} />
              </div>
            </div>
          </div>
        </div>

        <div className={classnames("modal__body")}>
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <div className="modal__list">
                  {entity.orderId && (
                    <div className="modal__list__item">
                      <p className="modal__list__text modal__list__text--1">Order ID</p>

                      <div className="modal__list__text modal__list__text--2">{entity.orderId}</div>
                    </div>
                  )}

                  {entity.company && (
                    <div className="modal__list__item">
                      <>
                        <p className="modal__list__text modal__list__text--1">Company name</p>

                        <div className="modal__list__text modal__list__text--2">{entity.company.name}</div>
                      </>
                    </div>
                  )}
                  {entity?.comments?.length > 0 && (
                    <div className="modal__list__item">
                      <>
                        <p className="modal__list__text modal__list__text--1">Consultant name</p>

                        <div className="modal__list__text modal__list__text--2">
                          {entity?.comments[0]?.author?.name}
                        </div>
                      </>
                    </div>
                  )}

                  {services.length > 0 && (
                    <div className="modal__list__item">
                      <p className="modal__list__text modal__list__text--1">Service type</p>

                      <div className="modal__list__text modal__list__text--2">
                        {firstService?.name} &nbsp;
                        {services.length > 0 && <CountLister listOptions={services} />}
                      </div>
                    </div>
                  )}
                  {entity.createdAt && (
                    <div className="modal__list__item">
                      <>
                        <p className="modal__list__text modal__list__text--1">Created At</p>

                        <div className="modal__list__text modal__list__text--2">
                          {new Date(entity.createdAt).toDateString()}
                        </div>
                      </>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-sm-6">
                {entity?.result?.files?.length ? (
                  <div className="modal__task-img">
                    {entity.result.files.map((file, index) => (
                      <React.Fragment key={index}>
                        <a href={file} target="_blank" rel="noreferrer">
                          View Test Result &nbsp; {entity.files.length > 1 && index + 1}{" "}
                        </a>
                        <br />
                      </React.Fragment>
                    ))}
                  </div>
                ) : (
                  <div className="modal__task-img">
                    {entity.files?.length
                      ? entity.files.map((file, index) => (
                          <React.Fragment key={index}>
                            <a href={file} target="_blank" rel="noreferrer noopener">
                              View Attachement &nbsp; {entity.files.length > 1 && index + 1}{" "}
                            </a>
                            <br />
                          </React.Fragment>
                        ))
                      : null}
                  </div>
                )}
                {entity?.results?.length > 0 && (
                  <div className="modal__task-img">
                    {entity.results.map((result, index) => (
                      <React.Fragment key={index}>
                        <span onClick={() => showLabComments(result)}>
                          View Test Result&nbsp; {entity.results.length > 1 && index + 1}{" "}
                        </span>
                        <br />
                      </React.Fragment>
                    ))}
                  </div>
                )}
              </div>
              <ViewLabCommentModal
                color="grey"
                isOpen={viewCommentModal}
                toggle={toggleViewCommentModal}
                comments={selectedResult}
                closeClick={toggleViewCommentModal}
              />
            </div>

            <div className="modal__list__item">
              <p className="modal__list__text modal__list__text--1">due date </p>
              <div className="modal__list__text modal__list__text--2 mb-3">
                {props.isSubmitting ? <Loader /> : entity.dueDate ? new Date(entity.dueDate).toDateString() : "---"}
              </div>
              {props.allowDateEdit && (
                <div className="modal__day-picker">
                  <DateInput
                    mode="single"
                    disabled={disablePastDays}
                    value={selectedDate}
                    placeholder="Click here to adjust due date"
                    onDayChange={(e) => {
                      props.changeDueDate(e);
                      setSelectedDate(e);
                    }}
                  />
                </div>
              )}
            </div>

            {showDeadline && props.deadline ? (
              props.deadline === "overdue" ? (
                <p className="modal__list__text modal__list__text--4">*This task is overdue for delivery.</p>
              ) : (
                <p className="modal__list__text modal__list__text--4">
                  *This task is due for delivery under the next hour.
                </p>
              )
            ) : null}

            {props.children}

            <div className="form-control--user-comments">
              <div className="comment">
                {entity.result?.comment ? (
                  <div className="comment-body">
                    <p className="comment-body__header"> Results Comment </p>
                    <p className="comment-body__footer"> {entity.result.comment} </p>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="modal__footer">{props.footer}</div>
      </BtModal>
    </>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  showDuedate: PropTypes.bool,
  dueDate: PropTypes.string,
  patientName: PropTypes.string,
  footer: PropTypes.node,
  handleDayChange: PropTypes.func,
  _id: PropTypes.any,
  img: PropTypes.string,
  isOpen: PropTypes.bool,
  viewCheckBox: PropTypes.bool,
  onCommentClick: PropTypes.func,
  orderId: PropTypes.string,
  referredPatient: PropTypes.string,
  selectValue: PropTypes.array,
  service: PropTypes.string,
  showComment: PropTypes.bool,
  showDeadline: PropTypes.bool,
  newDueDate: PropTypes.object,
  entity: PropTypes.object,
  name: PropTypes.string,
  handleServiceCheck: PropTypes.func,
  toggle: PropTypes.func,
  client: PropTypes.string,
  createdAt: PropTypes.string,
  deadline: PropTypes.string,
  services: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  files: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  comments: PropTypes.array,
  patient: PropTypes.object,
  company: PropTypes.object,
  result: PropTypes.object,
  allowDateEdit: PropTypes.bool,
  changeDueDate: PropTypes.func,
  isSubmitting: PropTypes.bool,
};
