import frontDeskSVG from 'assets/svg/front-desk.svg';
import nurseSVG from 'assets/svg/nurse.svg';
import doctorSVG from 'assets/svg/doctor.svg';
import labsSVG from 'assets/svg/labs.svg';
import adminSVG from 'assets/svg/adminSettings.svg';
import inventorySVG from 'assets/svg/inventoryImage.svg';
import reportingSVG from 'assets/svg/reporting.svg';

export const modules = [
  {
    _id: 1,
    avatar: frontDeskSVG,
    title: 'Front Desk',
    key: 'frontdesk',
    subtitle: 'Manage all patients registration, appointment bookings and information',
    link: '/frontdesk',
  },
  {
    _id: 2,
    avatar: nurseSVG,
    title: 'OutPatients',
    key: 'out-patient',
    subtitle: 'Manage all consultation patients',
    link: '/out-patient',
  },
  {
    _id: 3,
    avatar: doctorSVG,
    title: 'Doctor',
    key: 'doctor',
    subtitle: 'attend to patients and understand their health challenges',
    link: '#',
  },
  {
    _id: 4,
    avatar: labsSVG,
    title: 'Laboratory',
    key: 'laboratory',
    subtitle: 'Manages all laboratory tests and results',
    link: '/laboratory',
  },
  {
    _id: 5,
    avatar: nurseSVG,
    title: 'Pharmacy',
    key: 'pharmacy',
    subtitle: 'Manage all Pharmacy items',
    link: '/pharmacy',
  },
  {
    _id: 6,
    avatar: inventorySVG,
    title: 'Inventory',
    key: 'inventory',
    subtitle: 'Manage the hospital inventory',
    link: '/inventory',
  },
  {
    _id: 7,
    avatar: adminSVG,
    title: 'Admin Mgt',
    key: 'admin',
    subtitle: 'Manage the Activities of the hospital',
    link: '/settings',
  },
  {
    _id: 8,
    avatar: adminSVG,
    title: 'Central Store',
    key: 'admin',
    subtitle: 'Manage the InventorySettings items of the hospital',
    link: '/inventory',
  },
  {
    _id: 9,
    avatar: nurseSVG,
    title: 'In Patients',
    key: 'in-patient',
    subtitle: 'Manage all consultation patients',
    link: '/in-patient',
  },
  {
    _id: 10,
    avatar: doctorSVG,
    title: 'Billing',
    key: 'billing',
    subtitle: 'Manage all Finances',
    link: '/billing',
  },
  {
    _id: 11,
    avatar: labsSVG,
    title: 'Admission',
    key: 'admission',
    subtitle: 'Manage all Admission items',
    link: '#',
  },
  {
    _id: 12,
    avatar: reportingSVG,
    title: 'Reporting',
    key: 'report',
    subtitle: 'Manage the staff and hospital activities',
    link: '/report',
  },
  {
    _id: 13,
    avatar: reportingSVG,
    title: 'Reporting',
    key: 'admin',
    subtitle: 'Manage the staff and hospital activities',
    link: '/report',
  },
  {
    _id: 14,
    avatar: reportingSVG,
    title: 'Audit Trail',
    key: 'admin',
    subtitle: 'View and Manage Audit Trails in the System',
    link: '/audit-trail',
  },
];

export const medicalStandards = [
  {
    _id: 1,
    url: '/symptoms',
    avatar: frontDeskSVG,
    title: 'symptoms',
    subtitle: 'Manage International Classification of Symptoms',
  },
  {
    _id: 2,
    url: '/diagnosis',
    avatar: labsSVG,
    title: 'diagnosis',
    subtitle: 'Create and Manage diagnosis information for your clinical records',
  },
];
