/* eslint-disable no-empty-function */
import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as SearchSvg } from "assets/svg/search.svg";

export const SearchEntity = (props) => {
  return (
    <div className="dashboard-search-bar">
      <SearchSvg />
      <input
        type="text"
        className="dashboard-search-bar__bar"
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}
      />
    </div>
  );
};

SearchEntity.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
};
