import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/ui/buttons/button';

import { Modal } from './index';

const Footer = (props) => (
  <>
    <Button
      disabled={props.leftButtonDisabled}
      onClick={props.leftButtonPress}
      className="modal__btn modal__btn--decline"
    >
      Decline
    </Button>
    <Button
      disabled={props.rightButtonDisabled}
      onClick={props.rightButtonPress}
      className="modal__btn modal__btn--accept"
    >
      Accept
    </Button>
  </>
);

Footer.propTypes = {
  leftButtonPress: PropTypes.func,
  leftButtonDisabled: PropTypes.bool,
  rightButtonPress: PropTypes.func,
  rightButtonDisabled: PropTypes.bool,
};

export const TodoModal = ({ ...props }) => (
  <Modal {...props} color="blue" showDuedate={false} footer={<Footer {...props} />} />
);
