/* eslint-disable max-len */
import React from 'react';

export const PageLoader = () => (
  <div className="page-loader">
    <svg
      id="Layer_1"
      data-name="Layer 1"
      className="page-loader__logo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 132.47 102.28"
    >
      <path
        className="shape shape__left"
        d="M314.33,326.62c3.6-24.37-7.54-45-23.37-50-10-3.19-19.64.57-24.2,2.79-1.29,1.4-9.21,10.3-7.87,23.05a30,30,0,0,0,18.39,24.2h37Z"
        transform="translate(-258.74 -224.34)"
      />

      <path
        className="shape shape__right"
        d="M335.62,326.62c-3.6-24.37,7.54-45,23.37-50,10-3.19,19.64.57,24.2,2.79,1.29,1.4,9.21,10.3,7.87,23.05a30,30,0,0,1-18.39,24.2h-37Z"
        transform="translate(-258.74 -224.34)"
      />

      <path
        className="shape shape__top"
        d="M324.58,224.34c-20.51,0-37.14,13.16-37.14,29.39,0,14.92,14.06,27.23,32.26,29.13a10.1,10.1,0,1,1,9.77,0c18.21-1.89,32.26-14.21,32.26-29.13C361.72,237.49,345.09,224.34,324.58,224.34Z"
        transform="translate(-258.74 -224.34)"
      />
    </svg>

    <div className="page-loader__text">
      <div>Loading</div>
      <div className="page-loader__dot" /> <div className="page-loader__dot" />
      <div className="page-loader__dot" />
    </div>
  </div>
);
